import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Form } from '3map-models';
import { EnabledUserForm } from '../../containers/user-forms/user-forms.component';

@Component({
  selector: 'app-form-picker',
  template: `
    <div class="form-picker-wrapper" *ngIf="userFormList">
      <div class="label">Select Forms</div>
      <div class="checkbox-list">
        <ui-checkbox
          *ngFor="let form of userFormList"
          [checked]="form.enabled"
          [label]="form.form.name"
          (click)="toggleForm.emit(form.form)"
        />
      </div>
    </div>
  `,
  styles: [
    `
      .label {
        margin: 10px 0;
      }
      .checkbox-list {
        max-height: 200px;
        overflow: auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPickerComponent {
  @Input() userFormList?: EnabledUserForm[];
  @Output() toggleForm = new EventEmitter<Form>();
}
