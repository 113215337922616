import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

export type KeyPressEvent = 'Escape' | 'ArrowLeft' | 'ArrowRight';

@Directive({
  selector: '[appKeyboardListener]',
})
export class KeyboardListenerDirective {
  @Output() keyPress: EventEmitter<KeyPressEvent> =
    new EventEmitter<KeyPressEvent>();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const key = event.key;
    if (key === 'Escape' || key === 'ArrowLeft' || key === 'ArrowRight')
      this.keyPress.emit(key);
  }
}
