import { createAction, props } from '@ngrx/store';
import { WebHook } from '3map-models';

export const UPSERT_WEBHOOK = createAction(
  '[ Project ] Upsert WebHook ',
  props<{ webHook: WebHook }>()
);

export const REMOVE_WEBHOOK = createAction(
  '[ Project ] Remove WebHook',
  props<{ webHook: WebHook }>()
);
