import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Resource } from '3map-models';

@Component({
  selector: 'app-resource-name[resource]',
  template: `
    <div class="label">Resource Name</div>
    <input
      #nameInput
      [value]="resource.name"
      (input)="nameChange.emit(nameInput.value)"
      type="text"
    />
  `,
  styles: [
    `
      .label {
        font-size: 0.9em;
        margin: 20px 0 5px 0;
      }

      input {
        width: 300px;
        margin-right: 30px;
      }
    `,
  ],
})
export class ResourceNameComponent {
  @Input() resource!: Resource;
  @Output() nameChange: EventEmitter<string>;

  constructor() {
    this.nameChange = new EventEmitter<string>();
  }
}
