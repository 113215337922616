import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCol from './collaboration.reducer';

export const selectCollaborationState = createFeatureSelector<fromCol.State>(
  fromCol.FEATURE_KEY
);

export const selectCollaborations = () =>
  createSelector(selectCollaborationState, (state) => state.collaborations);

export const selectAdminCollaborations = () =>
  createSelector(selectCollaborationState, (state) =>
    state.collaborations.filter((col) => col.role === 'ADMIN')
  );

export const selectFetchPending = () =>
  createSelector(selectCollaborationState, (state) => state.fetchPending);

export const selectFetchError = () =>
  createSelector(selectCollaborationState, (state) => state.fetchError);

export const selectCreatePending = () =>
  createSelector(selectCollaborationState, (state) => state.createPending);

export const selectCreateError = () =>
  createSelector(selectCollaborationState, (state) => state.createError);

export const selectNewProjectName = () =>
  createSelector(selectCollaborationState, (state) => state.newProjectName);
