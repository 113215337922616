import { Action, createReducer, on } from '@ngrx/store';
import {
  deployMarkers,
  deployMarkersError,
  deployMarkersSuccess,
  deployProject,
  deployProjectError,
  deployProjectSuccess,
  deployResources,
  deployResourcesError,
  deployResourcesSuccess,
  reset,
  validateRecords,
  validateRecordsError,
  validateRecordsSuccess,
} from './deploy.actions';
import { DeployStatus } from '../models/DeployStatus';
import { Record } from '3map-models';
// import { AdminActions } from '../../+state';
import * as AdminActions from '../../+state/admin.actions';

export const ADMIN_DEPLOY_FEATURE_KEY = 'admin-deploy';

export interface State {
  records: DeployStatus;
  project: DeployStatus;
  markers: DeployStatus;
  resources: DeployStatus;
  invalidRecords: Record[];
}

export const initialState: State = {
  records: 'STAGED',
  project: 'STAGED',
  markers: 'STAGED',
  resources: 'STAGED',
  invalidRecords: [],
};

// TODO handle errors (keep records list)
const deployReducer = createReducer(
  initialState,
  on(
    reset,
    AdminActions.close,
    AdminActions.initProjectSuccess,
    AdminActions.initProjectError,
    (): State => ({ ...initialState })
  ),
  on(validateRecords, (state): State => ({ ...state, records: 'PENDING' })),
  on(validateRecordsSuccess, (state, { invalidRecords }): State => {
    const status = invalidRecords.length === 0 ? 'DEPLOYED' : 'ERROR';
    return { ...state, records: status, invalidRecords };
  }),
  on(validateRecordsError, (state): State => ({ ...state, records: 'ERROR' })),
  on(deployProject, (state): State => ({ ...state, project: 'PENDING' })),
  on(
    deployProjectSuccess,
    (state): State => ({ ...state, project: 'DEPLOYED' })
  ),
  on(deployProjectError, (state): State => ({ ...state, project: 'ERROR' })),
  on(deployMarkers, (state): State => ({ ...state, markers: 'PENDING' })),
  on(
    deployMarkersSuccess,
    (state): State => ({ ...state, markers: 'DEPLOYED' })
  ),
  on(deployMarkersError, (state): State => ({ ...state, markers: 'ERROR' })),
  on(deployResources, (state): State => ({ ...state, resources: 'PENDING' })),
  on(
    deployResourcesSuccess,
    (state): State => ({ ...state, resources: 'DEPLOYED' })
  ),
  on(
    deployResourcesError,
    (state): State => ({ ...state, resources: 'PENDING' })
  )
);

export function reducer(state: State | undefined, action: Action): State {
  return deployReducer(state, action);
}
