import { FieldType, Record } from '3map-models';
import { hash } from '@trim-web-apps/core';
// import * as moment from 'moment';
import * as momentTz from 'moment-timezone';

export function recordToRow(
  record: Record,
  fieldTypeList: FieldType[],
  specificMap: Map<string, string>
): any {
  const questionMap: { [questionId: string]: string } = {};
  for (const ft of fieldTypeList) {
    questionMap[ft.id] = ft.name;
  }
  const recordData: any = record.data;
  const row: any = {
    ...record,
    datetimeUtc: momentTz.utc(record.datetimeUtc),
    datetimeLocal: momentTz.utc(record.datetimeUtc).tz(record.zone),
    formSpecificName: specificMap.get(record.formSpecificId) || '---',
  };
  Object.keys(recordData as any).map((questionId) => {
    const question = recordData[questionId];
    const key = hash(questionMap[questionId]);
    row[key] = extractQuestionValue(question);
  });
  delete row.data;
  return row;
}

// 925de22b-99f2-45c4-a13f-88785e78f2ca	Vehicles	fede-test	1643895646372	1643895646372	Europe/Rome											Bike	BMW	app bike
function extractQuestionValue(question: any): any {
  switch (question.type) {
    case 'TEXT':
    case 'LIST':
      return question.text;
    case 'CHECK':
      return question.checked.join(', ');
    case 'NUMBER':
      return question.value;
    case 'RANGE':
      return `${question.min} - ${question.max}`;
    case 'INT':
      return question.valueInt;
    case 'DATE':
      return momentTz.utc(question.date);
    default:
      return 'UNKNOWN QUESTION TYPE';
  }
}
