import { createSelector } from '@ngrx/store';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { entityToResource } from './resource.entity';
import * as fromRoot from '../project.selectors';

export const selectState = createSelector(
  fromRoot.selectProjectState,
  (state) => state.resource
);

export const selectEntityResourceList = () =>
  createSelector(selectState, (state) => {
    const entities = state.entities;
    return Object.keys(entities)
      .map((k) => entities[k])
      .filter(notNullOrUndefined);
  });

export const selectEntityResourceById = (id: string) =>
  createSelector(selectState, (state) => {
    return state.entities[id];
  });

export const selectResourceById = (id: string) =>
  createSelector(selectEntityResourceById(id), (entity) =>
    entity ? entityToResource(entity) : null
  );

export const selectResourceList = () =>
  createSelector(selectEntityResourceList(), (entities) =>
    entities.map((e) => entityToResource(e)).filter(notNullOrUndefined)
  );

export const selectResourceToDeploy = () =>
  createSelector(selectEntityResourceList(), (resources) =>
    resources.filter(
      (resource) => resource.resourceData !== null && resource.filename !== ''
    )
  );
