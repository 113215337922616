import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StateDebugComponent } from './state-debug/state-debug.component';
import { MarkerIconComponent } from './ui/marker-icon/marker-icon.component';
import { CdkMenuModule } from '@angular/cdk/menu';
import { RecordIconComponent } from './ui/record-icon/record-icon.component';
import { SpecificIconComponent } from './ui/specific-icon/specific-icon.component';
import { MediaCardComponent } from './ui/media-card/media-card.component';
import { RecordCardHeaderComponent } from './ui/record-card-header/record-card-header.component';
import { ConfirmDialogComponent } from './ui/confirm-dialog/confirm-dialog.component';
import { CoreModule } from '@trim-web-apps/core';
import { FormSpecificListComponent } from './ui/form-specific-list/form-specific-list.component';
import { KeyboardListenerDirective } from './directives/keyboardListener/keyboard-listener.directive';
import { Map3UiModule } from '@trim-web-apps/map3-ui';

@NgModule({
  declarations: [
    StateDebugComponent,
    MarkerIconComponent,
    RecordIconComponent,
    SpecificIconComponent,
    MediaCardComponent,
    RecordCardHeaderComponent,
    ConfirmDialogComponent,
    FormSpecificListComponent,
    KeyboardListenerDirective,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    CdkMenuModule,
    CoreModule,
    Map3UiModule,
  ],
  exports: [
    StateDebugComponent,
    MarkerIconComponent,
    SpecificIconComponent,
    RecordIconComponent,
    MediaCardComponent,
    RecordCardHeaderComponent,
    ConfirmDialogComponent,
    FormSpecificListComponent,
    KeyboardListenerDirective,
  ],
})
export class SharedModule {}
