import * as mapboxgl from 'mapbox-gl';

class SetZoomControl {
  private map: mapboxgl.Map | undefined;
  private container!: HTMLElement;
  private iconBox!: HTMLElement;
  private inputBox!: HTMLElement;
  private input!: HTMLInputElement;
  private btnSet!: HTMLElement;

  onAdd(map: mapboxgl.Map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'set-zoom-control mapboxgl-ctrl';
    this.createIconBox();
    this.createInputBox();

    // append elements
    this.container.appendChild(this.inputBox);
    this.container.appendChild(this.iconBox);

    // bind events - map.on() should close the controls on map click
    this.iconBox.addEventListener('click', this.toggleInput);
    this.btnSet.addEventListener('click', this.setZoom);
    this.map.on('click', this.closeOnMapClick);
    this.map.on('zoomend', this.onZoomEnd);

    return this.container;
  }

  onRemove() {
    // unbind events
    this.iconBox.removeEventListener('click', this.toggleInput);
    this.btnSet.removeEventListener('click', this.setZoom);
    this.map?.off('click', this.closeOnMapClick);
    this.map?.off('click', this.onZoomEnd);

    // remove control
    this.container.parentNode?.removeChild(this.container);
    this.map = undefined;
  }

  /**
   * Create a div element for SVG icon (see `set-zoom-control.css`)
   */
  private createIconBox(): void {
    this.iconBox = document.createElement('div');
    this.iconBox.className = 'set-control-zoom-icon';
  }

  /**
   * Create input and button container.
   * By default, input has the current zoom value rounded to 3 decimals
   */
  private createInputBox(): void {
    this.inputBox = document.createElement('div');
    this.inputBox.className = 'set-control-zoom-input-box closed';

    this.btnSet = document.createElement('div');
    this.btnSet.className = 'set-control-zoom-btn';
    this.btnSet.textContent = 'Set zoom';

    this.input = document.createElement('input');
    this.input.className = 'set-control-zoom-input';
    this.input.addEventListener('keyup', (e) => {
      if (e.key === 'Enter') {
        this.setZoom();
      }
    });

    this.inputBox.appendChild(this.input);
    this.inputBox.appendChild(this.btnSet);
    this.setInputValueFromMap();
  }

  /**
   * Open/close `inputBox` by adding/removing `closed` class (see `set-zoom-control.css`)
   */
  private toggleInput = () => {
    this.inputBox.classList.contains('closed')
      ? this.openInputBox()
      : this.closeInputBox();
  };

  /**
   * Handle Map click Event and close `inputBox` (if open).
   */
  private closeOnMapClick = () => {
    if (!this.inputBox.classList.contains('closed')) {
      this.closeInputBox();
    }
  };

  private setInputValueFromMap(): void {
    if (this.map) {
      this.input.value = (
        Math.round(this.map.getZoom() * 1000) / 1000
      ).toString();
    }
  }

  /**
   * Just open/show the `inputBox` and set current map zoom
   */
  private openInputBox(): void {
    this.setInputValueFromMap();
    this.inputBox.classList.remove('closed');
  }

  /**
   * Close input box
   */
  private closeInputBox(): void {
    this.inputBox.classList.add('closed');
  }

  /**
   * Handle click event on `btnSet`
   * Set map zoom only if value is a number and between global min/max zoom settings
   */
  private setZoom = (): void => {
    if (this.map) {
      const zoom = parseFloat(this.input.value);
      if (
        !isNaN(zoom) &&
        zoom >= this.map.getMinZoom() &&
        zoom <= this.map.getMaxZoom()
      ) {
        this.map.setZoom(zoom);
      }
    }
  };

  /**
   * Update input box with current zoom value on zoomEnd Event
   * [ NOTE: zoom value is rounded to 3 decimals ]
   */
  private onZoomEnd = (): void => {
    const mapZoom = this.map?.getZoom() || null;
    if (mapZoom !== null) {
      const rounded = Math.round(mapZoom * 1000) / 1000;
      this.input.value = rounded.toString();
    }
  };
}

export const zoomControl = new SetZoomControl();
