import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';
import { WIND_KTS } from '../../weather-layers/weather.layers';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createAladin(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 73, 3600, '+00:00');
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[48],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 3600 };

  const aladinLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'ALADIN',
    label: 'ALADIN',
    category: 'FORECAST',
    layers: aladinLayers,
    initime: aladinLayers[0].timestepList[0].initime,
    timestep: aladinLayers[0].timestepList[0].timesteps[0],
    selectedLayerId: aladinLayers[0].id,
    dateRange: defaultDateRng,
    // todo this is wrong (?)
    bbox: [
      [-10.005, 37.495002],
      [-10.005, 51.005002],
      [16.005, 51.005002],
      [16.005, 37.495002],
      [-10.005, 37.495002],
    ],
  };
}
