/**
 * Search component with a simple input field (text).
 * When input value changes, a request to Nominatim API is made (see {@link NominatimService.search})
 * and response is emitted from `location` EventEmitter.
 * Note: response might be null (e.g. location does not exists).
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NominatimService } from '../nominatim.service';
import { FormControl } from '@angular/forms';
import { debounceTime, of, Subscription, switchMap, tap } from 'rxjs';
import { NominatimResponse } from '../nominatim-response.type';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import {
  faLocationCrosshairs,
  faMagnifyingGlass,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'nominatim-search',
  template: `
    <div class="search-wrapper">
      <div class="icon-left">
        <fa-icon *ngIf="!locationInput.value" [icon]="iconSearch"></fa-icon>
        <fa-icon
          *ngIf="locationInput.value"
          [icon]="iconClear"
          (click)="locationInput.value = ''; clear()"
          class="cursor-pointer"
        ></fa-icon>
      </div>
      <input
        placeholder="Search location"
        #locationInput
        type="text"
        [formControl]="locationForm"
        [class.error]="hasError"
      />
      <div class="icon-right">
        <fa-icon
          [icon]="iconGoTo"
          *ngIf="locationInput.value && !hasError"
          (click)="zoomToLocation.emit()"
          class="cursor-pointer"
        ></fa-icon>
      </div>
    </div>
  `,
  styles: [
    `
      .search-wrapper {
        display: flex;
        align-items: center;
      }

      .icon-left,
      .icon-right {
        width: 25px;
        font-size: 1.2em;
        padding: 0 5px;
      }

      input {
        border: none;
        outline: none;
        box-shadow: none;
      }

      .error {
        color: var(--error-color, red);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  @Output() location: EventEmitter<NominatimResponse | null>;
  @Output() zoomToLocation: EventEmitter<void>;
  locationForm: FormControl;
  iconSearch: IconDefinition = faMagnifyingGlass;
  iconClear: IconDefinition = faTimes;
  iconGoTo: IconDefinition = faLocationCrosshairs;
  hasError?: boolean;

  private sub?: Subscription;

  constructor(private nominatim: NominatimService) {
    this.locationForm = new FormControl('');
    this.location = new EventEmitter<NominatimResponse | null>();
    this.zoomToLocation = new EventEmitter<void>();
  }

  ngOnInit(): void {
    this.sub = this.locationForm.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((input) => {
          return !input || input.length < 3
            ? of(null)
            : this.nominatim
                .search(input)
                .pipe(tap((response) => (this.hasError = response === null)));
        })
      )
      .subscribe((location) => this.emitLocation(location));
  }

  emitLocation(location: NominatimResponse | null): void {
    this.location.emit(location);
  }

  clear(): void {
    this.hasError = false;
    this.emitLocation(null);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
