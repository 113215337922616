import { createSelector } from '@ngrx/store';
import { adapter } from './marker-style.reducer';
import { MarkerStyle } from '3map-models';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { MarkerStyleEntity } from './marker-style.entity';
import { selectAppAdminState } from '../../admin.reducer.map';

// const selectState = createFeatureSelector<State>(
//   ADMIN_MARKER_STYLE_FEATURE_KEY
// );

export const selectState = () =>
  createSelector(selectAppAdminState(), (state) => state.markerStyle);

export const { selectAll } = adapter.getSelectors();

export const selectEntity = (specificId: string) =>
  createSelector(selectState(), (state): MarkerStyleEntity | undefined => {
    return state.entities[specificId];
  });

export const selectMarkerStyle = (specificId: string) =>
  createSelector(selectEntity(specificId), (entity): MarkerStyle | null => {
    return entityToMarkerStyle(entity);
  });

export const selectEntities = () =>
  createSelector(selectState(), (state) => state.entities);

export const selectEntitiesArray = () =>
  createSelector(selectState(), (state) =>
    Object.keys(state.entities)
      .map((k) => state.entities[k])
      .filter(notNullOrUndefined)
  );

export const selectMarkerStyleList = () =>
  createSelector(selectAll, (entities): Array<MarkerStyle | null> => {
    return entities.map((e) => entityToMarkerStyle(e));
  });

export const selectImgBase64List = (specificId: string) =>
  createSelector(
    selectEntity(specificId),
    (entity) => entity?.imgBase64List || []
  );

export const selectMarkerToUpload = () =>
  createSelector(selectEntitiesArray(), (entities) =>
    entities.filter((e) => e.imgBase64List.length > 0)
  );

export function entityToMarkerStyle(
  entity: MarkerStyleEntity | undefined
): MarkerStyle | null {
  if (entity) {
    const markerStyle: MarkerStyle = {
      image: entity.image,
      imageNumber: entity.imageNumber,
    };

    if (entity.iconFieldType) markerStyle.iconFieldType = entity.iconFieldType;
    if (entity.iconNumberRanges)
      markerStyle.iconNumberRanges = entity.iconNumberRanges;

    return markerStyle;
  }

  return null;
}
