import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  faChevronDown,
  faChevronUp,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sidenav-item',
  template: `
    <div
      class="nav-item"
      [class.active]="active"
      [class.black]="expanded && sidenavOpen"
      (click)="toggleExpand()"
    >
      <div class="icon">
        <fa-icon [spin]="spin" *ngIf="icon" [icon]="icon" />
      </div>
      <div *ngIf="label && sidenavOpen" class="label">{{ label }}</div>
      <fa-icon
        class="icon-toggle"
        *ngIf="expandable && sidenavOpen"
        [icon]="expanded ? iconExpand : iconCollapse"
      />
    </div>

    <div class="content" *ngIf="expandable && expanded && sidenavOpen">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .nav-item {
        padding: var(--spacing-2) var(--spacing-1);
        display: flex;
        border-radius: var(--radius-2);
        align-items: center;
        cursor: pointer;
        /*border: 1px solid red;*/
      }

      .content {
        /*border: 1px solid blue;*/
        margin: 0 var(--spacing-4);
        margin-bottom: var(--spacing-3);
      }

      .icon,
      .label,
      .icon-toggle {
        color: var(--grey);
        padding: 0 var(--spacing-2);
      }

      .nav-item.active .icon,
      .nav-item.active .label {
        color: var(--bg-color) !important;
      }

      .active {
        background-color: var(--primary-color);
      }

      .icon {
        font-size: var(--font-size-3);
        display: flex;
        justify-content: center;
        width: var(--spacing-4);
      }

      .icon-toggle {
        font-size: var(--font-size-2);
      }

      .label {
        font-weight: var(--font-weight-3);
        flex: 1 1 auto;
      }

      .icon:hover,
      .label:hover,
      .nav-item:hover .icon,
      .nav-item:hover .icon-toggle,
      .nav-item:hover .label,
      .nav-item.black .icon,
      .nav-item.black .icon-toggle,
      .nav-item.black .label {
        color: var(--color-text);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavItemComponent {
  @Input() label?: string;
  @Input() sidenavOpen?: boolean;
  @Input() icon?: IconDefinition;
  @Input() active?: boolean;
  @Input() spin?: boolean;
  @Input() expandable?: boolean;
  @Input() expanded?: boolean;

  iconExpand = faChevronUp;
  iconCollapse = faChevronDown;

  toggleExpand(): void {
    if (!this.sidenavOpen) this.expanded = true;
    else this.expanded = !this.expanded;
  }
}
