import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {
  faImage,
  faMicrophone,
  faVideo,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Media } from '3map-models';
import { notNullOrUndefined } from '@trim-web-apps/core';

@Component({
  selector: 'map3-table-media-icon',
  template: `
    <div class="imageContainer" *ngIf="icons">
      <fa-icon
        (click)="openMediaGallery()"
        *ngFor="let icon of icons"
        class="cursor-pointer"
        [icon]="icon"
      ></fa-icon>
    </div>
  `,
  styles: [
    `
      .imageContainer {
        text-align: center;
        font-size: 1.3em;
      }

      fa-icon {
        padding: 0 5px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableMediaIconComponent implements ICellRendererAngularComp {
  @Input() params!: ICellRendererParams;
  iconCb?: (recordId: string, mediaIndex: number) => void;
  icons: IconDefinition[] | undefined;
  private recordId: string | undefined;
  private mediaList: Media[] | undefined;

  agInit(params: ICellRendererParams): void {
    this.mediaList = params?.data?.mediaList;
    this.recordId = params?.data?.recordId;
    this.iconCb = (params as any).iconCb;
    if (!this.recordId) return;
    if (!this.mediaList || !Array.isArray(this.mediaList)) return;
    this.icons = Array.from(
      new Set(
        this.mediaList
          .map((media) => {
            if (media.type === 'AUDIO') return faMicrophone;
            if (media.type === 'IMAGE') return faImage;
            if (media.type === 'VIDEO') return faVideo;
            return null;
          })
          .filter(notNullOrUndefined)
      )
    );
    // what??
    // mediaList.forEach((media) => {
    //   if (media.type === 'AUDIO') iconsMap[media.type] = faMicrophone;
    //   if (media.type === 'IMAGE') iconsMap[media.type] = faImage;
    //   if (media.type === 'VIDEO') iconsMap[media.type] = faVideo;
    // });
    // this.icons = Object.keys(iconsMap).map((key) => iconsMap[key]);
  }

  refresh(params: any): boolean {
    return false;
  }

  openMediaGallery(): void {
    if (
      this.recordId &&
      this.mediaList &&
      this.mediaList.length > 0 &&
      this.iconCb
    )
      this.iconCb(this.recordId, 0);
  }
}
