import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Link } from '3map-models';

@Component({
  selector: 'app-link-item',
  template: `
    <div class="link-item show-on-hover-toggle selectable-text" *ngIf="link">
      <div class="left text-ellipsis">
        <div class="link-name text-ellipsis">{{ link.name }}</div>
        <div class="link-url text-ellipsis">{{ link.url }}</div>
      </div>

      <div class="right">
        <div class=" show-on-hover">
          <core-double-confirm-inline (confirm)="remove.emit(link)" />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .link-item {
        border: 1px solid var(--border);
        border-radius: var(--radius-1);
        padding: var(--spacing-2);
        margin: var(--spacing-3) 0;
        display: flex;
        align-items: center;
      }

      .link-item:hover {
        background-color: var(--hover);
      }

      .left {
        flex: 1 1 auto;
      }

      .link-name {
        font-weight: var(--font-weight-3);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkItemComponent {
  @Input() link?: Link;
  @Output() remove = new EventEmitter<Link>();
}
