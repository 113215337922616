import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './weather.reducer';
import {
  createTiffUrl,
  timestepToLabel,
  timestepToMoment,
  timestepToTimeUnit,
} from '../utils/weather.functions';
import { TimeUnit, WeatherModel } from '@trim-web-apps/weather-models';
import { notNullOrUndefined } from '@trim-web-apps/core';

export const weatherState = createFeatureSelector<State>('weather');

export const popupState = () =>
  createSelector(weatherState, (state) => state.popups);

export const modelState = () =>
  createSelector(weatherState, (state) => state.models);

export const selectSelectedLngLat = () =>
  createSelector(weatherState, (state) => state.selectedLngLat);

export const selectUtcOffset = () =>
  createSelector(weatherState, (state) => state.utcOffset);

export const selectMapBoundingArray = () =>
  createSelector(weatherState, (state) => state.mapBoundingArray);

export const selectTiffUrl = (modelId: string) =>
  createSelector(weatherState, selectById(modelId), (state, model) =>
    model ? createTiffUrl(model, state.mapBoundingArray) : null
  );

export const selectEntities = () =>
  createSelector(modelState(), (state) => state.entities);

export const selectById = (modelId: string) =>
  createSelector(selectEntities(), (modelDict) => modelDict[modelId] || null);

export const selectAll = () =>
  createSelector(modelState(), (state) =>
    Object.keys(state.entities).map((k) => state.entities[k] as WeatherModel)
  );

export const selectModelsWithChartEnabled = () =>
  createSelector(selectAll(), (models) =>
    models.filter((m) => m.modelEnabled && m.chartEnabled)
  );

export const selectChartData = (modelId: string) =>
  createSelector(selectById(modelId), (model) => {
    if (!model) return [];
    return model.chartData.map((data) => {
      return model.chartMode === 'compact'
        ? {
            ...data,
            labels: data.labels.map((label) =>
              (label as any).map((l: string) => `  ${l}  `)
            ),
            chartHeight: 150,
          }
        : { ...data, labels: data.labels.map((l) => `${l[1] || ''} ${l[0]}`) };
    });
  });

export const selectWeatherModelsByCategory = () =>
  createSelector(selectAll(), (modelList) => {
    const forecastList = modelList.filter((m) => m.category === 'FORECAST');
    const monitoringList = modelList.filter((m) => m.category === 'MONITORING');
    return { forecastList, monitoringList };
  });

export const selectEnabledWeatherModels = () =>
  createSelector(selectAll(), (models) => models.filter((m) => m.modelEnabled));

export const selectInitimeList = (modelId: string) =>
  createSelector(
    selectById(modelId),
    (model) => model?.layers[0].timestepList.map((t) => t.initime) || []
  );

export const selectLayer = (modelId: string) =>
  createSelector(selectById(modelId), (model) => {
    const layers = model?.layers || [];
    const layer = layers.find((l) => l.id === model?.selectedLayerId);
    return layer || null;
  });

export const selectFullTimestepList = (modelId: string) =>
  createSelector(selectById(modelId), selectLayer(modelId), (model, layer) => {
    if (!model || !layer) return [];
    const tsList = layer.timestepList.find(
      (ts) => ts.initime === model.initime
    );
    return tsList?.timesteps || [];
  });

export const selectTimestepList = (modelId: string) =>
  createSelector(
    selectById(modelId),
    selectFullTimestepList(modelId),
    (model, tsList) => {
      if (!model) return [];
      return tsList.filter(
        (t: number) => t >= model.dateRange.from && t <= model.dateRange.to
      );
    }
  );

export const selectTimestepListAsTimeUnit = (modelId: string) =>
  createSelector(
    weatherState,
    selectById(modelId),
    selectLayer(modelId),
    selectTimestepList(modelId),
    (appState, model, layer, tsList) => {
      if (!model || !layer) return [];
      return tsList.map(
        (t: number): TimeUnit => ({
          apiValue: t,
          label: timestepToLabel(t, model.initime, layer, appState.utcOffset),
          asDate: timestepToMoment(t, model.initime, layer, appState.utcOffset),
        })
      );
    }
  );

export const selectTimestepFullListAsTimeUnit = (modelId: string) =>
  createSelector(
    weatherState,
    selectById(modelId),
    selectLayer(modelId),
    selectFullTimestepList(modelId),
    (appState, model, layer, tsList) => {
      if (!model || !layer) return [];
      return tsList.map(
        (t: number): TimeUnit => ({
          apiValue: t,
          label: timestepToLabel(t, model.initime, layer, appState.utcOffset),
          asDate: timestepToMoment(t, model.initime, layer, appState.utcOffset),
        })
      );
    }
  );

export const selectMinMaxTimestepAsTimeUnit = (modelId: string) =>
  createSelector(
    weatherState,
    selectById(modelId),
    selectLayer(modelId),
    selectFullTimestepList(modelId),
    (appState, model, layer, tsList) => {
      if (!model || !layer) return null;
      return {
        min: timestepToTimeUnit(
          tsList[0],
          model.initime,
          layer,
          appState.utcOffset
        ),
        max: timestepToTimeUnit(
          tsList[tsList.length - 1],
          model.initime,
          layer,
          appState.utcOffset
        ),
      };
    }
  );

export const selectDateRangeAsTimeUnit = (modelId: string) =>
  createSelector(
    weatherState,
    selectById(modelId),
    selectLayer(modelId),
    (appState, model, layer) => {
      if (!model || !layer) return null;
      return {
        from: timestepToTimeUnit(
          model.dateRange.from,
          model.initime,
          layer,
          appState.utcOffset
        ),
        to: timestepToTimeUnit(
          model.dateRange.to,
          model.initime,
          layer,
          appState.utcOffset
        ),
      };
    }
  );

export const selectDatePickerInlineData = (modelId: string) =>
  createSelector(
    weatherState,
    selectById(modelId),
    selectLayer(modelId),
    selectDateRangeAsTimeUnit(modelId),
    selectTimestepFullListAsTimeUnit(modelId),
    (appState, model, layer, dateRange, tsList) => {
      return layer && tsList && dateRange && model
        ? {
            ...dateRange,
            tsList,
            model,
            layer,
            initime: model.initime,
            utcOffset: appState.utcOffset,
          }
        : null;
    }
  );

/** POPUP SELECTORS */

export const selectAllPopupData = () =>
  createSelector(popupState(), selectSelectedLngLat(), (popups, lngLat) => {
    const popupDataList = popups.ids
      .map((id) => popups.entities[id])
      .filter(notNullOrUndefined);
    return {
      lngLat,
      popupDataList,
    };
  });
