import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionLabelComponent } from './components/section-label/section-label.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NameUrlDialogComponent } from './components/name-url-dialog/name-url-dialog.component';
import { FormsModule } from '@angular/forms';
import { SidenavItemComponent } from './components/sidenav-item/sidenav-item.component';
import { MarkerCanvasComponent } from './components/marker-canvas/marker-canvas.component';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SectionLabelComponent,
    SidenavComponent,
    NameUrlDialogComponent,
    SidenavItemComponent,
    MarkerCanvasComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    MatTooltipModule,
    FormsModule,
    Map3UiModule,
  ],
  exports: [SidenavComponent, SectionLabelComponent, MarkerCanvasComponent],
})
export class SharedModule {}
