import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {
  faCloudUploadAlt,
  faCogs,
  faLink,
  faMapMarkedAlt,
  faPollH,
  faRetweet,
  faShare,
  faSignOutAlt,
  faUsers,
  faVectorSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { AdminSelectors } from '../../../+state';
import { Project } from '3map-models';

@Component({
  selector: 'app-sidenav',
  template: `
    <div class="sidenav-container">
      <div class="logo-nav-item-wrapper">
        <div class="logo">
          <img src="assets/3map-project/trim_white_simple.png" alt="logo" />
        </div>

        <div class="project-name" *ngIf="project$ | async as project">
          {{ project.name }}
        </div>
      </div>

      <div class="nav-items">
        <app-sidenav-item
          *ngFor="let item of sectionList"
          [label]="item.label"
          [icon]="item.icon"
          [active]="activeSection === item.label"
          [routerLink]="item.path"
          (click)="activeSection = item.label"
        />

        <div class="span fill-remaining-space"></div>
        <div class="separator"></div>

        <app-sidenav-item
          *ngFor="let item of actionList"
          [label]="item.label"
          [icon]="item.icon"
          [routerLink]="item.path"
        />
      </div>
    </div>
  `,
  styles: [
    `
      .sidenav-container {
        background-color: var(--bg-color);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        display: flex;
        flex-direction: column;
        height: 100vh;
        z-index: 5;
        width: 300px;
      }

      .project-name {
        display: flex;
        justify-content: right;
        font-size: var(--font-size-2);
        color: var(--bg-color);
        font-weight: var(--font-weight-3);
        padding: 0 var(--spacing-3);
      }

      .logo-nav-item-wrapper {
        padding: var(--spacing-3);
        background-color: var(--primary-color);
        color: var(--bg-color);
      }

      .logo {
        margin-bottom: var(--spacing-3);
      }

      .logo img {
        height: 30px;
        cursor: pointer;
      }

      .nav-items {
        display: flex;
        flex-direction: column;
        padding: var(--spacing-2);
        height: 100%;
        overflow: hidden;
      }

      .separator {
        border-bottom: 2px solid #eeeeee;
        margin: var(--spacing-3) 0;
      }
    `,
  ],
})
export class SidenavComponent {
  project$: Observable<Project | null>;
  activeSection = 'Forms Collections';

  sectionList: { label: string; path: string; icon: any }[] = [
    { label: 'Deploy Project', path: 'deploy', icon: faCloudUploadAlt },
    { label: 'Forms Collections', path: 'forms', icon: faPollH },
    { label: 'Users', path: 'users', icon: faUsers },
    { label: 'Web Hooks', path: 'webhooks', icon: faShare },
    { label: 'Links', path: 'links', icon: faLink },
    { label: 'Resources', path: 'resources', icon: faVectorSquare },
    { label: 'Settings', path: 'settings', icon: faCogs },
  ];

  actionList: { label: string; path: string; icon: any }[] = [
    { label: 'Change Project', path: '/auth/projects', icon: faRetweet },
    { label: 'Go to Project App', path: '/project', icon: faMapMarkedAlt },
    { label: 'Logout', path: '/auth/logout', icon: faSignOutAlt },
  ];

  constructor(private router: Router, private store: Store) {
    this.project$ = this.store.select(AdminSelectors.selectProject());
  }
}
