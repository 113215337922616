import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SelectBaseComponent } from '../select/select-base.component';

export type DropdownItem<T> = {
  label: string;
  payload: T;
};

export type DropdownEvent<T> = {
  source: DropdownItem<T> | null;
  index: number | null;
};

@Component({
  selector: 'ui-dropdown',
  template: `
    <div #wrapper [ngStyle]="{ width: widthPx ? widthPx + 'px' : 'auto' }">
      <div
        #menuTrigger
        class="menu-trigger"
        [cdkMenuTriggerFor]="menu"
        [class.opened-above]="menuOpenedDirection === 'above'"
        [class.opened-below]="menuOpenedDirection === 'below'"
        (cdkMenuOpened)="onCdkMenuOpened()"
        (cdkMenuClosed)="onCdkMenuClosed()"
      >
        <div class="menu-item text-ellipsis">
          {{ itemActive ? itemActive.label : noneOptionLabel || placeholder }}
        </div>
        <fa-icon [icon]="iconExpand" />
      </div>

      <ng-template #menu>
        <div
          class="menu-content menu-single"
          cdkMenu
          [class.opened-above]="menuOpenedDirection === 'above'"
          [class.opened-below]="menuOpenedDirection === 'below'"
        >
          <div class="menu-content-inner" [style.width.px]="menuWidth" #menuDiv>
            <div
              (click)="itemSelected.emit({ source: null, index: null })"
              *ngIf="noneOptionLabel"
              class="menu-item text-ellipsis"
              cdkMenuItem
            >
              {{ noneOptionLabel }}
            </div>
            <div
              *ngFor="let item of itemList; let index = index"
              (click)="itemSelected.emit({ source: item, index: index })"
              class="menu-item"
              cdkMenuItem
            >
              <div class="text-ellipsis">{{ item.label }}</div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // see style in +styles/menu.style.scss
})
/**
 * See SelectBaseComponent for more details.
 */
export class DropdownComponent<T> extends SelectBaseComponent {
  @Input() itemList: DropdownItem<T>[] = [];
  @Input() itemActive?: DropdownItem<T> | null;
  @Input() noneOptionLabel?: string;
  @Input() placeholder?: string = 'Select one';
  @Output() itemSelected: EventEmitter<DropdownEvent<T>> = new EventEmitter();
}
