import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../state/auth';

@Component({
  selector: 'app-logout',
  template: ``,
})
export class LogoutComponent {
  constructor(private store: Store) {
    this.store.dispatch(AuthActions.authLogout());
  }
}
