import { createSelector } from '@ngrx/store';
import * as fromSpecific from './form-specific.reducer';
import * as fromMarkerStyle from '../marker-style/marker-style.selectors';
import { FormSpecific, MarkerStyle, Record } from '3map-models';
import { notNullOrUndefined } from '@trim-web-apps/core';
import * as fromRoot from '../project.selectors';
import { MarkerItem } from '@trim-web-apps/project-core';

const selectState = createSelector(
  fromRoot.selectProjectState,
  (state) => state.specifics
);

export const { selectAll } = fromSpecific.adapter.getSelectors();

export const selectEntities = () => {
  return createSelector(selectState, (state) =>
    Object.keys(state.entities)
      .map((k) => state.entities[k])
      .filter(notNullOrUndefined)
  );
};

export const selectIds = () => {
  return createSelector(selectState, (state) => Object.keys(state.entities));
};

export const selectSpecificById = (props: { specificId: string }) => {
  return createSelector(
    selectState,
    fromMarkerStyle.selectMarkerStyle(props),
    (state, markerStyle): FormSpecific | null => {
      return entityToSpecific(state.entities[props.specificId], markerStyle);
    }
  );
};

export const selectSpecificEntitiesByFormId = (props: { formId: string }) => {
  return createSelector(selectAll, (entities):
    | fromSpecific.EntitySpecific[]
    | undefined => entities.filter((e) => e.formId === props.formId));
};

export const selectSpecificListByFormId = (props: { formId: string }) => {
  return createSelector(
    selectSpecificEntitiesByFormId(props),
    fromMarkerStyle.selectEntities(),
    (entities, msDict) => {
      return entities
        ?.map((e) => {
          const ms = fromMarkerStyle.entityToMarkerStyle(msDict[e.id]);
          const specific = entityToSpecific(e, ms);
          return specific || null;
        })
        .filter(notNullOrUndefined);
    }
  );
};

export const selectAllSpecific = () => {
  return createSelector(
    selectState,
    fromMarkerStyle.selectEntities(),
    (state, markerStyleDict) => {
      return Object.keys(state.entities)
        .map((specificId) => {
          const specificEntity = state.entities[specificId];
          const markerStyleEntity = markerStyleDict[specificId];
          return entityToSpecific(
            specificEntity,
            fromMarkerStyle.entityToMarkerStyle(markerStyleEntity)
          );
        })
        .filter(notNullOrUndefined);
    }
  );
};

export const selectMarkerStyleQuestion = (props: { specificId: string }) => {
  return createSelector(
    selectState,
    fromMarkerStyle.selectMarkerStyle(props),
    (state, markerStyle) => {
      const specific = state.entities[props.specificId];
      const questionStyle = markerStyle?.iconFieldType;
      const ft = specific?.questions.find((q) => q.id === questionStyle);
      return ft || null;
    }
  );
};

export const selectQuestionById = (props: {
  specificId: string;
  fieldId: string;
}) => {
  return createSelector(
    selectSpecificById({ specificId: props.specificId }),
    (specific) => {
      return specific?.questions.find((q) => q.id === props.fieldId);
    }
  );
};

export const selectMarkerItems = (props: { specificId: string }) => {
  return createSelector(
    selectMarkerStyleQuestion(props),
    fromMarkerStyle.selectImgBase64List(props),
    (question, imgBase64List): MarkerItem[] => {
      if (imgBase64List.length === 0) return [];
      // if (!question) return [];

      const fields = question?.options || [];
      const defaultMarker: MarkerItem[] = [
        {
          fieldTypeId: question ? question.id : null,
          fieldName: 'Default',
          imageBase64: imgBase64List[0],
        },
      ];

      const markerItems: MarkerItem[] = fields.map((field, index) => ({
        fieldTypeId: question ? question.id : null,
        fieldName: field,
        imageBase64: imgBase64List[index + 1],
      }));
      return [...defaultMarker, ...markerItems];
    }
  );
};

export const selectRecordBase64Icon = (props: { record: Record }) => {
  return createSelector(
    selectMarkerStyleQuestion({ specificId: props.record.formSpecificId }),
    selectMarkerItems({ specificId: props.record.formSpecificId }),
    (markerStyleQuestion, markerItems): string => {
      if (markerStyleQuestion?.type !== 'LIST')
        return markerItems[0].imageBase64;
      const answer = (props.record.data as any)[markerStyleQuestion.id];
      const item = markerItems.find((m) => m.fieldName === answer?.text);
      return item ? item.imageBase64 : markerItems[0].imageBase64;
    }
  );
};

export function entityToSpecific(
  entity: fromSpecific.EntitySpecific | undefined,
  markerStyle: MarkerStyle | null
): FormSpecific | null {
  if (entity && markerStyle) {
    const specific: FormSpecific = {
      id: entity.id,
      name: entity.name,
      questions: entity.questions,
      markerStyle,
    };

    if (entity.questionGroups) specific.questionGroups = entity.questionGroups;
    if (entity.listLogic) specific.listLogic = entity.listLogic;
    return specific;
  }
  return null;
}
