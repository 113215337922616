export interface GeoStyle {
  type: string;
  color: string;
}

export function getDefaultGeoStyle(): GeoStyle {
  return {
    type: 'line',
    color: '#333333',
  };
}

export function getDefaultRawStyle(): any {
  return geoStyleToRaw(getDefaultGeoStyle());
}

// example style: { "fill-color": "#424242", "fill-opacity": 0.4 }
export function rawToGeoStyle(style: any): GeoStyle {
  let type: string | null = null;
  let color: string | null = null;
  let opacity: number | null = null;

  for (const key of Object.keys(style)) {
    /*
    { "fill-color": "#424242", "fill-opacity": 0.4 }
         ^    ^                   ^     ^ 
         |    |                   |     |
         |  keySecond             |   keySecond
         |                        |
      keyFirst                 keyFirst
    */
    const keyFirst = key.split('-')[0] || null;
    const keySecond = key.split('-')[1] || null;

    if (keyFirst) type = keyFirst;
    if (keySecond === 'color') color = style[key];
    // if (keySecond === 'opacity') opacity = style[key];
  }

  // if (type !== null && color !== null && opacity !== null)
  if (type !== null && color !== null) return { type, color };

  throw Error('Invalid geojson style');
}

export function geoStyleToRaw(style: GeoStyle): any {
  return {
    [`${style.type}-color`]: style.color,
    // [`${style.type}-opacity`]: style.opacity,
  };
}
