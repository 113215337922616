import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './containers/sidenav/sidenav.component';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsNavItemComponent } from './components/forms-nav-item/forms-nav-item.component';
import { WeatherNavItemComponent } from './components/weather-nav-item/weather-nav-item.component';
import { ResourcesNavItemComponent } from './components/resources-nav-item/resources-nav-item.component';
import { UserNavItemComponent } from './components/user-nav-item/user-nav-item.component';
import { LogoNavItemComponent } from './components/logo-nav-item/logo-nav-item.component';
import { SearchNavItemComponent } from './components/search-nav-item/search-nav-item.component';
import { StoreModule } from '@ngrx/store';
import * as fromSidenav from './+state/sidenav.reducer';
import { LinksNavItemComponent } from './components/links-nav-item/links-nav-item.component';
import { EffectsModule } from '@ngrx/effects';
import { SidenavEffects } from './+state/sidenav.effects';
import { CoreModule } from '@trim-web-apps/core';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { NominatimModule } from '@trim-web-apps/nominatim';
import { SettingsComponent } from './components/settings/settings.component';
import { SidenavItemComponent } from './components/sidenav-item/sidenav-item.component';
import { SidenavHeaderComponent } from './components/sidenav-header/sidenav-header.component';

@NgModule({
  declarations: [
    SidenavComponent,
    SidenavItemComponent,
    FormsNavItemComponent,
    WeatherNavItemComponent,
    ResourcesNavItemComponent,
    LinksNavItemComponent,
    UserNavItemComponent,
    LogoNavItemComponent,
    SearchNavItemComponent,
    SettingsComponent,
    SidenavHeaderComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSidenav.FEATURE_KEY, fromSidenav.reducer),
    EffectsModule.forFeature([SidenavEffects]),
    SharedModule,
    FontAwesomeModule,
    CoreModule,
    Map3UiModule,
    NominatimModule,
  ],
  exports: [SidenavComponent, SidenavItemComponent],
})
export class SidenavModule {}
