import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-deploy-actions',
  template: `
    <div class="deploy">
      <ui-btn
        (btnClick)="onDeploy()"
        [disabled]="!deployAllowed"
        label="Deploy"
      ></ui-btn>
      <ui-btn
        (click)="revalidate.emit()"
        type="border"
        label="Rerun validation"
      ></ui-btn>
    </div>
  `,
  styles: [
    `
      .deploy {
        display: flex;
      }

      ui-btn {
        margin-right: 20px;
      }
    `,
  ],
})
export class DeployActionsComponent {
  @Input() deployAllowed: boolean | undefined;
  @Output() deploy: EventEmitter<void>;
  @Output() revalidate: EventEmitter<void>;

  constructor() {
    this.deploy = new EventEmitter<void>();
    this.revalidate = new EventEmitter<void>();
  }

  onDeploy(): void {
    if (this.deployAllowed) this.deploy.emit();
  }
}
