/**
 * Simple notification system.
 *
 * Add `core-notification-list` in a top level component (see {@link NotificationListComponent})
 *
 * Notification can be displayed using this service. Supported type:
 * - success
 * - error
 * - warn
 * - info
 *
 * Every method accepts 2 params:
 * - msg: string - notification content
 * - dueTime: number [optional] - duration in milliseconds
 *
 * Note on `dueTime`: use zero or negative value for permanent notification.
 *
 * TODO use cdk overlay (https://stackoverflow.com/questions/52294398/angular6-open-show-component-by-service)
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as uuid from 'uuid';
import { Notification, NotificationType } from './notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationQueue: Subject<Notification> =
    new Subject<Notification>();

  getNotificationStream(): Observable<Notification> {
    return this.notificationQueue.asObservable();
  }

  success(msg: string, dueTime: number = 5000): void {
    const type: NotificationType = 'SUCCESS';
    this.dispatchNotification(msg, type, dueTime);
  }

  error(msg: string, dueTime: number = 5000): void {
    const type: NotificationType = 'ERROR';
    this.dispatchNotification(msg, type, dueTime);
  }

  warn(msg: string, dueTime: number = 5000): void {
    const type: NotificationType = 'WARN';
    this.dispatchNotification(msg, type, dueTime);
  }

  info(msg: string, dueTime: number = 5000): void {
    const type: NotificationType = 'INFO';
    this.dispatchNotification(msg, type, dueTime);
  }

  private dispatchNotification(
    msg: string,
    type: NotificationType,
    dueTime: number
  ): void {
    const id = uuid.v4();
    this.notificationQueue.next({ id, msg, type, dueTime });
  }
}
