import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from '@trim-web-apps/api3map';
import { EditorActions, EditorSelectors } from './';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { AuthSelectors } from '../../../auth/state/auth';
// eslint-disable-next-line
// @ts-ignore
import * as tz_lookup from 'tz-lookup';
import * as uuid from 'uuid';
import { MapActions } from '../../map/+state';
import { ProjectSelectors } from '../../project/+state';
import { EditorTab, MEDIA_TAB, QUESTION_TAB } from '../models/EditorTab';
import { FormSpecific, Media, Record } from '3map-models';
import { TableActions } from '../../table/+state';
import { getSpecific } from '../../shared/project.utils';
import { UploadMediaService } from '../upload-media.service';
import { ConfirmDialogService } from '../../shared/ui/confirm-dialog/confirm-dialog.service';
import { NotificationService } from '@trim-web-apps/core';

@Injectable()
export class RecordEditorEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store,
    private notify: NotificationService,
    private uploadMediaService: UploadMediaService,
    private confirmDialog: ConfirmDialogService
  ) {}

  createRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.createRecord, TableActions.createRecord),
      withLatestFrom(
        this.store.select(ProjectSelectors.selectProject()),
        this.store.pipe(select(AuthSelectors.selectAuthUsername()))
      ),
      map(([action, project, username]) => {
        if (!project || !username)
          return EditorActions.setError({
            error: 'Invalid Project or username',
          });

        const specific = getSpecific(project, action.specificId);
        if (!specific)
          return EditorActions.setError({
            error: 'SpecificID does not exists',
          });

        const record: Record = {
          recordId: uuid.v4(),
          featureId: uuid.v4(),
          projectName: project.name,
          formId: action.formId,
          formSpecificId: action.specificId,
          username: username,
          datetimeUtc: new Date(),
          zone: tz_lookup(action.latitude, action.longitude),
          latitude: action.latitude,
          longitude: action.longitude,
          altitude: action.altitude,
          mediaList: [],
          data: {},
        };

        return EditorActions.enableRecordEditor({
          record,
          mode: 'CREATE',
          activeTab: getActiveTab(specific),
        });
      })
    )
  );

  editRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.editRecord, TableActions.editRecord),
      switchMap(({ recordId }) =>
        this.store
          .select(EditorSelectors.selectEditorDataEditUpdate(recordId))
          .pipe(
            take(1),
            map((selected) => {
              const { record, specific, username, col } = selected;
              const isRecordOwner = record.username === username;
              const adminOrMod = col.role === 'ADMIN' || col.role === 'MOD';
              return adminOrMod || isRecordOwner
                ? EditorActions.enableRecordEditor({
                    record,
                    mode: 'EDIT',
                    activeTab: getActiveTab(specific),
                  })
                : EditorActions.setError({ error: 'Not allowed' });
            }),
            catchError((e: Error) =>
              of(EditorActions.setError({ error: e.message }))
            )
          )
      )
    )
  );

  updateRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.updateRecord, MapActions.updateRecord),
      switchMap(({ recordId }) =>
        this.store
          .select(EditorSelectors.selectEditorDataEditUpdate(recordId))
          .pipe(
            take(1),
            map((selected) => {
              const { record, specific, username } = selected;
              const recordData = { ...(record.data as any) };
              const newRecordData: any = {};
              for (const q of specific.questions) {
                if (
                  q.immutable &&
                  Object.prototype.hasOwnProperty.call(recordData, q.id)
                )
                  newRecordData[q.id] = recordData[q.id];
              }
              const recordForUpdate: Record = {
                recordId: uuid.v4(),
                featureId: record.featureId,
                projectName: record.projectName,
                formId: record.formId,
                formSpecificId: record.formSpecificId,
                username: username,
                datetimeUtc: new Date(),
                zone: record.zone,
                latitude: record.latitude,
                longitude: record.longitude,
                altitude: record.altitude,
                mediaList: [],
                data: newRecordData,
              };

              return EditorActions.enableRecordEditor({
                record: recordForUpdate,
                mode: 'UPDATE',
                activeTab: getActiveTab(specific),
              });
            }),
            catchError((e: Error) =>
              of(EditorActions.setError({ error: e.message }))
            )
          )
      )
    )
  );

  deleteRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.deleteRecords, MapActions.deleteRecords),
      switchMap(({ recordIdsList }) => {
        return this.confirmDialog
          .showConfirmDialog({
            title:
              recordIdsList.length > 1
                ? `Delete ${recordIdsList.length} Records?`
                : 'Delete Record?',
            content: 'This action can not be undone',
            confirmText: 'CONFIRM',
            cancelText: 'BACK',
          })
          .pipe(
            take(1),
            switchMap((confirmDelete) => {
              return confirmDelete
                ? this.deleteRecords(recordIdsList)
                : of(EditorActions.deleteRecordsCanceled());
            })
          );
      })
    )
  );

  uploadRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditorActions.uploadRecord),
      withLatestFrom(this.store.select(EditorSelectors.selectEditorState)),
      switchMap(([, state]) => {
        const { record, uploadMediaList } = state;
        if (record === null) return of(this.uploadError('No Record to upload'));

        const updatedRecord: Record = {
          ...record,
          mediaList: [...record.mediaList, ...uploadMediaList],
        };

        return forkJoin([
          this.uploadMediaList(uploadMediaList),
          this.uploadRecord(updatedRecord),
        ]).pipe(
          map(([mediaListResponse, recordResponse]) => {
            return recordResponse && mediaListResponse
              ? EditorActions.uploadRecordSuccess({ record: updatedRecord })
              : this.uploadError('Cannot upload Record');
          })
        );
      })
    )
  );

  onNextTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditorActions.nextTab),
        switchMap(() =>
          combineLatest([
            this.store.select(EditorSelectors.selectGroupNameList()),
            this.store.select(EditorSelectors.selectActiveTab()),
          ]).pipe(take(1))
        ),
        map(([groupNames, currentActiveTab]) => {
          if (!currentActiveTab) return;
          let activeTab = currentActiveTab ? { ...currentActiveTab } : null;
          const hasGroups = groupNames && groupNames.length > 0;

          switch (currentActiveTab.type) {
            case 'METADATA':
              activeTab = hasGroups
                ? {
                    ...QUESTION_TAB,
                    label: groupNames[0],
                    payload: groupNames[0],
                  }
                : { ...QUESTION_TAB };
              break;

            case 'QUESTIONS':
              if (!hasGroups) {
                activeTab = { ...MEDIA_TAB };
                break;
              }
              // eslint-disable-next-line no-case-declarations
              if (currentActiveTab.payload !== null) {
                const groupIndex = groupNames.indexOf(currentActiveTab.payload);
                activeTab =
                  groupIndex < groupNames.length - 1
                    ? {
                        ...QUESTION_TAB,
                        payload: groupNames[groupIndex + 1],
                        label: groupNames[groupIndex + 1],
                      }
                    : { ...MEDIA_TAB };
              }

              break;
          }
          this.store.dispatch(EditorActions.setActiveTab({ activeTab }));
        })
      ),
    { dispatch: false }
  );

  editorError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditorActions.setError, EditorActions.uploadRecordError),
        withLatestFrom(this.store.select(EditorSelectors.selectRequest())),
        tap(([, request]) => {
          if (request.error !== null) this.notify.error(request.error, 5000);
        })
      ),
    { dispatch: false }
  );

  editorUploadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditorActions.uploadRecordSuccess),
        tap(() => {
          this.notify.success('Record uploaded');
        })
      ),
    { dispatch: false }
  );

  private deleteRecords(recordIdsList: string[]): Observable<Action> {
    return this.api.deleteRecords(recordIdsList).pipe(
      map((response) =>
        response.success
          ? EditorActions.deleteRecordsSuccess({
              recordIdsList: response.responsePayload,
            })
          : EditorActions.deleteRecordsError({
              error: 'Cannot delete Records',
            })
      ),
      catchError(() =>
        of(
          EditorActions.deleteRecordsError({
            error: 'Cannot delete Records',
          })
        )
      )
    );
  }

  private uploadRecord(record: Record): Observable<boolean> {
    return this.api.uploadRecords([record]).pipe(
      map((response) => response.success),
      catchError(() => of(false))
    );
  }

  private uploadMediaList(mediaList: Media[]): Observable<boolean> {
    if (mediaList.length === 0) return of(true);
    return forkJoin(
      mediaList.map((media) => {
        const blob = this.uploadMediaService.getMediaBlob(media);
        if (!blob) throw Error('Blob not found');
        return this.api.uploadProjectStaticFile(blob.blob, media.fileName).pipe(
          map((response) => response.success),
          catchError(() => of(false))
        );
      })
    ).pipe(
      map((responseList) =>
        responseList.reduce((acc, item) => acc && item, true)
      ),
      catchError(() => of(false))
    );
  }

  private uploadError(error: string): Action {
    return EditorActions.uploadRecordError({ error });
  }
}

function getActiveTab(specific: FormSpecific): EditorTab | null {
  return specific.questionGroups && specific.questionGroups.length > 0
    ? {
        ...QUESTION_TAB,
        label: specific.questionGroups[0].name,
        payload: specific.questionGroups[0].name,
      }
    : { ...QUESTION_TAB, label: 'Questions' };
}
