import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NotificationService } from '@trim-web-apps/core';
import { UploadMedia } from '../../models/UploadMedia';
import * as uuid from 'uuid';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-media-file-picker[type]',
  template: `
    <core-icon-button (click)="input.click()" [iconLeft]="icon" [text]="label">
    </core-icon-button>
    <input
      style="display:none;"
      [multiple]="true"
      #input
      type="file"
      (change)="onFileSelected($event)"
    />
  `,
  styles: [
    `
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      fa-icon {
        margin-right: 5px;
      }
    `,
  ],
})
export class MediaFilePickerComponent {
  @Input() label: string | undefined;
  @Input() type!: 'IMAGE' | 'AUDIO' | 'VIDEO';
  @Input() icon: IconDefinition | undefined;
  @Output() fileListSelected: EventEmitter<UploadMedia[]>;
  @ViewChild('input') input: ElementRef | undefined;

  constructor(private notify: NotificationService) {
    this.fileListSelected = new EventEmitter<UploadMedia[]>();
  }

  onFileSelected(evt: Event): void {
    const element = evt.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      const files: UploadMedia[] = [];
      const allowedMimeTypes = this.getMimeType();
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        if (file && allowedMimeTypes.includes(file.type))
          files.push({
            media: {
              fileName: uuid.v4(),
              type: this.type,
            },
            data: file,
            status: 'UPLOADING',
            userFilename: file.name,
          });
        else this.showMimeTypeError();
      }
      this.fileListSelected.emit(files);
      if (this.input) this.input.nativeElement.value = '';
    }
  }

  private getMimeType(): string[] {
    if (this.type === 'IMAGE') return ['image/png', 'image/jpeg'];
    if (this.type === 'VIDEO') return ['video/mp4'];
    if (this.type === 'AUDIO') return ['audio/mp3'];
    return [];
  }

  private showMimeTypeError(): void {
    let msg = `Invalid ${this.type} file. Supported types:`;
    if (this.type === 'IMAGE') msg = `${msg} PNG, JPEG`;
    if (this.type === 'VIDEO') msg = `${msg} MP4`;
    if (this.type === 'AUDIO') msg = `${msg} MP3`;
    this.notify.error(msg, 5000);
  }
}
