import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Record } from '3map-models';
import { FetchStatus } from '../../shared/models/fetch-status.model';
import { SidenavActions } from '../../sidenav/+state';
// import { ProjectActions } from '../../project/+state';
import * as ProjectActions from '../../project/+state/project.actions';
import * as RecordActions from './record.actions';
import { EditorActions } from '../../record-editor/+state';

export const recordsFeatureKey = 'records2';

export interface State extends EntityState<Record> {
  recordsFetchState: FetchStatus;
}

export const adapter: EntityAdapter<Record> = createEntityAdapter<Record>({
  selectId: (record: Record) => record.recordId,
});

export const initialState: State = adapter.getInitialState({
  recordsFetchState: 'INITIAL',
});

export const reducer = createReducer(
  initialState,
  on(ProjectActions.closeProject, () => ({
    ...initialState,
  })),
  on(
    ProjectActions.fetchProject,
    ProjectActions.fetchProjectError,
    (): State => initialState
  ),
  on(
    RecordActions.fetchRecords,
    SidenavActions.reloadRecords,
    (state): State => ({
      ...state,
      recordsFetchState: 'PENDING',
    })
  ),
  on(
    RecordActions.fetchRecordsSuccess,
    (state, { records }): State =>
      adapter.setAll(records, {
        ...state,
        recordsFetchState: 'SUCCESS',
      })
  ),
  on(
    RecordActions.fetchRecordsError,
    (state): State => ({ ...state, recordsFetchState: 'ERROR' })
  ),
  on(
    EditorActions.uploadRecordSuccess,
    (state, { record }): State => adapter.upsertOne(record, state)
  ),
  on(
    EditorActions.deleteRecordsSuccess,
    (state, { recordIdsList }): State =>
      adapter.removeMany(recordIdsList, state)
  ),
  on(RecordActions.clearRecords, (state): State => adapter.removeAll(state))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
