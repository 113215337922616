import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromRecord from './+state/record.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RecordEffects } from './+state/record.effects';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromRecord.recordsFeatureKey, fromRecord.reducer),
    EffectsModule.forFeature([RecordEffects]),
    SharedModule,
  ],
  exports: [],
})
export class RecordModule {}
