import { createAction, props } from '@ngrx/store';
import { Collaboration } from './collaboration.reducer';
import { Project } from '3map-models';

export const fetchCollaborations = createAction(
  '[Collab] Fetch Collaborations'
);

export const fetchCollaborationsSuccess = createAction(
  '[Collab] Fetch Collaborations Success',
  props<{ collaborations: Collaboration[] }>()
);

export const fetchCollaborationsError = createAction(
  '[Collab] Fetch Collaborations Error'
);

export const createNewProject = createAction(
  '[Collab] Create New Project',
  props<{ project: Project }>()
);

export const createNewProjectSuccess = createAction(
  '[Collab] Create New Project Success'
);

export const createNewProjectError = createAction(
  '[Collab] Create New Project Error'
);

export const setProjectName = createAction(
  '[Collab] Set New Project Name',
  props<{ newProjectName: string }>()
);
