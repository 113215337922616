import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-spinner-circle',
  template: `
    <div class="spinner-wrapper">
      <div
        class="loading"
        [style.width.px]="size"
        [style.height.px]="size"
        *ngIf="loading"
      ></div>
    </div>
  `,
  styles: [
    `
      :host {
        display: inline;
      }

      .loading {
        display: flex;
        border: 3px solid var(--primary-color);
        border-radius: 50%;
        border-top-color: #fff;
        margin-right: 5px;
        animation: spin 0.5s ease-in-out infinite;
        -webkit-animation: spin 0.5s ease-in-out infinite;
      }

      @keyframes spin {
        to {
          -webkit-transform: rotate(360deg);
        }
      }

      @-webkit-keyframes spin {
        to {
          -webkit-transform: rotate(360deg);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerCircleComponent {
  @Input() loading?: boolean;
  @Input() size = 20;
}
