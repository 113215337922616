import {
  PRESSURE,
  RAINFALL_1HOUR,
  SNOWFALL_1HOUR,
  TEMPERATURE,
  TOTAL_CLOUD_COVER,
  WIND_GUST,
  WIND_KTS,
} from '../../weather-layers/weather.layers';
import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createMoloch(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 71, 3600, '+00:00');
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[70],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 3600 };

  const molochLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...WIND_GUST,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...PRESSURE,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...TOTAL_CLOUD_COVER,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RAINFALL_1HOUR,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...SNOWFALL_1HOUR,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'MOLOCH',
    label: 'MOLOCH',
    category: 'FORECAST',
    layers: molochLayers,
    initime: molochLayers[0].timestepList[0].initime,
    timestep: molochLayers[0].timestepList[0].timesteps[0],
    selectedLayerId: molochLayers[0].id,
    dateRange: defaultDateRng,
    bbox: [
      [2.3827, 34.199999],
      [2.3827, 49.464598],
      [19.890301, 49.464598],
      [19.890301, 34.199999],
      [2.3827, 34.199999],
    ],
    info: {
      resolution: '0.034° x 0.024° (~2.5 km)',
      coverage: 'Lat: 34.2; 49.6 -- Lon: 2.4; 19.6',
      url: 'http://www.lamma.rete.toscana.it/modelli/atmo/bolam-e-moloch-info-sui-modelli',
      credits: 'Consorzio LaMMA',
    },
  };
}
