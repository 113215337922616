class WindSymbol {
  // @ts-ignore
  height: number;
  // @ts-ignore
  width: number;
  // @ts-ignore
  type: string;
}

class WindBarb extends WindSymbol {
  numSeg: number;
  segHeight: number;
  speedStepOn: number;

  constructor(height: number) {
    super();
    this.height = height;
    this.width = height * 0.5;
    this.numSeg = 5;
    this.segHeight = this.height / this.numSeg;
    this.speedStepOn = 10;
  }
}

class Arrow extends WindSymbol {
  headHeight: number;
  tailWidth: number;
  tailHeadOffset: number;
  border: boolean;
  nocolor: boolean;
  fillBackground: boolean;

  constructor(height: number) {
    super();
    this.height = height;
    this.width = height * 0.5;
    this.headHeight = this.height * 0.45;
    this.tailWidth = this.width * 0.3;
    this.tailHeadOffset = this.headHeight * 0;
    this.border = true;
    this.nocolor = false;
    this.fillBackground = false;
  }
}

class ArrowWithoutBorder extends Arrow {
  constructor(height: number) {
    super(height);
    this.border = false;
  }
}

class AnotherArrow extends Arrow {
  constructor(height: number) {
    super(height);
    this.border = true;
    this.width = (height / 4) * 3;
    this.headHeight = height / 3;
    this.tailWidth = this.width * 0.4;
  }
}

class AnotherArrowWithoutBorder extends AnotherArrow {
  constructor(height: number) {
    super(height);
    this.border = false;
  }
}

class ArrowThreddsLike extends Arrow {
  constructor(height: number) {
    super(height);
    this.headHeight = this.height * 0.6;
    this.tailWidth = this.width * 0.2;
    this.tailHeadOffset = this.headHeight * 0.5;
  }
}

class ArrowThreddsLikeWithoutBorder extends ArrowThreddsLike {
  constructor(height: number) {
    super(height);
    this.border = false;
  }
}

class AAA extends Arrow {
  constructor(height: number) {
    super(height);
    this.headHeight = this.height * 0.6;
    this.tailWidth = this.width * 0.01;
    this.tailHeadOffset = this.headHeight * 1;
    this.nocolor = true;
    this.fillBackground = true;
  }
}

class ArrowDirectionOnly extends Arrow {
  constructor(height: number) {
    super(height);
    this.headHeight = this.height * 0.6;
    this.tailWidth = this.width * 0.2;
    this.tailHeadOffset = this.headHeight * 0.5;
    this.nocolor = true;
    this.fillBackground = false;
  }
}

const arrHeight = 12;
const barbHeight = 20;
const arrows = new Map<string, Arrow | WindBarb>();

export enum ArrStyles {
  ARR_STYLE1 = 'Arrow',
  ARR_STYLE2 = 'Standard',
  ARR_STYLE3 = 'Light',
  BARBS = 'Barbs',
  ARR_FILLBOX = 'Boxfill',
  ARR_DIR_ONLY = 'Direction Only',
}

arrows.set(ArrStyles.ARR_STYLE1, new AnotherArrow(arrHeight));
// arrows.set('old-arrow-no-border', new AnotherArrowWithoutBorder(arrHeight))
arrows.set(ArrStyles.ARR_STYLE2, new Arrow(arrHeight));
// arrows.set('standard-no-border', new ArrowWithoutBorder(arrHeight))
arrows.set(ArrStyles.ARR_STYLE3, new ArrowThreddsLike(arrHeight));
// arrows.set('thredds-no-border', new ArrowThreddsLikeWithoutBorder(arrHeight))
arrows.set(ArrStyles.ARR_FILLBOX, new AAA(arrHeight));
arrows.set(ArrStyles.BARBS, new WindBarb(barbHeight));
arrows.set(ArrStyles.ARR_DIR_ONLY, new ArrowDirectionOnly(arrHeight));

// @ts-ignore
export { arrows };

// @ts-ignore
export { Arrow, WindBarb };
