import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { SelectBaseComponent } from './select-base.component';
import { insertElementArrayAtIndex } from '@trim-web-apps/core';

type SelectableItem = { selected: boolean; item: string };

@Component({
  selector: 'ui-select-multiple',
  template: `
    <div #wrapper [ngStyle]="{ width: widthPx ? widthPx + 'px' : 'auto' }">
      <div
        #menuTrigger
        class="menu-trigger"
        [cdkMenuTriggerFor]="menu"
        [class.opened-above]="menuOpenedDirection === 'above'"
        [class.opened-below]="menuOpenedDirection === 'below'"
        (cdkMenuOpened)="onCdkMenuOpened()"
        (cdkMenuClosed)="onCdkMenuClosed()"
      >
        <div class="menu-item text-ellipsis">
          {{ (itemListSelected | arrayString) || 'Select' }}
        </div>
        <fa-icon [icon]="iconExpand" />
      </div>
      <ng-template #menu>
        <div
          class="menu-content"
          cdkMenu
          [class.opened-above]="menuOpenedDirection === 'above'"
          [class.opened-below]="menuOpenedDirection === 'below'"
        >
          <div class="menu-content-inner" [style.width.px]="menuWidth" #menuDiv>
            <div
              class="menu-item text-ellipsis"
              *ngFor="let item of selectableItems; let index = index"
              (click)="onItemClick(item, index)"
            >
              <ui-checkbox [checked]="item.selected" [label]="item.item" />
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  // see style in +styles/menu.style.scss
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMultipleComponent
  extends SelectBaseComponent
  implements OnChanges
{
  @Input() itemListSelected?: string[] | null;
  @Output() itemListSelectedChange = new EventEmitter<string[] | null>();
  selectableItems: SelectableItem[] = [];

  ngOnChanges(): void {
    this.selectableItems = this.items.map((item) => {
      const isSelected = this.itemListSelected?.includes(item) || false;
      return { selected: isSelected, item };
    });
  }

  onItemClick(item: SelectableItem, index: number): void {
    this.selectableItems = insertElementArrayAtIndex<SelectableItem>(
      this.selectableItems,
      { ...item, selected: !item.selected },
      index
    );
  }

  override onCdkMenuClosed(): void {
    super.onCdkMenuClosed();
    const selected = this.selectableItems
      .filter((item) => item.selected)
      .map((item) => item.item);
    this.itemListSelectedChange.emit(selected);
  }
}
