import { FieldType, FormSpecific, Record } from '3map-models';
import moment from 'moment';

export function getRecordQuestions(
  record: Record,
  specific: FormSpecific
): { fieldTypeName: string; answer: string }[] {
  return specific.questions.slice(0, 3).map((fieldType) => {
    const answer = recordAnswerToString(record, fieldType);
    const fieldTypeName = fieldType.name;
    return answer === null
      ? { fieldTypeName, answer: '---' }
      : { fieldTypeName, answer };
  });
}

export function recordAnswerToString(
  record: Record,
  fieldType: FieldType
): string | null {
  const answer = (record as any).data[fieldType.id];

  if (!answer) return null;

  switch (fieldType.type) {
    case 'NUMBER':
      return answer.value.toString();
    case 'TEXT':
      return answer.text;
    case 'RANGE':
      return `${answer.min} - ${answer.max}`;
    case 'LIST':
      return answer.text;
    case 'CHECK':
      return answer.checked.toString();
    case 'INT':
      return answer.valueInt.toString();
    case 'DATE':
      return moment(answer.date).format('DD MMM YYYY');
    default:
      return '---';
  }
}
