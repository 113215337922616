import { createSelector } from '@ngrx/store';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { selectAppAdminState } from '../../admin.reducer.map';

// export const selectState = createFeatureSelector<State>(
//   ADMIN_WEB_HOOK_FEATURE_KEY
// );

export const selectState = () =>
  createSelector(selectAppAdminState(), (state) => state.webhooks);

export const selectWebhooks = () => {
  return createSelector(selectState(), (hooks) =>
    Object.keys(hooks.entities)
      .map((k) => hooks.entities[k])
      .filter(notNullOrUndefined)
  );
};
