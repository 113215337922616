import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AuthActions, AuthSelectors } from '../state/auth';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    console.log('guard auth');
    const redirectPath = route.data['redirectPath'];

    return this.store.pipe(
      select(AuthSelectors.selectIsProjectLogged()),
      tap((isLogged) => {
        if (!isLogged && redirectPath !== undefined) {
          this.store.dispatch(AuthActions.authLogout());
          this.router.navigate([redirectPath]);
        } else if (!isLogged && redirectPath === undefined) {
          console.warn(
            `[ Auth Guard ] route with path: '${route.routeConfig?.path}' has no redirectPath`
          );
          this.router.navigate(['auth']);
        }
      })
    );
  }
}
