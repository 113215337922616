import { createAction, props } from '@ngrx/store';
import { FormSpecific } from '3map-models';
import { FieldType } from '3map-models/dist/lib/model';
import { QuestionsGroup } from '@trim-web-apps/project-core';
import { FormSpecificLogic } from './models/FormSpecificLogic';

export const createSpecific = createAction(
  '[ Specific ] Create',
  props<{ formId: string; specific: FormSpecific }>()
);

export const onDuplicateSpecific = createAction(
  '[ Specific ] On Duplicate',
  props<{ formId: string; specific: FormSpecific }>()
);

export const duplicateSpecific = createAction(
  '[ Specific ] Duplicate',
  props<{
    formId: string;
    specific: FormSpecific;
    icons: { specificId: string; imgBase64List: string[] }[];
  }>()
);

export const removeSpecific = createAction(
  '[ Specific ] Remove',
  props<{ formId: string; specificId: string }>()
);

export const upsertQuestions = createAction(
  '[ Specific ] Upsert Questions',
  props<{ specificId: string; questions: FieldType[] }>()
);

export const upsertQuestionGroup = createAction(
  '[ Specific ] Upsert Questions Group',
  props<{ specificId: string; questionsGroupList: QuestionsGroup[] }>()
);

export const setSpecificName = createAction(
  '[ Specific ] Set Name',
  props<{ specificId: string; name: string }>()
);

export const removeQuestion = createAction(
  '[ Specific ] Remove Question',
  props<{ question: FieldType; specificId: string; groupName?: string }>()
);

export const moveQuestionGroup = createAction(
  '[ Specific ] Move Questions Group',
  props<{
    specificId: string;
    fromGroup: QuestionsGroup;
    toGroupName: string | null;
  }>()
);

export const addQuestionGroup = createAction(
  '[ Specific ] Add Question Group',
  props<{ specificId: string; groupName: string; questionList?: FieldType[] }>()
);

export const removeQuestionGroup = createAction(
  '[ Specific ] Remove Question Group',
  props<{ specificId: string; groupName: string }>()
);

export const removeAllQuestionGroup = createAction(
  '[ Specific ] Remove All Question Group',
  props<{ specificId: string }>()
);

export const renameQuestionGroup = createAction(
  '[ Specific ] Rename Question Group',
  props<{ specificId: string; oldName: string; newName: string }>()
);

export const setLogicList = createAction(
  '[ Specific ] Set Logic List',
  props<{ specificId: string; logicList: FormSpecificLogic[] }>()
);
