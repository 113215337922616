import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form } from '3map-models';

@Component({
  selector: 'app-form-properties',
  template: `
    <div class="wrapper" *ngIf="formProperties">
      <div class="section-title">{{ titleLabel }}</div>
      <input
        type="text"
        #formNameInput
        [(ngModel)]="formProperties.name"
        placeholder="Form name"
      />

      <div class="form-media-label">Allow media</div>

      <ui-checkbox
        (click)="formProperties.allowImage = !formProperties.allowImage"
        [checked]="formProperties.allowImage"
        label="Image"
      />
      <ui-checkbox
        (click)="formProperties.allowAudio = !formProperties.allowAudio"
        [checked]="formProperties.allowAudio"
        label="Audio"
      />
      <ui-checkbox
        (click)="formProperties.allowVideo = !formProperties.allowVideo"
        [checked]="formProperties.allowVideo"
        label="Video"
      />

      <div class="actions">
        <ui-btn (btnClick)="onSaveChanges()" [label]="actionLabel"></ui-btn>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        width: 350px;
        padding: 20px;
        display: flex;
        flex-direction: column;
      }

      .section-title {
        font-size: 1.2em;
        margin-bottom: 20px;
        color: var(--primary-color);
      }

      input {
        width: 100%;
        margin-bottom: 20px;
      }

      .actions {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }

      map3-ui-checkbox {
        margin-top: 10px;
      }
    `,
  ],
})
export class FormPropertiesComponent implements OnInit {
  @Input() mode: 'create' | 'edit' | 'duplicate' | undefined;
  @Input() form?: Form;
  @Output() formChanged: EventEmitter<Form>;
  titleLabel: string | undefined;
  actionLabel: string | undefined;
  formProperties?: {
    name: string;
    allowImage: boolean;
    allowVideo: boolean;
    allowAudio: boolean;
  };

  constructor() {
    this.formChanged = new EventEmitter<Form>();
  }

  ngOnInit(): void {
    if (!this.form) throw Error('[FormProperties] Missing input Form');
    if (!this.mode) throw Error('[FormProperties] "mode" not defined');

    this.titleLabel =
      this.mode.charAt(0).toUpperCase() +
      this.mode.slice(1) +
      ' Form Collection';
    if (this.mode === 'create') this.actionLabel = 'Create';
    if (this.mode === 'edit') this.actionLabel = 'Save';
    if (this.mode === 'duplicate') this.actionLabel = 'Duplicate';

    this.formProperties = {
      name: this.form.name,
      allowImage: this.form.allowImage,
      allowVideo: this.form.allowVideo,
      allowAudio: this.form.allowAudio,
    };
  }

  onSaveChanges(): void {
    if (!this.form || !this.formProperties) return;
    this.formChanged.emit({ ...this.form, ...this.formProperties });
  }
}
