import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  CREATE_RESOURCE,
  REMOVE_RESOURCE,
  SET_RESOURCE_BBOX,
  SET_RESOURCE_DATA,
  SET_RESOURCE_NAME,
  SET_RESOURCE_STYLE,
} from './resource.actions';
import * as uuid from 'uuid';
import { EntityResource } from './resource.entity';
import { notNullOrUndefined } from '@trim-web-apps/core';
import * as projectActions from '../project.actions';

export interface State extends EntityState<EntityResource> {}

export const adapter = createEntityAdapter<EntityResource>();
export const initialState = adapter.getInitialState();

const resourceReducer = createReducer(
  initialState,
  on(projectActions.RESET, () => initialState),
  on(projectActions.RESTORE_PROJECT_STATE, (state, { projectState }) => ({
    ...projectState.resource,
  })),
  on(projectActions.INIT_PROJECT, (state, { project }) => {
    const entities: EntityResource[] = project.resourceList.map((resource) => ({
      ...resource,
      id: uuid.v4(),
      resourceData: null,
    }));
    return adapter.setAll(entities, state);
  }),
  on(CREATE_RESOURCE, (state, { entity }) => {
    const resNameList = Object.keys(state.entities)
      .map((k) => state.entities[k])
      .filter(notNullOrUndefined)
      .map((res) => res.name);
    const resourceExists = resNameList.includes(entity.name);
    return resourceExists ? state : adapter.addOne(entity, state);
  }),
  on(REMOVE_RESOURCE, (state, { id }) => adapter.removeOne(id, state)),
  on(SET_RESOURCE_NAME, (state, { id, name }) =>
    adapter.updateOne({ id, changes: { name } }, state)
  ),
  on(SET_RESOURCE_DATA, (state, { id, resourceData }) => {
    const entity = state.entities[id];
    if (!entity) return state;
    const filename =
      entity.type === 'GEOJSON' ? `${uuid.v4()}.geojson` : `${uuid.v4()}.png`;
    return adapter.updateOne(
      { id, changes: { resourceData, filename } },
      state
    );
  }),
  on(SET_RESOURCE_STYLE, (state, { id, style }) =>
    adapter.updateOne({ id, changes: { style } }, state)
  ),
  on(SET_RESOURCE_BBOX, (state, { id, boundingBox }) =>
    adapter.updateOne({ id, changes: { boundingBox } }, state)
  )
);

export function reducer(state: State | undefined, action: Action): State {
  return resourceReducer(state, action);
}
