import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faCloudSun } from '@fortawesome/free-solid-svg-icons';
import { WeatherService } from '@trim-web-apps/weather-core';
import { map, Observable } from 'rxjs';
import { WeatherModel } from '@trim-web-apps/weather-models';

@Component({
  selector: 'app-weather-nav-item',
  template: `
    <app-sidenav-item
      label="Weather"
      [icon]="iconWeather"
      [sidenavOpen]="sidenavOpen"
      [expandable]="true"
    >
      <ng-container *ngIf="weatherModelsByCategory$ | async as modelByCat">
        <div class="no-models" *ngIf="modelByCat.length === 0">
          Weather data not available
        </div>
        <ng-container *ngFor="let cat of modelByCat">
          <ng-container *ngIf="cat.modelList.length > 0">
            <div class="model-category-label primary-text-bold">
              {{ cat.label }}
            </div>
            <div class="model-by-category" *ngFor="let model of cat.modelList">
              <div class="nav-item-checkbox">
                <ui-checkbox
                  [checked]="model.modelEnabled"
                  [label]="model.label"
                  (checkboxClick)="toggleModel(model)"
                ></ui-checkbox>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </app-sidenav-item>
  `,
  styles: [
    `
      .model-category-label {
        padding: var(--spacing-2) 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherNavItemComponent {
  @Input() sidenavOpen?: boolean;
  iconWeather = faCloudSun;
  weatherModelsByCategory$: Observable<
    {
      label: string;
      modelList: WeatherModel[];
    }[]
  >;

  constructor(private weather: WeatherService) {
    this.weatherModelsByCategory$ = this.weather.getWeatherModelsList().pipe(
      map((models) => {
        return [...models].sort((modelA, modelB) => {
          const modelNameA = modelA.label.toLowerCase();
          const modelNameB = modelB.label.toLowerCase();
          if (modelNameA < modelNameB) return -1;
          if (modelNameA > modelNameB) return 1;
          return 0;
        });
      }),
      map((sortedModels) => {
        return [
          {
            label: 'Forecast',
            modelList: sortedModels.filter((m) => m.category === 'FORECAST'),
          },
          {
            label: 'Monitoring',
            modelList: sortedModels.filter((m) => m.category === 'MONITORING'),
          },
        ];
      })
    );
  }

  toggleModel(model: WeatherModel): void {
    model.modelEnabled
      ? this.weather.removeWeatherModel(model.id)
      : this.weather.addWeatherModel(model.id);
  }
}
