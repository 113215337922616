import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormSpecificLogic } from '@trim-web-apps/project-core';

@Component({
  selector: 'app-logic-r2-selector',
  template: `
    <ui-select-multiple
      *ngIf="isListOrCheck"
      [itemListSelected]="r2List"
      [items]="logic.q2.options ?? []"
      (itemListSelectedChange)="r2ListChange.emit($event ?? [])"
    />

    <ng-container *ngIf="!isListOrCheck">
      <!-- not sure why we need preventDefault() and emit empty array... -->
      <ui-checkbox
        [checked]="isChecked"
        (click)="$event.preventDefault(); r2ListChange.emit([])"
      />
    </ng-container>
  `,
  styles: [],
})
export class LogicR2SelectorComponent implements OnChanges {
  @Input() logic!: FormSpecificLogic;
  @Input() r1!: string;
  @Output() r2ListChange: EventEmitter<string[]>;
  isListOrCheck: boolean | undefined;
  isChecked: boolean | undefined;
  r2List: string[];

  constructor() {
    this.r2List = [];
    this.r2ListChange = new EventEmitter<string[]>();
  }

  // this can be rewritten for sure... but it works
  ngOnChanges(changes: SimpleChanges) {
    const logic = this.logic.logic.find((l) => l.r1 === this.r1);
    const type = this.logic.q2.type;
    this.r2List = logic?.r2 || [];
    this.isListOrCheck = type === 'LIST' || type == 'CHECK';
    this.isChecked = logic?.r1 === this.r1;
  }
}
