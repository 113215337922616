import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from './weather.reducer';
import { WeatherApiService } from '../services/weather-api.service';
import { forkJoin, of, take } from 'rxjs';
import { WeatherActions, WeatherSelectors } from './';

@Injectable()
export class WeatherEffects {
  constructor(
    private actions$: Actions,
    private weatherApi: WeatherApiService,
    private store: Store<State>
  ) {}

  fetchWeatherModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WeatherActions.fetchModel),
      switchMap(({ modelId, setEnabled }) =>
        this.weatherApi.fetchModelById(modelId).pipe(
          map((model) => {
            return { ...model, modelEnabled: !!setEnabled };
          })
        )
      ),
      map((model) => WeatherActions.fetchModelSuccess({ model }))
    )
  );

  fetchModelsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WeatherActions.fetchAllModels),
      switchMap(() => this.weatherApi.fetchModels()),
      map((modelList) => WeatherActions.fetchAllModelSuccess({ modelList }))
    )
  );

  // tiffLoading$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(setTiffLoading),
  //     mergeMap((action) =>
  //       of(action).pipe(
  //         withLatestFrom(
  //           this.store.pipe(
  //             select(selectTiffFetchError, { modelId: action.modelId })
  //           )
  //         )
  //       )
  //     ),
  //     switchMap(([action, tiffError]) =>
  //       /**
  //        * This effect is triggered BEFORE the new tiff fetch/request!
  //        * `tiffError` is the current value of `tiffFetchError` (WeatherModel), this value refers to the last fetched tiff.
  //        *
  //        * If `tiffError` is true (it means the last call did not succeed) then reset its value to false because we don't
  //        * know which status will have the next request.
  //        * If new fetch/request fails again, other component (WeatherBoxComponent) will dispatch proper action.
  //        */
  //       tiffError && action.loading
  //         ? [setTiffFetchError({ modelId: action.modelId, error: false })]
  //         : []
  //     )
  //   )
  // );
  // TODO check updateChartData effect, something went wrong here...
  // updateChartData$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(
  //       setLayer,
  //       setInitime,
  //       setChartEnabled,
  //       fetchChartData,
  //       setDateRange
  //     ),
  //     map((action) => action.modelId),
  //     mergeMap((modelId) =>
  //       of(modelId).pipe(
  //         withLatestFrom(
  //           this.store.pipe(select(selectWeatherModelById, { modelId }))
  //         ),
  //         withLatestFrom(this.store.pipe(select(selectLayer, { modelId }))),
  //         withLatestFrom(this.store.pipe(select(selectSelectedLngLat))),
  //         withLatestFrom(this.store.pipe(select(selectUtcOffset))),
  //         // withLatestFrom(this.store.pipe(select(selectInitime, {modelId}))),
  //         withLatestFrom(this.store.pipe(select(selectChartData, { modelId })))
  //       )
  //     ),
  //     // WHAT THE FUCK
  //     mergeMap(
  //       ([[[[[modelId, model], layer], lngLat], utcOffset], chartData]) => {
  //         return lngLat && model.chartEnabled
  //           ? forkJoin([
  //               [model],
  //               [chartData],
  //               this.weatherApi.getChartArray(model, layer, lngLat, utcOffset),
  //             ])
  //           : [];
  //       }
  //     ),
  //     switchMap(
  //       ([model, oldChartData, fetchedChartData]: [
  //         WeatherModel,
  //         ChartData[],
  //         ChartData[]
  //       ]) => {
  //         const chartData = fetchedChartData;
  //         fetchedChartData.map((d, index) => {
  //           if (
  //             oldChartData[index] &&
  //             oldChartData[index].legend === d.legend
  //           ) {
  //             // help!
  //             chartData[index].visible = oldChartData[index].visible;
  //           }
  //         });
  //         return [setChartData({ modelId: model.id, chartData })];
  //       }
  //     )
  //   )
  // );

  updateChartData2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WeatherActions.setLayer,
        WeatherActions.setInitime,
        WeatherActions.setChartEnabled,
        WeatherActions.fetchChartData,
        WeatherActions.setDateRange
      ),
      switchMap(({ modelId }) => {
        const state = this.store
          .select(WeatherSelectors.weatherState)
          .pipe(take(1));
        const model = this.store
          .select(WeatherSelectors.selectById(modelId))
          .pipe(take(1));
        const layer = this.store
          .select(WeatherSelectors.selectLayer(modelId))
          .pipe(take(1));

        return forkJoin([state, model, layer]);
      }),
      mergeMap(([state, model, layer]) => {
        /** these should be available at this point... if anything goes wrong just return empty list */
        // TODO this does not make sense...
        if (!model || !layer || !state.selectedLngLat) return [];
        return this.weatherApi
          .getChartArray(model, layer, state.selectedLngLat, state.utcOffset)
          .pipe(
            map((chartDataList) => {
              /**
               * getChartArray() will return a new list of ChartData where `visible` property is TRUE by default
               * (it determines if a specific Chart/ChartData is rendered - e.g. rainfall yes, rainfall accumulated not).
               *
               * Since user may have changed this property, we need to find and restore the current `visible` value
               * in the newly ChartData items.
               * */

              if (model.chartData.length === 0) {
                return WeatherActions.setChartData({
                  modelId: model.id,
                  chartData: chartDataList,
                });
              }

              const chartDataUpdatedVisible = chartDataList.map(
                (newChartData, index) => {
                  const currentVisibleChart =
                    model.chartData[index] &&
                    model.chartData[index].legend === newChartData.legend
                      ? model.chartData[index].visible
                      : true;
                  return { ...newChartData, visible: currentVisibleChart };
                }
              );

              return WeatherActions.setChartData({
                modelId: model.id,
                chartData: chartDataUpdatedVisible,
              });
            }),
            catchError((e) => {
              return of(
                WeatherActions.setChartData({
                  modelId: model.id,
                  chartData: [],
                })
              );
            })
          );
      })
    )
  );

  updateChartDataOnLngLat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WeatherActions.setSelectedLngLat),
      withLatestFrom(
        this.store.select(WeatherSelectors.selectModelsWithChartEnabled())
      ),
      switchMap(([lngLat, models]) =>
        models.map((model) =>
          WeatherActions.fetchChartData({ modelId: model.id })
        )
      )
    )
  );

  // disableChart$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(WeatherActions.setChartDisabled),
  //     map(({ modelId }) =>
  //       WeatherActions.setChartData({ modelId, chartData: [] })
  //     )
  //   )
  // );
}
