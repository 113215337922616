import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-marker-icon',
  template: `<img [width]="widthPx" [src]="getImage()" alt="" />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkerIconComponent {
  @Input() bitmap: ImageBitmap | null | undefined;
  @Input() widthPx: number = 30;

  getImage(): any {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx || !this.bitmap) return;
    ctx.canvas.width = this.bitmap.width;
    ctx.canvas.height = this.bitmap.height;
    ctx.drawImage(this.bitmap, 0, 0, this.bitmap.width, this.bitmap.height);
    return ctx.canvas.toDataURL();
  }
}
