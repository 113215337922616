import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkListComponent } from './containers/link-list/link-list.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '@trim-web-apps/core';
import { FormsModule } from '@angular/forms';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { LinkItemComponent } from './components/link-item/link-item.component';

@NgModule({
  declarations: [LinkListComponent, LinkItemComponent],
  imports: [
    CommonModule,
    // StoreModule.forFeature(ADMIN_LINK_FEATURE_KEY, reducer),
    SharedModule,
    CoreModule,
    FormsModule,
    Map3UiModule,
  ],
})
export class LinkModule {}
