import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { FormEffects } from './form.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([FormEffects])],
})
export class FormModule {}
