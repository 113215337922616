import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromProjectProp from './';

@Injectable({
  providedIn: 'root',
})
export class ProjectPropService {
  constructor(private store: Store) {}

  getProjectName(): Observable<string | null> {
    return this.store.pipe(select(fromProjectProp.selectName()));
  }

  getIsModified(): Observable<boolean> {
    return this.store.pipe(select(fromProjectProp.selectIsModified()));
  }

  setIsModified(modified: boolean): void {
    this.store.dispatch(fromProjectProp.SET_MODIFIED({ modified }));
  }
}
