import { Component, Input } from '@angular/core';
import { Form, FormSpecific, Record } from '3map-models';

@Component({
  selector: 'app-record-card-header[specific][record]',
  template: `
    <div class="card-header-wrapper" *ngIf="specific && form && record">
      <app-record-icon
        [widthPx]="50"
        [record]="record"
        [form]="form"
      ></app-record-icon>
      <div class="metadata selectable-text" *ngIf="record">
        <div class="title-bold">{{ specific.name }}</div>
        <div class="datetime secondary-text-bold">
          {{ record.datetimeUtc | date : 'dd LLL YYYY - HH:mm ZZZZ' }}
        </div>
        <div class="username secondary-text-bold">{{ record.username }}</div>
      </div>
    </div>
  `,
  styles: [
    `
      .card-header-wrapper {
        display: flex;
        align-items: center;
      }

      .metadata {
        margin-left: 10px;
      }

      .datetime,
      .username {
        margin-top: 5px;
      }
    `,
  ],
})
export class RecordCardHeaderComponent {
  @Input() record?: Record;
  @Input() specific?: FormSpecific;
  @Input() form?: Form;
}
