import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './containers/map/map.component';
import { MapModule } from '@trim-web-apps/map';
import { StoreModule } from '@ngrx/store';
import * as fromMapbox from './+state/map.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MapEffects } from './+state/map.effects';
import { MapFormCardComponent } from './components/map-form-card/map-form-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { DatasetLayerComponent } from './containers/dataset-layer/dataset-layer.component';
import { FeatureViewComponent } from './components/feature-view/feature-view.component';
import { RecordCardCompactComponent } from './components/record-card-compact/record-card-compact.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FeatureMetadataComponent } from './components/feature-metadata/feature-metadata.component';
import { MapContextMenuComponent } from './components/map-context-menu/map-context-menu.component';
import { MapResourceModule } from '../map-resource/map-resource.module';
import { WeatherCoreModule } from '@trim-web-apps/weather-core';
import { WeatherComponent } from './containers/weather/weather.component';
import { CoreModule } from '@trim-web-apps/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkMenuModule } from '@angular/cdk/menu';
import { FormCardLegendComponent } from './containers/form-card-legend/form-card-legend.component';
import { Map3UiModule } from '@trim-web-apps/map3-ui';

@NgModule({
  declarations: [
    MapComponent,
    MapFormCardComponent,
    DatasetLayerComponent,
    FeatureViewComponent,
    RecordCardCompactComponent,
    FeatureMetadataComponent,
    MapContextMenuComponent,
    WeatherComponent,
    FormCardLegendComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromMapbox.mapFeatureKey, fromMapbox.reducer),
    EffectsModule.forFeature([MapEffects]),
    MapModule,
    FontAwesomeModule,
    SharedModule,
    ScrollingModule,
    MapResourceModule,
    WeatherCoreModule,
    CoreModule,
    DragDropModule,
    CdkMenuModule,
    Map3UiModule,
  ],
  exports: [MapComponent],
})
export class MapBoxModule {}
