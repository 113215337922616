import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DUPLICATE_FORM, ON_DUPLICATE_FORM } from './form.actions';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromMarkerStyle from '../marker-style';
import * as fromForm from './form.selectors';
import { Form, FormSpecific } from '3map-models';
import { cloneSpecific } from '../specific/form-specific.functions';
import * as uuid from 'uuid';
import { createUniqueName } from '@trim-web-apps/core';

@Injectable({ providedIn: 'root' })
export class FormEffects {
  constructor(private actions$: Actions, private store: Store) {}

  onDuplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ON_DUPLICATE_FORM),
      switchMap(({ form }) =>
        combineLatest([
          this.store.pipe(select(fromMarkerStyle.selectEntities())),
          this.store.pipe(select(fromForm.selectFormList())),
          of(form),
        ]).pipe(take(1))
      ),
      map(([markerStyleDict, existingForms, form]) => {
        const existingFormNames = existingForms.map((f) => f.name);
        const newSpecificList: FormSpecific[] = [];
        const icons: { specificId: string; imgBase64List: string[] }[] = [];

        for (const specific of form.specificList) {
          const imgBase64List =
            markerStyleDict[specific.id]?.imgBase64List || [];
          const newSpecific = cloneSpecific(specific);
          newSpecificList.push({ ...newSpecific, name: specific.name });
          icons.push({ specificId: newSpecific.id, imgBase64List });
        }

        const newForm: Form = {
          ...form,
          id: uuid.v4(),
          name: createUniqueName(existingFormNames, form.name),
          specificList: newSpecificList,
        };
        return DUPLICATE_FORM({ form: newForm, icons });
      })
    )
  );
}
