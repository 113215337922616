import { createSelector } from '@ngrx/store';
import * as fromRoot from '../project.selectors';

export const selectState = createSelector(
  fromRoot.selectProjectState,
  (state) => state.projectProps
);

export const selectName = () =>
  createSelector(selectState, (state) => state.name);

export const selectIsModified = () =>
  createSelector(selectState, (state) => state.modified);
