import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, filter, map, Observable, take } from 'rxjs';
import { ChartData } from '@trim-web-apps/weather-models';
import { Store } from '@ngrx/store';
import { WeatherActions, WeatherSelectors } from '../../+state';
import { DownloadService, notNullOrUndefined } from '@trim-web-apps/core';
import * as papa from 'papaparse';
import { utcOffsetFormatter } from '../../utils/weather.functions';

@Component({
  selector: 'weather-core-chart',
  template: `
    <ng-container *ngIf="chartEnabled$ | async">
      <ng-container *ngIf="chartData$ | async as chartDataList">
        <div class="weather-chart-wrapper" *ngIf="chartDataList.length > 0">
          <weather-core-chart-actions
            [chartDataList]="chartDataList"
            (requestCsvDownload)="onDownloadCsv()"
            (chartDataVisibilityChanged)="onVisibilityChanged($event)"
            (chartModeChanged)="onChartModeChanged($event)"
          >
          </weather-core-chart-actions>

          <weather-core-chart-legend
            [chartDataList]="chartDataList"
          ></weather-core-chart-legend>

          <div class="standard" *ngIf="chartMode === 'Standard'">
            <div
              class="chart-inner-wrapper"
              *ngFor="let chartData of chartDataList; trackBy: trackBy"
            >
              <weather-core-chart-view
                *ngIf="chartData.visible"
                [xAxisLabels]="true"
                [xAxisLabelsColor]="'#666'"
                [showLegend]="false"
                [chartData]="chartData"
              >
              </weather-core-chart-view>
            </div>
          </div>

          <div *ngIf="chartMode === 'Combined'">
            <weather-core-chart-view-multi
              [chartDataList]="chartDataList"
              [xAxisLabels]="true"
            ></weather-core-chart-view-multi>
          </div>

          <div *ngIf="chartMode === 'Compact'">
            <div
              class="chart-inner-wrapper"
              *ngFor="
                let chartData of chartDataList;
                let i = index;
                trackBy: trackBy
              "
            >
              <div *ngIf="(chartVisibleCount$ | async)! > 1">
                <weather-core-chart-view
                  *ngIf="chartData.visible"
                  [chartData]="chartData"
                  [xAxisLabels]="true"
                  [xAxisLabelsColor]="
                    chartDataList.length > 1 && i === 0 ? '#fff' : '#666'
                  "
                  [xAxisLabelRotation]="{ min: 0, max: 0 }"
                  [xAxisPadding]="chartDataList.length > 1 && i === 0 ? -20 : 0"
                  [showLegend]="false"
                >
                </weather-core-chart-view>
              </div>

              <div *ngIf="(chartVisibleCount$ | async)! <= 1">
                <weather-core-chart-view
                  *ngIf="chartData.visible"
                  [chartData]="chartData"
                  [xAxisLabels]="true"
                  [xAxisLabelsColor]="'#666'"
                  [xAxisLabelRotation]="{ min: 0, max: 0 }"
                  [showLegend]="false"
                >
                </weather-core-chart-view>
              </div>
            </div>
          </div>
        </div>

        <div class="chart-point-alert" *ngIf="chartDataList.length === 0">
          Select a point on map
        </div>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      .chart-point-alert {
        text-align: center;
        margin: 10px 0;
      }
    `,
  ],
})
export class WeatherChartComponent implements OnInit {
  @Input() weatherModelId?: string;
  chartData$?: Observable<ChartData[]>;
  chartEnabled$?: Observable<boolean>;
  chartVisibleCount$?: Observable<number>;
  chartMode = 'Compact';

  constructor(private store: Store, private downloadService: DownloadService) {}

  ngOnInit(): void {
    if (!this.weatherModelId) return;
    this.chartEnabled$ = this.store
      .select(WeatherSelectors.selectById(this.weatherModelId))
      .pipe(
        filter(notNullOrUndefined),
        map((model) => model.chartEnabled)
      );

    this.chartData$ = this.store
      .select(WeatherSelectors.selectChartData(this.weatherModelId))
      .pipe(
        map((data) => {
          return data.map((d) => {
            return this.chartMode === 'Compact'
              ? {
                  ...d,
                  labels: d.labels.map((label) =>
                    (label as any).map((l: string) => `  ${l}  `)
                  ),
                  chartHeight: 150,
                }
              : { ...d, labels: d.labels.map((l) => `${l[1] || ''} ${l[0]}`) };
          });
        })
      );
    this.chartVisibleCount$ = this.chartData$.pipe(
      map((chartData) => chartData.filter((d) => d.visible).length)
    );
  }

  trackBy(index: number, data: ChartData) {
    return data.legend;
  }

  onChartModeChanged(mode: string): void {
    this.chartMode = mode;
  }

  onVisibilityChanged(evt: { data: ChartData; visibility: boolean }): void {
    if (this.weatherModelId)
      this.store.dispatch(
        WeatherActions.setChartDataVisibility({
          modelId: this.weatherModelId,
          data: evt.data,
          visibility: evt.visibility,
        })
      );
  }

  onDownloadCsv(): void {
    if (!this.weatherModelId) return;
    combineLatest([
      this.store.select(WeatherSelectors.selectById(this.weatherModelId)),
      this.store.select(WeatherSelectors.selectLayer(this.weatherModelId)),
      this.store.select(WeatherSelectors.selectSelectedLngLat()),
      this.store.select(WeatherSelectors.selectUtcOffset()),
      this.store.select(
        WeatherSelectors.selectDateRangeAsTimeUnit(this.weatherModelId)
      ),
    ])
      .pipe(take(1))
      .subscribe(([model, layer, lngLat, utcOffset, dateRange]) => {
        if (
          !model ||
          model.chartData.length === 0 ||
          !layer ||
          !lngLat ||
          !dateRange
        )
          return;
        const chartData = model.chartData;
        const utcString =
          layer.timestepType.type === 'UNIX'
            ? utcOffsetFormatter(utcOffset)
            : 'UTC';

        const dateList = chartData[0].labels.map((label) =>
          label[1] === undefined ? label[0] : `${label[1]} ${label[0]}`
        );
        const variableName1 = chartData[0].legend;
        const values1 = chartData[0].data;
        const variableName2 = chartData.length > 1 ? chartData[1].legend : null;
        const values2 = chartData.length > 1 ? chartData[1].data : null;

        const baseHeaders = ['Datetime', 'UTC offset', 'Latitude', 'Longitude'];
        const headers =
          variableName2 !== null // if layer has multiple attributes (e.g. wind)
            ? [...baseHeaders, variableName1, variableName2] // then add both as header
            : [...baseHeaders, variableName1]; // otherwise add only one

        const data = chartData[0].labels.map((d, index) => {
          const row = [
            dateList[index],
            utcString,
            lngLat.lat,
            lngLat.lng,
            values1[index],
          ];

          // if layer has multiple attributes (e.g. wind), then add it
          return variableName2 !== null && values2 !== null
            ? [...row, values2[index]]
            : row;
        });

        const range = `${dateRange.from.label}__${dateRange.to.label}`;
        const filename = `${model.label}__${layer.label}__${range}__${utcString}`;
        const csv2 = papa.unparse({ fields: headers, data });
        const blob = new Blob([csv2]);
        this.downloadService.showDownloadDialog(blob, `${filename}.csv`);
      });
  }
}
