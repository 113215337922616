import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTable from './+state/table.reducer';
import { TableEffects } from './+state/table.effects';
import { TableComponent } from './containers/table/table.component';
import { TableListComponent } from './containers/table-list/table-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from '../shared/shared.module';
import { TableTabsComponent } from './components/table-tabs/table-tabs.component';
import { CdkMenuModule } from '@angular/cdk/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { Map3TableModule } from '@trim-web-apps/map3-table';

@NgModule({
  declarations: [TableComponent, TableListComponent, TableTabsComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTable.FEATURE_KEY, fromTable.reducer),
    EffectsModule.forFeature([TableEffects]),
    AgGridModule,
    SharedModule,
    CdkMenuModule,
    FontAwesomeModule,
    Map3UiModule,
    Map3TableModule,
  ],
  exports: [TableListComponent],
})
export class TableModule {}
