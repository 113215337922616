import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { AgGridModule } from 'ag-grid-angular';
import { TableActionsComponent } from './table-actions/table-actions.component';
import { CdkMenuModule } from '@angular/cdk/menu';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { TableMediaIconComponent } from './table-media-icon/table-media-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule,
    CdkMenuModule,
    Map3UiModule,
    FontAwesomeModule,
  ],
  declarations: [
    TableComponent,
    TableActionsComponent,
    TableMediaIconComponent,
  ],
  exports: [TableComponent],
})
export class Map3TableModule {}
