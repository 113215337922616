import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { faXmark, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Notification } from '../notification.model';

@Component({
  selector: 'core-notification-item',
  template: `
    <div
      class="notification-wrapper"
      [ngClass]="notification.type"
      *ngIf="notification"
    >
      <div class="msg">
        {{ notification.msg }}
      </div>

      <fa-icon
        [icon]="iconClose"
        (click)="dismiss.emit(notification)"
      ></fa-icon>
    </div>
  `,
  styles: [
    `
      .notification-wrapper {
        padding: 10px;
        border-radius: 7px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px;
        /*height: 42px;*/
        user-select: text;
      }

      fa-icon {
        cursor: pointer;
        margin-left: 10px;
      }

      .msg {
        flex: 1 1 auto;
      }

      .INFO {
        background-color: #b4c6fb;
      }

      .SUCCESS {
        background-color: #4caf50;
      }

      .WARN {
        background-color: #ffc107;
      }

      .ERROR {
        background-color: #d32f2f;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent {
  @Input() notification: Notification | undefined;
  @Output() dismiss: EventEmitter<Notification> =
    new EventEmitter<Notification>();
  iconClose: IconDefinition = faXmark;
}
