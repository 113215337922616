import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { passwordValidator } from '../../utils/input.validators';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-input-password',
  template: `
    <div class="input-field" [ngClass]="{ 'input-error': error$ | async }">
      <fa-icon class="input-icon" [icon]="icon"></fa-icon>
      <input
        [type]="type"
        [formControl]="pwdControl"
        [autocomplete]="false"
        [placeholder]="label"
      />

      <fa-icon
        *ngIf="type === 'password'"
        class="input-icon password-show"
        [icon]="iconHidePassword"
        (click)="type = 'text'"
      ></fa-icon>
      <fa-icon
        *ngIf="type === 'text'"
        class="input-icon password-show"
        [icon]="iconShowPwd"
        (click)="type = 'password'"
      ></fa-icon>
    </div>
    <div class="input-error-msg" *ngIf="validation">{{ error$ | async }}</div>
  `,
  styleUrls: ['./input-password.component.scss'],
})
export class InputPasswordComponent implements OnInit {
  @Input() label = 'Password';
  @Input() validation = true;
  @Output()
  formControl: EventEmitter<UntypedFormControl> = new EventEmitter<UntypedFormControl>();
  pwdControl: UntypedFormControl;
  error$: Observable<string>;
  type: 'password' | 'text';
  icon = faKey;
  iconShowPwd = faEye;
  iconHidePassword = faEyeSlash;

  constructor() {
    this.pwdControl = new UntypedFormControl('');
    this.type = 'password';
    this.error$ = this.pwdControl.valueChanges.pipe(
      debounceTime(1000),
      map(() =>
        this.pwdControl.invalid
          ? `${this.pwdControl.errors?.['invalidPassword']?.errorMsg}`
          : ''
      )
    );
  }

  ngOnInit(): void {
    this.pwdControl.setValidators(
      this.validation ? [Validators.required, passwordValidator] : []
    );
    this.formControl.emit(this.pwdControl);
  }

  togglePwdVisible(): void {
    if (this.type === 'text') this.type = 'password';
    else this.type = 'text';
  }
}
