import { createAction, props } from '@ngrx/store';
import { ProjectUser } from '3map-models';

export const SET_USERS = createAction(
  '[ Project ] Set Users',
  props<{ users: ProjectUser[] }>()
);

export const REMOVE_USERS = createAction(
  '[ Project ] Remove Users',
  props<{ users: ProjectUser[] }>()
);
