import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WeatherModel } from '@trim-web-apps/weather-models';

@Component({
  selector: 'weather-core-model-status',
  template: `
    <div class="model-status-wrapper" *ngIf="weatherModel">
      <ui-checkbox
        [checked]="weatherModel.visible"
        [loading]="weatherModel.tiffLoading"
        [error]="weatherModel.tiffFetchError"
        [label]="weatherModel.label"
      ></ui-checkbox>
    </div>
  `,
  styles: [
    `
      .model-status-wrapper {
        display: flex;
        /*width: 200px;*/
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelStatusComponent {
  @Input() weatherModel?: WeatherModel;
}
