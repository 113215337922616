import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from '../shared/shared.module';
import { Map3UiModule } from '@trim-web-apps/map3-ui';

@NgModule({
  declarations: [SettingsComponent],
  imports: [CommonModule, SharedModule, Map3UiModule],
})
export class SettingsModule {}
