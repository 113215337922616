import { createAction, props } from '@ngrx/store';
import { ResAdminToken, ResProjectToken } from '3map-models';
import { Collaboration } from '../collaboration/collaboration.reducer';

export const doAdminLogin = createAction(
  '[Auth] Do Admin Login',
  props<{ username: string; password: string }>()
);

export const adminLoginSuccess = createAction(
  '[Auth] Admin Login Success',
  props<{ resAdminToken: ResAdminToken }>()
);

export const adminLoginError = createAction('[ Auth ] Admin Login Error');

export const doAdminProjectLogin = createAction(
  '[Auth] Do Admin Project Login',
  props<{ collaboration: Collaboration }>()
);

export const adminProjectLoginSuccess = createAction(
  '[Auth] Do Admin Project Login Success',
  props<{ resProjectToken: ResProjectToken }>()
);

export const adminProjectLoginError = createAction(
  '[Auth] Admin Project Login Error'
);

/** PROJECT/USER ACTIONS */

export const doProjectLogin = createAction(
  '[Auth] Do Project Login',
  props<{ collaboration: Collaboration }>()
);

export const projectLoginSuccess = createAction(
  '[Auth] Project Login Success',
  props<{ resProjectToken: ResProjectToken }>()
);

export const projectLoginError = createAction('[ Auth ] Project Login Error');

export const authLogout = createAction('[Auth] Logout ');

export const setProjectName = createAction(
  '[Auth] Set Project Login',
  props<{ projectName: string }>()
);
