import { storeFreeze } from 'ngrx-store-freeze';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { environment } from '../environments/environment';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as fromAuth from './auth/state/auth/auth.reducer';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  const keys: string[] | Object[] = [fromAuth.syncKey];
  return localStorageSync({ keys, rehydrate: true })(reducer);
}

// export const localStorageSyncReducer = (
//   reducer: ActionReducer<any>
// ): ActionReducer<any> => {
//   return (state, action) => {
//     if (action.type === INIT || action.type === UPDATE) {
//       const storageValue = localStorage.getItem(environment.LSK_STATE);
//       if (storageValue) {
//         try {
//           return JSON.parse(storageValue);
//         } catch {
//           localStorage.removeItem(environment.LSK_STATE);
//         }
//       }
//     }
//     const nextState = reducer(state, action);
//     localStorage.setItem(environment.LSK_STATE, JSON.stringify(nextState));
//     return nextState;
//   };
// };

export const metaReducers: MetaReducer[] = !environment.production
  ? [storeFreeze, localStorageSyncReducer]
  : [localStorageSyncReducer];
