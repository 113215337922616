import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FieldTypeComponent } from './components/field-type/field-type.component';
import { FieldBaseComponent } from './components/fields/field-base/field-base.component';
import { FieldListComponent } from './components/fields/field-list/field-list.component';
import { FieldNumberComponent } from './components/fields/field-number/field-number.component';
import { FieldRangeComponent } from './components/fields/field-range/field-range.component';
import { FieldTextComponent } from './components/fields/field-text/field-text.component';
import { FormListComponent } from './containers/form-list/form-list.component';
import { FormSpecificViewComponent } from './containers/form-specific-view/form-specific-view.component';
import { FormSpecificQuestionsComponent } from './components/form-specific-questions/form-specific-questions.component';
import { CanDeactivateGuard } from './utils/guards/can-deactivate.guard';
import { FormPropertiesComponent } from './components/form-properties/form-properties.component';
import { FieldTypeIconComponent } from './components/field-type-icon/field-type-icon.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { MarkerStyleSelectorComponent } from './components/marker-style-selector/marker-style-selector.component';
import { MarkerListComponent } from './components/marker-list/marker-list.component';
import { MarkerItemComponent } from './components/marker-item/marker-item.component';
import { FormIdToNamePipe } from './utils/pipes/form-id-to-name.pipe';
import { CoreModule } from '@trim-web-apps/core';
import { SharedModule } from '../shared/shared.module';
import { FormSpecificQuestionsGroupsComponent } from './containers/form-specific-questions-groups/form-specific-questions-groups.component';
import { FormSpecificQuestionsNoGroupComponent } from './containers/form-specific-questions-no-group/form-specific-questions-no-group.component';
import { RemoveQuestionGroupDialogComponent } from './components/remove-question-group-dialog/remove-question-group-dialog.component';
import { LogicFormSpecificComponent } from './containers/logic/logic-form-specific.component';
import { LogicItemComponent } from './components/logic-item/logic-item.component';
import { AddLogicItemComponent } from './components/add-logic-item/add-logic-item.component';
import { LogicQuestionsPickerComponent } from './components/logic-questions-picker/logic-questions-picker.component';
import { LogicR2SelectorComponent } from './components/logic-r2-selector/logic-r2-selector.component';
import { QuestionGroupRenameComponent } from './components/question-group-rename/question-group-rename.component';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { FormListItemComponent } from './components/form-list-item/form-list-item.component';

@NgModule({
  declarations: [
    FormListComponent,
    FormSpecificViewComponent,
    FieldTextComponent,
    FieldNumberComponent,
    FieldRangeComponent,
    FieldListComponent,
    FieldBaseComponent,
    FieldTypeComponent,
    FormSpecificQuestionsComponent,
    FormPropertiesComponent,
    FieldTypeIconComponent,
    FormDialogComponent,
    MarkerStyleSelectorComponent,
    MarkerListComponent,
    MarkerItemComponent,
    FormIdToNamePipe,
    FormSpecificQuestionsGroupsComponent,
    FormSpecificQuestionsNoGroupComponent,
    RemoveQuestionGroupDialogComponent,
    LogicFormSpecificComponent,
    LogicItemComponent,
    AddLogicItemComponent,
    LogicQuestionsPickerComponent,
    LogicR2SelectorComponent,
    QuestionGroupRenameComponent,
    FormListItemComponent,
  ],
  imports: [
    CommonModule,
    // StoreModule.forFeature(fromForm.ADMIN_FORM_FEATURE_KEY, fromForm.reducer),
    // StoreModule.forFeature(
    //   fromSpecific.ADMIN_SPECIFIC_FEATURE_KEY,
    //   fromSpecific.reducer
    // ),
    // StoreModule.forFeature(
    //   fromMarker.ADMIN_MARKER_STYLE_FEATURE_KEY,
    //   fromMarker.reducer
    // ),
    ReactiveFormsModule,
    FontAwesomeModule,
    FormsModule,
    DragDropModule,
    CoreModule,
    MatTooltipModule,
    RouterModule,
    SharedModule,
    MatTabsModule,
    Map3UiModule,
  ],
  exports: [FormIdToNamePipe],
  providers: [CanDeactivateGuard],
})
export class FormModule {}
