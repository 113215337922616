import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { WebHook } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
import { REMOVE_WEBHOOK, UPSERT_WEBHOOK } from './webhook.actions';
import * as projectActions from '../project.actions';

export interface State extends EntityState<WebHook> {}

export const adapter = createEntityAdapter<WebHook>({
  selectId: (webhook) => webhook.name,
});

const initialState = adapter.getInitialState();

const webHookReducer = createReducer(
  initialState,
  on(projectActions.RESET, () => initialState),
  on(projectActions.RESTORE_PROJECT_STATE, (state, { projectState }) => ({
    ...projectState.webhooks,
  })),
  on(projectActions.INIT_PROJECT, (state, { project }) => {
    return project.webHookList
      ? adapter.setAll(project.webHookList, state)
      : state;
  }),
  on(UPSERT_WEBHOOK, (state, { webHook }) => {
    return adapter.upsertOne(webHook, state);
  }),
  on(REMOVE_WEBHOOK, (state, { webHook }) => {
    return adapter.removeOne(webHook.name, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return webHookReducer(state, action);
}
