import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherComponent } from './containers/weather/weather.component';
import { WeatherPopupComponent } from './containers/weather-popup/weather-popup.component';
import { WeatherLegendComponent } from './components/weather-legend/weather-legend.component';
import { WeatherLegendLabelPipe } from './components/weather-legend/weather-legend-label.pipe';
import { WeatherChartViewComponent } from './components/weather-chart-view/weather-chart-view.component';
import { WeatherCanvasComponent } from './components/weather-canvas/weather-canvas.component';
import { WeatherChartComponent } from './containers/weather-chart/weather-chart.component';
import { WeatherBoxComponent } from './containers/weather/weather-box/weather-box.component';
import { WeatherChartLegendComponent } from './components/weather-chart-legend/weather-chart-legend.component';
import { WeatherChartViewMultiComponent } from './components/weather-chart-view-multi/weather-chart-view-multi.component';
import { WeatherChartMobileComponent } from './containers/weather-chart-mobile/weather-chart-mobile.component';
import { WeathersListComponent } from './containers/weathers-list/weathers-list.component';
import { WeatherItemComponent } from './containers/weather-item/weather-item.component';
import { OffsetLabelPipe } from './utils/pipes/offset-label.pipe';
import { DialogChangeModeComponent } from './components/dialog-change-mode/dialog-change-mode.component';
import { ChartActionsComponent } from './components/chart-actions/chart-actions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@trim-web-apps/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { LayerPickerComponent } from './containers/layer-picker/layer-picker.component';
import { TimestepPickerComponent } from './containers/timestep-picker/timestep-picker.component';
import { ModelStatusComponent } from './components/model-status-icon/model-status.component';
import { SettingsDesktopComponent } from './containers/setting-desktop/settings-desktop.component';
import { CdkMenuModule } from '@angular/cdk/menu';
import { WeatherEffects } from './+state/weather.effects';
import { weatherReducer } from './+state/weather.reducer';
import { DatetimePickerInlineComponent } from './components/date-range-picker-inline/datetime-picker-inline/datetime-picker-inline.component';
import { DateRangePickerInlineComponent } from './components/date-range-picker-inline/date-range-picker-inline.component';
import { WeatherItemMobileComponent } from './containers/weather-item/weather-item-mobile.component';
import { WeatherItemDesktopComponent } from './containers/weather-item/weather-item-desktop.component';
import { ModelInfoComponent } from './components/model-info/model-info.component';
import { DialogModule } from '@angular/cdk/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DateIndexTestComponent } from './components/date-index-test/date-index-test.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    WeatherComponent,
    WeatherPopupComponent,
    WeatherLegendComponent,
    WeatherLegendLabelPipe,
    WeatherChartViewComponent,
    WeatherCanvasComponent,
    WeatherChartComponent,
    WeatherBoxComponent,
    WeatherChartLegendComponent,
    WeatherChartViewMultiComponent,
    WeatherChartMobileComponent,
    WeathersListComponent,
    WeatherItemComponent,
    OffsetLabelPipe,
    DialogChangeModeComponent,
    ChartActionsComponent,
    LayerPickerComponent,
    TimestepPickerComponent,
    ModelStatusComponent,
    SettingsDesktopComponent,
    DateRangePickerInlineComponent,
    DatetimePickerInlineComponent,
    WeatherItemMobileComponent,
    WeatherItemDesktopComponent,
    ModelInfoComponent,
    DateIndexTestComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    CoreModule,
    MatMenuModule,
    MatButtonModule,
    FontAwesomeModule,
    StoreModule.forFeature('weather', weatherReducer),
    EffectsModule.forFeature([WeatherEffects]),
    MatTooltipModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    PortalModule,
    OverlayModule,
    HttpClientModule,
    RouterModule,
    Map3UiModule,
    CdkMenuModule,
    DialogModule,
    DragDropModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      // deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  exports: [
    WeatherComponent,
    WeatherPopupComponent,
    WeatherChartComponent,
    WeatherLegendComponent,
    WeatherChartViewMultiComponent,
    WeatherChartMobileComponent,
    WeathersListComponent,
    WeatherItemComponent,
    OffsetLabelPipe,
    WeatherChartViewComponent,
    TimestepPickerComponent
  ],
})
export class WeatherCoreModule {}
