import { Injectable } from '@angular/core';
import { ApiService } from '@trim-web-apps/api3map';
import { MapResource } from './map-resource.model';
import { map, Observable, of, switchMap } from 'rxjs';
import { blobImageToBase64, blobToString } from '@trim-web-apps/core';
import { catchError } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({ providedIn: 'root' })
export class MapResourceService {
  private fetchedImageBase64: { [resourceFilename: string]: string } = {};
  private fetchedGeojson: { [resourceFilename: string]: string } = {};

  constructor(private api: ApiService) {}

  /**
   * Fetch remote Resource and save result in map-like object:
   * - IMAGE: {@link fetchedImageBase64} - saved as base64
   * - GEOJSON: {@link fetchedGeojson} - saved as plain text/string
   *
   * In both cases, keys are the Resource filename (uuid).
   * Saving them here should behave like a cache... but:
   * @todo avoid base64 images transform and/or move them to some browser storage
   * @param mapResource
   * @param projectName
   */
  fetchImage(
    mapResource: MapResource,
    projectName: string
  ): Observable<boolean> {
    const key = mapResource.filename;
    const type = mapResource.type;
    if (type === 'IMAGE' && this.fetchedImageBase64[key]) return of(true);
    if (type === 'GEOJSON' && this.fetchedGeojson[key]) return of(true);

    return this.api
      .fetchAdminStaticFile(mapResource.filename, projectName)
      .pipe(
        switchMap((blob) => {
          if (type === 'IMAGE') {
            return fromPromise(blobImageToBase64(blob)).pipe(
              map((base64) => {
                this.fetchedImageBase64[key] = base64;
                return true;
              })
            );
          }
          if (type === 'GEOJSON') {
            return blobToString(blob).pipe(
              map((json) => {
                this.fetchedGeojson[key] = json;
                return true;
              }),
              catchError(() => of(false))
            );
          }
          return of(false);
        }),
        catchError(() => of(false))
      );
  }

  getGeojson(mapResource: MapResource): string | null {
    return this.fetchedGeojson[mapResource.filename] || null;
  }

  getImageBase64(mapResource: MapResource): string | null {
    return this.fetchedImageBase64[mapResource.filename] || null;
  }
}
