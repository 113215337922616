import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[coreMouseDrag]'
})
export class MouseDragDirective {
  @Output() onDrag: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  private dragEnabled: boolean | undefined;

  @HostListener('window:mousemove', ['$event'])
  private _onWindowMouseMove(evt: MouseEvent): void {
    if (this.dragEnabled) {
      this.onDrag.emit(evt);
    }
  }

  @HostListener('window:mouseup', ['$event'])
  private _onWindowMouseUp(): void {
    this.dragEnabled = false;
  }

  @HostListener('mouseup', ['$event'])
  private _onMouseUp() {
    this.dragEnabled = false;
  }

  @HostListener('mousedown', ['$event'])
  private _onMouseDown() {
    this.dragEnabled = true;
  }
}
