import { Component } from '@angular/core';
import { Form, ProjectUser } from '3map-models';
import {
  UserDialogComponent,
  UserDialogData,
} from '../../components/user-dialog/user-dialog.component';
import { take } from 'rxjs/operators';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { lastValueFrom, map, Observable } from 'rxjs';
import * as UserSelectors from '../../+state/user.selectors';
import * as UserActions from '../../+state/user.actions';
import * as FormSelectors from '../../../form/+state/form.selectors';
import { Store } from '@ngrx/store';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-user-list',
  template: `
    <div class="header">
      <app-section-label sectionLabel="Users">
        <ui-btn
          label="Add Users"
          [icon]="iconAdd"
          (click)="showUserDialog()"
          type="border"
        />
      </app-section-label>
    </div>

    <div class="user-list-container">
      <app-user-list-item
        *ngFor="let user of userList$ | async"
        (removeUser)="onUserRemove($event)"
        (editUser)="showUserDialog($event)"
        [user]="user"
      >
      </app-user-list-item>
    </div>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .user-list-container {
        margin: var(--spacing-2) var(--spacing-4);
        overflow: auto;
      }
    `,
  ],
})
export class UserListComponent {
  userList$: Observable<ProjectUser[]>;
  formList$: Observable<Form[]>;
  iconAdd = faPlus;

  constructor(private dialog: Dialog, private store: Store) {
    this.userList$ = this.store
      .select(UserSelectors.selectUsers())
      .pipe(
        map((users) =>
          users.sort((a, b) => a.username.localeCompare(b.username))
        )
      );
    this.formList$ = this.store.select(FormSelectors.selectFormList());
  }

  onUserRemove(user: ProjectUser): void {
    this.store.dispatch(UserActions.removeUsers({ users: [user] }));
  }

  async showUserDialog(user?: ProjectUser): Promise<void> {
    const formList = await lastValueFrom(this.formList$.pipe(take(1)));
    const data: UserDialogData = {
      userList: user ? [user] : null,
      formList: formList,
    };
    this.dialog.open(UserDialogComponent, { data });
  }
}
