/* eslint-disable */

export const colors =
    {
        'wind_gust': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 3, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 100],
            'colorsHex': ['transparent', '#ffffff', '#cddeff', '#688fff', '#5600e6', '#00cc00', '#007800', '#f2ff00', '#ff9d1a', '#ff0000', '#a90000', '#ff00ff', '#878787', '#000000', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [255, 255, 255, 255], [205, 222, 255, 255], [104, 143, 255, 255], [86, 0, 230, 255], [0, 204, 0, 255], [0, 120, 0, 255], [242, 255, 0, 255], [255, 157, 26, 255], [255, 0, 0, 255], [169, 0, 0, 255], [255, 0, 255, 255], [135, 135, 135, 255], [0, 0, 0, 255], [0, 0, 0, 0]]
        },
        'wind': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 3, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 100],
            'colorsHex': ['#ffffff', '#ffffff', '#E1EBFF', '#ABC1FF', '#7B85E4', '#B1EAB1', '#6EBF6E', '#FBFFB1', '#FFC16D', '#FF8E8E', '#BB7878', '#FF89FF', '#B5B5B5', '#7C7C7C', 'transparent'],
            'colorsArray': [[255, 255, 255, 255], [255, 255, 255, 255], [225, 235, 255, 255], [171, 193, 255, 255], [123, 133, 228, 255], [177, 234, 177, 255], [110, 191, 110, 255], [251, 255, 177, 255], [255, 193, 109, 255], [255, 142, 142, 255], [187, 120, 120, 255], [255, 137, 255, 255], [181, 181, 181, 255], [124, 124, 124, 255], [0, 0, 0, 0]]
        },
        'pressure': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 984, 988, 992, 996, 1000, 1004, 1008, 1012, 1016, 1020, 1024, 1028, 1032, 1036, 1040, 10000],
            'colorsHex': ['transparent', '#8700df', '#5700f2', '#574af5', '#5d6ffa', '#6589fa', '#82a4fe', '#98c8fe', '#aff0fc', '#f2ff51', '#f4fd00', '#ffa900', '#ff5700', '#ff0000', '#e70000', '#c50000', '#a90000', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [135, 0, 223, 255], [87, 0, 242, 255], [87, 74, 245, 255], [93, 111, 250, 255], [101, 137, 250, 255], [130, 164, 254, 255], [152, 200, 254, 255], [175, 240, 252, 255], [242, 255, 81, 255], [244, 253, 0, 255], [255, 169, 0, 255], [255, 87, 0, 255], [255, 0, 0, 255], [231, 0, 0, 255], [197, 0, 0, 255], [169, 0, 0, 255], [0, 0, 0, 0]]
        },
        'mean_sea_level_pressure': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 984, 988, 992, 996, 1000, 1004, 1008, 1012, 1016, 1020, 1024, 1028, 1032, 1036, 1040, 10000],
            'colorsHex': ['transparent', '#8700df', '#5700f2', '#574af5', '#5d6ffa', '#6589fa', '#82a4fe', '#98c8fe', '#aff0fc', '#f2ff51', '#f4fd00', '#ffa900', '#ff5700', '#ff0000', '#e70000', '#c50000', '#a90000', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [135, 0, 223, 255], [87, 0, 242, 255], [87, 74, 245, 255], [93, 111, 250, 255], [101, 137, 250, 255], [130, 164, 254, 255], [152, 200, 254, 255], [175, 240, 252, 255], [242, 255, 81, 255], [244, 253, 0, 255], [255, 169, 0, 255], [255, 87, 0, 255], [255, 0, 0, 255], [231, 0, 0, 255], [197, 0, 0, 255], [169, 0, 0, 255], [0, 0, 0, 0]]
        },
        'temperature': {
            'ranges': [Number.NEGATIVE_INFINITY, -33, -30, -27, -24, -21, -18, -15, -12, -9, -6, -3, 0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 34, 38, 42, 46, 50, 54, 100],
            'colorsHex': ['#e0d0ff', '#e0d0ff', '#b349ff', '#9600ef', '#8700df', '#5c00b6', '#4b00d6', '#5246e8', '#6589fa', '#82a4fe', '#98c8fe', '#aff0fc', '#dfffe5', '#abffb1', '#00e27b', '#00bd00', '#52e700', '#b9ff00', '#eaff00', '#f9e000', '#ffa400', '#ff5c00', '#ff0000', '#d40000', '#a00000', '#7b0000', '#a9009b', '#ff00ff', '#ff7dff', 'transparent'],
            'colorsArray': [[224, 208, 255, 255], [224, 208, 255, 255], [179, 73, 255, 255], [150, 0, 239, 255], [135, 0, 223, 255], [92, 0, 182, 255], [75, 0, 214, 255], [82, 70, 232, 255], [101, 137, 250, 255], [130, 164, 254, 255], [152, 200, 254, 255], [175, 240, 252, 255], [223, 255, 229, 255], [171, 255, 177, 255], [0, 226, 123, 255], [0, 189, 0, 255], [82, 231, 0, 255], [185, 255, 0, 255], [234, 255, 0, 255], [249, 224, 0, 255], [255, 164, 0, 255], [255, 92, 0, 255], [255, 0, 0, 255], [212, 0, 0, 255], [160, 0, 0, 255], [123, 0, 0, 255], [169, 0, 155, 255], [255, 0, 255, 255], [255, 125, 255, 255], [0, 0, 0, 0]]
        },
        'temperature_surface': {
            'ranges': [Number.NEGATIVE_INFINITY, -33, -30, -27, -24, -21, -18, -15, -12, -9, -6, -3, 0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 34, 38, 42, 46, 50, 54, 100],
            'colorsHex': ['#e0d0ff', '#e0d0ff', '#b349ff', '#9600ef', '#8700df', '#5c00b6', '#4b00d6', '#5246e8', '#6589fa', '#82a4fe', '#98c8fe', '#aff0fc', '#dfffe5', '#abffb1', '#00e27b', '#00bd00', '#52e700', '#b9ff00', '#eaff00', '#f9e000', '#ffa400', '#ff5c00', '#ff0000', '#d40000', '#a00000', '#7b0000', '#a9009b', '#ff00ff', '#ff7dff', 'transparent'],
            'colorsArray': [[224, 208, 255, 255], [224, 208, 255, 255], [179, 73, 255, 255], [150, 0, 239, 255], [135, 0, 223, 255], [92, 0, 182, 255], [75, 0, 214, 255], [82, 70, 232, 255], [101, 137, 250, 255], [130, 164, 254, 255], [152, 200, 254, 255], [175, 240, 252, 255], [223, 255, 229, 255], [171, 255, 177, 255], [0, 226, 123, 255], [0, 189, 0, 255], [82, 231, 0, 255], [185, 255, 0, 255], [234, 255, 0, 255], [249, 224, 0, 255], [255, 164, 0, 255], [255, 92, 0, 255], [255, 0, 0, 255], [212, 0, 0, 255], [160, 0, 0, 255], [123, 0, 0, 255], [169, 0, 155, 255], [255, 0, 255, 255], [255, 125, 255, 255], [0, 0, 0, 0]]
        },
        'relative_humidity': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 20, 40, 60, 80, 99, 101],
            'colorsHex': ['#ff0000', '#ff0000', '#efff00', '#00ff00', '#00ffff', '#4548e0', '#ff00ff', 'transparent'],
            'colorsArray': [[255, 0, 0, 255], [255, 0, 0, 255], [239, 255, 0, 255], [0, 255, 0, 255], [0, 255, 255, 255], [69, 72, 224, 255], [255, 0, 255, 255], [0, 0, 0, 0]]
        },
        'accumulated_precipitation': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 0.5, 1, 3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 100, 125, 150, 175, 200, 250, 350, 1000],
            'colorsHex': ['transparent', 'transparent', '#94ffff', '#53b1ff', '#6371ff', '#7f00ff', '#7300ff', '#00cf3b', '#8fee00', '#f1fd00', '#f7d300', '#ff7f00', '#dc0000', '#cd0000', '#de00c2', '#c1009e', '#900091', '#b3b3b3', '#8b8b8b', '#5b5b5b', '#2f2f2f', '#9e8312', '#7e5900', '#633700', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [148, 255, 255, 255], [83, 177, 255, 255], [99, 113, 255, 255], [127, 0, 255, 255], [115, 0, 255, 255], [0, 207, 59, 255], [143, 238, 0, 255], [241, 253, 0, 255], [247, 211, 0, 255], [255, 127, 0, 255], [220, 0, 0, 255], [205, 0, 0, 255], [222, 0, 194, 255], [193, 0, 158, 255], [144, 0, 145, 255], [179, 179, 179, 255], [139, 139, 139, 255], [91, 91, 91, 255], [47, 47, 47, 255], [158, 131, 18, 255], [126, 89, 0, 255], [99, 55, 0, 255], [0, 0, 0, 0]]
        },
        'precipitation_rate': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 0.5, 1, 3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 100, 125, 150, 175, 200, 250, 350, 1000],
            'colorsHex': ['transparent', 'transparent', '#94ffff', '#53b1ff', '#6371ff', '#7f00ff', '#7300ff', '#00cf3b', '#8fee00', '#f1fd00', '#f7d300', '#ff7f00', '#dc0000', '#cd0000', '#de00c2', '#c1009e', '#900091', '#b3b3b3', '#8b8b8b', '#5b5b5b', '#2f2f2f', '#9e8312', '#7e5900', '#633700', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [148, 255, 255, 255], [83, 177, 255, 255], [99, 113, 255, 255], [127, 0, 255, 255], [115, 0, 255, 255], [0, 207, 59, 255], [143, 238, 0, 255], [241, 253, 0, 255], [247, 211, 0, 255], [255, 127, 0, 255], [220, 0, 0, 255], [205, 0, 0, 255], [222, 0, 194, 255], [193, 0, 158, 255], [144, 0, 145, 255], [179, 179, 179, 255], [139, 139, 139, 255], [91, 91, 91, 255], [47, 47, 47, 255], [158, 131, 18, 255], [126, 89, 0, 255], [99, 55, 0, 255], [0, 0, 0, 0]]
        },
        'cloud_cover': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 10, 25, 40, 55, 70, 85, 100, 101],
            'colorsHex': ['transparent', 'transparent', '#f2f2f2', '#d3d3d3', '#b0b0b0', '#8c8c8c', '#606060', '#404040', '#404040', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [242, 242, 242, 255], [211, 211, 211, 255], [176, 176, 176, 255], [140, 140, 140, 255], [96, 96, 96, 255], [64, 64, 64, 255], [64, 64, 64, 255], [0, 0, 0, 0]]
        },
        'low_cloud_cover': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 10, 25, 40, 55, 70, 85, 100, 101],
            'colorsHex': ['transparent', 'transparent', '#f2f2f2', '#d3d3d3', '#b0b0b0', '#8c8c8c', '#606060', '#404040', '#404040', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [242, 242, 242, 255], [211, 211, 211, 255], [176, 176, 176, 255], [140, 140, 140, 255], [96, 96, 96, 255], [64, 64, 64, 255], [64, 64, 64, 255], [0, 0, 0, 0]]
        },
        'medium_cloud_cover': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 10, 25, 40, 55, 70, 85, 100, 101],
            'colorsHex': ['transparent', 'transparent', '#f2f2f2', '#d3d3d3', '#b0b0b0', '#8c8c8c', '#606060', '#404040', '#404040', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [242, 242, 242, 255], [211, 211, 211, 255], [176, 176, 176, 255], [140, 140, 140, 255], [96, 96, 96, 255], [64, 64, 64, 255], [64, 64, 64, 255], [0, 0, 0, 0]]
        },
        'high_cloud_cover': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 10, 25, 40, 55, 70, 85, 100, 101],
            'colorsHex': ['transparent', 'transparent', '#f2f2f2', '#d3d3d3', '#b0b0b0', '#8c8c8c', '#606060', '#404040', '#404040', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [242, 242, 242, 255], [211, 211, 211, 255], [176, 176, 176, 255], [140, 140, 140, 255], [96, 96, 96, 255], [64, 64, 64, 255], [64, 64, 64, 255], [0, 0, 0, 0]]
        },
        'altitude': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 11, 40, 65, 90, 115, 140, 165, 190, 8000],
            'colorsHex': ['#1fa5ff', '#1fa5ff', '#75ff73', '#45cc42', '#159810', '#4ca701', '#b8d702', '#eada03', '#a98102', '#692901', 'transparent'],
            'colorsArray': [[31, 165, 255, 255], [31, 165, 255, 255], [117, 255, 115, 255], [69, 204, 66, 255], [21, 152, 16, 255], [76, 167, 1, 255], [184, 215, 2, 255], [234, 218, 3, 255], [169, 129, 2, 255], [105, 41, 1, 255], [0, 0, 0, 0]]
        },
        'turbolence': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 0.04, 0.08, 0.13, 0.19, 0.26, 0.33, 0.41, 0.49, 0.59, 0.69, 0.8, 0.92, 1.05, 1.21, 1.39, 1.63, 1.92, 2.3, 3.15, 10],
            'colorsHex': ['transparent', '#1e00ff', '#361bff', '#4d36ff', '#6551ff', '#7d6bff', '#9486ff', '#aca1ff', '#c4bcff', '#dbd7ff', '#f3f2ff', '#fff2f2', '#ffd7d7', '#febdbd', '#fea2a2', '#fe8888', '#fd6d6d', '#fd5353', '#fd3838', '#fc1e1e', '#fc0303', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [30, 0, 255, 255], [54, 27, 255, 255], [77, 54, 255, 255], [101, 81, 255, 255], [125, 107, 255, 255], [148, 134, 255, 255], [172, 161, 255, 255], [196, 188, 255, 255], [219, 215, 255, 255], [243, 242, 255, 255], [255, 242, 242, 255], [255, 215, 215, 255], [254, 189, 189, 255], [254, 162, 162, 255], [254, 136, 136, 255], [253, 109, 109, 255], [253, 83, 83, 255], [253, 56, 56, 255], [252, 30, 30, 255], [252, 3, 3, 255], [0, 0, 0, 0]]
        },
        'rainfall_1day': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 2, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 200, 250, 300, 350, 400, 450, 1000],
            'colorsHex': ['transparent', 'transparent', '#dffdfd', '#94ffff', '#53b1ff', '#6371ff', '#7f00ff', '#7300ff', '#00cf3b', '#8fee00', '#f1fd00', '#f7d300', '#ff7f00', '#dc0000', '#cd0000', '#de00c2', '#900091', '#b3b3b3', '#8b8b8b', '#5b5b5b', '#2f2f2f', '#9e8312', '#7e5900', '#633700', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [223, 253, 253, 255], [148, 255, 255, 255], [83, 177, 255, 255], [99, 113, 255, 255], [127, 0, 255, 255], [115, 0, 255, 255], [0, 207, 59, 255], [143, 238, 0, 255], [241, 253, 0, 255], [247, 211, 0, 255], [255, 127, 0, 255], [220, 0, 0, 255], [205, 0, 0, 255], [222, 0, 194, 255], [144, 0, 145, 255], [179, 179, 179, 255], [139, 139, 139, 255], [91, 91, 91, 255], [47, 47, 47, 255], [158, 131, 18, 255], [126, 89, 0, 255], [99, 55, 0, 255], [0, 0, 0, 0]]
        },
        'rainfall_3day': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 2, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 200, 250, 300, 350, 400, 450, 1000],
            'colorsHex': ['transparent', 'transparent', '#dffdfd', '#94ffff', '#53b1ff', '#6371ff', '#7f00ff', '#7300ff', '#00cf3b', '#8fee00', '#f1fd00', '#f7d300', '#ff7f00', '#dc0000', '#cd0000', '#de00c2', '#900091', '#b3b3b3', '#8b8b8b', '#5b5b5b', '#2f2f2f', '#9e8312', '#7e5900', '#633700', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [223, 253, 253, 255], [148, 255, 255, 255], [83, 177, 255, 255], [99, 113, 255, 255], [127, 0, 255, 255], [115, 0, 255, 255], [0, 207, 59, 255], [143, 238, 0, 255], [241, 253, 0, 255], [247, 211, 0, 255], [255, 127, 0, 255], [220, 0, 0, 255], [205, 0, 0, 255], [222, 0, 194, 255], [144, 0, 145, 255], [179, 179, 179, 255], [139, 139, 139, 255], [91, 91, 91, 255], [47, 47, 47, 255], [158, 131, 18, 255], [126, 89, 0, 255], [99, 55, 0, 255], [0, 0, 0, 0]]
        },
        'rainfall_7day': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 2, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 200, 250, 300, 350, 400, 450, 1000],
            'colorsHex': ['transparent', 'transparent', '#dffdfd', '#94ffff', '#53b1ff', '#6371ff', '#7f00ff', '#7300ff', '#00cf3b', '#8fee00', '#f1fd00', '#f7d300', '#ff7f00', '#dc0000', '#cd0000', '#de00c2', '#900091', '#b3b3b3', '#8b8b8b', '#5b5b5b', '#2f2f2f', '#9e8312', '#7e5900', '#633700', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [223, 253, 253, 255], [148, 255, 255, 255], [83, 177, 255, 255], [99, 113, 255, 255], [127, 0, 255, 255], [115, 0, 255, 255], [0, 207, 59, 255], [143, 238, 0, 255], [241, 253, 0, 255], [247, 211, 0, 255], [255, 127, 0, 255], [220, 0, 0, 255], [205, 0, 0, 255], [222, 0, 194, 255], [144, 0, 145, 255], [179, 179, 179, 255], [139, 139, 139, 255], [91, 91, 91, 255], [47, 47, 47, 255], [158, 131, 18, 255], [126, 89, 0, 255], [99, 55, 0, 255], [0, 0, 0, 0]]
        },
        'rainfall_monthly': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 5, 10, 20, 30, 40, 50, 60, 80, 100, 150, 200, 300, 400, 500, 750, 1000, 1500, 2000, 2500],
            'colorsHex': ['transparent', 'transparent', '#DFFDFD', '#A9FFFF', '#A5D6FF', '#ABB3FF', '#C994FF', '#B578FF', '#94CDA4', '#B8EA6D', '#FAFF9C', '#F7E88E', '#FFCC9A', '#DDA3A3', '#CC8080', '#D9A2D2', '#936994', '#D9D9D9', '#B8B8B8', '#979595', '#858585', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [223, 253, 253, 255], [169, 255, 255, 255], [165, 214, 255, 255], [171, 179, 255, 255], [201, 148, 255, 255], [181, 120, 255, 255], [148, 205, 164, 255], [184, 234, 109, 255], [250, 255, 156, 255], [247, 232, 142, 255], [255, 204, 154, 255], [221, 163, 163, 255], [204, 128, 128, 255], [217, 162, 210, 255], [147, 105, 148, 255], [217, 217, 217, 255], [184, 184, 184, 255], [151, 149, 149, 255], [133, 133, 133, 255], [0, 0, 0, 0]]
        },
        'alert_rainfall_1day': {
            'ranges': [-1, 0, 1, 2, 3, 1000],
            'colorsHex': ['transparent', 'transparent', '#f1fd00', '#ff7f00', '#dc0000', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [241, 253, 0, 255], [255, 127, 0, 255], [220, 0, 0, 255], [0, 0, 0, 0]]
        },
        'alert_rainfall_3day': {
            'ranges': [-1, 0, 1, 2, 3, 1000],
            'colorsHex': ['transparent', 'transparent', '#f1fd00', '#ff7f00', '#dc0000', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [241, 253, 0, 255], [255, 127, 0, 255], [220, 0, 0, 255], [0, 0, 0, 0]]
        },
        'alert_rainfall_7day': {
            'ranges': [-1, 0, 1, 2, 3, 1000],
            'colorsHex': ['transparent', 'transparent', '#f1fd00', '#ff7f00', '#dc0000', 'transparent'],
            'colorsArray': [[0, 0, 0, 0], [0, 0, 0, 0], [241, 253, 0, 255], [255, 127, 0, 255], [220, 0, 0, 255], [0, 0, 0, 0]]
        },
        'rainfall_monthly_anomalies': {
            'ranges': [Number.NEGATIVE_INFINITY, -400, -200, -100, -50, -20, 20, 50, 100, 200, Number.NEGATIVE_INFINITY],
            'colorsHex': ['#FE5A5A', '#FE5A5A', '#FE7070', '#FEB8B8', '#FEDCDC', '#FEFEFE', '#C8C8FE', '#8080FE', '#4B4BFE', '#0000FE', '#0000FE'],
            'colorsArray': [[254, 90, 90, 255], [254, 90, 90, 255], [254, 112, 112, 255], [254, 184, 184, 255], [254, 220, 220, 255], [254, 254, 254, 255], [200, 200, 254, 255], [128, 128, 254, 255], [75, 75, 254, 255], [0, 0, 254, 255], [0, 0, 254, 255]]
        },
        'current': {
            'ranges': [Number.NEGATIVE_INFINITY, 0, 0.1, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2],
            'colorsHex': ['#ffffff', '#ffffff', '#CCE5FF', '#52ACFF', '#0D4DE2', '#02B500', '#016A04', '#FFF201', '#FF983F', '#FF0F00', '#A50000', '#FF00FF', '#888888', '#000000'],
            'colorsArray': [[255, 255, 255, 255], [255, 255, 255, 255], [204, 229, 255, 255], [82, 172, 255, 255], [13, 77, 226, 255], [2, 181, 0, 255], [1, 106, 4, 255], [255, 242, 1, 255], [255, 152, 63, 255], [255, 15, 0, 255], [165, 0, 0, 255], [255, 0, 255, 255], [136, 136, 136, 255], [0, 0, 0, 255]]
        }
    }
