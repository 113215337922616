import { FieldType } from '3map-models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import {
  isString,
  validatorArrayUniqueItems,
  validatorNotEmptyArray,
} from '@trim-web-apps/core';

import { FieldComponent } from '../FieldComponent';

@Component({
  providers: [
    {
      provide: FieldComponent,
      useExisting: forwardRef(() => FieldListComponent),
    },
  ],
  selector: 'app-field-list',
  template: `
    <form [formGroup]="formGroup">
      <div class="question-name">
        <input formControlName="question" type="text" placeholder="Question" />
        <div *ngIf="!questionControl.valid" class="error-label">
          Title is required
        </div>
      </div>

      <div class="answers-section" formArrayName="answers">
        <div
          *ngIf="answers.length > 0"
          class="collapse-answer-toggle"
          (click)="answersCollapsed = !answersCollapsed"
        >
          {{ answersCollapsed ? 'Show' : 'Hide' }} answers
        </div>
        <div *ngIf="duplicateError" class="error-label">
          Each answer must be unique
        </div>
        <div *ngIf="emptyAnswersError" class="error-label">
          At least one answer is required
        </div>

        <div *ngIf="!answersCollapsed">
          <div
            cdkDropList
            (cdkDropListDropped)="drop($event)"
            class="drop-list"
          >
            <div
              cdkDrag
              class="answer"
              *ngFor="let answer of answers.controls; let i = index"
            >
              <div class="answer-input">
                <fa-icon
                  cdkDragHandle
                  class="drag-icon"
                  [icon]="iconDrag"
                ></fa-icon>
                <input [formControlName]="i" type="text" class="answer-text" />
                <fa-icon
                  class="remove-icon"
                  [icon]="iconRemove"
                  (click)="removeAnswer(i)"
                ></fa-icon>
              </div>

              <div
                *ngIf="answers.controls[i].errors && answers.controls[i].dirty"
                class="error-label"
              >
                Answer cannot be empty
              </div>
            </div>
          </div>

          <button class="btn-success" (click)="addAnswer()">Add answer</button>
        </div>
      </div>
    </form>
  `,
  styles: [
    `
      input {
        width: 100%;
      }

      .question-name,
      .answer {
        background-color: #fff;
        margin-bottom: 10px;
      }

      .answer-input {
        display: flex;
        align-items: center;
        margin-top: 10px;
      }

      .answer .error-label {
        margin-left: 38px;
      }

      .remove-icon,
      .drag-icon {
        margin: 5px;
        padding: 5px;
      }

      .collapse-answer-toggle {
        font-size: 0.9em;
        cursor: pointer;
      }
    `,
  ],
})
export class FieldListComponent extends FieldComponent implements OnInit {
  @Input() override question!: FieldType;
  formGroup: UntypedFormGroup;
  iconRemove = faTrash;
  iconDrag = faBars;
  answersCollapsed = false;

  constructor(private fb: UntypedFormBuilder) {
    super();
    this.formGroup = fb.group({
      question: new UntypedFormControl('', [Validators.required]),
      // tslint:disable-next-line:no-any
      answers: fb.array([], [
        validatorArrayUniqueItems,
        validatorNotEmptyArray,
      ] as any),
    });
  }

  ngOnInit(): void {
    if (!this.question || this.question.type !== 'LIST') {
      throw Error(`Field "${this.question?.type}" is not a FieldList`);
    }
    this.formGroup.patchValue({ question: this.question.name });
  }

  get answers(): UntypedFormArray {
    return this.formGroup.get('answers') as UntypedFormArray;
  }

  get questionControl(): UntypedFormControl {
    return this.formGroup.get('question') as UntypedFormControl;
  }

  get duplicateError(): boolean {
    return this.answers.dirty && this.answers.hasError('duplicateItems');
  }

  get emptyAnswersError(): boolean {
    return this.answers.dirty && this.answers.hasError('emptyArray');
  }

  addAnswer(): void {
    this.answers.push(
      this.fb.control('', [Validators.required, Validators.minLength(0)])
    );
  }

  removeAnswer(index: number): void {
    this.answers.removeAt(index);
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.answers.controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  override getQuestion(): FieldType | null {
    this.answers.controls.forEach((control) => control.markAsDirty());
    this.questionControl.markAsDirty();

    if (!this.formGroup.valid) {
      return null;
    }

    const questionName = this.formGroup.get('question')?.value as string;
    const answers = this.answers.controls
      .filter((control) => isString(control.value)) // filter answers that are not strings
      .map((c) => c.value as string) // get control `value` as string (Reactive Forms are not strongly typed, FormControl returns any)
      .filter((value) => value.length !== 0); // filter out answers with 0 length

    this.question = {
      ...this.question,
      name: questionName,
      options: answers,
    };

    return this.question;
  }
}
