import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthActions, AuthSelectors } from '../../state/auth';

@Component({
  selector: 'app-login',
  template: `
    <div class="title">Sign in</div>
    <div class="msg-success" *ngIf="userHasBeenActivated">
      Account activated!
    </div>
    <div class="msg-success" *ngIf="passwordUpdated">Password changed!</div>
    <div class="msg-success" *ngIf="userLogout">Logged out!</div>

    <form [formGroup]="form" (keyup.enter)="onSubmit()">
      <app-input-username
        label="Username"
        (formControl)="onFormControlReady($event, 'username')"
        [validation]="false"
      ></app-input-username>
      <app-input-password
        (formControl)="onFormControlReady($event, 'password')"
        [validation]="false"
      ></app-input-password>

      <div class="error-label">
        <span *ngIf="errorRequest$ | async"> Wrong username or password </span>
      </div>

      <ui-btn
        (btnClick)="onSubmit()"
        [fullWidth]="true"
        [disabled]="!form.valid || ((pendingRequest$ | async) ?? true)"
        label=" {{ (pendingRequest$ | async) ? 'Loading...' : 'Login' }}"
      ></ui-btn>

      <div
        class="password-recovery cursor-pointer"
        [routerLink]="['/auth/password-recovery']"
      >
        Forgot password?
      </div>
    </form>

    <div class="fill-remaining-space"></div>

    <ui-btn
      [routerLink]="['/auth/signup']"
      [fullWidth]="true"
      type="border"
      label="Create new account"
    ></ui-btn>
  `,
  styles: [
    `
      form > button {
        width: 100%;
        margin-top: 30px;
      }

      .password-recovery {
        font-size: 0.8em;
        text-align: center;
        margin-top: 20px;
      }

      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      form {
        display: flex;
        flex-direction: column;
      }

      form > app-input-password,
      form > app-input-username {
        width: 100%;
        margin-bottom: 10px;
      }

      .msg-success {
        border: 1px solid green;
        padding: 10px;
        margin-bottom: 30px;
        text-align: center;
        color: green;
        border-radius: 3px;
        font-size: 0.9em;
      }

      .error-label {
        height: 30px;
        text-align: center;
      }

      .title {
        margin-bottom: 30px;
        text-align: right;
        font-size: 1.5em;
      }
    `,
  ],
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup;
  errorRequest$: Observable<boolean>;
  pendingRequest$: Observable<boolean>;
  userHasBeenActivated: boolean;
  passwordUpdated: boolean;
  userLogout: boolean;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({});
    this.pendingRequest$ = this.store.select(AuthSelectors.selectPendingReq());
    this.errorRequest$ = this.store.select(AuthSelectors.selectErrorRequest());
    this.userHasBeenActivated = this.passwordUpdated = this.userLogout = false;
  }

  ngOnInit(): void {
    this.userHasBeenActivated =
      this.route.snapshot.queryParamMap.get('activated') !== null;
    this.passwordUpdated =
      this.route.snapshot.queryParamMap.get('passwordUpdated') !== null;
    this.userLogout = this.route.snapshot.queryParamMap.get('logout') !== null;
  }

  onFormControlReady(fc: UntypedFormControl, name: string): void {
    this.form.addControl(name, fc);
  }

  onSubmit(): void {
    if (this.form.valid) {
      const username = this.form.get('username')?.value;
      const password = this.form.get('password')?.value;
      this.store.dispatch(AuthActions.doAdminLogin({ username, password }));
    }
  }
}
