import { createAction, props } from '@ngrx/store';
import { FormSpecific } from '3map-models';
import { FieldType } from '3map-models/dist/lib/model';
import { QuestionsGroup } from '@trim-web-apps/project-core';
import { FormSpecificLogic } from './models/FormSpecificLogic';

export const CREATE_SPECIFIC = createAction(
  '[ Project ] Create Specific',
  props<{ formId: string; specific: FormSpecific }>()
);

export const ON_DUPLICATE_SPECIFIC = createAction(
  '[ Project ] On Duplicate Specific',
  props<{ formId: string; specific: FormSpecific }>()
);

export const DUPLICATE_SPECIFIC = createAction(
  '[ Project ] Duplicate Specific',
  props<{
    formId: string;
    specific: FormSpecific;
    icons: { specificId: string; imgBase64List: string[] }[];
  }>()
);

export const REMOVE_SPECIFIC = createAction(
  '[ Project ] Remove Specific',
  props<{ formId: string; specificId: string }>()
);

export const UPSERT_QUESTIONS = createAction(
  '[ Project ] Upsert Specific Questions',
  props<{ specificId: string; questions: FieldType[] }>()
);

export const UPSERT_QUESTIONS_GROUPS = createAction(
  '[ Project ] Upsert Specific Questions Group',
  props<{ specificId: string; questionsGroupList: QuestionsGroup[] }>()
);

export const SET_SPECIFIC_NAME = createAction(
  '[ Project ] Set Specific Name',
  props<{ specificId: string; name: string }>()
);

export const REMOVE_QUESTION = createAction(
  '[ Project ] Remove Question',
  props<{ question: FieldType; specificId: string; groupName?: string }>()
);

export const MOVE_QUESTIONS_GROUP = createAction(
  '[ Project ] Move Questions Group',
  props<{
    specificId: string;
    fromGroup: QuestionsGroup;
    toGroupName: string | null;
  }>()
);

export const ADD_QUESTION_GROUP = createAction(
  '[ Project ] Add Question Group',
  props<{ specificId: string; groupName: string; questionList?: FieldType[] }>()
);

export const REMOVE_QUESTION_GROUP = createAction(
  '[ Project ] Remove Question Group',
  props<{ specificId: string; groupName: string }>()
);

export const REMOVE_ALL_QUESTION_GROUP = createAction(
  '[ Project ] Remove All Question Group',
  props<{ specificId: string }>()
);

export const RENAME_QUESTION_GROUP = createAction(
  '[ Project ] Rename Question Group',
  props<{ specificId: string; oldName: string; newName: string }>()
);

export const SET_LOGIC_LIST = createAction(
  '[ Project ] Set Logic List',
  props<{ specificId: string; logicList: FormSpecificLogic[] }>()
);
