import { createAction, props } from '@ngrx/store';
import { Record } from '3map-models';

export const reset = createAction('[ Deploy ] Reset State');

export const validateRecords = createAction('[ Deploy ] Validate Records');

export const validateRecordsSuccess = createAction(
  '[ Deploy ] Validate Records Complete',
  props<{ invalidRecords: Record[] }>()
);

export const validateRecordsError = createAction(
  '[ Deploy ] Validate Records Error'
);

export const deployProject = createAction('[ Deploy ] Project Deploy (start)');

export const deployProjectSuccess = createAction(
  '[ Deploy ] Project Deploy Success'
);

export const deployProjectError = createAction(
  '[ Deploy ] Project Deploy Error'
);

export const deployMarkers = createAction('[ Deploy ] Upload Markers');

export const deployMarkersSuccess = createAction(
  '[ Deploy ] Upload Markers Success'
);

export const deployMarkersError = createAction(
  '[ Deploy ] Upload Markers Error'
);

export const deployMarkersSkip = createAction('[ Deploy ] Upload Markers SKIP');

export const deployResources = createAction('[ Deploy ] Upload Resources');

export const deployResourcesSuccess = createAction(
  '[ Deploy ] Upload Resources Success'
);

export const deployResourcesError = createAction(
  '[ Deploy ] Upload Resources Error'
);
