import { Component, Input, OnInit } from '@angular/core';
import { FieldTypeBaseComponent } from '../../../models/FieldTypeBaseComponent';
import { FieldCheck } from '3map-models';

@Component({
  selector: 'app-field-check',
  template: `
    <app-field-name [fieldType]="fieldType"></app-field-name>
    <div class="disabled-select text-ellipsis" *ngIf="!allowEdit">
      {{ fieldCheck?.checked }}
    </div>
    <ui-select-multiple
      *ngIf="allowEdit"
      [items]="fieldType.options ?? []"
      [itemListSelected]="fieldCheck?.checked"
      (itemListSelectedChange)="onItemSelectChange($event)"
    />
    <div class="error" *ngIf="showError">Required field</div>
  `,
  styleUrls: ['../../field-type.base.component.scss'],
})
export class FieldCheckComponent
  extends FieldTypeBaseComponent
  implements OnInit
{
  @Input() fieldCheck: FieldCheck | undefined;
  showError?: boolean;

  ngOnInit(): void {
    if (!this.fieldType) throw Error('[ FieldCheck ] Missing FieldType');
  }

  onItemSelectChange(items: string[] | null): void {
    this.showError = false;
    const isFieldFilled = Array.isArray(items) && items.length > 0;

    if (this.fieldType.required && !isFieldFilled) {
      this.showError = true;
      return;
    }

    const field: FieldCheck | null =
      items !== null ? { checked: items, type: 'CHECK' } : null;

    this.fieldChange.emit({
      fieldType: this.fieldType,
      field,
    });
  }
}
