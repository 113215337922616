import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-input-text',
  template: `
    <div class="left">
      <div class="label">{{ label }}</div>
    </div>
    <div class="right">
      <input
        #inputElement
        type="text"
        [formControl]="inputControl"
        [placeholder]="placeholder"
      />
      <ng-container *ngIf="inputControl.errors">
        <div *ngFor="let error of errors" class="error-label">{{ error }}</div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
        display: flex;
      }

      .label {
        margin-top: 5px;
        margin-right: 10px;
      }

      .right {
        flex: 1;
      }
    `,
  ],
})
export class InputTextComponent implements OnInit, OnDestroy, OnChanges {
  @Input() text: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() inputDebounce: number;
  @Input() validators: ValidatorFn[];
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
  inputControl!: UntypedFormControl;
  inputControlSub: Subscription | undefined;
  errors: string[];

  constructor() {
    this.validators = this.errors = [];
    this.text = this.label = this.placeholder = '';
    this.inputDebounce = 0;
  }

  ngOnInit(): void {
    this.inputControl = new UntypedFormControl(this.text, this.validators);
    this.inputControlSub = this.inputControl.valueChanges
      .pipe(debounceTime(this.inputDebounce))
      .subscribe(() => this.onInputChanges());
  }

  ngOnDestroy(): void {
    this.inputControlSub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inputControl?.patchValue(changes['text'].currentValue, {
      emitEvent: false,
    });
  }

  onInputChanges(): void {
    this.updateErrors();
    this.textChange.emit(this.inputControl.value);
  }

  private updateErrors(): void {
    const errors = this.inputControl?.errors || {};
    if (this.inputControl) {
      this.errors = Object.keys(errors).map((k) => {
        switch (k) {
          case 'required':
            return 'This field is required';
          default:
            return k;
        }
      });
    }
  }
}
