import {
  ChartData,
  RAINFALL_1DAY,
  RAINFALL_1HOUR,
  RAINFALL_1MONTH,
  WeatherLayer,
  WeatherModel,
  WIND_KTS,
} from '@trim-web-apps/weather-models';
import { createArome } from '../../../weather-models/src/lib/weather-models/factories/arome';
import { createGfs } from '../../../weather-models/src/lib/weather-models/factories/gfs';
import { createGpm } from '../../../weather-models/src/lib/weather-models/factories/gpm';

export function createAromeMockup(initimeList?: number[]): WeatherModel {
  initimeList = initimeList || [1595462400, 1595419200];
  return createArome(initimeList);
}

export function createGfsMockup(initimeList?: number[]): WeatherModel {
  initimeList = initimeList || [1595462400, 1595419200];
  return createGfs(initimeList);
}

export function createGpmMockup(
  days?: number[],
  months?: number[]
): WeatherModel {
  days = days || [40177, 40178, 40179, 40180, 40181, 40182];
  months = months || [1440, 1441, 1442, 1443, 1444, 1445, 1446];
  const serverResponse = {
    timestepList: days,
    timestepMonthList: months,
    timestep3MonthList: [],
    timestepYearList: [],
  };
  // todo add test for month3
  return createGpm(serverResponse);
}

export const mockWeatherLayerWind: WeatherLayer = {
  ...WIND_KTS,
  timestepList: [
    { initime: 1595462400, timesteps: Array.from(Array(48).keys()) },
    { initime: 1595419200, timesteps: Array.from(Array(48).keys()) },
  ],
};

export const mockWeatherLayerRainfall1hour: WeatherLayer = {
  ...RAINFALL_1HOUR,
  timestepList: [
    { initime: 1595462400, timesteps: Array.from(Array(47).keys()).slice(1) },
    { initime: 1595419200, timesteps: Array.from(Array(47).keys()).slice(1) },
  ],
};

export const mockWeatherLayerGpmDay: WeatherLayer = {
  ...RAINFALL_1DAY,
  timestepList: [
    {
      initime: -1,
      timesteps: Array.from(Array(40177).keys()).filter((v) => v >= 44032),
    },
  ],
};

export const mockWeatherLayerGpmMonth: WeatherLayer = {
  ...RAINFALL_1MONTH,
  timestepList: [
    {
      initime: -2,
      timesteps: Array.from(Array(1446).keys()).filter((v) => v >= 1206),
    },
  ],
};

export const mockChartData: ChartData = {
  data: [1, 2, 3, 4, 5],
  labels: ['one', 'two', 'three', 'four', 'five'],
  legend: 'Chart legend',
  type: 'line',
  chartHeight: 250,
  color: 'yellow',
  visible: true,
};
