import { WeatherTimestep } from '../types/weather.timestep';

export const modelTemplate = {
  modelEnabled: false,
  interpolate: 'bicubic',
  chartEnabled: false,
  visible: true,
  tiffLoading: false,
  needRedraw: false,
  chartData: [],
  chartDataLoading: false,
  chartMode: 'compact',
  windStyle: 'Arrow',
  tiffUrl: '',
  hasChart: true,
  tiffFetchError: false,
};

export function createTimestepListForecast(
  initimeList: number[],
  tsCount: number,
  interval: number,
  utcOffset: string
): WeatherTimestep[] {
  return initimeList
    .sort((a, b) => b - a)
    .map((ini) => ({
      initime: ini,
      timesteps: new Array(tsCount).fill(null).map((v, i) => i),
    }));
}

export function createTimestepListGpm(
  timestepList: number[],
  isMonth: boolean
): WeatherTimestep[] {
  timestepList.sort();
  return [
    {
      initime: isMonth ? -2 : -1,
      timesteps: timestepList,
    },
  ];
}
