import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayString',
})
export class ArrayStringPipe implements PipeTransform {
  transform(value?: string[] | null): string {
    if (!value || !Array.isArray(value)) return '';
    return value.toString().replace(/,/g, ', ');
  }
}
