import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as FormSelectors from '../../+state/form.selectors';

@Pipe({
  name: 'formIdToName',
})
export class FormIdToNamePipe implements PipeTransform {
  formName: string;
  formId: string;

  constructor(private store: Store) {
    this.formName = '';
    this.formId = '';
  }

  transform(formId: string): string {
    if (formId !== this.formId) {
      this.store
        .select(FormSelectors.selectFormEntityById(formId))
        .pipe(take(1))
        .subscribe((form) => (this.formName = form?.name || 'FORM_NOT_FOUND'));
    }
    return this.formName;
  }
}
