import { Component, Input } from '@angular/core';
import { map, Observable } from 'rxjs';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { Store } from '@ngrx/store';
import { WeatherSelectors } from '../../+state';
import { Dialog } from '@angular/cdk/dialog';
import { ModelInfoComponent } from '../../components/model-info/model-info.component';

@Component({
  selector: 'weather-core-weathers-list',
  template: `
    <div class="weather-list-wrapper">
      <weather-core-item
        class="weather-item"
        *ngFor="let model of weatherModels$ | async; trackBy: trackBy"
        [weatherModelId]="model.id"
        [showRemoveIcon]="showRemoveIcon"
        [isMobile]="isMobile"
        (showInfo)="onShowInfo(model)"
      >
      </weather-core-item>
    </div>
  `,
  styles: [
    `
      .weather-list-wrapper {
        width: 100%;
      }
    `,
  ],
})
export class WeathersListComponent {
  @Input() showRemoveIcon?: boolean;
  @Input() isMobile?: boolean;
  weatherModels$: Observable<WeatherModel[]>;
  utcOffset$: Observable<string>;

  constructor(private store: Store, private dialog: Dialog) {
    this.utcOffset$ = this.store.select(WeatherSelectors.selectUtcOffset());
    this.weatherModels$ = this.store
      .select(WeatherSelectors.selectEnabledWeatherModels())
      .pipe(
        map((enabledModels) => {
          return this.isMobile && enabledModels.length > 0
            ? [enabledModels[0]]
            : enabledModels;
        })
      );
  }

  trackBy(index: number, model: WeatherModel) {
    return model.id;
  }

  onShowInfo(model: WeatherModel): void {
    this.dialog.open(ModelInfoComponent, {
      width: '350px',
      data: model,
    });
  }
}
