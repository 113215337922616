import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormSpecific } from '3map-models';
import { MEDIA_TAB_ID } from '../feature-history.module';

@Component({
  selector: 'app-record-card-tabs',
  template: `
    <div class="tabs">
      <div
        class="tab subtitle hover-1"
        (click)="tabClicked.emit(group)"
        [class.active-tab]="group === activeTab"
        *ngFor="let group of getTabGroupList()"
      >
        {{ group ?? 'Questions' }}
      </div>
      <div
        class="tab subtitle hover-1 media-tab"
        [class.active-tab]="isMediaActiveTab()"
        (click)="mediaTabClick()"
      >
        Media
      </div>
    </div>
  `,
  styles: [
    `
      .tabs {
        display: flex;
        overflow-y: auto;
        width: 100%;
        margin: 10px 0;
        border-top: 1px solid var(--border);
      }

      .tab {
        flex: 0 0 auto;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        cursor: pointer;
      }

      .active-tab {
        font-weight: 400;
        border-bottom: 1px solid var(--primary-color);
      }

      .tabs,
      .tab {
        transform: rotateX(180deg);
        -moz-transform: rotateX(180deg); /* Mozilla */
        -webkit-transform: rotateX(180deg); /* Safari and Chrome */
        -ms-transform: rotateX(180deg); /* IE 9+ */
        -o-transform: rotateX(180deg); /* Opera */
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordCardTabsComponent {
  @Input() specific?: FormSpecific;
  @Input() activeTab: string | null = null;
  @Output() tabClicked = new EventEmitter<string | null>();

  // TODO performance: memoize
  getTabGroupList(): (string | null)[] {
    const questionGroups = this.specific?.questionGroups;
    return questionGroups ? questionGroups.map((group) => group.name) : [null];
  }

  mediaTabClick(): void {
    this.tabClicked.emit(MEDIA_TAB_ID);
  }

  isMediaActiveTab(): boolean {
    return this.activeTab === MEDIA_TAB_ID;
  }
}
