import { Component, Input } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Collaboration } from '../../../state/collaboration/collaboration.reducer';

@Component({
  selector: 'app-auth-project-item',
  template: `
    <div
      class="col-wrapper"
      *ngIf="collaboration"
      [ngStyle]="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
    >
      <div class="col-name">
        {{ collaboration.projectName }}
      </div>
      <div class="fill-remaining-space"></div>
      <fa-icon [icon]="iconArrow"></fa-icon>
    </div>
  `,
  styles: [
    `
      .col-wrapper {
        height: 40px;
        display: flex;
        align-items: center;
        border: 1px solid #dbdbdb;
        border-radius: 3px;
        padding: 5px 10px;
        margin: 10px;
        cursor: pointer;
      }

      .fill-remaining-space {
        flex: 1 1 auto;
      }
    `,
  ],
})
export class ProjectItemComponent {
  @Input() collaboration: Collaboration | undefined;
  @Input() disabled: boolean | undefined;
  iconArrow = faChevronRight;
}
