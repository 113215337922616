import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { MapSelectors } from '../../+state';
import { Subscription } from 'rxjs';
import { createRecordLayerId } from '../../mapbox.utils';
import { MapboxService } from '../../mapbox.service';
import { EventData, MapboxGeoJSONFeature, MapMouseEvent } from 'mapbox-gl';

@Component({
  selector: 'app-dataset-layer[datasetId]',
  template: ``,
})
export class DatasetLayerComponent implements OnInit, OnDestroy {
  @Input() datasetId?: string;
  @Output() featureClick = new EventEmitter<string>();

  private storeSub: Subscription | undefined;

  constructor(private store: Store, private mapService: MapboxService) {}

  ngOnInit(): void {
    if (!this.datasetId) return;
    this.bindDatasetMapEvent();
    this.storeSub = this.store
      .select(MapSelectors.selectMapLayer(this.datasetId))
      .subscribe((layer) => {
        this.removeDatasetLayer();
        if (layer !== null) this.mapService.map.addLayer(layer);
      });
  }

  ngOnDestroy(): void {
    this.storeSub?.unsubscribe();
    this.removeDatasetLayer();
  }

  private get map(): mapboxgl.Map {
    return this.mapService.map;
  }

  private removeDatasetLayer(): void {
    if (!this.datasetId) return;
    const layerId = createRecordLayerId(this.datasetId);
    if (this.mapService.map.getLayer(layerId)) {
      this.mapService.map.removeLayer(layerId);
      this.mapService.map.removeSource(layerId);
    }
  }

  private bindDatasetMapEvent(): void {
    if (!this.map || !this.datasetId) return;
    const layerId = createRecordLayerId(this.datasetId);
    this.map.on('click', layerId, this.mouseClickEvt);
    this.map.on('mouseenter', layerId, this.mouseEnterEvt);
    this.map.on('mouseleave', layerId, this.mouseLeaveEvt);
  }

  private mouseEnterEvt = () =>
    this.map ? (this.map.getCanvas().style.cursor = 'pointer') : null;

  private mouseLeaveEvt = () =>
    this.map ? (this.map.getCanvas().style.cursor = '') : null;

  private mouseClickEvt = (
    e: MapMouseEvent & { features?: MapboxGeoJSONFeature[] } & EventData
  ) => this.onRecordClick(e);

  private onRecordClick(
    evt: MapMouseEvent & { features?: MapboxGeoJSONFeature[] } & EventData
  ): void {
    const properties =
      evt.features && Array.isArray(evt.features)
        ? evt.features[0].properties
        : null;
    const featureId = properties?.['featureId'];
    if (!featureId) return;
    this.featureClick.emit(featureId);
  }
}
