import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-input-mail',
  template: `
    <div class="input-field" [ngClass]="{ 'input-error': error$ | async }">
      <fa-icon class="input-icon" [icon]="icon"></fa-icon>
      <input
        #mailInput
        type="email"
        [formControl]="mailControl"
        [autocomplete]="false"
        [placeholder]="label"
      />
    </div>
    <div class="input-error-msg" *ngIf="validation">{{ error$ | async }}</div>
  `,
  styleUrls: ['../input.styles.scss'],
})
export class InputMailComponent implements OnInit {
  @Input() label = 'Mail';
  @Input() validation = true;
  @Input() defaultValue = '';
  @Output()
  formControl: EventEmitter<UntypedFormControl> = new EventEmitter<UntypedFormControl>();

  mailControl: UntypedFormControl;
  error$: Observable<string>;
  icon = faEnvelope;

  constructor() {
    this.mailControl = new UntypedFormControl(this.defaultValue);
    this.error$ = this.mailControl.valueChanges.pipe(
      debounceTime(1000),
      map(() => (this.mailControl.invalid ? 'Invalid mail' : ''))
    );
  }

  ngOnInit(): void {
    this.mailControl.setValidators(
      this.validation ? [Validators.required, Validators.email] : []
    );
    this.mailControl.setValue(this.defaultValue);
    this.formControl.emit(this.mailControl);
  }
}
