import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Record } from '3map-models';

@Component({
  selector: 'app-record-card-media-list',
  template: `
    <div class="media-list" *ngIf="record">
      <app-media-card
        *ngFor="let media of record.mediaList"
        [media]="media"
        [projectName]="record.projectName"
      ></app-media-card>
    </div>
  `,
  styles: [
    `
      .media-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordCardMediaListComponent {
  @Input() record: Record | undefined;
}
