import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { Form, FormSpecific } from '3map-models';

@Component({
  selector: 'app-form-list-item',
  template: `
    <div class="form-list-item-wrapper" *ngIf="form">
      <ui-expansion-panel>
        <div class="header show-on-hover-toggle" header>
          <div class="primary-text-bold">{{ form.name }}</div>
          <div class="show-on-hover" (click)="$event.stopPropagation()">
            <div class="icons-actions">
              <fa-icon
                [icon]="iconDuplicate"
                (click)="formDuplicate.emit(form)"
              ></fa-icon>
              <fa-icon
                [icon]="iconEdit"
                (click)="formEdit.emit(form)"
              ></fa-icon>
              <core-double-confirm-inline (confirm)="formDelete.emit(form)" />
            </div>
          </div>
        </div>
        <div class="body" content>
          <div
            class="specific show-on-hover-toggle"
            *ngFor="let specific of form.specificList; let i = index"
            [routerLink]="'' + form.id + '/' + specific.id"
          >
            <div class="specific-info">
              <div class="specific-name">{{ specific.name }}</div>
              <div class="secondary-text">
                {{ specific.questions.length }} questions
              </div>
            </div>

            <div class="show-on-hover" (click)="$event.stopPropagation()">
              <div class="icons-actions">
                <fa-icon
                  [icon]="iconDuplicate"
                  (click)="onSpecificDuplicate(form, specific)"
                ></fa-icon>
                <core-double-confirm-inline
                  (confirm)="onSpecificDelete(form, specific)"
                />
              </div>
            </div>

            <div
              class="error-no-specific"
              *ngIf="form.specificList.length === 0"
            >
              At least one Form Specific is required
            </div>
          </div>

          <div class="actions">
            <ui-btn
              type="flat"
              label="Add Form"
              [icon]="iconAdd"
              (click)="createSpecific.emit(form)"
            ></ui-btn>
          </div>
        </div>
      </ui-expansion-panel>
    </div>
  `,
  styles: [
    `
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .specific {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--spacing-2);
        cursor: pointer;
        border-radius: var(--radius-1);
      }

      .specific:hover {
        background-color: var(--hover);
      }

      fa-icon {
        margin: 0 10px;
      }

      .icons-actions {
        display: flex;
        align-items: center;
        padding-right: 10px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormListItemComponent {
  @Input() form?: Form;
  @Output() formDuplicate = new EventEmitter<Form>();
  @Output() formDelete = new EventEmitter<Form>();
  @Output() formEdit = new EventEmitter<Form>();
  @Output() createSpecific = new EventEmitter<Form>();
  @Output() specificDuplicate = new EventEmitter<{
    form: Form;
    specific: FormSpecific;
  }>();
  @Output() specificDelete = new EventEmitter<{
    form: Form;
    specific: FormSpecific;
  }>();

  iconEdit = faPen;
  iconDelete = faTrash;
  iconAdd = faPlus;
  iconDuplicate = faClone;

  onSpecificDuplicate(form: Form, specific: FormSpecific) {
    this.specificDuplicate.emit({ form, specific });
  }

  onSpecificDelete(form: Form, specific: FormSpecific) {
    this.specificDelete.emit({ form, specific });
  }
}
