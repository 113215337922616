import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { NominatimResponse } from './nominatim-response.type';

@Injectable({ providedIn: 'root' })
export class NominatimService {
  constructor(private http: HttpClient) {}

  /**
   * Perform an HTTP GET request to `/search` endpoint, which will return a list
   * of matching results (empty list if none is found).
   * Return the FIRST result of the list or NULL if list is empty.
   */
  search(search: string): Observable<NominatimResponse | null> {
    const url = `https://nominatim.openstreetmap.org/search?q=${search}&format=json`;
    return this.http.get(url).pipe(
      map((res: any) => {
        if (!Array.isArray(res) || res.length === 0) return null;
        return {
          lng: parseFloat(res[0]['lon']),
          lat: parseFloat(res[0]['lat']),
          location: search,
        };
      }),
      catchError(() => of(null))
    );
  }
}
