/* eslint-disable max-len */
/* eslint-disable no-var */

export function nearestValue(ds: number[][], x: number, y: number): number {
    return ds[Math.round(y)][Math.round(x)]
}

export function bilinearInterpolation(ds: number[][], x: number, y: number): number {
    let res: number
    const dist1: number = (Math.ceil(x) - x) * (Math.ceil(y) - y)
    const dist2: number = (x - Math.floor(x)) * (Math.ceil(y) - y)
    const dist3: number = (Math.ceil(x) - x) * (y - Math.floor(y))
    const dist4: number = (x - Math.floor(x)) * (y - Math.floor(y))

    if (dist1 !== 0 || dist2 !== 0 || dist3 !== 0 || dist4 !== 0) {
        res =
            ds[Math.floor(y)][Math.floor(x)] * dist1 +
            ds[Math.floor(y)][Math.ceil(x)] * dist2 +
            ds[Math.ceil(y)][Math.floor(x)] * dist3 +
            ds[Math.ceil(y)][Math.ceil(x)] * dist4
    } else {
        res = ds[Math.floor(y)][Math.floor(x)]
    }

    return res
}

/**
 * https://www.youtube.com/watch?v=poY_nGzEEWM
 * https://www.paulinternet.nl/?page=bicubic
 */
export function bicubicInterpolation(ds: number[][], x: number, y: number): number {
    const x0 = Math.floor(x) - 1
    const y0 = Math.floor(y) - 1

    if (x0 < 0 || x0 + 3 >= ds[0].length || y0 < 0 || y0 + 3 >= ds.length) {
        return ds[y0 + 1][x0 + 1]
    }
    x = x - x0 - 1
    y = y - y0 - 1

    const a0 = ds[y0][x0]
    const a1 = ds[y0 + 1][x0]
    const a2 = ds[y0 + 2][x0]
    const a3 = ds[y0 + 3][x0]

    const b0 = ds[y0][x0 + 1]
    const b1 = ds[y0 + 1][x0 + 1]
    const b2 = ds[y0 + 2][x0 + 1]
    const b3 = ds[y0 + 3][x0 + 1]

    const c0 = ds[y0][x0 + 2]
    const c1 = ds[y0 + 1][x0 + 2]
    const c2 = ds[y0 + 2][x0 + 2]
    const c3 = ds[y0 + 3][x0 + 2]

    const d0 = ds[y0][x0 + 3]
    const d1 = ds[y0 + 1][x0 + 3]
    const d2 = ds[y0 + 2][x0 + 3]
    const d3 = ds[y0 + 3][x0 + 3]

    const i0 = 0.5 * (c0 - a0 + (2.0 * a0 - 5.0 * b0 + 4.0 * c0 - d0 + (3.0 * (b0 - c0) + d0 - a0) * x) * x) * x + b0
    const i1 = 0.5 * (c1 - a1 + (2.0 * a1 - 5.0 * b1 + 4.0 * c1 - d1 + (3.0 * (b1 - c1) + d1 - a1) * x) * x) * x + b1
    const i2 = 0.5 * (c2 - a2 + (2.0 * a2 - 5.0 * b2 + 4.0 * c2 - d2 + (3.0 * (b2 - c2) + d2 - a2) * x) * x) * x + b2
    const i3 = 0.5 * (c3 - a3 + (2.0 * a3 - 5.0 * b3 + 4.0 * c3 - d3 + (3.0 * (b3 - c3) + d3 - a3) * x) * x) * x + b3

    return 0.5 * (i2 - i0 + (2.0 * i0 - 5.0 * i1 + 4.0 * i2 - i3 + (3.0 * (i1 - i2) + i3 - i0) * y) * y) * y + i1
}


function TERP(t: number, a: number, b: number, c: number, d: number) {
    return 0.5 * (c - a + (2.0 * a - 5.0 * b + 4.0 * c - d + (3.0 * (b - c) + d - a) * t) * t) * t + b
}

export function bicubicInterpolationOld(data: number[][], y: number, x: number): number {
    var i0, i1, i2, i3

    const ds: number[][] = []
    const x0 = Math.floor(x) - 1
    const y0 = Math.floor(y) - 1

    if (x0 < 0 || x0 + 4 > data.length || y0 < 0 || y0 + 4 > data[0].length) {
        return 0
    }

    for (let i = 0; i < 4; i++) {
        ds[i] = []
        for (let j = 0; j < 4; j++) {
            ds[i][j] = data[x0 + i][y0 + j]
        }
    }

    x = x - Math.floor(x)
    y = y - Math.floor(y)

    i0 = TERP(x, ds[0][0], ds[1][0], ds[2][0], ds[3][0])
    i1 = TERP(x, ds[0][1], ds[1][1], ds[2][1], ds[3][1])
    i2 = TERP(x, ds[0][2], ds[1][2], ds[2][2], ds[3][2])
    i3 = TERP(x, ds[0][3], ds[1][3], ds[2][3], ds[3][3])
    return TERP(y, i0, i1, i2, i3)
}

export function bicubicInterpolationOld2(ds: number[][], y: number, x: number): number {
    const x0 = Math.floor(x) - 1
    const y0 = Math.floor(y) - 1

    x = x - x0 - 1
    y = y - y0 - 1

    const i0 = 0.5 * (ds[x0 + 2][y0] - ds[x0][y0] + (2.0 * ds[x0][y0] - 5.0 * ds[x0 + 1][y0] + 4.0 * ds[x0 + 2][y0] - ds[x0 + 3][y0] + (3.0 * (ds[x0 + 1][y0] - ds[x0 + 2][y0]) + ds[x0 + 3][y0] - ds[x0][y0]) * x) * x) * x + ds[x0 + 1][y0]
    const i1 = 0.5 * (ds[x0 + 2][y0 + 1] - ds[x0][y0 + 1] + (2.0 * ds[x0][y0 + 1] - 5.0 * ds[x0 + 1][y0 + 1] + 4.0 * ds[x0 + 2][y0 + 1] - ds[x0 + 3][y0 + 1] + (3.0 * (ds[x0 + 1][y0 + 1] - ds[x0 + 2][y0 + 1]) + ds[x0 + 3][y0 + 1] - ds[x0][y0 + 1]) * x) * x) * x + ds[x0 + 1][y0 + 1]
    const i2 = 0.5 * (ds[x0 + 2][y0 + 2] - ds[x0][y0 + 2] + (2.0 * ds[x0][y0 + 2] - 5.0 * ds[x0 + 1][y0 + 2] + 4.0 * ds[x0 + 2][y0 + 2] - ds[x0 + 3][y0 + 2] + (3.0 * (ds[x0 + 1][y0 + 2] - ds[x0 + 2][y0 + 2]) + ds[x0 + 3][y0 + 2] - ds[x0][y0 + 2]) * x) * x) * x + ds[x0 + 1][y0 + 2]
    const i3 = 0.5 * (ds[x0 + 2][y0 + 3] - ds[x0][y0 + 3] + (2.0 * ds[x0][y0 + 3] - 5.0 * ds[x0 + 1][y0 + 3] + 4.0 * ds[x0 + 2][y0 + 3] - ds[x0 + 3][y0 + 3] + (3.0 * (ds[x0 + 1][y0 + 3] - ds[x0 + 2][y0 + 3]) + ds[x0 + 3][y0 + 3] - ds[x0][y0 + 3]) * x) * x) * x + ds[x0 + 1][y0 + 3]

    return 0.5 * (i2 - i0 + (2.0 * i0 - 5.0 * i1 + 4.0 * i2 - i3 + (3.0 * (i1 - i2) + i3 - i0) * y) * y) * y + i1
}
