import { Component, Input, OnInit } from '@angular/core';
import { FormSpecificLogic } from '@trim-web-apps/project-core';
import { Observable } from 'rxjs';
import { FormSpecific } from '3map-models';
import { removeElementFromArrayByIndex } from '@trim-web-apps/core';
import { AddLogicItemComponent } from '../../components/add-logic-item/add-logic-item.component';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as SpecificSelectors from '../../+state/form-specific.selectors';
import * as SpecificActions from '../../+state/form-specific.actions';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-form-specific-logic[specific]',
  template: `
    <div class="form-specific-logic" *ngIf="logicList$ | async as logicList">
      <ui-btn label="Add Logic" (click)="addLogic(logicList)" type="border" />
      <div
        class="logic-item"
        *ngFor="let logic of logicList; let i = index; trackBy: trackBy"
      >
        <app-logic-item
          [logic]="logic"
          (remove)="removeLogic(logicList, i)"
          (logicChange)="upsertLogic($event, logicList, i)"
        ></app-logic-item>
      </div>
    </div>
  `,
  styles: [
    `
      .form-specific-logic {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        right: 0;
        padding: 10px;
      }

      .logic-item {
        margin: 10px 0;
      }
    `,
  ],
})
export class LogicFormSpecificComponent implements OnInit {
  @Input() specific!: FormSpecific;
  logicList$: Observable<FormSpecificLogic[]> | undefined;

  constructor(
    private dialog: Dialog,
    private store: Store // private projectStore: ProjectCoreService
  ) {}

  ngOnInit(): void {
    this.logicList$ = this.store.select(
      SpecificSelectors.selectLogic(this.specific.id)
    );
  }

  trackBy(index: number, item: FormSpecificLogic): string {
    return item.q1.id + item.q2.id;
  }

  addLogic(logicList: FormSpecificLogic[]): void {
    const data = { specific: this.specific };
    this.dialog
      .open<FormSpecificLogic | undefined>(AddLogicItemComponent, { data })
      .closed.pipe(take(1))
      .subscribe((logic) => {
        if (!logic) return;
        const newLogicList = [...logicList, logic];
        this.store.dispatch(
          SpecificActions.setLogicList({
            specificId: this.specific.id,
            logicList: newLogicList,
          })
        );
      });
  }

  removeLogic(logicList: FormSpecificLogic[], logicIndex: number): void {
    const newLogicList = removeElementFromArrayByIndex<FormSpecificLogic>(
      logicList,
      logicIndex
    );
    this.store.dispatch(
      SpecificActions.setLogicList({
        specificId: this.specific.id,
        logicList: newLogicList,
      })
    );
  }

  upsertLogic(
    newLogic: FormSpecificLogic,
    logicList: FormSpecificLogic[],
    index: number
  ): void {
    const newLogicList = [...logicList];
    newLogicList[index] = newLogic;
    this.store.dispatch(
      SpecificActions.setLogicList({
        specificId: this.specific.id,
        logicList: newLogicList,
      })
    );
  }
}
