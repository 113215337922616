import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromUser from './';
import { Observable } from 'rxjs';
import { ProjectUser } from '3map-models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private store: Store<fromUser.State>) {}

  getUserList(): Observable<ProjectUser[]> {
    return this.store.pipe(select(fromUser.selectUsers()));
  }

  upsertUsers(users: ProjectUser[]): void {
    this.store.dispatch(fromUser.SET_USERS({ users }));
  }

  removeUser(user: ProjectUser): void {
    this.store.dispatch(fromUser.REMOVE_USERS({ users: [user] }));
  }
}
