import { Component, Input } from '@angular/core';
import { WeatherLayer } from '@trim-web-apps/weather-models';

@Component({
  selector: 'weather-core-legend',
  template: `
    <div class="weather-legend-wrapper" *ngIf="layer">
      <div class="fill-remaining-space"></div>
      <div class="legend-wrapper" [ngClass]="isMobile ? 'mobile' : ''">
        <div *ngIf="showLabel" class="legend-label">Legend</div>
        <ng-container *ngFor="let value of layer.legend.values; let i = index">
          <div *ngIf="i > 0" class="legend-item">
            <div
              class="color"
              [ngStyle]="{ backgroundColor: layer.legend.hex[i] }"
            ></div>
            <div class="value">{{ value | weatherLegendLabel }}</div>
          </div>
        </ng-container>

        <div class="legend-item units">
          <div class="color"></div>
          <div class="value">({{ this.layer.unit }})</div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .legend-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .legend-label {
        margin-right: 10px;
      }

      .color {
        height: 7px;
        opacity: 0.6;
      }

      .legend-item {
        width: 35px;
        display: flex;
        flex-direction: column;
      }

      .value {
        text-align: left;
        font-size: 0.9em;
      }

      .units {
        width: 45px;
      }

      .legend-wrapper.mobile {
        flex-wrap: nowrap;
        overflow-x: auto;
      }

      .legend-wrapper .legend-item {
        flex: 0 0 auto;
      }
    `,
  ],
})
export class WeatherLegendComponent {
  @Input() layer?: WeatherLayer;
  @Input() showLabel?: boolean;
  @Input() isMobile?: boolean;
}
