import { Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { LoginComponent } from './containers/login/login.component';
import { LogoutComponent } from './containers/logout/logout.component';
import { PasswordRecoveryComponent } from './containers/password-recovery/password-recovery.component';
import { SignupComponent } from './containers/signup/signup.component';
import { VerifyUserComponent } from './containers/verify-user/verify-user.component';
import { ProjectSelectorComponent } from './containers/project-selector/project-selector.component';
import { AuthAdminGuard } from './utils/auth-admin-guard.service';

export const authRoutes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'verifyUser/:token', component: VerifyUserComponent },
      { path: 'password-recovery', component: PasswordRecoveryComponent },
      { path: 'changePsw/:token', component: ChangePasswordComponent },
      { path: 'logout', component: LogoutComponent },
      {
        path: 'projects',
        component: ProjectSelectorComponent,
        canActivate: [AuthAdminGuard],
        data: { fallbackRoute: 'lol' },
      },
    ],
  },
  { path: '**', redirectTo: '' },
];
