import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Link } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
// import { AdminActions } from '../../+state';
import * as AdminActions from '../../+state/admin.actions';
import { removeLink, upsertLink } from './link.actions';

export const ADMIN_LINK_FEATURE_KEY = 'admin-link';
export type State = EntityState<Link>;
export const adapter = createEntityAdapter<Link>({
  selectId: (link) => link.name,
});

const initialState = adapter.getInitialState();

const webHookReducer = createReducer(
  initialState,
  on(
    AdminActions.initProjectError,
    AdminActions.close,
    (): State => ({ ...initialState })
  ),
  on(AdminActions.initProjectSuccess, (state, { project }): State => {
    return project.linkList ? adapter.setAll(project.linkList, state) : state;
  }),
  on(upsertLink, (state, { link }) => {
    return adapter.upsertOne(link, state);
  }),
  on(removeLink, (state, { link }) => {
    return adapter.removeOne(link.name, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return webHookReducer(state, action);
}
