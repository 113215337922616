import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import {
  blobToString,
  NotificationService,
  notNullOrUndefined,
} from '@trim-web-apps/core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {
  GeoStyle,
  geoStyleToRaw,
  rawToGeoStyle,
} from '@trim-web-apps/project-core';
import * as ResourceSelectors from '../../+state/resource.selectors';
import * as ResourceActions from '../../+state/resource.actions';
import { Store } from '@ngrx/store';
import { Resource } from '3map-models';

@Component({
  selector: 'app-resource-geojson[resource]',
  template: `
    <div class="resource-geojson-wrapper" *ngIf="geoStyle$ | async as geoStyle">
      <div class="row">
        <app-resource-name
          [resource]="resource"
          (nameChange)="onResourceNameChange($event)"
        ></app-resource-name>
        <!--                <app-resource-select-file-->
        <!--                  label="Select GeoJSON"-->
        <!--                  (fileSelected)="onFileSelected($event)"-->
        <!--                ></app-resource-select-file>-->
      </div>

      <div class="row">
        <div class="control type">
          <div class="label">Type</div>
          <ui-select
            [items]="['fill', 'line', 'circle']"
            [itemSelected]="geoStyle.type"
            [widthPx]="150"
            (itemSelectedChange)="onTypeChange($event, geoStyle)"
          />
        </div>

        <div class="control color">
          <div class="label">Color</div>
          <input
            class="control-item color-picker"
            (colorPickerChange)="onColorChange($event, geoStyle)"
            [colorPicker]="geoStyle.color"
            [style.background]="geoStyle.color"
          />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .resource-geojson-wrapper {
        display: flex;
        flex-direction: column;
      }

      .resource-geojson-wrapper .row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }

      .resource-geojson-wrapper .row:last-child input,
      .resource-geojson-wrapper .row:last-child button {
        width: 150px;
      }

      .resource-geojson-wrapper .label {
        font-size: 0.9em;
        margin-bottom: 5px;
      }

      .resource-geojson-wrapper .control {
        margin-right: 30px;
      }
    `,
  ],
})
export class ResourceGeojsonComponent implements OnInit, OnDestroy {
  @Input() resource!: Resource;
  geoStyle$: Observable<GeoStyle> | undefined;
  opacityError: boolean;
  colorError: boolean;
  colorDebounce: Subject<{ geoStyle: GeoStyle; color: string }>;
  colorDebounceSub: Subscription | undefined;
  icon = faCaretDown;

  constructor(private store: Store, private notify: NotificationService) {
    this.opacityError = false;
    this.colorError = false;
    this.colorDebounce = new Subject<{ geoStyle: GeoStyle; color: string }>();
  }

  ngOnInit(): void {
    this.geoStyle$ = this.store
      .select(
        ResourceSelectors.selectEntityResourceById(this.resource.filename)
      )
      .pipe(
        filter(notNullOrUndefined),
        map((resource) => rawToGeoStyle(resource?.style))
      );

    this.colorDebounceSub = this.colorDebounce
      .pipe(debounceTime(500))
      .subscribe(({ geoStyle, color }) => {
        const style = geoStyleToRaw({ ...geoStyle, color });
        this.store.dispatch(
          ResourceActions.setResourceStyle({
            id: this.resource.filename,
            style,
          })
        );
      });
  }

  ngOnDestroy(): void {
    this.colorDebounceSub?.unsubscribe();
  }

  onTypeChange(value: string | null, geoStyle: GeoStyle): void {
    if (!value) return;
    const style = geoStyleToRaw({ ...geoStyle, type: value });
    this.store.dispatch(
      ResourceActions.setResourceStyle({
        id: this.resource.filename,
        style,
      })
    );
  }

  onColorChange(color: string, geoStyle: GeoStyle): void {
    this.colorDebounce.next({ geoStyle, color });
  }

  onResourceNameChange(name: string): void {
    this.store.dispatch(
      ResourceActions.setResourceName({
        id: this.resource.filename,
        name,
      })
    );
  }

  onFileSelected(file: File): void {
    const isGeoJson = file?.type === 'application/geo+json';
    if (file && isGeoJson)
      blobToString(file)
        .pipe(take(1))
        .subscribe((json) =>
          this.store.dispatch(
            ResourceActions.setResourceData({
              id: this.resource.filename,
              resourceData: json,
            })
          )
        );
    else
      this.notify.error(
        'File not supported. Please select a GeoJSON file',
        3000
      );
  }
}
