import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY } from './feature.key';
import { projectReducerMap } from './project.reducer.map';
import { FormModule } from './form/form.module';
import { FormSpecificModule } from './specific/form-specific.module';

@NgModule({
  imports: [
    CommonModule,
    FormModule,
    FormSpecificModule,
    StoreModule.forFeature(FEATURE_KEY, projectReducerMap),
  ],
})
export class ProjectCoreModule {}
