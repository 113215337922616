import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import * as ResourceSelectors from '../../+state/resource.selectors';
import { Store } from '@ngrx/store';
import { Project, Resource } from '3map-models';
import { AdminSelectors } from '../../../+state';

@Component({
  selector: 'app-resource',
  template: `
    <ng-container *ngIf="project$ | async as project">
      <div class="header">
        <app-section-label sectionLabel="Edit Resource" backRoute="resources" />
      </div>
      <div class="resource-container" *ngIf="resource$ | async as resource">
        <app-resource-geojson
          *ngIf="resource.type === 'GEOJSON'"
          [resource]="resource"
        />
        <app-resource-image
          *ngIf="resource.type === 'IMAGE'"
          [resource]="resource"
        />

        <div class="row map-wrapper">
          <app-resource-map [project]="project" [resource]="resource" />
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .resource-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 0 30px 30px 30px;
        overflow: auto;
      }

      .map-wrapper {
        /*height: 70vh;*/
        flex: 1 1 auto;
      }
    `,
  ],
})
export class ResourceComponent implements OnInit {
  resource$?: Observable<Resource | undefined>;
  project$: Observable<Project | null>;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {
    this.project$ = this.store.select(AdminSelectors.selectProjectOrigin());
  }

  ngOnInit(): void {
    const { resourceId } = this.route.snapshot.params;

    this.resource$ = this.store
      .select(ResourceSelectors.selectEntityResourceById(resourceId))
      .pipe(
        tap((resource) => {
          if (!resource) this.router.navigate(['/admin/resources']);
        })
      );
  }
}
