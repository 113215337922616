import { Component, Input } from '@angular/core';
import { faCheck, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DeployStatus } from '../../models/DeployStatus';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-deploy-stepper-item',
  template: `
    <div class="stepper-item-wrapper">
      <fa-icon
        class="icon-staged"
        *ngIf="deployStatus === 'STAGED'"
        [icon]="iconStaged"
      />
      <fa-icon
        class="icon-pending"
        *ngIf="!deployStatus || deployStatus === 'PENDING'"
        [spin]="true"
        [icon]="iconPending"
      />
      <fa-icon
        class="icon-deployed"
        *ngIf="deployStatus === 'DEPLOYED'"
        [icon]="iconDeployed"
      />
      <fa-icon
        class="icon-error"
        *ngIf="deployStatus === 'ERROR'"
        [icon]="iconError"
      />
      <div class="label">{{ label }}</div>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .stepper-item-wrapper {
        display: flex;
        margin: 10px 0;
        align-items: center;
        height: 30px;
      }

      .label {
        margin-left: 10px;
      }

      .icon-deployed {
        color: #388e3c;
      }

      .icon-error {
        color: #d32f2f;
      }
    `,
  ],
})
export class DeployStepperItemComponent {
  @Input() deployStatus: DeployStatus | undefined;
  @Input() label: string | undefined;

  iconStaged = faCircle;
  iconPending = faSpinner;
  iconDeployed = faCheck;
  iconError = faTimes;
}
