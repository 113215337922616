import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApiService } from 'libs/api3map/src/lib/api.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { RecordActions } from './index';
import { SidenavActions } from '../../sidenav/+state';
import { ProjectActions, ProjectSelectors } from '../../project/+state';

@Injectable()
export class RecordEffects {
  constructor(
    private action$: Actions,
    private api: ApiService,
    private store: Store
  ) {}

  appInit$ = createEffect(() =>
    this.action$.pipe(
      ofType(ProjectActions.fetchProjectSuccess),
      map(() => RecordActions.fetchRecords())
    )
  );

  fetchRecord$ = createEffect(() =>
    this.action$.pipe(
      ofType(RecordActions.fetchRecords, SidenavActions.reloadRecords),
      concatLatestFrom(() =>
        this.store.select(ProjectSelectors.selectProject())
      ),
      switchMap(([, project]) => {
        if (project === null) return of(RecordActions.fetchRecordsError());
        return this.api.getRecordList(project).pipe(
          map((records) => RecordActions.fetchRecordsSuccess({ records })),
          catchError(() => of(RecordActions.fetchRecordsError()))
        );
      })
    )
  );
}
