import * as moment from 'moment';
import { Moment } from 'moment';
import {
  TimeUnit,
  WeatherLayer,
  WeatherModel,
} from '@trim-web-apps/weather-models';
import * as mapboxgl from 'mapbox-gl';

export function createTiffUrl(model: WeatherModel, bbox: number[]): string {
  const url =
    model.id +
    '/geotiff?' +
    'layer=' +
    model.selectedLayerId +
    '&timestep=' +
    model.timestep +
    bboxForApiRequest(bbox);

  return model.initime >= 0 ? `${url}&initime=${model.initime}` : url;
}

export function createBaseArrayUrl(
  modelId: string,
  layerId: string,
  point: { lng: number; lat: number }
): string {
  return (
    `${modelId}/array?` +
    'layer=' +
    layerId +
    '&lon=' +
    point.lng +
    '&lat=' +
    point.lat
  );
}

export function createArrayForecastUrl(
  modelId: string,
  layerId: string,
  point: { lng: number; lat: number },
  initime: number
): string {
  const url = createBaseArrayUrl(modelId, layerId, point);
  return `${url}&initime=${initime}`;
}

export function createArrayMonitoringUrl(
  modelId: string,
  layerId: string,
  point: { lng: number; lat: number },
  from: number,
  to: number
): string {
  const url = createBaseArrayUrl(modelId, layerId, point);
  return `${url}&timestep_from=${from}&timestep_to=${to}`;
}

/**
 * @deprecated use createArrayForecastUrl or createArrayMonitoringUrl instead
 */
export function createArrayUrl(
  model: WeatherModel,
  point: { lng: number; lat: number }
): string {
  const url = createBaseArrayUrl(model.id, model.selectedLayerId, point);
  return model.initime >= 0
    ? `${url}&initime=${model.initime}`
    : `${url}&timestep_from=${model.dateRange.from}&timestep_to=${model.dateRange.to}`;
}

export function createInitimeLabel(value: number, utcOffset?: string): string {
  utcOffset = utcOffset || '+00:00';
  return moment.unix(value).utcOffset(utcOffset).format('DD MMM YYYY HH:mm');
}

export function timestepToLabel(
  timestep: number,
  initime: number,
  layer: WeatherLayer,
  utcOffset?: string
): string {
  return timestepToLabelArray(timestep, initime, layer, utcOffset).join(' ');
}

export function timestepToLabelArray(
  timestep: number,
  initime: number,
  layer: WeatherLayer,
  utcOffset?: string
): string[] {
  utcOffset = utcOffset || '+00:00';
  const mom = timestepToMoment(timestep, initime, layer, utcOffset);
  switch (layer.timestepType.type) {
    case 'UNIX':
      return [mom.format('HH:mm'), mom.format('DD MMM')];
    case 'DAYS':
      return [mom.format('DD'), mom.format('MMM YYYY')];
    case '1MONTH':
    case '3MONTH':
    case '6MONTH':
      return [mom.format('MMM YYYY')];
    case '1MONTH_AVG':
      return [mom.format('MMM')];
    case 'QUARTER':
    case 'QUARTER_AVG': {
      const to = timestepToMoment(timestep, initime, layer, utcOffset);
      const from = to.clone().subtract(2, 'months');
      return layer.timestepType.type === 'QUARTER'
        ? [`${from.format('MMM')} - ${to.format('MMM YYYY')}`]
        : [`${from.format('MMM')} - ${to.format('MMM')}`];
    }
    case 'SEMESTER':
    case 'SEMESTER_AVG': {
      const to = timestepToMoment(timestep, initime, layer, utcOffset);
      const from = to.clone().subtract(5, 'months');
      return layer.timestepType.type === 'SEMESTER'
        ? [`${from.format('MMM')} - ${to.format('MMM YYYY')}`]
        : [`${from.format('MMM')} - ${to.format('MMM')}`];
    }
    case '1YEAR':
    case '1YEAR_AVG':
      return [mom.format('YYYY')];
    default:
      throw Error('Unsupported TimestepType');
  }
}

export function timestepToMoment(
  timestep: number,
  initime: number,
  layer: WeatherLayer,
  utcOffset?: string
): Moment {
  utcOffset = utcOffset || '+00:00';
  switch (layer.timestepType.type) {
    case 'UNIX':
      return moment
        .unix(initime + layer.timestepType.interval * timestep)
        .utcOffset(utcOffset);
    case 'DAYS':
      return moment.utc('1900-01-01').add(timestep, 'days');
    case '1MONTH':
    case '3MONTH':
    case 'QUARTER':
    case '6MONTH':
    case 'SEMESTER':
      return moment.utc('1899-12-01').add(timestep, 'months');
    case '1MONTH_AVG':
    case 'QUARTER_AVG':
    case 'SEMESTER_AVG':
      return moment('2021-01-01').add(timestep, 'months');
    case '1YEAR':
    case '1YEAR_AVG':
      return moment.utc(`${timestep}-01-01`);
    default:
      throw Error('Unsupported TimestepType');
  }
}

export function momentToTimestep(
  mom: Moment,
  initime: number,
  layer: WeatherLayer,
  utcOffset: string
): number {
  switch (layer.timestepType.type) {
    case 'UNIX':
      return (mom.unix() - initime) / layer.timestepType.interval;
    case 'DAYS':
      return mom.diff('1900-01-01', 'days');
    case '1MONTH':
    case '3MONTH':
    case 'QUARTER':
    case '6MONTH':
    case 'SEMESTER':
      return mom.diff('1899-12-01', 'months');
    case '1YEAR':
    case '1YEAR_AVG':
      return parseInt(mom.format('YYYY'));
    default:
      throw Error('Unsupported TimestepType');
  }
}

export function getDefaultTimestep(
  layer: WeatherLayer,
  initime: number
): number {
  const timestepList = layer.timestepList.find(
    (ts) => ts.initime === initime
  )?.timesteps;
  if (!timestepList) {
    throw Error('[weather-core.functions] Timestep list is undefined');
  }
  return initime <= 0 ? timestepList[timestepList.length - 1] : timestepList[0];
}

export function getDefaultInitime(layer: WeatherLayer): number {
  return layer.timestepList[0].initime;
}

export function timestepToTimeUnit(
  timestep: number,
  initime: number,
  layer: WeatherLayer,
  utcOffset?: string
): TimeUnit {
  return {
    apiValue: timestep,
    asDate: timestepToMoment(timestep, initime, layer, utcOffset),
    label: timestepToLabel(timestep, initime, layer, utcOffset),
  };
}

export function getAutoUtcOffset(): string {
  return moment().format('Z');
}

/**
 * Return true if `bounds1` in contained in `bounds2
 * @param bounds1: mapboxgl.LngLatBounds
 * @param bounds2 mapboxgl.LngLatBounds
 */
export function isBoundsContained(
  bounds1: mapboxgl.LngLatBounds,
  bounds2: mapboxgl.LngLatBounds
): boolean {
  if (!bounds1 || !bounds2) {
    return false;
  }
  return (
    bounds1.getWest() > bounds2.getWest() &&
    bounds1.getEast() < bounds2.getEast() &&
    bounds1.getNorth() < bounds2.getNorth() &&
    bounds1.getSouth() > bounds2.getSouth()
  );
}

export function bboxToBounds(bbox: number[][]): mapboxgl.LngLatBounds {
  return new mapboxgl.LngLatBounds([
    bbox[0][0],
    bbox[0][1],
    bbox[3][0],
    bbox[1][1],
  ]);
}

export function bboxForApiRequest(bbox: number[]): string {
  return (
    '&min_lon=' +
    bbox[0] +
    '&min_lat=' +
    bbox[1] +
    '&max_lon=' +
    bbox[2] +
    '&max_lat=' +
    bbox[3]
  );
}

export function utcOffsetFormatter(utcOffset: string): string {
  return utcOffset.split(':')[1] === '00' // if utcOffset has 00 minutes (eg +02:00, +11:00...)
    ? utcOffset === '+00:00'
      ? 'UTC'
      : `UTC${utcOffset.slice(0, 3)}` // then return only hour or UTC if +00:00
    : `UTC${utcOffset}`; // otherwise (offset minutes !== :00) return full offset
}

export function calculateWind(
  u: number,
  v: number,
  conversionFactor: number
): { dir: number; speed: number } {
  const speed = Math.sqrt(Math.pow(u, 2) + Math.pow(v, 2));
  const dir = (Math.acos(v / speed) * 360) / (2 * Math.PI);
  const reversedDir =
    u < 0 ? Math.round((360 - dir + 180) % 360) : Math.round((dir + 180) % 360);

  return {
    speed: Math.round(speed * conversionFactor * 10) / 10,
    dir: Math.round(reversedDir * 10) / 10,
  };
}

export function isVectorLayer(layer: WeatherLayer | string): boolean {
  if (!layer) return false;
  const vectorLayers = ['wind', 'current'];
  const id = typeof layer === 'string' ? layer : layer.id;
  return vectorLayers.find((l) => id.toLowerCase().includes(l)) !== undefined;
}

/**
 * Parse server response and try to get name of u and v component.
 * Return null if response is not valid.
 *
 * Example of response:
 {
    timestep_list: number[]
    v_comp: number[]    // example wind_v, current_v, etc
    u_comp: number[]    // example wind_u, current_u, etc
 }
 *
 * Return example: { u: 'wind_u', v: 'wind_v' }
 *
 * WARNING!
 * Response keys are considered as u and v component if they contain '_u' or '_v' in their name.
 * For these reason, if response contains other keys with '_u' or '_v' in their name, they will be
 * considered as u and v component!
 */
export function getVectorCompArray(apiResponse: {
  [k: string]: number[];
}): { u_comp: string; v_comp: string } | null {
  const keys = Object.keys(apiResponse);
  if (keys.length !== 3 || !keys.includes('timestep_list')) return null;
  const u_comp = keys.find((k) => k.toLowerCase().includes('_u'));
  const v_comp = keys.find((k) => k.toLowerCase().includes('_v'));
  return u_comp && v_comp ? { u_comp, v_comp } : null;
}
