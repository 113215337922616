import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromForm from './form/+state/form.reducer';
import * as fromSpecific from './form/+state/form-specific.reducer';
import * as fromMarkerStyle from './form/+state/marker-style.reducer';
import * as fromUser from './user/+state/user.reducer';
import * as fromWebHook from './webhooks/+state/webhook.reducer';
import * as fromLink from './link/+state/link.reducer';
import * as fromResource from './resource/+state/resource.reducer';
import * as fromDeploy from './deploy/+state/deploy.reducer';
import * as fromAdmin from './+state/admin.reducer';

export type State = {
  forms: fromForm.State;
  specifics: fromSpecific.State;
  markerStyle: fromMarkerStyle.State;
  users: fromUser.State;
  webhooks: fromWebHook.State;
  links: fromLink.State;
  resources: fromResource.State;
  deploy: fromDeploy.State;
  admin: fromAdmin.State;
};

export const adminReducerMap: ActionReducerMap<State> = {
  forms: fromForm.reducer,
  specifics: fromSpecific.reducer,
  markerStyle: fromMarkerStyle.reducer,
  users: fromUser.reducer,
  webhooks: fromWebHook.reducer,
  links: fromLink.reducer,
  resources: fromResource.reducer,
  deploy: fromDeploy.reducer,
  admin: fromAdmin.reducer,
};

export const APP_ADMIN_FEATURE_KEY = 'app-admin';

export const selectAppAdminState = () =>
  createFeatureSelector<State>(APP_ADMIN_FEATURE_KEY);

// import {
//   FormReducer,
//   MarkerStyleReducer,
//   SpecificReducer,
// } from './form/+state';
// import { UserReducer } from './user/+state';
// import { WebHookReducer } from './webhooks/+state';
// import { LinkReducer } from './link/+state';
// import { ResourceReducer } from './resource/+state';
// import { DeployReducer } from './deploy/+state';
// import { AdminReducer } from './+state';

// export type State = {
//   forms: FormReducer.State;
//   specifics: SpecificReducer.State;
//   markerStyle: MarkerStyleReducer.State;
//   users: UserReducer.State;
//   webhooks: WebHookReducer.State;
//   links: LinkReducer.State;
//   resources: ResourceReducer.State;
//   deploy: DeployReducer.State;
//   admin: AdminReducer.State;
// };
//
// export const adminReducerMap: ActionReducerMap<State> = {
//   forms: FormReducer.reducer,
//   specifics: SpecificReducer.reducer,
//   markerStyle: MarkerStyleReducer.reducer,
//   users: UserReducer.reducer,
//   webhooks: WebHookReducer.reducer,
//   links: LinkReducer.reducer,
//   resources: ResourceReducer.reducer,
//   deploy: DeployReducer.reducer,
//   admin: AdminReducer.reducer,
// };
