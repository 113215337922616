import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromLink from './';
import { Observable } from 'rxjs';
import { Link } from '3map-models';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  constructor(private store: Store<fromLink.State>) {}

  getList(): Observable<Link[]> {
    return this.store.pipe(select(fromLink.selectLinks()));
  }

  addLink(link: Link): void {
    this.store.dispatch(fromLink.UPSERT_LINK({ link }));
  }

  removeLink(link: Link): void {
    this.store.dispatch(fromLink.REMOVE_LINK({ link }));
  }
}
