import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Form, FormSpecific, Media, Record } from '3map-models';
import { MEDIA_TAB_ID } from '../feature-history.module';

@Component({
  selector: 'app-record-card[record][form][specific]',
  template: `
    <div class="record-card-wrapper" *ngIf="record && form && specific">
      <div class="record-card-header">
        <app-record-card-header
          [record]="record"
          [specific]="specific"
          [form]="form"
        ></app-record-card-header>

        <app-record-card-tabs
          [specific]="specific"
          [activeTab]="activeTab"
          (tabClicked)="tabClicked.emit($event)"
        ></app-record-card-tabs>
      </div>

      <div class="record-card-body">
        <app-record-card-questions
          *ngIf="activeTab !== mediaTab"
          [record]="record"
          [specific]="specific"
          [activeTab]="activeTab"
        >
        </app-record-card-questions>

        <div class="media-list" *ngIf="activeTab === mediaTab">
          <app-media-card
            *ngFor="let media of record.mediaList"
            [media]="media"
            [projectName]="record.projectName"
            [showControls]="false"
            (click)="mediaClick.emit(media)"
          ></app-media-card>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .record-card-wrapper {
        background-color: #ffffff;
        height: 100%;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        display: flex;
        flex-direction: column;
      }

      .record-card-header {
        height: 120px;
        padding: 10px;
      }

      .record-card-body {
        flex: 1;
        overflow: auto;
        padding: 10px;
      }

      .media-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      app-media-card {
        margin: 5px;
        width: 200px;
        height: 200px;
        border: 1px solid var(--border);
        border-radius: 10px;
        overflow: hidden;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordCardComponent {
  @Input() record: Record | undefined;
  @Input() form: Form | undefined;
  @Input() specific: FormSpecific | undefined;
  @Input() activeTab: string | null = null;
  @Output() tabClicked = new EventEmitter<string | null>();
  @Output() mediaClick = new EventEmitter<Media>();
  mediaTab = MEDIA_TAB_ID;
}
