import { createAction, props } from '@ngrx/store';
import { NominatimResponse } from '@trim-web-apps/nominatim';

export const setSection = createAction(
  '[Sidenav] Set Section',
  props<{ section: 'MAP' | 'TABLE' }>()
);

export const openToSection = createAction(
  '[Sidenav] Open To Section',
  props<{ section: 'MAP' | 'TABLE' }>()
);

export const setOpened = createAction(
  '[Sidenav] Set Sidenav Opened',
  props<{ opened: boolean }>()
);

export const createDataset = createAction(
  '[Sidenav] Create Dataset',
  props<{ formId: string }>()
);

export const removeDataset = createAction(
  '[Sidenav] Remove Dataset',
  props<{ id: string }>()
);

export const reloadRecords = createAction('[Sidenav] Reload Records');

export const sidenavChangeProject = createAction('[Sidenav] Change Project');

export const sidenavLogout = createAction('[Sidenav] Logout');

export const enableMapResource = createAction(
  '[Sidenav] Enable MapResource',
  props<{ resourceId: string }>()
);

export const disableMapResource = createAction(
  '[Sidenav] Disable MapResource',
  props<{ resourceId: string }>()
);

export const enableMapResourceSuccess = createAction(
  '[Sidenav] Enable MapResource Success',
  props<{ resourceId: string }>()
);

export const enableMapResourceError = createAction(
  '[Sidenav] Enable MapResource Error',
  props<{ resourceId: string }>()
);

export const searchLocation = createAction(
  '[Sidenav] Search Location',
  props<{ searchLocation: NominatimResponse | null }>()
);

export const zoomToSearchLocation = createAction(
  '[Sidenav] Zoom To Search Location'
);
