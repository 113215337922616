import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Validate offset string
 */
export function isValidOffset(offset: string): boolean {
  const regex = /^(?:Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])$/;
  return regex.test(offset);
}

/**
 * Check if the given value is not null and not undefined (useful in rxjs functions like filter())
 */
export function notNullOrUndefined<T>(val: T | null | undefined): val is T {
  return val !== null && val !== undefined;
}

export function notNullOrUndefinedArray<T>(
  val: T[] | null | undefined
): val is T[] {
  return val !== null && val !== undefined;
}

/**
 * Decode a JWT and return it as `Object` if valid, `null` otherwise
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseJwt(token: string): any | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
}

export function isValidLongitude(value: unknown): boolean {
  if (typeof value !== 'number') return false;
  return !isNaN(value) && value <= 180 && value >= -180;
}

export function isValidLatitude(value: unknown): boolean {
  if (typeof value !== 'number') return false;
  return !isNaN(value) && value <= 90 && value >= -90;
}

export function createUniqueName(nameList: string[], baseName: string): string {
  for (let i = 1; i < 500; i++) {
    const name = `${baseName} (${i})`;
    if (nameList.indexOf(name) === -1) {
      return name;
    }
  }
  throw Error('Cannot create unique name');
}

/**
 * Return true is `n` is a number.
 * @param n
 * @deprecated this function will return `true` if `n` can be parsed with parseFloat (eg. '2' returns true!)
 */
export function isNumber(n: any): boolean {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export function isHexColor(color: string): boolean {
  return /^#[0-9A-F]{6}$/i.test(color);
}

export async function base64toBlob(base64: string): Promise<Blob> {
  const type = 'application/octet-stream';
  return new Promise((resolve) =>
    fetch(`${base64}`).then((res) => resolve(res.blob()))
  );
}

export async function blobImageToBase64(blob: Blob): Promise<string> {
  const allowedMimeTypes = ['image/png', 'image/jpeg'];
  // if (!allowedMimeTypes.includes(blob.type)) throw Error('Invalid image file');
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      typeof reader.result === 'string' ? resolve(reader.result) : reject();
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function blobToString(file: Blob): Observable<string> {
  const fileReader = new FileReader();
  const promise = new Promise((resolve) => (fileReader.onload = resolve));
  fileReader.readAsText(file, 'UTF-8');
  return from(promise).pipe(map((evt: any) => evt.target.result));
}

export function hash(str: string): string {
  let hash = 0,
    i,
    chr;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
}

/**
 * Return a random number (int) between `min` and `max` INCLUDED.
 * @param min
 * @param max
 */
export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getTodayMidnightUTC(): Date {
  const dateNoTime = new Date().toISOString().substring(0, 10);
  return new Date(`${dateNoTime}T00:00:00.000Z`);
}

export function roundToNearest(num: number, roundTo: number): number {
  if (roundTo <= 0) throw Error(`roundTo must be >= 0 (input: "${roundTo}" )`);
  if (num < 0)
    throw Error(`test with positive numbers only!! TODO: add negative tests`);
  // return Math.ceil(num / roundTo) * roundTo;
  return Math.round(num / roundTo) * roundTo;
}
