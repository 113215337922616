import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export type ButtonType = 'fill' | 'border' | 'flat';

@Component({
  selector: 'ui-btn',
  template: `
    <div
      class="btn-container"
      (click)="onClick()"
      [ngClass]="type"
      [class.full-width]="fullWidth"
      [class.disabled]="disabled"
    >
      <fa-icon [spin]="spin" *ngIf="icon" [icon]="icon"></fa-icon>
      <div class="label">{{ label }}</div>
    </div>
  `,
  styles: [
    `
      .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--spacing-1) var(--spacing-4);
        border-radius: var(--radius-1);
        width: fit-content;
      }

      .full-width {
        width: 100%;
      }

      fa-icon {
        font-size: 1.2em;
        margin-right: 10px;
      }

      .label {
        font-weight: var(--font-weight-3);
      }

      .btn-container:hover {
        color: #333333;
        cursor: pointer;
      }

      .fill {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        color: #ffffff;
      }

      .fill:hover {
        opacity: 0.8;
        color: #ffffff;
      }

      .border {
        border: 1px solid var(--border);
        color: var(--primary-color);
        background-color: transparent;
      }

      .border:hover {
        color: var(--primary-color);
        background-color: var(--hover);
      }

      .disabled {
        opacity: 0.6 !important;
        cursor: not-allowed !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnComponent {
  @Input() label?: string;
  @Input() icon?: IconDefinition;
  @Input() type: ButtonType = 'fill';
  @Input() fullWidth?: boolean;
  @Input() disabled?: boolean;
  @Input() spin?: boolean;
  @Output() btnClick: EventEmitter<void> = new EventEmitter<void>();

  onClick(): void {
    if (!this.disabled) this.btnClick.emit();
  }
}
