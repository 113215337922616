import { createSelector } from '@ngrx/store';
import { adapter } from './marker-style.reducer';
import { MarkerStyle } from '3map-models';
import * as fromRoot from '../project.selectors';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { MarkerStyleEntity } from './marker-style.entity';

const selectState = createSelector(
  fromRoot.selectProjectState,
  (state) => state.markerStyle
);

export const { selectAll } = adapter.getSelectors();

export const selectEntity = (props: { specificId: string }) =>
  createSelector(selectState, (state): MarkerStyleEntity | undefined => {
    return state.entities[props.specificId];
  });

export const selectMarkerStyle = (props: { specificId: string }) =>
  createSelector(selectEntity(props), (entity): MarkerStyle | null => {
    return entityToMarkerStyle(entity);
  });

export const selectEntities = () =>
  createSelector(selectState, (state) => state.entities);

export const selectEntitiesArray = () =>
  createSelector(selectState, (state) =>
    Object.keys(state.entities)
      .map((k) => state.entities[k])
      .filter(notNullOrUndefined)
  );

export const selectMarkerStyleList = () =>
  createSelector(
    selectAll,
    (entities): Array<MarkerStyle | null> => {
      return entities.map((e) => entityToMarkerStyle(e));
    }
  );

export const selectImgBase64List = (props: { specificId: string }) =>
  createSelector(selectEntity(props), (entity) => entity?.imgBase64List || []);

export const selectMarkerToUpload = () =>
  createSelector(selectEntitiesArray(), (entities) =>
    entities.filter((e) => e.imgBase64List.length > 0)
  );

export function entityToMarkerStyle(
  entity: MarkerStyleEntity | undefined
): MarkerStyle | null {
  if (entity) {
    let markerStyle: MarkerStyle = {
      image: entity.image,
      imageNumber: entity.imageNumber,
    };

    if (entity.iconFieldType) markerStyle.iconFieldType = entity.iconFieldType;
    if (entity.iconNumberRanges)
      markerStyle.iconNumberRanges = entity.iconNumberRanges;

    return markerStyle;
  }

  return null;
}
