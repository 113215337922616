import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SidenavData } from '../../+types/sidenav-data.type';

@Component({
  selector: 'app-user-nav-item',
  template: `
    <div class="user" *ngIf="data">
      <div class="user-img">
        {{ data.username.charAt(0) }}
      </div>
      <div class="right" *ngIf="data.opened">
        <div class="username text-ellipsis">{{ data.username }}</div>
        <div class="user-actions">
          <div class="logout" (click)="logout.emit()">Logout</div>
          <div class="admin" *ngIf="data.isAdmin" (click)="admin.emit()">
            Admin panel
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .user {
        display: grid;
        grid-template-columns: 45px 1fr;
        align-items: center;
      }

      .user-img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: var(--primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-size-3);
        color: var(--bg-color);
      }

      .right {
        padding: 0 var(--spacing-2);
        /*border: 1px solid var(--grey);*/
      }

      .username {
        font-size: var(--font-size-2);
      }

      .user-actions {
        display: flex;
        font-size: var(--font-size-1);
        line-height: var(--font-size-1);
        margin-top: var(--spacing-2);
        cursor: pointer;
        color: var(--grey);
      }

      .user-actions > div:hover {
        color: var(--text-color);
      }

      .user-actions > .logout {
        padding-right: var(--spacing-3);
      }

      .user-actions > .admin {
        border-left: 1px solid var(--border);
        padding-left: var(--spacing-3);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNavItemComponent {
  @Input() data?: SidenavData;
  @Output() logout: EventEmitter<void> = new EventEmitter();
  @Output() admin: EventEmitter<void> = new EventEmitter();
}
