import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { FieldType } from '3map-models';
import * as uuid from 'uuid';
import { FieldTypeComponent } from '../field-type/field-type.component';
import {
  moveElement,
  removeElementFromArrayByIndex,
} from '@trim-web-apps/core';

@Component({
  selector: 'app-form-specific-questions',
  template: `
    <div class="wrapper">
      <div class="top">
        <div class="label">Questions list</div>
        <div class="fill-remaining-space"></div>
        <ui-btn
          (btnClick)="switchToGroups.emit()"
          *ngIf="!isGroupQuestions"
          label="Switch to Questions Group"
          type="flat"
        ></ui-btn>
        <ui-select
          [items]="availableFieldTypes"
          itemSelected="Add Question"
          (itemSelectedChange)="onFieldTypeSelected($event)"
        />
      </div>
      <div class="questions">
        <form class="specific-wrapper">
          <div
            class="field-type-list"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
          >
            <app-field-type
              cdkDrag
              cdkDragLockAxis="y"
              *ngFor="
                let question of questions;
                let i = index;
                trackBy: trackBy
              "
              (removeQuestion)="removeQuestion(i)"
              (fieldTypeChange)="onFieldTypeChange()"
              [fieldType]="question"
            >
              <fa-icon
                class="drag-icon"
                cdkDragHandle
                [icon]="iconDrag"
              ></fa-icon>
            </app-field-type>
          </div>
        </form>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .top {
        border-bottom: 1px solid #dbdbdb;
        padding: 10px;
        display: flex;
        align-items: center;
      }

      .questions {
        overflow: auto;
        flex: 1;
        padding: 0 10px;
      }

      .drag-icon {
        cursor: grab;
      }
    `,
  ],
})
export class FormSpecificQuestionsComponent {
  @Input() questions: FieldType[] = [];
  @Input() isGroupQuestions: boolean | undefined;
  @Output() questionsChange: EventEmitter<FieldType[]>;
  @Output() fieldTypeChange: EventEmitter<FieldType>;
  @Output() switchToGroups: EventEmitter<void>;
  @ViewChildren(FieldTypeComponent) fieldTypeComponentsList:
    | QueryList<FieldTypeComponent>
    | undefined;
  iconDrag = faBars;
  availableFieldTypes = [
    'NUMBER',
    'TEXT',
    'RANGE',
    'LIST',
    'CHECK',
    'INT',
    'DATE',
  ];

  constructor() {
    this.fieldTypeChange = new EventEmitter<FieldType>();
    this.questionsChange = new EventEmitter<FieldType[]>();
    this.switchToGroups = new EventEmitter<void>();
  }
  trackBy(index: number, item: FieldType) {
    return item.id;
  }

  onFieldTypeChange(): void {
    this.questionsChange.emit(this.getGroupQuestions());
  }

  getGroupQuestions(): FieldType[] {
    if (this.fieldTypeComponentsList)
      return this.fieldTypeComponentsList.map((comp) => comp.getFieldType());
    throw Error('FieldType list not found');
  }

  removeQuestion(index: number): void {
    this.questionsChange.emit(
      removeElementFromArrayByIndex<FieldType>(this.getGroupQuestions(), index)
    );
  }

  drop(event: CdkDragDrop<string[]>): void {
    const questions = moveElement<FieldType>(
      this.getGroupQuestions(),
      event.previousIndex,
      event.currentIndex
    );
    this.questionsChange.emit(questions);
  }

  onFieldTypeSelected(type: string | null): void {
    if (type !== null && this.availableFieldTypes.includes(type as any)) {
      const ft: FieldType = {
        id: uuid.v4(),
        name: `New question ${type}`,
        type: type as any,
        required: false,
        immutable: false,
        options: [],
      };

      this.questionsChange.emit([...this.getGroupQuestions(), ft]);
    }
  }
}
