import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Form, FormSpecific } from '3map-models';
import * as uuid from 'uuid';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { take } from 'rxjs/operators';
import { FormDialogComponent } from '../../components/form-dialog/form-dialog.component';
import { createUniqueName } from '@trim-web-apps/core';
import { Store } from '@ngrx/store';
import * as FormSelectors from '../../+state/form.selectors';
import * as FormActions from '../../+state/form.actions';
import * as SpecificActions from '../../+state/form-specific.actions';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-form-list',
  template: `
    <div class="header">
      <app-section-label sectionLabel="Forms Collections">
        <ui-btn
          type="border"
          label="Create Form Collection"
          [icon]="iconAdd"
          (click)="onCreateForm()"
        />
      </app-section-label>
    </div>
    <div class="form-list-container" *ngIf="formList$ | async as formList">
      <div class="empty-form-list" *ngIf="formList.length === 0">
        <div>No Forms here!</div>
        <div>Use the above button to create a new Form</div>
      </div>

      <app-form-list-item
        *ngFor="let form of formList; trackBy: trackBy"
        [form]="form"
        (formDuplicate)="onFormDuplicate(form)"
        (formDelete)="onFormDelete(form)"
        (formEdit)="showFormDialog(form, 'edit')"
        (createSpecific)="createSpecific(form)"
        (specificDuplicate)="onSpecificDuplicate($event)"
        (specificDelete)="onSpecificDelete($event)"
      />
    </div>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .form-list-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        grid-gap: var(--spacing-4);
        padding: var(--spacing-4);
        justify-content: center;
        overflow: auto;
      }
    `,
  ],
})
export class FormListComponent {
  iconAdd = faPlus;
  formList$: Observable<Form[]>;

  constructor(
    private router: Router,
    private dialog: Dialog,
    private store: Store
  ) {
    this.formList$ = this.store.select(FormSelectors.selectFormList());
  }

  trackBy(index: number, item: Form): string {
    return item.id;
  }

  onCreateForm(): void {
    const form: Form = {
      id: uuid.v4(),
      name: 'New Form Collection',
      specificList: [],
      allowImage: false,
      allowVideo: false,
      allowAudio: false,
    };
    this.showFormDialog(form, 'create');
  }

  async onFormDelete(form: Form): Promise<void> {
    this.store.dispatch(FormActions.removeForm({ form }));
    // this.showDeleteDialog('Form Collection', form.name)
    //   .pipe(take(1))
    //   .subscribe((confirmed) => {
    //     if (confirmed) this.store.dispatch(FormActions.removeForm({ form }));
    //   });
  }

  async onSpecificDelete(evt: {
    form: Form;
    specific: FormSpecific;
  }): Promise<void> {
    this.store.dispatch(
      SpecificActions.removeSpecific({
        formId: evt.form.id,
        specificId: evt.specific.id,
      })
    );
    // const { form, specific } = evt;
    // this.showDeleteDialog('Form', specific.name)
    //   .pipe(take(1))
    //   .subscribe((confirmed) => {
    //     if (confirmed)
    //       this.store.dispatch(
    //         SpecificActions.removeSpecific({
    //           formId: form.id,
    //           specificId: specific.id,
    //         })
    //       );
    //     // this.specificService.deleteSpecific(form.id, specific.id);
    //   });
  }

  showFormDialog(form: Form, mode: 'edit' | 'create' | 'duplicate'): void {
    const data = { form, mode };
    const dialogRef = this.dialog.open<Form | undefined>(FormDialogComponent, {
      data,
    });
    dialogRef.closed.pipe(take(1)).subscribe((updatedForm) => {
      if (!updatedForm) return;
      // todo move duplicate to store/lib
      if (mode === 'duplicate')
        this.store.dispatch(FormActions.onDuplicateForm({ form })); // this.formService.createForm(updatedForm);
      if (mode === 'create')
        this.store.dispatch(FormActions.createForm({ form })); //this.formService.createForm(updatedForm);
      if (mode === 'edit')
        this.store.dispatch(FormActions.updateForm({ form })); // this.formService.updateForm(updatedForm);
    });
  }

  onFormDuplicate(form: Form): void {
    this.store.dispatch(FormActions.onDuplicateForm({ form })); // this.formService.duplicateForm(form);
  }

  onSpecificDuplicate(evt: { form: Form; specific: FormSpecific }): void {
    const { form, specific } = evt;
    this.store.dispatch(
      SpecificActions.onDuplicateSpecific({ formId: form.id, specific })
    );
    // this.specificService.duplicateSpecific(form.id, specific);
  }

  createSpecific(form: Form): void {
    const existingSpecificNameList = form.specificList.map((s) => s.name) || [];
    const specific = {
      id: uuid.v4(),
      name: createUniqueName(existingSpecificNameList, 'New Form'),
      questions: [],
      markerStyle: {
        image: uuid.v4(),
        imageNumber: 1,
      },
    };
    this.store.dispatch(
      SpecificActions.createSpecific({ formId: form.id, specific })
    );
    // this.specificService.createSpecific(form.id, specific);
  }

  // private showDeleteDialog(
  //   target: string,
  //   targetName: string
  // ): Observable<boolean> {
  //   const data: DialogConfirmData = {
  //     title: `Delete ${target}?`,
  //     message: `"${targetName}" will be deleted. This action can not be undone.`,
  //     acceptLabel: 'Delete',
  //     rejectLabel: 'Back',
  //   };
  //   const dialogRef = this.dialog.open(DialogConfirmComponent, { data });
  //   return dialogRef.afterClosed();
  // }
}
