import { FormSpecific, MarkerStyle } from '3map-models';
import * as uuid from 'uuid';

type QuestionGroup = {
  name: string;
  questions: string[];
};

type ListLogic = {
  q1: string;
  q2: string;
  logic: {
    r1: string;
    r2: string[];
  }[];
};

export function cloneSpecific(specific: FormSpecific): FormSpecific {
  const idsMap: Map<string, string> = new Map<string, string>();
  const questions = specific.questions.map((fieldType) => {
    const newId = uuid.v4();
    idsMap.set(fieldType.id, newId);
    return { ...fieldType, id: newId };
  });
  const markerStyle = changeIdMarkerStyle(specific.markerStyle, idsMap);
  const questionGroups = changeIdGroups(specific.questionGroups, idsMap);
  const listLogic = changeIdLogic(specific.listLogic, idsMap);

  return {
    id: uuid.v4(),
    name: `Copy of ${specific.name}`,
    questions,
    markerStyle,
    questionGroups,
    listLogic,
  };
}

function changeIdMarkerStyle(
  ms: MarkerStyle,
  idsMap: Map<string, string>
): MarkerStyle {
  if (ms.iconFieldType) {
    return { ...ms, iconFieldType: idsMap.get(ms.iconFieldType) };
  }
  return ms;
}

function changeIdGroups(
  groups: QuestionGroup[] | undefined,
  idsMap: Map<string, string>
): QuestionGroup[] | undefined {
  return groups?.map((group) => {
    const questions = group.questions.map((id) => idsMap.get(id) || '');
    return { ...group, questions };
  });
}

function changeIdLogic(
  logicList: ListLogic[] | undefined,
  idsMap: Map<string, string>
): ListLogic[] | undefined {
  return logicList?.map((logic) => {
    const q1 = idsMap.get(logic.q1) || '';
    const q2 = idsMap.get(logic.q2) || '';
    return { ...logic, q1, q2 };
  });
}
