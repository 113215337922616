import {
  RAINFALL_10DAY,
  RAINFALL_15DAY,
  RAINFALL_1DAY,
  RAINFALL_3DAY,
  RAINFALL_7DAY,
} from '../../weather-layers/weather.layers';
import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createGfsAcc(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 1, 1, '+00:00');
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[0],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 1 };

  const gfsLayers: WeatherLayer[] = [
    {
      ...RAINFALL_1DAY,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RAINFALL_3DAY,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RAINFALL_7DAY,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RAINFALL_10DAY,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RAINFALL_15DAY,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'GFS_ACC',
    label: 'GFS_ACC',
    category: 'FORECAST',
    layers: gfsLayers,
    initime: gfsLayers[0].timestepList[0].initime,
    timestep: gfsLayers[0].timestepList[0].timesteps[0],
    hasChart: false,
    selectedLayerId: gfsLayers[0].id,
    dateRange: defaultDateRng,
    bbox: [
      [-180, 90],
      [180, 90],
      [180, -90],
      [-180, -90],
      [-180, 90],
    ],
    info: {
      resolution: '0.25°',
      coverage: 'Global',
      url: 'https://www.ncei.noaa.gov/products/weather-climate-models/global-forecast',
      credits: 'NOAA',
    },
  };
}
