import { Component, Input, OnInit } from '@angular/core';
import { FieldType } from '3map-models';
import { moveElement, upsertElementInArray } from '@trim-web-apps/core';
import { QuestionsGroup } from '@trim-web-apps/project-core';
import {
  RemoveGroupAction,
  RemoveQuestionGroupDialogComponent,
} from '../../components/remove-question-group-dialog/remove-question-group-dialog.component';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { QuestionGroupRenameComponent } from '../../components/question-group-rename/question-group-rename.component';
import * as SpecificSelectors from '../../+state/form-specific.selectors';
import * as SpecificActions from '../../+state/form-specific.actions';
import { Store } from '@ngrx/store';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-form-specific-questions-groups[specificId]',
  template: `
    <mat-tab-group
      cdkDropList
      cdkDropListOrientation="horizontal"
      *ngIf="questionsGroups$ | async as questionsGroups"
      (cdkDropListDropped)="drop($event, questionsGroups)"
      class="allow-tab-events"
    >
      <mat-tab
        *ngFor="let group of questionsGroups; trackBy: trackBy"
        [labelClass]="['show-on-hover-toggle']"
      >
        <ng-template mat-tab-label>
          <div class="drag" cdkDrag cdkDragLockAxis="x">
            <div class="tab-label">
              <div class="hover-icon">
                <fa-icon
                  class="show-on-hover drag-icon"
                  cdkDragHandle
                  [icon]="iconDrag"
                />
              </div>
              <span class="group-name">{{
                group.groupName | truncateStr
              }}</span>
              <div class="hover-icon">
                <ui-dropdown-icon
                  class="show-on-hover"
                  [icon]="iconMenu"
                  [itemList]="['Change Group name', 'Remove Group']"
                  (itemSelected)="
                    $event === 'Remove Group'
                      ? onRemoveGroup(group, questionsGroups)
                      : onRenameGroup(group, questionsGroups)
                  "
                />
              </div>
            </div>
          </div>
        </ng-template>
        <div class="tab-content">
          <app-form-specific-questions
            [questions]="group.questions"
            [isGroupQuestions]="true"
            (questionsChange)="
              onQuestionsChange($event, group.groupName, questionsGroups)
            "
          ></app-form-specific-questions>
        </div>
      </mat-tab>
      <mat-tab label="Add new Group">
        <div class="add-group-wrapper">
          <div class="add-group-input">
            <input type="text" [(ngModel)]="newGroupName" />
            <ui-btn
              (click)="onAddGroup(questionsGroups)"
              type="border"
              label="Add Group"
            ></ui-btn>
          </div>
          <div *ngIf="newGroupNameError">
            Group name is mandatory and must be unique
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  `,
  styles: [
    `
      ::ng-deep .mat-mdc-tab-group .mat-mdc-tab .mdc-tab__content {
        pointer-events: all !important;
      }

      mat-tab-group {
        height: 100%;
      }

      .add-group-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
      }

      .tab-label,
      .tab-content {
        display: flex;
      }

      .tab-label {
        align-items: center;
      }

      .add-group-input input {
        width: 350px;
        margin-bottom: 20px;
      }

      .drag-icon {
        cursor: grab;
      }

      .hover-icon {
        width: 20px;
        margin: 0 var(--spacing-2);
      }
    `,
  ],
})
export class FormSpecificQuestionsGroupsComponent implements OnInit {
  @Input() specificId!: string;
  questionsGroups$: Observable<QuestionsGroup[]> | undefined;
  newGroupName: string | undefined;
  newGroupNameError: boolean;
  iconDrag = faBars;
  iconMenu = faEllipsisV;

  constructor(
    // private projectStore: ProjectCoreService,
    private dialog: Dialog,
    private store: Store
  ) {
    this.newGroupNameError = false;
  }

  ngOnInit(): void {
    this.questionsGroups$ = this.store.select(
      SpecificSelectors.selectQuestionsGroupList(this.specificId)
    );
  }

  trackBy(index: number, item: QuestionsGroup) {
    return item.groupName;
  }

  drop(
    event: CdkDragDrop<string[]>,
    questionsGroupList: QuestionsGroup[]
  ): void {
    const { previousIndex, currentIndex } = event;
    if (previousIndex === currentIndex) return;
    const groups = moveElement(questionsGroupList, previousIndex, currentIndex);
    this.store.dispatch(
      SpecificActions.upsertQuestionGroup({
        specificId: this.specificId,
        questionsGroupList,
      })
    );
  }

  onAddGroup(questionsGroups: QuestionsGroup[]): void {
    const groupNameList = questionsGroups.map((g) => g.groupName);
    this.newGroupNameError =
      this.newGroupName === undefined ||
      this.newGroupName.length === 0 ||
      !!groupNameList?.includes(this.newGroupName);

    if (!this.newGroupNameError && this.newGroupName !== undefined) {
      this.store.dispatch(
        SpecificActions.addQuestionGroup({
          specificId: this.specificId,
          groupName: this.newGroupName,
        })
      );
      this.newGroupName = '';
    }
  }

  onQuestionsChange(
    questions: FieldType[],
    groupName: string,
    questionsGroups: QuestionsGroup[]
  ): void {
    if (!questionsGroups) return;
    const newQuestionsGroup = upsertElementInArray<QuestionsGroup>(
      questionsGroups,
      { questions, groupName },
      'groupName'
    );
    this.store.dispatch(
      SpecificActions.upsertQuestionGroup({
        specificId: this.specificId,
        questionsGroupList: newQuestionsGroup,
      })
    );
  }

  onRemoveGroup(group: QuestionsGroup, questionGroups: QuestionsGroup[]): void {
    if (group.questions.length === 0) {
      this.store.dispatch(
        SpecificActions.removeQuestionGroup({
          specificId: this.specificId,
          groupName: group.groupName,
        })
      );
    }
    const data = {
      questionGroup: group,
      questionGroupList: questionGroups,
    };
    this.dialog
      .open<RemoveGroupAction | undefined>(RemoveQuestionGroupDialogComponent, {
        data,
      })
      .closed.pipe(take(1))
      .subscribe((action: RemoveGroupAction | undefined) => {
        if (!action) return;

        if (action.keepQuestions && action.moveToGroup) {
          this.store.dispatch(
            SpecificActions.moveQuestionGroup({
              specificId: this.specificId,
              fromGroup: group,
              toGroupName: action.moveToGroup,
            })
          );
        }

        action.keepQuestions && action.moveToGroup === null
          ? this.store.dispatch(
              SpecificActions.removeAllQuestionGroup({
                specificId: this.specificId,
              })
            )
          : this.store.dispatch(
              SpecificActions.removeQuestionGroup({
                specificId: this.specificId,
                groupName: group.groupName,
              })
            );
      });
  }

  onRenameGroup(
    questionGroup: QuestionsGroup,
    questionGroupList: QuestionsGroup[]
  ): void {
    const data = { questionGroup, questionGroupList };
    this.dialog
      .open<string | undefined>(QuestionGroupRenameComponent, { data })
      .closed.pipe(take(1))
      .subscribe((newGroupName: string | undefined) => {
        if (newGroupName)
          this.store.dispatch(
            SpecificActions.renameQuestionGroup({
              specificId: this.specificId,
              oldName: questionGroup.groupName,
              newName: newGroupName,
            })
          );
      });
  }
}
