import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { EventData, MapMouseEvent } from 'mapbox-gl';
import { MapStore } from '../map-store.service';
import { getMapPlugins } from '../map-utils/map.utils';
import { createMap } from '../map-utils/map.builder';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MapboxPlugin, MapConfig } from '../map-utils/MapConfig';
import { MapService } from '../map.service';

@Component({
  selector: 'map-core',
  template: `
    <div #mapContainer class="map"></div>
    <!--    <ng-template #mapContextMenuTemplate>-->
    <!--      <lib-map-context-menu [lat]='selectedLat'-->
    <!--                            [lng]='selectedLng'-->
    <!--                            [forms]='activeForms$ | async'-->
    <!--                            (addRecord)='addRecord.emit($event)'>-->
    <!--      </lib-map-context-menu>-->
    <!--    </ng-template>-->
  `,
  styles: [
    `
      :host,
      .map {
        width: 100%;
        height: 100%;
      }

      ::ng-deep .mapboxgl-canvas {
        outline: none;
      }
    `,
  ],
})
export class MapBaseComponent implements AfterViewInit, OnDestroy {
  @Input() center: { lng: number; lat: number } | undefined;
  @Input() zoom: number | undefined;
  @Input() plugins: MapboxPlugin[] | undefined;
  @Input() maxBounds: mapboxgl.LngLatBoundsLike | undefined;
  @Input() controlPosition:
    | 'top-right'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-left'
    | undefined;
  @Output() mapReady: EventEmitter<mapboxgl.Map>;
  @Output() mapContainerRef: EventEmitter<ViewContainerRef>;
  @Output() mapClick: EventEmitter<MapMouseEvent>;
  @Output() mapStyleChanged: EventEmitter<string>;
  @Output() mapRightClick: EventEmitter<MapMouseEvent>;
  @ViewChild('mapContainer') mapContainer: ElementRef | undefined;
  // @ViewChild('mapContextMenuTemplate', { static: false }) mapContextMenuTemplateRef: TemplateRef<any>;
  // @Input() disableContextMenu: boolean;

  private map: mapboxgl.Map | undefined;
  private mapStyleId: string | undefined;
  private styleSub: Subscription | undefined;

  constructor(
    private mapService: MapService,
    private mapStore: MapStore,
    private viewContainerRef: ViewContainerRef
  ) {
    this.mapClick = new EventEmitter<MapMouseEvent>();
    this.mapReady = new EventEmitter<mapboxgl.Map>();
    this.mapContainerRef = new EventEmitter<ViewContainerRef>();
    this.mapStyleChanged = new EventEmitter<string>();
    this.mapRightClick = new EventEmitter<mapboxgl.MapMouseEvent>();
  }

  ngAfterViewInit() {
    this.mapService.removeMap();

    if (this.mapContainer === undefined) {
      throw Error('[lib-map] Map container not found');
    }

    this.map = createMap(this.mapContainer.nativeElement, this.getMapConfig());
    this.map
      .on('load', (e) => this.handleMapLoad(e))
      .on('click', (e) => this.mapClick.emit(e))
      // .on('contextmenu', (evt: mapboxgl.MapMouseEvent) => this.showMapContextMenu(evt))
      .on('moveend', () => this.onMoveEnd());

    this.styleSub = fromEvent(this.map as any, 'styledata')
      .pipe(debounceTime(500))
      .subscribe((e: any) => {
        if (this.mapStyleId !== e.style.stylesheet.id) {
          this.mapStyleId = e.style.stylesheet.id;
          this.mapStyleChanged.emit(this.mapStyleId);
        }
      });
  }

  ngOnDestroy() {
    this.styleSub?.unsubscribe();
  }

  private getMapConfig(): MapConfig {
    const fromStore = this.mapStore.getValues();
    const zoom = this.zoom || fromStore.zoom;
    const center = this.center || fromStore.center;
    const plugins = this.plugins || getMapPlugins();
    const maxBounds = this.maxBounds || null;
    const controlPosition = !!this.controlPosition
      ? this.controlPosition
      : 'top-right';
    return { center, zoom, plugins, maxBounds, controlPosition };
  }

  private handleMapLoad(loadEvent: EventData): void {
    if (this.map) {
      this.mapService.map = this.map;
      this.mapStyleId = loadEvent['target'].style.stylesheet.id;
      this.mapReady.emit(this.map);
      this.mapContainerRef.emit(this.viewContainerRef);
      this.mapStyleChanged.emit(this.mapStyleId);
    }
  }

  private onMoveEnd(): void {
    if (this.map) {
      const { lng, lat } = this.map.getCenter();
      const zoom = this.map.getZoom();
      this.mapStore.patchState({ center: { lng, lat }, zoom });
    }
  }

  // private showMapContextMenu(evt: MapMouseEvent): void {
  //   this.mapRightClick.emit(evt);
  //   if (!this.disableContextMenu) {
  //     this.selectedLat = evt.lngLat.lat;
  //     this.selectedLng = evt.lngLat.lng;
  //     this.contextMenuService.openContextMenu(this.mapContextMenuTemplateRef, evt.originalEvent, this.viewContainerRef);
  //   }
  // }

  // private resizeMap(): void {
  //   // console.log('resize map!');
  //   // this.map?.resize();
  // }
  //
  // private setMapContainerSize(): void {
  //   const height = this.mapContainer?.nativeElement.offsetHeight;
  //   const width = this.mapContainer?.nativeElement.offsetWidth;
  //   this.mapContainerSize = height && width ? { height, width } : undefined;
  // }
  //
  // private isMapContainerSizeChanged(): boolean {
  //   if (
  //     this.mapContainer === undefined ||
  //     this.mapContainerSize === undefined
  //   ) {
  //     return false;
  //   }
  //
  //   const heightChanged =
  //     this.mapContainer.nativeElement.offsetHeight !==
  //     this.mapContainerSize.height;
  //   const widthChanged =
  //     this.mapContainer.nativeElement.offsetWidth !==
  //     this.mapContainerSize.width;
  //
  //   return heightChanged || widthChanged;
  // }
}
