import { createSelector } from '@ngrx/store';
import { State } from './admin.reducer';
import { FormSelectors } from '../form/+state';
import { UserSelectors } from '../user/+state';
import { LinkSelectors } from '../link/+state';
import { WebHookSelectors } from '../webhooks/+state';
import { ResourceSelectors } from '../resource/+state';
import { Project, projectDecoder } from '3map-models';
import { selectAppAdminState } from '../admin.reducer.map';

// const adminState = createFeatureSelector<State>(ADMIN_FEATURE_KEY);
const adminState = () =>
  createSelector(selectAppAdminState(), (state) => state.admin);

export const selectProjectInit = () =>
  createSelector(adminState(), (state: State) => state.projectInit);

export const selectProjectOrigin = () =>
  createSelector(adminState(), (state: State) => state.projectOrigin);

export const selectModified = () =>
  createSelector(adminState(), (state: State) => state.modified);

export const selectProject = () =>
  createSelector(
    selectProjectOrigin(),
    FormSelectors.selectFormList(),
    UserSelectors.selectUsers(),
    WebHookSelectors.selectWebhooks(),
    LinkSelectors.selectLinks(),
    ResourceSelectors.selectEntityResourceList(),
    (
      project,
      formList,
      userList,
      webHookList,
      linkList,
      resourceList
    ): Project | null => {
      if (!project) return null;

      return {
        name: project.name,
        formList,
        userList,
        webHookList,
        linkList,
        resourceList,
      };
    }
  );

export const selectIsProjectValid = () =>
  createSelector(selectProject(), (project): boolean => {
    if (!project) return false;
    if (project === null) return false;
    const decoded = projectDecoder().run(project);
    if (!decoded.ok) console.error(decoded.error);
    return decoded.ok;
  });
