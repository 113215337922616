import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProject from './project.reducer';
import { Form, Project } from '3map-models';
import { AuthSelectors } from '../../../auth/state/auth';
import { notNullOrUndefined } from '@trim-web-apps/core';

export const selectProjectState = createFeatureSelector<fromProject.State>(
  fromProject.projectFeatureKey
);

export const selectEnabledFormState = () =>
  createSelector(selectProjectState, (state) => state.enabledForms);

export const selectProject = () =>
  createSelector(selectProjectState, (state) => state.project);

export const selectLinks = () =>
  createSelector(selectProject(), (project) => project?.linkList ?? []);

export const selectUserForms = () =>
  createSelector(
    selectProject(),
    AuthSelectors.selectAuthUsername(),
    (project, username): Form[] => {
      if (project === null || username === null) return [];
      const { userList, formList } = project;
      const pUser = userList.find((u) => u.username === username);
      if (!pUser) return [];
      return formList.filter((f) => pUser.formList.includes(f.id));
    }
  );

export const selectFormById = (id: string) =>
  createSelector(
    selectProject(),
    (project) => project?.formList.find((f) => f.id === id) || null
  );

export const selectFormSpecificById = (specificId: string) =>
  createSelector(
    selectProject(),
    (project: Project | null) =>
      project?.formList
        .map((f) => f.specificList)
        .reduce((acc, item) => [...acc, ...item], [])
        .find((specific) => specific.id === specificId) || null
  );

const { selectAll, selectEntities, selectIds } =
  fromProject.enabledFormAdapter.getSelectors();

export const selectAllDataset = () =>
  createSelector(selectEnabledFormState(), (state) => selectAll(state));

export const selectDatasetEntities = () =>
  createSelector(selectEnabledFormState(), (state) => selectEntities(state));

export const selectDatasetIds = () =>
  createSelector(
    selectEnabledFormState(),
    (state) => selectIds(state) as string[]
  );

export const selectDatasetById = (id: string) =>
  createSelector(selectDatasetEntities(), (entities) => entities[id]);

export const selectDatasetFormList = () =>
  createSelector(
    selectAllDataset(),
    selectUserForms(),
    (datasetList, formList) => {
      return datasetList
        .map((dataset) => {
          const form = formList.find((f) => f.id === dataset.formId);
          return form ? { dataset, form } : null;
        })
        .filter(notNullOrUndefined);
    }
  );

export const selectDatasetForm = (id: string) =>
  createSelector(selectDatasetById(id), selectFormById(id), (dataset, form) => {
    return dataset && form ? { dataset, form } : null;
  });
