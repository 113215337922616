// export const passwordRegex = new RegExp('^(?=\P{Ll}*\p{Ll})(?=\P{Lu}*\p{Lu})(?=\P{N}*\p{N})(?=[\p{L}\p{N}]*[^\p{L}\p{N}])[\s\S]{8,}$');
// export const passwordRegex = new RegExp('^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$');
// export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
import {AbstractControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';

/**
 * Form Control Validator: check if two fields in the given {@link AbstractControl} contains the same value.
 * Return `null` if they match, an `Object` otherwise with the following structure:
 * {
 *     valuesMismatch: {
 *         valid: false,
 *         field1: any
 *         field2: any
 *     }
 * }
 */
export const validateFormControlSameValues = (field1: string, field2: string): ValidatorFn => (control: AbstractControl) => {
    if (control.get(field1)?.value === control.get(field2)?.value) {
        return null;
    }
    return {valuesMismatch: {valid: false}, field1, field2};
};

/**
 * Form Control Validator: return `null` is argument {@link AbstractControl} has no error, `Object` otherwise.
 * Error `Object` has the following structure and types:
 * {
 *     invalidPassword: {
 *         valid: boolean // false if error
 *         value: any // value contained in control
 *         errorMsg: string // an error message that can be displayed
 *     }
 * }
 *
 * `errorMsg` can contains several messages, see {@link validatePassword} function
 */
// tslint:disable-next-line:no-any
export function passwordValidator(control: AbstractControl): { [key: string]: any } | null {
    const pwdValidated = validatePassword(control.value);
    return pwdValidated.valid
        ? null
        : {invalidPassword: {valid: false, value: control.value, errorMsg: pwdValidated.missingRule}};
}

/**
 * Form Group Validator: checks if two passwords (strings) are equals and have been both modified by the user.
 * Since this is a specific use-case for password-only, it require a {@link FormGroup} with two {@link AbstractControl}
 * named `password` and `passwordConfirm`. See {@link validateFormControlSameValues} function for a more generic solution.
 *
 * Return `null` if both `password` and `passwordConfirm`:
 * - have been modified by the user (check on `dirty` property)
 * - are valid (according to {@link passwordValidator})
 * - are equal
 *
 * Return an `Object` otherwise, with the following structure"
 * {
 *     passwordMismatch: {
 *         valid: boolean // false if error
 *         errorMsg: string // 'Passwords do not match'
 *     }
 * }
 */
// tslint:disable-next-line:no-any
export function passwordsMatchValidator(form: UntypedFormGroup): { [key: string]: any } | null {
    const pwd = form.get('password');
    const pwdConfirm = form.get('passwordConfirm');
    const fieldsFilled = pwd?.dirty && pwdConfirm?.dirty;
    const fieldsValid = pwd?.valid && pwdConfirm?.valid;
    return fieldsFilled && fieldsValid && pwd?.value !== pwdConfirm?.value
        ? {passwordMismatch: {valid: false, errorMsg: `Passwords do not match`}}
        : null;
}

/**
 * Simple password validation function. Checks are done step-by-step in order to return a clear error message.
 */
export function validatePassword(password: string): { valid: boolean, missingRule: string } {
    if (!isString(password)) {
        return {valid: false, missingRule: 'Invalid password'};
    }

    if (password.length < 8) {
        return {valid: false, missingRule: 'Password must have at least 8 chars'};
    }

    if (password.length > 64) {
        return {valid: false, missingRule: 'Password must have maximum 64 chars'};
    }

    if (!stringHasAtLeastOneNumber(password)) {
        return {valid: false, missingRule: 'Password must have at least one number'};
    }

    if (!stringHasAtLeastOneUpperChar(password)) {
        return {valid: false, missingRule: 'Password must have at least one upper case char'};
    }

    if (!stringHasAtLeastOneLowerChar(password)) {
        return {valid: false, missingRule: 'Password must have at least one lower case char'};
    }

    return {valid: true, missingRule: ''};
}

/**
 * Check if argument is a `string`, return boolean.
 */
// tslint:disable-next-line:no-any
export function isString(str: any): boolean {
    return typeof str === 'string';
}

/**
 * Check if a string contains at least one digit/number. Used mainly in {@link validatePassword} function.
 */
export function stringHasAtLeastOneNumber(str: string): boolean {
    return /\d/.test(str);
}

/**
 * Check if argument (string) has at least one uppercase char.
 * Compare the input string with itself after a `toLowerCase`: if they are identical, then no transformation were made and all chars were
 * already lowercase. Likewise if they differ, then there were at least one char that was uppercase.
 */
export function stringHasAtLeastOneUpperChar(str: string): boolean {
    return str !== (str.toLowerCase());
}

/**
 * Check if argument (string) has at least one lowercase char.
 * Works like {@link stringHasAtLeastOneUpperChar}
 */
export function stringHasAtLeastOneLowerChar(str: string): boolean {
    return str !== (str.toUpperCase());
}
