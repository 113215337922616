import { Action, createReducer, on } from '@ngrx/store';
import * as EditorActions from './record-editor.actions';
import { FieldType, FormSpecific, Media, Record } from '3map-models';
import {
  FieldCheck,
  FieldDate,
  FieldInt,
  FieldList,
  FieldNumber,
  FieldRange,
  FieldText,
} from '3map-models/dist/lib/model';
import { removeElementFromArray } from '@trim-web-apps/core';
import { EditorMode } from '../models/EditorMode';
import { EditorTab } from '../models/EditorTab';
import * as ProjectActions from '../../project/+state/project.actions';
import { AuthActions } from '../../../auth/state/auth';

export const FEATURE_KEY = 'record-editor';

export interface State {
  mode: EditorMode | null;
  record: Record | null;
  request: { pending: boolean; error: string | null };
  activeTab: EditorTab | null;
  uploadMediaList: Media[];
}

export const initialState: State = {
  mode: null,
  record: null,
  request: { pending: false, error: null },
  activeTab: null,
  uploadMediaList: [],
};

const editorReducer = createReducer(
  initialState,
  on(
    ProjectActions.closeProject,
    EditorActions.disableRecordEditor,
    EditorActions.uploadRecordSuccess,
    AuthActions.authLogout,
    (): State => initialState
  ),
  // TODO on change project
  // on(fromAppProject.EXIT, (): State => initialState),
  on(
    EditorActions.setError,
    (state, { error }): State => ({
      ...state,
      request: { pending: false, error },
    })
  ),
  on(
    EditorActions.enableRecordEditor,
    (state, action): State => ({
      ...initialState,
      mode: action.mode,
      record: action.record,
      activeTab: action.activeTab,
    })
  ),
  on(
    EditorActions.setActiveTab,
    (state, { activeTab }): State => ({ ...state, activeTab })
  ),
  on(
    EditorActions.editQuestion,
    (state, { fieldType, field, specific }): State => {
      const targetRecord = state.record;
      if (!targetRecord) return state;

      const record =
        field === null
          ? removeQuestionRecord(targetRecord, fieldType.id, specific)
          : upsertQuestionInRecord(targetRecord, fieldType, specific, field);
      return { ...state, record };
    }
  ),
  on(EditorActions.editRecordMetadata, (state, { metaLocation }): State => {
    if (!state.record) return state;
    return {
      ...state,
      record: { ...state.record, ...metaLocation },
    };
  }),
  on(EditorActions.addMedia, (state, { media }): State => {
    if (!state.record) return state;
    return {
      ...state,
      record: {
        ...state.record,
        mediaList: [...state.record.mediaList, media],
      },
    };
  }),
  on(EditorActions.removeMedia, (state, { media }): State => {
    if (!state.record) return state;
    const mediaList = removeElementFromArray<Media>(
      state.record.mediaList,
      media,
      'fileName'
    );
    const uploadMediaList = removeElementFromArray<Media>(
      state.uploadMediaList,
      media,
      'fileName'
    );
    return {
      ...state,
      record: { ...state.record, mediaList },
      uploadMediaList,
    };
  }),
  on(
    EditorActions.uploadRecord,
    (state): State => ({ ...state, request: { pending: true, error: null } })
  ),
  on(
    EditorActions.uploadRecordError,
    (state, { error }): State => ({
      ...state,
      request: { pending: false, error },
    })
  ),
  on(EditorActions.addUploadMediaList, (state, { mediaList }): State => {
    const uploadMediaList = [...state.uploadMediaList, ...mediaList];
    return { ...state, uploadMediaList };
  })
);

export function reducer(state: State | undefined, action: Action): State {
  return editorReducer(state, action);
}

function removeQuestionRecord(
  record: Record,
  fieldTypeId: string,
  specific: FormSpecific
): Record {
  const data: { [k: string]: any } = { ...(record.data as any) };
  const idsToDelete = getIdsToRemove(fieldTypeId, specific);
  for (const id of idsToDelete) delete data[id];
  return { ...record, data };
}

function getIdsToRemove(fieldTypeId: string, specific: FormSpecific): string[] {
  if (!specific.listLogic) return [fieldTypeId];
  return specific.listLogic
    .filter((l) => l.q1 === fieldTypeId)
    .map((l) => getIdsToRemove(l.q2, specific))
    .reduce((acc, item) => [...acc, ...item], [fieldTypeId]);
}

function upsertQuestionInRecord(
  record: Record,
  fieldType: FieldType,
  specific: FormSpecific,
  field:
    | FieldNumber
    | FieldText
    | FieldList
    | FieldRange
    | FieldCheck
    | FieldInt
    | FieldDate
): Record {
  const newRecord = removeQuestionRecord(record, fieldType.id, specific);
  const data: { [k: string]: any } = { ...(newRecord.data as any) };
  data[fieldType.id] = field;
  return { ...newRecord, data };
}

// function upsertQuestion2(
//   record: Record,
//   fieldType: FieldType,
//   field:
//     | FieldNumber
//     | FieldText
//     | FieldList
//     | FieldRange
//     | FieldCheck
//     | FieldInt
//     | FieldDate
//     | null,
//   specific: FormSpecific
// ): Record {
//   const recordData: { [k: string]: any } = { ...(record.data as any) };
//   if (field === null) delete recordData[fieldType.id];
//   else recordData[fieldType.id] = field;
//
//   // const temp = { ...recordData };
//   // console.log(temp);
//
//   for (const fieldTypeId of Object.keys(recordData)) {
//     const logic = specific.listLogic?.find((logic) => logic.q2 === fieldTypeId);
//     if (logic) {
//       const controlQuestion = specific.questions.find((q) => q.id === logic.q1);
//       const controlAnswer = recordData[logic.q1];
//       if (!controlAnswer) delete recordData[fieldTypeId];
//       if (controlQuestion && controlAnswer) {
//         const answerSubset = logic.logic.find(
//           (l) => l.r1 === controlAnswer.text
//         );
//
//         if (answerSubset) {
//           if (recordData[fieldTypeId].type === 'CHECK') {
//             const checked = recordData[fieldTypeId].checked as string[];
//             const included = checked
//               .map((check) => answerSubset.r2.includes(check))
//               .reduce((acc, item) => acc && item);
//             if (!included) delete recordData[fieldTypeId];
//           } else if (recordData[fieldTypeId].type === 'LIST') {
//             if (!answerSubset.r2.includes(recordData[fieldTypeId].text))
//               delete recordData[fieldTypeId];
//           }
//         }
//       }
//     }
//   }
//   return { ...record, data: recordData };
// }

// todo remove recursively all controlled questions (see region/prov/city example)
// function removeQuestionFromRecord2(
//   record: Record,
//   fieldTypeId: string,
//   specific: FormSpecific
// ): Record {
//   const data: { [k: string]: any } = { ...(record.data as any) };
//   delete data[fieldTypeId];
//   specific.listLogic
//     ?.filter((l) => l.q1 === fieldTypeId)
//     .map((l) => l.q2)
//     .forEach((id) => delete data[id]);
//   return { ...record, data };
// }

// function removeQuestionRecord3(
//   record: Record,
//   fieldTypeId: string,
//   specific: FormSpecific
// ): Record {
//   const data: { [k: string]: any } = { ...(record.data as any) };
//
//   // if (data[fieldTypeId] === undefined) return { ...record, data };
//
//   const q2List = specific.listLogic
//     ?.filter((l) => l.q1 === fieldTypeId)
//     .map((l) => l.q2);
//
//   console.log(q2List, fieldTypeId);
//
//   delete data[fieldTypeId];
//
//   if (!q2List || q2List.length === 0) return { ...record, data };
//
//   return q2List
//     .map((id) => removeQuestionRecord({ ...record, data }, id, specific))
//     .reduce((acc, item) => ({ ...acc, ...item }));
// }
