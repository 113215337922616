import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogRef } from '@angular/cdk/dialog';

export type DialogConfirmData = {
  title?: string;
  message?: string;
  acceptLabel?: string;
  rejectLabel?: string;
};

@Component({
  selector: 'core-dialog-confirm',
  template: `
    <div class="dialog-confirm-container">
      <div class="dialog-title">{{ data?.title }}</div>
      <div class="message">
        {{ data?.message }}
      </div>
      <div class="actions">
        <button class="reject" *ngIf="data?.rejectLabel" (click)="reject()">
          {{ data?.rejectLabel }}
        </button>
        <button
          class="accept btn-primary"
          *ngIf="data?.acceptLabel"
          (click)="accept()"
        >
          {{ data?.acceptLabel }}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-confirm-container {
        min-width: 250px;
        max-width: 400px;
      }

      .dialog-title {
        font-size: 1.2em;
      }

      .dialog-title,
      .message {
        padding: 10px 20px;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
      }
    `,
  ],
})
export class DialogConfirmComponent {
  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(MAT_DIALOG_DATA)
    public data: DialogConfirmData | undefined
  ) {}

  accept(): void {
    this.dialogRef.close(true);
  }

  reject(): void {
    this.dialogRef.close(false);
  }
}
