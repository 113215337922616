import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputMailComponent } from './components/input-mail/input-mail.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { InputUsernameComponent } from './components/input-username/input-username.component';
import { AuthComponent } from './auth.component';
import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { LoginComponent } from './containers/login/login.component';
import { LogoutComponent } from './containers/logout/logout.component';
import { PasswordRecoveryComponent } from './containers/password-recovery/password-recovery.component';
import { SignupComponent } from './containers/signup/signup.component';
import { VerifyUserComponent } from './containers/verify-user/verify-user.component';
import { AuthGuard } from './utils/auth-guard.service';
import { EffectsModule } from '@ngrx/effects';
import { ProjectSelectorComponent } from './containers/project-selector/project-selector.component';
import { AuthAdminGuard } from './utils/auth-admin-guard.service';
import { CreateProjectNameComponent } from './containers/project-selector/create-project-name/create-project-name.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { ProjectItemComponent } from './containers/project-selector/project-item/project-item.component';
import { StoreModule } from '@ngrx/store';
import * as fromAuth from './state/auth/auth.reducer';
import * as fromCol from './state/collaboration/collaboration.reducer';
import { AuthEffects } from './state/auth/auth.effects';
import { CollaborationEffects } from './state/collaboration/collaboration.effects';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    InputPasswordComponent,
    InputMailComponent,
    InputUsernameComponent,
    VerifyUserComponent,
    PasswordRecoveryComponent,
    ChangePasswordComponent,
    AuthComponent,
    LogoutComponent,
    ProjectSelectorComponent,
    CreateProjectNameComponent,
    ProjectItemComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    StoreModule.forFeature(fromAuth.FEATURE_KEY, fromAuth.reducer),
    StoreModule.forFeature(fromCol.FEATURE_KEY, fromCol.reducer),
    EffectsModule.forFeature([AuthEffects, CollaborationEffects]),
    MatTabsModule,
    Map3UiModule,
  ],
  providers: [
    AuthGuard,
    AuthAdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
