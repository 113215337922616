import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-range',
  template: ` <p>field-range works!</p> `,
  styles: [],
})
export class FieldRangeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
