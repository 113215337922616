import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  CollaborationActions,
  CollaborationSelectors,
} from '../../state/collaboration';
import { Project } from '3map-models';
import { Observable } from 'rxjs';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { AuthActions, AuthSelectors } from '../../state/auth';
import { Collaboration } from '../../state/collaboration/collaboration.reducer';

@Component({
  selector: 'app-project-selector',
  template: `
    <div class="app-project-selector-container">
      <div class="header">
        <span class="back-login" (click)="logout()">
          <fa-icon [icon]="iconLogout"></fa-icon>
          Logout
        </span>
        <span class="title">Select Project</span>
      </div>
      <div
        class="project-selector-wrapper"
        *ngIf="username$ | async as username; else notLogged"
      >
        <mat-tab-group>
          <mat-tab label="3Map project">
            <div class="project">
              <app-auth-project-item
                *ngFor="let col of collaborations$ | async"
                [collaboration]="col"
                (click)="projectLogin(col)"
              ></app-auth-project-item>
            </div>
          </mat-tab>

          <mat-tab label="3Map Admin">
            <div class="admin">
              <app-create-project-name
                [collaborationList]="(collaborations$ | async) ?? []"
                (projectNameCreated)="createProject($event, username)"
              >
              </app-create-project-name>

              <div *ngIf="fetchPending$ | async">Loading Projects list</div>
              <div *ngIf="fetchError$ | async">Project list not available</div>
              <div *ngIf="createPending$ | async">Creating new Project</div>
              <div *ngIf="createError$ | async">Error creating Project</div>

              <div class="collaboration-list">
                <app-auth-project-item
                  *ngFor="let col of adminCollaborations$ | async"
                  [collaboration]="col"
                  (click)="adminLogin(col)"
                ></app-auth-project-item>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <ng-template #notLogged>
        <p>Mmmh something went wrong...</p>
        <p>Please sign in again.</p>
        <ui-btn (click)="logout()" type="border" label="Back to login" />
      </ng-template>
    </div>
  `,
  styles: [
    `
      .app-project-selector-container {
        height: 100%;
        overflow: hidden;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }

      .title {
        margin-bottom: 30px;
        text-align: right;
        font-size: 1.5em;
      }

      .project-selector-wrapper {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        justify-content: space-between;
      }

      .project,
      .admin {
        height: 380px;
      }

      .back-login {
        color: var(--primary-color);
        cursor: pointer;
      }
    `,
  ],
})
export class ProjectSelectorComponent {
  collaborations$: Observable<Collaboration[]>;
  adminCollaborations$: Observable<Collaboration[]>;
  fetchPending$: Observable<boolean>;
  fetchError$: Observable<boolean>;
  createPending$: Observable<boolean>;
  createError$: Observable<boolean>;
  username$: Observable<string | null>;
  iconLogout = faChevronLeft;

  constructor(private store: Store) {
    this.store.dispatch(CollaborationActions.fetchCollaborations());
    this.collaborations$ = store.pipe(
      select(CollaborationSelectors.selectCollaborations())
    );
    this.adminCollaborations$ = store.pipe(
      select(CollaborationSelectors.selectAdminCollaborations())
    );

    this.username$ = this.store.pipe(
      select(AuthSelectors.selectAuthUsername())
    );

    this.fetchPending$ = this.store.pipe(
      select(CollaborationSelectors.selectFetchPending())
    );
    this.fetchError$ = this.store.pipe(
      select(CollaborationSelectors.selectFetchError())
    );
    this.createPending$ = this.store.pipe(
      select(CollaborationSelectors.selectCreatePending())
    );
    this.createError$ = this.store.pipe(
      select(CollaborationSelectors.selectCreateError())
    );
  }

  projectLogin(collaboration: Collaboration): void {
    this.store.dispatch(AuthActions.doProjectLogin({ collaboration }));
  }

  adminLogin(collaboration: Collaboration): void {
    this.store.dispatch(AuthActions.doAdminProjectLogin({ collaboration }));
  }

  createProject(projectName: string, username: string): void {
    const project = createEmptyProject(projectName, username);
    this.store.dispatch(CollaborationActions.createNewProject({ project }));
  }

  logout(): void {
    this.store.dispatch(AuthActions.authLogout());
  }
}

function createEmptyProject(projectName: string, username: string): Project {
  return {
    name: projectName,
    formList: [],
    userList: [
      {
        username,
        role: 'ADMIN',
        preferences: {},
        formList: [],
      },
    ],
    webHookList: [],
    resourceList: [],
  };
}
