import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Form } from '3map-models';

@Component({
  selector: 'app-forms-nav-item',
  template: `
    <app-sidenav-item
      label="Forms Collections"
      [icon]="iconForms"
      [sidenavOpen]="sidenavOpen"
      [expandable]="true"
      [expanded]="true"
    >
      <div *ngIf="formDatasetList.length === 0" class="no-forms-label">
        No Forms Collections found
      </div>
      <div class="form-dataset-list">
        <ng-container *ngFor="let form of formDatasetList">
          <div class="nav-item-checkbox">
            <ui-checkbox
              (checkboxClick)="toggleForm.emit(form)"
              [checked]="form.enabled"
              [label]="form.form.name"
            ></ui-checkbox>
          </div>
        </ng-container>
      </div>
    </app-sidenav-item>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsNavItemComponent {
  @Input() formDatasetList: { form: Form; enabled: boolean }[] = [];
  @Input() sidenavOpen?: boolean;
  @Output() toggleForm = new EventEmitter<{ form: Form; enabled: boolean }>();
  iconForms = faLayerGroup;
}
