import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { WebHook } from '3map-models';
import { take } from 'rxjs/operators';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  NameUrlDialogComponent,
  NameUrlDialogData,
} from '../../../shared/components/name-url-dialog/name-url-dialog.component';
import { Store } from '@ngrx/store';
import { selectWebhooks } from '../../+state/webhook.selectors';
import { removeWebHook, upsertWebHook } from '../../+state/webhook.actions';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-webhook-list',
  template: `
    <div class="header">
      <app-section-label sectionLabel="Web Hooks">
        <ui-btn
          label="Add WebHook"
          [icon]="iconAdd"
          (click)="onAdd()"
          type="border"
        />
      </app-section-label>
    </div>
    <div class="webhook-list">
      <app-webhook-list-item
        *ngFor="let wh of webHookList$ | async"
        [webHook]="wh"
        (remove)="onRemove($event)"
      />
    </div>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
      }

      .webhook-list {
        margin: var(--spacing-2) var(--spacing-4);
        overflow: auto;
      }
    `,
  ],
})
export class WebhookListComponent {
  webHookList$: Observable<WebHook[]>;
  iconAdd = faPlus;

  constructor(private store: Store, private dialog: Dialog) {
    this.webHookList$ = this.store.select(selectWebhooks());
  }

  onRemove(webHook: WebHook): void {
    this.store.dispatch(removeWebHook({ webHook }));
  }

  onAdd(): void {
    const data: NameUrlDialogData = { title: 'Webhook' };
    this.dialog
      .open<WebHook | undefined>(NameUrlDialogComponent, { data })
      .closed.pipe(take(1))
      .subscribe((res) => {
        if (res) this.store.dispatch(upsertWebHook({ webHook: res }));
      });
  }
}
