import { Inject, Injectable, Optional } from '@angular/core';
import { Store } from '@trim-web-apps/store';
import { isValidLngLat, isValidZoom } from './map-utils/map.utils';

interface MapState {
  center: { lng: number, lat: number };
  zoom: number;
}

const initialMapState = {
  zoom: 2.5,
  center: { lng: 0, lat: 25 }
};

@Injectable({
  providedIn: 'root'
})
export class MapStore extends Store<MapState> {
  constructor(@Optional() @Inject('LSK_MAP') private LSK_MAP?: string) {
    super(initialMapState, LSK_MAP || null);
  }

  getValues(): { center: { lng: number, lat: number }, zoom: number } {
    const { center, zoom } = initialMapState;
    const fromStorage = super.getValue();

    return isValidLngLat(fromStorage.center) && isValidZoom(fromStorage.zoom)
      ? fromStorage
      : { center, zoom };
  }
}
