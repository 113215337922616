import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMapResource from './map-resource.reducer';

const selectState = createFeatureSelector<fromMapResource.State>(
  fromMapResource.FEATURE_KEY
);
const { selectAll, selectEntities } = fromMapResource.adapter.getSelectors();

export const selectMapResourceList = () =>
  createSelector(selectState, (state) => selectAll(state));

export const selectMapResourceDict = () =>
  createSelector(selectState, (state) => selectEntities(state));

export const selectMapResourceEnabled = () =>
  createSelector(selectMapResourceList(), (list) =>
    list.filter((r) => r.status === 'ENABLED')
  );

export const selectMapResourceById = (resourceId: string) =>
  createSelector(selectState, (state) => state.entities[resourceId] || null);
