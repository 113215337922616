import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthApiService } from '../../services/auth-api.service';

type ActivationState = 'PENDING' | 'SUCCESS' | 'ERROR';

@Component({
  selector: 'app-verify-user',
  template: `
    <ng-container *ngIf="activationState$ | async as activation">
      <div class="title">Account validation</div>

      <div *ngIf="activation === 'PENDING'" class="pending">
        <p>Activating account...</p>
      </div>

      <div *ngIf="activation === 'SUCCESS'" class="activated">
        <p>Account activated!</p>
        <p>You can now login into <b>3Map mobile</b> app</p>
      </div>

      <div *ngIf="activation === 'ERROR'" class="error">
        <p>Invalid or expired token</p>
        <p>Please make sure to copy/paste the full link you received.</p>
      </div>

      <div class="fill-remaining-space"></div>

      <ui-btn [fullWidth]="true" [routerLink]="['/auth']" label="Go to login" />
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .title {
        margin-bottom: 30px;
        text-align: right;
        font-size: 1.5em;
      }

      .pending,
      .activated,
      .error {
        text-align: center;
      }

      .activated {
        border: 1px solid green;
        padding: 10px;
        margin-bottom: 30px;
        text-align: center;
        color: green;
        border-radius: 3px;
        font-size: 1em;
      }
    `,
  ],
})
export class VerifyUserComponent implements OnInit {
  private activationStateSub: BehaviorSubject<ActivationState>;
  activationState$: Observable<ActivationState>;

  constructor(
    private router: Router,
    private authApi: AuthApiService,
    private route: ActivatedRoute
  ) {
    this.activationStateSub = new BehaviorSubject<ActivationState>('PENDING');
    this.activationState$ = this.activationStateSub.asObservable();
  }

  ngOnInit(): void {
    // TODO check verifyUser
    const token = this.route.snapshot.paramMap.get('token');
    // maybe useless, route should be activated only if `/:token` param exists
    if (!token) {
      this.router.navigate(['/auth']);
      return;
    }

    this.authApi
      .verifyUser(token)
      .pipe(
        take(1),
        catchError(() => of(false))
      )
      .subscribe((verified) => {
        verified
          ? this.activationStateSub.next('SUCCESS')
          : this.activationStateSub.next('ERROR');
        // if (verified) {
        //   this.router.navigate(['/auth'], {
        //     queryParams: { activated: true },
        //   });
        // }
      });
  }
}
