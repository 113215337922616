import { WIND_KTS } from '../../weather-layers/weather.layers';
import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createEcmwf(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 48, 3600 * 3, '+00:00');
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[47],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 3600 * 3 };

  const layers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...WIND_KTS,
      id: 'wind_925',
      label: 'Wind (925 hPa)',
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'ECMWF',
    label: 'ECMWF',
    category: 'FORECAST',
    layers: layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].timestepList[0].timesteps[0],
    selectedLayerId: layers[0].id,
    dateRange: defaultDateRng,
    bbox: [
      [-180, 90],
      [180, 90],
      [180, -90],
      [-180, -90],
      [-180, 90],
    ],
    info: {
      resolution: '0.4°',
      coverage: 'Global',
      url: 'https://www.ecmwf.int/en/forecasts/datasets/open-data',
      credits: 'ECMWF',
    },
  };
}
