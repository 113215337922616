import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { WeatherCanvasComponent } from '../../../components/weather-canvas/weather-canvas.component';
import { WeatherLayer, WeatherModel } from '@trim-web-apps/weather-models';
import { Store } from '@ngrx/store';
import { WeatherActions, WeatherSelectors } from '../../../+state';

@Component({
  selector: 'weather-core-box',
  template: `
    <weather-core-canvas
      *ngIf="weatherLayer$ | async as layer"
      [weatherModelId]="weatherModel.id"
      [weatherLayer]="layer"
      [initime]="weatherModel.initime"
      [timestep]="weatherModel.timestep"
      [weatherModelVisible]="weatherModel.visible"
      [interpolate]="weatherModel.interpolate"
      [windStyle]="weatherModel.windStyle"
      [weatherModelBbox]="weatherModel.bbox"
      (tiffLoading)="onTiffLoadingChange($event)"
      (tiffParsed)="setPopupData()"
      (tiffFetchError)="onTiffFetchError($event)"
    >
    </weather-core-canvas>
  `,
  styles: [],
})
export class WeatherBoxComponent implements OnInit, OnChanges {
  @Input() weatherModel!: WeatherModel;
  @Input() mapStyleId!: string;
  @Input() selectedLngLat!: { lng: number; lat: number } | null;
  @ViewChild(WeatherCanvasComponent) canvasComp!: WeatherCanvasComponent;

  weatherLayer$?: Observable<WeatherLayer | null>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.weatherLayer$ = this.store.select(
      WeatherSelectors.selectLayer(this.weatherModel.id)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedLngLat']) {
      this.setPopupData();
    }

    if (changes['mapStyleId'] && !changes['mapStyleId']?.firstChange) {
      this.canvasComp.rebuild();
    }
  }

  setPopupData(): void {
    if (this.selectedLngLat !== null && this.canvasComp) {
      const popupData = this.canvasComp.getPixelValue(this.selectedLngLat);
      this.store.dispatch(WeatherActions.addPopupData({ popupData }));
    }
  }

  onTiffLoadingChange(loading: boolean): void {
    const modelId = this.weatherModel.id;
    const action = loading
      ? WeatherActions.tiffLoading({ modelId })
      : WeatherActions.tiffLoadingSuccess({ modelId });
    this.store.dispatch(action);
  }

  onTiffFetchError(error: boolean): void {
    const modelId = this.weatherModel.id;
    this.store.dispatch(WeatherActions.tiffLoadingError({ modelId }));
  }
}
