import { Component, Input } from '@angular/core';
import { FieldType } from '3map-models';
import {
  faAsterisk,
  faInfoCircle,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-field-name[fieldType]',
  template: `
    <div class="field-name-wrapper" *ngIf="fieldType">
      <div class="name subtitle text-ellipsis">{{ fieldType.name }}</div>
      <div class="icons">
        <fa-icon
          *ngIf="fieldType.required"
          [icon]="iconRequired"
          class="required"
        ></fa-icon>

        <fa-icon *ngIf="fieldType.immutable" [icon]="iconImmutable"></fa-icon>

        <!-- TODO show question info/hint -->
        <fa-icon
          *ngIf="fieldType.info && fieldType.info.length > 0"
          [icon]="iconInfo"
          class="info"
        ></fa-icon>
      </div>
    </div>
  `,
  styles: [
    `
      .field-name-wrapper {
        display: flex;
      }

      .name {
        flex: 1 1 auto;
      }

      .icons {
        display: flex;
        align-items: center;
      }

      .icons fa-icon {
        margin: 0 5px;
        cursor: help;
      }

      .required {
        color: var(--error-color);
      }

      .info {
        color: var(--primary-color);
      }
    `,
  ],
})
export class FieldNameComponent {
  @Input() fieldType?: FieldType;
  iconInfo = faInfoCircle;
  iconImmutable = faLock;
  iconRequired = faAsterisk;
}
