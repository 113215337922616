import {
  Project,
  ReqLinkChangePsw,
  ReqSignUp,
  ResAdminToken,
  resAdminTokenDecoder,
  ResCollabs,
  resCollabsDecoder,
  ResProjectToken,
  resProjectTokenDecoder,
} from '3map-models';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private http: HttpClient) {}

  /**
   * Do admin login and run decoder on response.
   * Return {@link ResAdminToken} is request is successful
   * @throws HttpErrorResponse | DecoderException
   */
  public adminLogin(
    username: string,
    password: string
  ): Observable<ResAdminToken> {
    return this.http
      .post(environment.API_URL + '/auth/adminLogin', { username, password })
      .pipe(
        map((response) => resAdminTokenDecoder().runWithException(response))
      );
  }

  /**
   * Do admin loginold and run decoder on response.
   * Return {@link ResAdminToken} is request is successful
   * @throws HttpErrorResponse | DecoderException
   */
  public projectLogin(projectName: string): Observable<ResProjectToken> {
    return this.http
      .get(environment.API_URL + '/auth/projectLogin/' + projectName)
      .pipe(
        map((response) => resProjectTokenDecoder().runWithException(response))
      );
  }

  /**
   * Create new user and return `true` is success.
   * * @throws HttpErrorResponse
   */
  public signUp(body: ReqSignUp): Observable<boolean> {
    return this.http
      .post(environment.API_URL + '/auth/signUp', body)
      .pipe(map(() => true));
  }

  /**
   * Verify user through its token and return `true` is success.
   * * @throws HttpErrorResponse
   */
  public verifyUser(token: string): Observable<boolean> {
    return this.http
      .get(environment.API_URL + '/auth/verifyUser/' + token)
      .pipe(map(() => true));
  }

  /**
   * Request a link to change password, return `true` is success.
   * * @throws HttpErrorResponse
   */
  public sendChangePasswordLink(body: ReqLinkChangePsw): Observable<boolean> {
    return this.http
      .post(environment.API_URL + '/auth/requestLinkChangePsw', body)
      .pipe(map(() => true));
  }

  /**
   * Change user password, require a valid token. Return `true` is success.
   * * @throws HttpErrorResponse
   */
  public changePassword(
    username: string,
    password: string,
    token: string
  ): Observable<boolean> {
    return this.http
      .post(environment.API_URL + '/auth/changePsw/' + token, {
        username,
        password,
      })
      .pipe(
        map(() => true),
        catchError((err: HttpErrorResponse) => {
          return err.status === 200 ? of(true) : of(false);
        })
      );
  }

  public getCollaborationList(): Observable<ResCollabs> {
    return this.http
      .get(environment.API_URL + '/admin/collabs')
      .pipe(map((res) => resCollabsDecoder().runWithException(res)));
  }

  public createProject(project: Project): Observable<boolean> {
    return this.http
      .post(`${environment.API_URL}/admin/addProject`, project)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }
}
