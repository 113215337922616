import { Routes } from '@angular/router';
import { authRoutes } from './auth/auth.routes';
import { adminRoutes } from './admin/admin.routes';
import { AuthGuard } from './auth/utils/auth-guard.service';
import { ProjectComponent } from './project/app-project.component';

export const routes: Routes = [
  { path: 'auth', children: authRoutes },
  { path: 'project', component: ProjectComponent, canActivate: [AuthGuard] },
  { path: 'admin', children: adminRoutes, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'project', pathMatch: 'full' },
];
