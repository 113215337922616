import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FieldType, FormSpecific } from '3map-models';
import { FormSpecificLogic } from '@trim-web-apps/project-core';
import { DropdownItem } from '@trim-web-apps/map3-ui';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-add-logic-item',
  template: `
    <div class="add-logic-item-wrapper">
      <div class="title">Add new Logic</div>
      <div class="quest">
        <ui-dropdown
          [itemList]="controllerQuestionList"
          [itemActive]="controllerQuestionItem"
          (itemSelected)="onControllerQuestionSelect($event.source)"
        />
      </div>

      <div class="quest">
        <ui-dropdown
          [itemList]="controlledQuestionList"
          [itemActive]="controlledQuestionItem"
          (itemSelected)="onControlledQuestionSelect($event.source)"
        />
      </div>

      <div class="error" *ngIf="notSameGroupError">
        Both questions must be in the same group
      </div>
    </div>
  `,
  styles: [
    `
      .add-logic-item-wrapper {
        padding: 20px;
        background-color: var(--bg-color);
        border-radius: var(--radius-1);
      }

      .quest {
        width: 300px;
        margin: 20px 0;
      }

      .error {
        font-size: 0.9em;
        color: var(--error-color);
        text-align: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLogicItemComponent {
  notSameGroupError: boolean | undefined;
  controllerQuestionList: DropdownItem<FieldType>[] = [];
  controllerQuestionItem?: DropdownItem<FieldType>;
  controlledQuestionList: DropdownItem<FieldType>[] = [];
  controlledQuestionItem?: DropdownItem<FieldType>;

  constructor(
    public dialogRef: DialogRef<FormSpecificLogic | undefined>,
    @Inject(DIALOG_DATA)
    public data: { specific: FormSpecific }
  ) {
    this.setControllerQuestionList();
    this.setControlledQuestions();
  }

  setControllerQuestionList(): void {
    const controlledQuestId = this.controlledQuestionItem?.payload.id;
    const notControlledQuest = (ft: FieldType) =>
      controlledQuestId ? controlledQuestId !== ft.id : true;
    const isList = (ft: FieldType) => ft.type === 'LIST';

    this.controllerQuestionList = this.data.specific.questions
      .filter(isList)
      .filter(notControlledQuest)
      .map((ft) => ({ label: ft.name, payload: ft }));
  }

  setControlledQuestions(): void {
    const controllerQuestId = this.controllerQuestionItem?.payload.id;
    const notControllerQuest = (ft: FieldType) =>
      controllerQuestId ? controllerQuestId !== ft.id : true;

    this.controlledQuestionList = this.data.specific.questions
      .filter(notControllerQuest)
      .map((ft) => ({ label: ft.name, payload: ft }));
  }

  onControllerQuestionSelect(item: DropdownItem<FieldType> | null): void {
    if (!item) return;
    this.controllerQuestionItem = item;
    this.onQuestionSelected2();
  }

  onControlledQuestionSelect(item: DropdownItem<FieldType> | null): void {
    if (!item) return;
    this.controlledQuestionItem = item;
    this.onQuestionSelected2();
  }

  onQuestionSelected2(): void {
    const controllerQuestion = this.controllerQuestionItem?.payload;
    const controlledQuestion = this.controlledQuestionItem?.payload;
    this.notSameGroupError = false;

    if (!controllerQuestion || !controlledQuestion) return;

    // check if questions are in the same group
    const groups = this.data.specific.questionGroups;
    const controllerQuestionGroup = groups?.find((group) =>
      group.questions.includes(controllerQuestion.id)
    );
    if (!controllerQuestionGroup) return;
    const isControlledQuestionInGroup =
      controllerQuestionGroup.questions.includes(controlledQuestion.id);

    if (!isControlledQuestionInGroup) {
      this.notSameGroupError = true;
      return;
    }

    const logic: FormSpecificLogic = {
      q1: controllerQuestion,
      q2: controlledQuestion,
      logic: [],
    };
    this.dialogRef.close(logic);
  }
}
