import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Record } from '3map-models';

// https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude#:~:text=Then%20the%20nominal%20precision%20of,precision%20of%20about%208.5%20km.

@Component({
  selector: 'app-feature-metadata',
  template: `
    <div class="feature-metadata-wrapper" *ngIf="record">
      <div class="row">
        <div class="label">LATITUDE:</div>
        <div class="value lat">{{ record.latitude | number: '1.0-7' }}</div>
      </div>
      <div class="row">
        <div class="label">LONGITUDE:</div>
        <div class="value lng">{{ record.longitude | number: '1.0-7' }}</div>
      </div>
      <div class="row">
        <div class="label">ALTITUDE:</div>
        <div class="value alt">{{ record.altitude | number: '1.0-0' }}</div>
      </div>
      <div class="row">
        <div class="label">ZONE:</div>
        <div class="value zone">{{ record.zone }}</div>
      </div>
    </div>
  `,
  styles: [
    `
      .row {
        display: flex;
        align-items: baseline;
        user-select: text;
      }

      .label {
        font-size: 0.7em;
        font-weight: bold;
        margin-right: 5px;
      }

      .value {
        font-size: 0.8em;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureMetadataComponent {
  @Input() record: Record | undefined;
}
