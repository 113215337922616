import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditorTab } from '../../models/EditorTab';

@Component({
  selector: 'app-record-editor-bottom-actions',
  template: `
    <ng-container *ngIf="activeTab && request">
      <ui-btn
        *ngIf="activeTab.type !== 'MEDIA'"
        (click)="next.emit()"
        label="Next"
      ></ui-btn>

      <ng-container *ngIf="activeTab.type === 'MEDIA'">
        <div class="invalid-record" *ngIf="!recordValid">
          Record is not valid. Please review questions (e.g. required fields)
        </div>

        <ng-container *ngIf="recordValid">
          <ui-btn
            *ngIf="request.pending === false && request.error === null"
            (click)="confirm.emit()"
            label="Confirm"
          ></ui-btn>

          <ui-btn *ngIf="request.pending" label="Uploading..."></ui-btn>

          <ui-btn
            *ngIf="request.pending === false && request.error !== null"
            (click)="confirm.emit()"
            label="Retry"
          ></ui-btn>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      .invalid-record {
        color: var(--error-color);
      }
    `,
  ],
})
export class RecordEditorBottomActionsComponent {
  @Input() activeTab: EditorTab | undefined;
  @Input() request:
    | { pending: boolean; error: string | null }
    | null
    | undefined;
  @Input() recordValid: boolean | undefined;
  @Output() next: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();
}
