import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop';

@Component({
  selector: 'weather-core-dialog-change-mode',
  template: `
    <div *ngIf="suggestedView === 'mobile'" class="mobile-alert">
      <div class="message">
        <p> It seems you're using a mobile device! </p>
        <p> Would you like to switch to mobile version? </p>
      </div>
      <div class="actions" (click)="close()">
        <div [routerLink]="'/mobile'" class="btn btn-accept"> Go to mobile version</div>
        <div class="btn btn-reject"> Close</div>
      </div>
    </div>

    <div *ngIf="suggestedView === 'desktop'" class="desktop-alert">
      <div class="message">
        You're using mobile version, would you like to switch to desktop version?
      </div>
      <div class="actions" (click)="close()">
        <div [routerLink]="'/desktop'" class="btn btn-accept"> Go to desktop version</div>
        <div class="btn btn-reject"> Close</div>
      </div>
    </div>
  `,
  styles: [`
      .message,
      .actions {
          padding: 0 20px;
      }

      .actions {
          text-align: center;
      }

      .btn {
          margin-top: 20px;
          padding: 10px;
          cursor: pointer;
          border-radius: 3px;
      }


      .btn-accept {
          color: #fff;
          background-color: #0078D4;
          font-weight: 400;
      }

      .btn-reject {
          color: #9E9E9E;
          font-weight: 400;
      }

      .desktop-alert .actions {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          margin-bottom: 20px;
      }

      .desktop-alert .message {
          padding: 20px;
      }
  `]
})
export class DialogChangeModeComponent {
  iconMobile = faMobileAlt;
  iconDesktop = faDesktop;

  constructor(
    public dialogRef: MatDialogRef<DialogChangeModeComponent>,
    @Inject(MAT_DIALOG_DATA) public suggestedView: 'desktop' | 'mobile') {
  }

  close(): void {
    this.dialogRef.close();
  }
}
