import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { Router } from '@angular/router';
import { SidenavActions } from './index';
import { ProjectActions } from '../../project/+state';
import { AuthActions } from '../../../auth/state/auth';

@Injectable()
export class SidenavEffects {
  constructor(private actions$: Actions, private router: Router) {}

  sidenavLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SidenavActions.sidenavLogout),
      // Project close action is dispatched in AppProjectComponent OnDestroy
      map(() => AuthActions.authLogout())
    )
  );

  sidenavChangeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SidenavActions.sidenavChangeProject),
      // Project close action is dispatched in AppProjectComponent OnDestroy
      map(() => {
        this.router.navigate(['/auth/projects']);
        return ProjectActions.closeProject();
      })
    )
  );
}
