import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromHistoryFeature from './+state/feature-history.reducer';
import { RecordCardComponent } from './record-card/record-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { RecordCardTabsComponent } from './record-card-tabs/record-card-tabs.component';
import { RecordCardMediaListComponent } from './record-card-media-list/record-card-media-list.component';
import { RecordCardQuestionsComponent } from './record-card-questions/record-card-questions.component';
import { FeatureHistoryModalComponent } from './feature-history-modal/feature-history-modal/feature-history-modal.component';
import { EffectsModule } from '@ngrx/effects';
import { FeatureHistoryEffects } from './+state/feature-history.effects';
import { Map3UiModule } from '@trim-web-apps/map3-ui';

// TODO mediaTab: reuse same strategy of RecordEditor?
export const MEDIA_TAB_ID = 'MEDIA_5dk29fgh2389s';
export const QUESTIONS_NO_GROUP_TAB_ID = 'NO_GROUP_s0fks93nmf1g2';

@NgModule({
  declarations: [
    FeatureHistoryModalComponent,
    RecordCardComponent,
    RecordCardTabsComponent,
    RecordCardMediaListComponent,
    RecordCardQuestionsComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromHistoryFeature.FEATURE_HISTORY_FEATURE_KEY,
      fromHistoryFeature.reducer
    ),
    EffectsModule.forFeature([FeatureHistoryEffects]),
    FontAwesomeModule,
    SharedModule,
    Map3UiModule,
  ],
  exports: [FeatureHistoryModalComponent],
})
export class FeatureHistoryModule {}
