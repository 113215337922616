import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MapActions } from '../../map/+state';
import { ProjectActions } from '../../project/+state';
import { SidenavActions } from '../../sidenav/+state';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '@trim-web-apps/core';

@Component({
  selector: 'app-state-debug',
  template: `
    <div class="actions" *ngIf="!isProd">
      <div class="click" (click)="test1()">create record</div>
      <div class="click" (click)="test5()">close project</div>
      <div class="click" (click)="test6()">notify</div>
      <div class="click" (click)="fetchProject()">FETCH PROJECT</div>
    </div>
  `,
  styles: [
    `
      .actions {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 3;
      }

      .click {
        border: 1px solid red;
        margin: 5px;
        padding: 10px;
        cursor: pointer;
        background-color: #ffffff;
      }
    `,
  ],
})
export class StateDebugComponent implements OnInit {
  isProd = environment.production;

  constructor(private store: Store, private notify: NotificationService) {}

  ngOnInit(): void {
    if (this.isProd) return;
    setTimeout(() => this.test2(), 2500);
    // setTimeout(() => this.test4(), 2500);
  }

  test1(): void {
    this.store.dispatch(
      MapActions.createRecord({
        formId: '9dfad8ec-3791-451c-8554-a6adf5d71ef1',
        specificId: '886f70d8-7db4-4694-804e-63110c168122',
        latitude: 10,
        longitude: 20,
        altitude: 0,
      })
    );
  }

  test2(): void {
    // form oh 14923814-45c5-48d2-b5cb-98abfb007528
    // specific dwm 9ac31e01-f2d4-4be5-acd7-7754f9effd42
    this.store.dispatch(
      SidenavActions.createDataset({
        formId: '234a6fd5-c956-4001-8f7a-ce50c16b985d',
      })
    );
    this.store.dispatch(
      SidenavActions.createDataset({
        formId: '9dfad8ec-3791-451c-8554-a6adf5d71ef1',
      })
    );
  }

  test3(): void {
    this.store.dispatch(
      MapActions.editRecord({
        recordId: '4bf3ecb3-07eb-44c2-a604-a3997f9592fe',
      })
    );
  }

  test4(): void {
    this.store.dispatch(
      MapActions.selectSingleRecord({
        selectedRecordId: '4bf3ecb3-07eb-44c2-a604-a3997f9592fe',
      })
    );
  }

  test5(): void {
    this.store.dispatch(ProjectActions.closeProject());
  }

  test6(): void {
    console.log('aaa');
    this.notify.warn('aaa', 2000);
  }

  fetchProject(): void {
    this.store.dispatch(ProjectActions.fetchProject());
  }
}
