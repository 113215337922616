import { Component, Input, OnInit } from '@angular/core';
import { FieldNumber } from '3map-models';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FieldTypeBaseComponent } from '../../../models/FieldTypeBaseComponent';
import { EditorError } from '../../../models/EditorError';
import { isNumber } from '@trim-web-apps/core';

@Component({
  selector: 'app-field-number',
  template: `
    <app-field-name [fieldType]="fieldType"></app-field-name>
    <input
      type="number"
      placeholder="Enter a number"
      [formControl]="formControl!"
      [readOnly]="!allowEdit"
      (focusout)="inputFocusOut()"
    />
    <div class="error" *ngIf="hasError()">Number is required</div>
  `,
  styleUrls: ['../../field-type.base.component.scss'],
})
export class FieldNumberComponent
  extends FieldTypeBaseComponent
  implements OnInit
{
  @Input() fieldNumber: FieldNumber | undefined;

  ngOnInit(): void {
    if (!this.fieldType) throw Error('[ FieldNumber ] Missing FieldType');
    const value = isNumber(this.fieldNumber?.value)
      ? this.fieldNumber?.value
      : '';
    const validators = this.fieldType.required ? [Validators.required] : [];
    this.formControl = new UntypedFormControl(value, validators);
    // this.bindFormControlEmitter();
  }

  override getField(): FieldNumber | null {
    if (this.formControl?.valid) {
      const value = extractTheFuckingNumber(this.formControl.value);
      return value !== null
        ? {
            value: this.formControl.value,
            type: 'NUMBER',
          }
        : null;
    }
    throw new EditorError('Invalid FieldNumber');
  }
}

export function extractTheFuckingNumber(valueFromControl: any): number | null {
  if (valueFromControl === null || valueFromControl === undefined) {
    return null;
  }

  const valueAsString = valueFromControl.toString();

  if (valueAsString.length > 0) {
    return valueFromControl;
  }

  return null;
}
