import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromMapResource from './+state/map-resource.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MapResourceEffects } from './+state/map-resource.effects';
import { MapResourceListComponent } from './containers/map-resource-list/map-resource-list.component';

@NgModule({
  declarations: [MapResourceListComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromMapResource.FEATURE_KEY,
      fromMapResource.reducer
    ),
    EffectsModule.forFeature([MapResourceEffects]),
  ],
  exports: [MapResourceListComponent],
})
export class MapResourceModule {}
