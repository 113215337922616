// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* eslint-disable max-len */

export const environment = {
  production: false,

  // INJECTION TOKENS
  LSK_APP: 'project3map-app',
  LSK_AUTH: 'project3map-auth',
  LSK_GRID_STATE: 'project3map-grid-filters',
  LSK_MAP: 'project3map-map',

  BRANCH: 'dev',
  API_URL: 'https://3map-dev-server.trimweb.it',

  // LICENCES
  LICENSE:
    'CompanyName=TRIM s.r.l.,LicensedApplication=3map,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-011335,ExpiryDate=21_October_2021_[v2]_MTYzNDc3MDgwMDAwMA==ab204b07af6a0f4983300d840cd17a2f',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
