import { createReducer, on } from '@ngrx/store';
import { FetchStatus } from '../../shared/models/fetch-status.model';
import * as MapActions from './map.actions';
import { ProjectActions } from '../../project/+state';
import { SidenavActions } from '../../sidenav/+state';
import { TableActions } from '../../table/+state';
import { NominatimResponse } from '@trim-web-apps/nominatim';

export const mapFeatureKey = 'map2';
/**
 * mapStyleId contains the style emitted by mapbox when Map (style) is fully loaded.
 * Is also used to determine when Map has been fully loaded.
 */
export type State = {
  mapStyleId: string | null;
  iconsLoaded: FetchStatus;
  processedRecordIds: {
    [datasetId: string]: { [featureId: string]: string[] };
  };
  selectedFeatureId: { featureId: string; formId: string } | null;
  searchLocation: NominatimResponse | null;
};

export const initialState: State = {
  mapStyleId: null,
  iconsLoaded: 'INITIAL',
  processedRecordIds: {},
  selectedFeatureId: null,
  searchLocation: null,
};

export const reducer = createReducer(
  initialState,
  on(MapActions.mapComponentDestroy, () => ({
    ...initialState,
  })),
  on(
    ProjectActions.closeProject,
    (state): State => ({
      ...state,
      iconsLoaded: initialState.iconsLoaded,
      processedRecordIds: initialState.processedRecordIds,
      selectedFeatureId: initialState.selectedFeatureId,
    })
  ),
  on(
    ProjectActions.fetchProject,
    (state): State => ({
      ...state,
      iconsLoaded: 'INITIAL',
    })
  ),
  on(
    MapActions.MAP_STYLE_CHANGED,
    (state, { mapStyleId }): State => ({
      ...state,
      mapStyleId,
      iconsLoaded: 'INITIAL',
    })
  ),
  on(
    MapActions.ADD_MARKERS,
    (state): State => ({
      ...state,
      iconsLoaded: 'PENDING',
    })
  ),
  on(
    MapActions.ADD_MARKERS_SUCCESS,
    (state): State => ({
      ...state,
      iconsLoaded: 'SUCCESS',
    })
  ),
  on(
    MapActions.ADD_MARKERS_ERROR,
    (state): State => ({
      ...state,
      iconsLoaded: 'ERROR',
    })
  ),
  on(MapActions.setMapRecords, (state, { formId, recordIdsByFeatureId }) => {
    // TODO check if selectedFeatureId and selectedRecordId still exist
    const processedRecordIds = { ...state.processedRecordIds };
    processedRecordIds[formId] = recordIdsByFeatureId;
    return { ...state, processedRecordIds };
  }),
  on(MapActions.mapClick, (state, { feature }) => {
    if (state.selectedFeatureId === null && feature === null) return state;
    return { ...state, selectedFeatureId: feature };
  }),
  on(
    MapActions.removeDataset,
    SidenavActions.removeDataset,
    (state, { id }) => {
      const processedRecordIds = { ...state.processedRecordIds };
      delete processedRecordIds[id];
      return { ...state, processedRecordIds };
    }
  ),
  on(TableActions.zoomToRecord, (state, action): State => {
    return {
      ...state,
      selectedFeatureId: {
        featureId: action.featureId,
        formId: action.formId,
      },
    };
  }),
  on(
    SidenavActions.searchLocation,
    (state, { searchLocation }): State => ({
      ...state,
      searchLocation,
    })
  )
);
