import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Record } from '3map-models';
import { DeployStatus } from '../../models/DeployStatus';
import { Store } from '@ngrx/store';
import * as DeploySelectors from '../../+state/deploy.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-deploy-stepper',
  template: `
    <app-deploy-stepper-item
      label="Project Valid"
      [deployStatus]="(projectValid$ | async)!"
    ></app-deploy-stepper-item>
    <app-deploy-stepper-item
      label="Conflict Records"
      [deployStatus]="(recordsStatus$ | async)!"
    >
      <div class="invalid-records">
        <app-invalid-records
          class=""
          [records]="(invalidRecords$ | async) ?? []"
        ></app-invalid-records>
      </div>
    </app-deploy-stepper-item>
    <app-deploy-stepper-item
      label="Project Deploy"
      [deployStatus]="(projectStatus$ | async)!"
    ></app-deploy-stepper-item>
    <app-deploy-stepper-item
      label="Markers Upload"
      [deployStatus]="(markersStatus$ | async)!"
    ></app-deploy-stepper-item>
    <app-deploy-stepper-item
      label="Resources Upload"
      [deployStatus]="(resourcesStatus$ | async)!"
    ></app-deploy-stepper-item>
  `,
  styles: [
    `
      .invalid-records {
        margin-left: 30px;
      }
    `,
  ],
})
export class DeployStepperComponent {
  invalidRecords$: Observable<Record[]>;
  recordsStatus$: Observable<DeployStatus>;
  projectValid$: Observable<DeployStatus>;
  projectStatus$: Observable<DeployStatus>;
  markersStatus$: Observable<DeployStatus>;
  resourcesStatus$: Observable<DeployStatus>;

  constructor(private store: Store) {
    this.projectValid$ = this.store
      .select(DeploySelectors.selectProjectDeployable())
      .pipe(map((valid) => (valid ? 'DEPLOYED' : 'ERROR')));
    this.invalidRecords$ = this.store.select(
      DeploySelectors.selectInvalidRecords()
    );
    this.recordsStatus$ = this.store.select(
      DeploySelectors.selectRecordsRequest()
    );
    this.projectStatus$ = this.store.select(
      DeploySelectors.selectProjectRequest()
    );
    this.markersStatus$ = this.store.select(
      DeploySelectors.selectMarkerRequest()
    );
    this.resourcesStatus$ = this.store.select(
      DeploySelectors.selectResourcesRequest()
    );
  }
}
