import {
  TimestepType,
  WeatherLayer,
  WeatherModel,
  WIND_KTS,
} from '@trim-web-apps/weather-models';
import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';

export function createEra5Marseille(initimeList: number[]): WeatherModel {
  const totalTsCount = 121 * 24; // 121 days * 24 ts per day
  const ts = createTimestepListForecast(
    initimeList,
    totalTsCount,
    3600,
    '+00:00'
  );
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[totalTsCount - 1],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 3600 };

  const layers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'ERA5_MARSEILLE',
    label: 'ERA5_MARSEILLE',
    category: 'FORECAST',
    layers: layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].timestepList[0].timesteps[0],
    selectedLayerId: layers[0].id,
    dateRange: defaultDateRng,
    bbox: [
      [1.6595, 44.765],
      [13.1725, 44.765],
      [13.1725, 40.255],
      [1.6595, 40.255],
      [1.6595, 44.765],
    ],
  };
}
