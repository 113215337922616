/**
 * Registration Form
 */

import { ReqSignUp } from '3map-models';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthApiService } from '../../services/auth-api.service';
import { passwordsMatchValidator } from '../../utils/input.validators';

@Component({
  selector: 'app-signup',
  template: `
    <div class="title">Create account</div>
    <form *ngIf="signUpResponse.success === false" [formGroup]="form">
      <app-input-username
        (formControl)="onFormControlReady($event, 'username')"
      ></app-input-username>
      <app-input-mail
        (formControl)="onFormControlReady($event, 'mail')"
      ></app-input-mail>
      <app-input-password
        (formControl)="onFormControlReady($event, 'password')"
      ></app-input-password>
      <app-input-password
        label="Confirm password"
        (formControl)="onFormControlReady($event, 'passwordConfirm')"
      >
      </app-input-password>

      <div
        class="signup-errors error-label"
        *ngIf="signUpResponse.errorMsg.length > 0"
      >
        {{ signUpResponse.errorMsg }}
      </div>
      <div class="signup-errors error-label" *ngIf="passwordMismatch$ | async">
        Passwords don't match
      </div>

      <div class="signup-btn">
        <ui-btn
          (btnClick)="onSubmit()"
          [fullWidth]="true"
          [disabled]="!form.valid || pendingRequest"
          label="{{ pendingRequest ? 'Creating...' : 'Create account' }}"
        ></ui-btn>
      </div>
    </form>

    <div class="signup-success" *ngIf="signUpResponse.success === true">
      <div class="big">Almost done!</div>
      <p>
        We've sent an activation link to your email address ({{
          form.get('mail')?.value
        }})
      </p>
      <p>Follow the instructions to complete your registration!</p>
    </div>

    <div class="fill-remaining-space"></div>

    <ui-btn
      [fullWidth]="true"
      type="border"
      [routerLink]="['/auth']"
      label="Back"
    >
    </ui-btn>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .big {
        font-size: 1.2em;
      }

      app-signup form {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .signup-btn {
        margin-top: 30px;
      }

      .signup-errors {
        text-align: center;
        margin-top: 10px;
      }

      .title {
        margin-bottom: 30px;
        text-align: right;
        font-size: 1.5em;
      }
    `,
  ],
})
export class SignupComponent implements AfterViewInit {
  form: UntypedFormGroup;
  signUpResponse: { success: boolean; errorMsg: string };
  pendingRequest: boolean;
  passwordMismatch$: Observable<string> | undefined;

  constructor(private fb: UntypedFormBuilder, private authApi: AuthApiService) {
    this.pendingRequest = false;
    this.signUpResponse = { success: false, errorMsg: '' };
    this.form = fb.group({}, { validators: [passwordsMatchValidator] });
  }

  ngAfterViewInit(): void {
    this.passwordMismatch$ = this.form.valueChanges.pipe(
      map(() => this.form.errors?.['passwordMismatch']?.errorMsg)
    );
  }

  onFormControlReady(fc: UntypedFormControl, name: string): void {
    this.form.addControl(name, fc);
  }

  /**
   * Check if form is valid, then call authApi service to complete registration
   */
  onSubmit(): void {
    if (this.form.valid) {
      const username = (this.form.get('username')?.value as string).trim();
      const password = (this.form.get('password')?.value as string).trim();
      const mail = (this.form.get('mail')?.value as string)
        .trim()
        .toLocaleLowerCase();

      const bodyRequest: ReqSignUp = { username, mail, password };

      // const bodyRequest: ReqSignUp = {
      //   username: this.form.get('username')?.value as string,
      //   mail: this.form.get('mail')?.value as string,
      //   password: this.form.get('password')?.value as string,
      // };

      this.pendingRequest = true;
      this.authApi
        .signUp(bodyRequest)
        .pipe(
          take(1),
          map(() => ''),
          catchError((res: HttpErrorResponse) => {
            const errorMsg =
              res.status === 400 && res.error.errorText
                ? res.error.errorText
                : 'Oops... Something went wrong, please refresh the page and try again!';
            return of(errorMsg);
          })
        )
        .subscribe((response: string) => {
          this.pendingRequest = false;
          this.signUpResponse = {
            success: response.length === 0,
            errorMsg: response,
          };
        });
    }
  }
}
