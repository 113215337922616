import { Form, MarkerStyle, Record } from '3map-models';
import * as mapboxgl from 'mapbox-gl';
import { getIconId } from '../marker.service';

export function createRecordLayerId(datasetId: string): string {
  return `${datasetId}_records_layer`;
}

export function createRecordLayer(
  form: Form,
  records: Record[]
): mapboxgl.SymbolLayer {
  return {
    id: createRecordLayerId(form.id),
    type: 'symbol',
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: records.map((record) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [record.longitude, record.latitude],
            },
            properties: {
              icon: getIconId(record, form),
              recordId: record.recordId,
              featureId: record.featureId,
            },
          };
        }),
      },
    },
    layout: {
      'icon-allow-overlap': true,
      'icon-image': '{icon}',
      'icon-size': 0.15,
      'icon-rotate': {
        type: 'identity',
        property: 'rotation',
      },
    },
  };
}

export function createSelectedRecordLayer(
  form: Form,
  record: Record
): mapboxgl.SymbolLayer {
  const recordLayer = createRecordLayer(form, [record]);
  return {
    ...recordLayer,
    id: 'selectedRecordLayer',
    layout: {
      ...recordLayer.layout,
      'icon-size': 0.3,
    },
  };
}

export function getMarkerStyleDict(form: Form): {
  [specificId: string]: MarkerStyle;
} {
  const markerStyleDict: { [specificId: string]: MarkerStyle } = {};
  for (const specific of form.specificList)
    markerStyleDict[specific.id] = specific.markerStyle;
  return markerStyleDict;
}

export function removeLayerAndSource(map: mapboxgl.Map, layerId: string): void {
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
    map.removeSource(layerId);
  }
}
