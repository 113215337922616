import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FieldTypeComponentInput } from '../../models/FieldTypeComponentInput';
import { FieldType } from '3map-models';
import {
  FieldCheck,
  FieldDate,
  FieldInt,
  FieldList,
  FieldNumber,
  FieldRange,
  FieldText,
} from '3map-models/dist/lib/model';
import { FieldTypeBaseComponent } from '../../models/FieldTypeBaseComponent';

@Component({
  selector: 'app-field-types-list',
  template: `
    <ng-container *ngFor="let q of questions">
      <div class="field-item" [ngSwitch]="q.fieldType.type">
        <app-field-text
          *ngSwitchCase="'TEXT'"
          #fieldTypeRef
          (fieldChange)="onFieldChange($event)"
          [fieldType]="q.fieldType"
          [fieldText]="q.field"
          [allowEdit]="q.allowEdit"
        ></app-field-text>
        <app-field-number
          *ngSwitchCase="'NUMBER'"
          #fieldTypeRef
          (fieldChange)="onFieldChange($event)"
          [fieldType]="q.fieldType"
          [fieldNumber]="q.field"
          [allowEdit]="q.allowEdit"
        ></app-field-number>
        <app-field-list
          *ngSwitchCase="'LIST'"
          #fieldTypeRef
          (fieldChange)="onFieldChange($event)"
          [fieldType]="q.fieldType"
          [fieldList]="q.field"
          [allowEdit]="q.allowEdit"
        ></app-field-list>
        <app-field-check
          *ngSwitchCase="'CHECK'"
          #fieldTypeRef
          (fieldChange)="onFieldChange($event)"
          [fieldType]="q.fieldType"
          [fieldCheck]="q.field"
          [allowEdit]="q.allowEdit"
        ></app-field-check>
        <app-field-date
          *ngSwitchCase="'DATE'"
          #fieldTypeRef
          (fieldChange)="onFieldChange($event)"
          [fieldType]="q.fieldType"
          [fieldDate]="q.field"
          [allowEdit]="q.allowEdit"
        ></app-field-date>
        <app-field-int
          *ngSwitchCase="'INT'"
          #fieldTypeRef
          (fieldChange)="onFieldChange($event)"
          [fieldType]="q.fieldType"
          [fieldInt]="q.field"
          [allowEdit]="q.allowEdit"
        ></app-field-int>
        <app-field-range
          *ngSwitchCase="'RANGE'"
          #fieldTypeRef
          (fieldChange)="onFieldChange($event)"
          [fieldType]="q.fieldType"
          [fieldRange]="q.field"
          [allowEdit]="q.allowEdit"
        ></app-field-range>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .field-item {
        margin-bottom: 15px;
      }
    `,
  ],
})
export class FieldTypesListComponent {
  @ViewChildren('fieldTypeRef') fieldTypeComps:
    | QueryList<FieldTypeBaseComponent>
    | undefined;
  @Input() questions: FieldTypeComponentInput[];
  // @Input() markerItems: MarkerItem[];
  @Output() fieldChange: EventEmitter<{
    fieldType: FieldType;
    field:
      | FieldNumber
      | FieldText
      | FieldList
      | FieldRange
      | FieldCheck
      | FieldInt
      | FieldDate
      | null;
  }>;

  constructor() {
    this.questions = [];
    // this.markerItems = [];
    this.fieldChange = new EventEmitter();
  }

  trackBy = (index: number, item: FieldTypeComponentInput) => {
    return item.fieldType.id;
  };

  markDirty(): void {
    this.fieldTypeComps?.forEach((comp) => comp.formControl?.markAsDirty());
  }

  isValid(): boolean {
    if (!this.fieldTypeComps) return false;
    return this.fieldTypeComps
      .map((comp) => comp.formControl?.valid || false)
      .reduce((acc, item) => acc && item, true);
  }

  onFieldChange(evt: {
    fieldType: FieldType;
    field:
      | FieldNumber
      | FieldText
      | FieldList
      | FieldRange
      | FieldCheck
      | FieldInt
      | FieldDate
      | null;
  }): void {
    this.fieldChange.emit(evt);
  }
}
