import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FieldType, FormSpecific, Record } from '3map-models';
import { recordAnswerToString } from '../../project/project.utils';
import { notNullOrUndefined } from '@trim-web-apps/core';

@Component({
  selector: 'app-record-card-questions',
  template: `
    <div class="no-questions" *ngIf="questions.length === 0">
      No questions to display
    </div>
    <div class="field selectable-text" *ngFor="let question of questions">
      <span class="name"> {{ question.name }}:</span> {{ question.answer }}
    </div>
  `,
  styles: [
    `
      .name {
        font-weight: bold;
      }

      .no-questions {
        padding: var(--spacing-3) 0;
        text-align: center;
        color: var(--border);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordCardQuestionsComponent {
  @Input() record?: Record;
  @Input() specific?: FormSpecific;
  @Input() activeTab: string | null = null;

  // TODO performance: this might be called a lot, we should memoize it...?
  get questions(): { name: string; answer: string }[] {
    if (!this.record || !this.specific) return [];

    const { questions, questionGroups } = this.specific;

    if (this.activeTab === null)
      return getQuestionsFromRecord(this.record, questions);

    const questionGroup = questionGroups?.find(
      (group) => group.name === this.activeTab
    );

    if (!questionGroup) return [];

    return getQuestionsFromRecord(
      this.record,
      questions.filter((q) => questionGroup.questions.includes(q.id))
    );
  }
}

function getQuestionsFromRecord(
  record: Record,
  fieldTypeList: FieldType[]
): { name: string; answer: string }[] {
  return fieldTypeList
    .map((fieldType) => {
      const answer = recordAnswerToString(record, fieldType);
      return answer ? { name: fieldType.name, answer } : null;
    })
    .filter(notNullOrUndefined);
}
