import { createSelector } from '@ngrx/store';
import { notNullOrUndefined } from '@trim-web-apps/core';
import * as fromRoot from '../project.selectors';

export const selectState = createSelector(
  fromRoot.selectProjectState,
  (state) => state.users
);

export const selectUsers = () => {
  return createSelector(selectState, (users) =>
    Object.keys(users.entities)
      .map((k) => users.entities[k])
      .filter(notNullOrUndefined)
  );
};
