import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { SidenavActions } from '../../sidenav/+state';
import * as ProjectActions from '../../project/+state/project.actions';
import { MapResource } from './map-resource.model';

export const FEATURE_KEY = 'map-resource';

export type State = EntityState<MapResource>;

export const adapter = createEntityAdapter<MapResource>({
  selectId: (r: MapResource) => r.filename,
});

export const initialState = adapter.getInitialState();

export const reducer = createReducer<State>(
  initialState,
  on(ProjectActions.closeProject, () => ({
    ...initialState,
  })),
  on(
    ProjectActions.fetchProject,
    ProjectActions.fetchProjectError,
    (): State => initialState
  ),
  on(ProjectActions.fetchProjectSuccess, (state, { project }) => {
    const entities: MapResource[] = project.resourceList.map((resource) => ({
      ...resource,
      status: 'DISABLED',
    }));
    return adapter.setAll(entities, state);
  }),
  on(
    SidenavActions.enableMapResource,
    SidenavActions.enableMapResourceSuccess,
    SidenavActions.disableMapResource,
    SidenavActions.enableMapResourceError,
    (state, action): State => {
      const resourceId = action.resourceId;
      const entity = state.entities[resourceId];
      const status = getStatusFromAction(action);
      if (!entity || !status) return state;
      const update = { id: resourceId, changes: { ...entity, status } };
      return adapter.updateOne(update, state);
    }
  )
);

function getStatusFromAction(
  action: Action
): 'ENABLED' | 'DISABLED' | 'LOADING' | 'ERROR' | null {
  switch (action.type) {
    case SidenavActions.enableMapResource.type:
      return 'LOADING';
    case SidenavActions.disableMapResource.type:
      return 'DISABLED';
    case SidenavActions.enableMapResourceError.type:
      return 'ERROR';
    case SidenavActions.enableMapResourceSuccess.type:
      return 'ENABLED';
  }
  return null;
}
