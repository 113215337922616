import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WeatherItemData } from './weather-item-data';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons/faSlidersH';

@Component({
  selector: 'weather-core-item-mobile',
  template: `
    <div class="mobile-item-wrapper" *ngIf="weatherItemData">
      <div class="row">
        <weather-core-layer-picker
          [weatherModelId]="weatherItemData.model.id"
        ></weather-core-layer-picker>
      </div>

      <ng-container *ngIf="!weatherItemData.model.chartEnabled">
        <div class="row">
          <weather-core-timestep-picker
            [weatherModelId]="weatherItemData.model.id"
          ></weather-core-timestep-picker>
        </div>

        <div class="row">
          <weather-core-legend
            [layer]="weatherItemData.selectedLayer"
            [isMobile]="true"
          ></weather-core-legend>
        </div>
      </ng-container>

      <div class="chart-wrapper row" *ngIf="weatherItemData.model.chartEnabled">
        <weather-core-chart-mobile
          [weatherModelId]="weatherItemData.model.id"
        ></weather-core-chart-mobile>
      </div>

      <div class="bottom row">
        <div class="action-btn status">
          <weather-core-model-status
            [weatherModel]="weatherItemData.model"
            (click)="toggleVisibility.emit(weatherItemData.model.id)"
          ></weather-core-model-status>
        </div>

        <div class="action-btn">
          <fa-icon
            [icon]="iconRemove"
            (click)="removeModel.emit(weatherItemData.model.id)"
          ></fa-icon>
        </div>

        <div class="action-btn" (click)="toggleChartVisibility.emit()">
          <ng-container *ngIf="weatherItemData.model.chartEnabled">
            <fa-icon [icon]="iconControls"></fa-icon>
          </ng-container>

          <ng-container *ngIf="!weatherItemData.model.chartEnabled">
            <fa-icon [icon]="iconChart"></fa-icon>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .mobile-item-wrapper {
        background-color: white;
        width: 100%;
        padding: 0 10px;
      }

      weather-core-model-status {
      }

      .row {
        padding: 10px 0;
      }

      .bottom {
        display: flex;
        border-top: 1px solid #eee;
      }

      .chart-wrapper {
        padding: 10px;
        overflow: scroll;
      }

      .action-btn {
        width: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .action-btn.status {
        flex: 1 1 auto;
        align-items: start;
      }
    `,
  ],
})
export class WeatherItemMobileComponent {
  @Input() weatherItemData?: WeatherItemData;
  @Output() toggleVisibility: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeModel: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleChartVisibility: EventEmitter<void> =
    new EventEmitter<void>();
  iconRemove = faTrash;
  iconChart = faChartBar;
  iconControls = faSlidersH;
}
