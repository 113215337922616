import { createReducer, on } from '@ngrx/store';
import { SidenavActions } from './index';
import { TableActions } from '../../table/+state';
import * as ProjectActions from '../../project/+state/project.actions';

export const FEATURE_KEY = 'sidenav';
export type State = {
  section: 'MAP' | 'TABLE';
  opened: boolean;
};

const initialState: State = {
  section: 'MAP',
  opened: true,
};

export const reducer = createReducer(
  initialState,
  on(ProjectActions.closeProject, () => ({
    ...initialState,
  })),
  on(SidenavActions.setSection, (state, { section }): State => {
    return { ...state, section };
  }),
  on(SidenavActions.openToSection, (state, { section }): State => {
    return { ...state, section, opened: true };
  }),
  on(SidenavActions.setOpened, (state, { opened }): State => {
    return { ...state, opened };
  }),
  on(
    TableActions.zoomToRecords,
    TableActions.zoomToRecord,
    (state): State =>
      state.section === 'MAP' ? state : { ...state, section: 'MAP' }
  )
);
