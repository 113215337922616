import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMap from './map.reducer';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { ProjectSelectors } from '../../project/+state';
import { RecordSelectors } from '../../record/+state';
import { createRecordLayer } from '../mapbox.utils';
import { FeatureViewData } from '../components/feature-view/feature-view.component';
import { Form, FormSpecific } from '3map-models';

export const selectMapState = createFeatureSelector<fromMap.State>(
  fromMap.mapFeatureKey
);

export const selectMapReadyForRecords = () =>
  createSelector(
    selectMapState,
    (state) => state.iconsLoaded === 'SUCCESS' && state.mapStyleId !== null
  );

export const selectMapLoaded = () =>
  createSelector(selectMapState, (state) => state.mapStyleId !== null);

export const selectMapStyleId = () =>
  createSelector(selectMapState, (state) => state.mapStyleId);

export const selectMapDatasetList = () =>
  createSelector(
    ProjectSelectors.selectAllDataset(),
    ProjectSelectors.selectUserForms(),
    (datasetList, formList) => {
      return datasetList
        .map((dataset) => {
          const form = formList.find((f) => f.id === dataset.formId);
          return form ? { form, dataset } : null;
        })
        .filter(notNullOrUndefined);
    }
  );

export const selectMapRecordsByDatasetId = (datasetId: string) =>
  createSelector(
    selectMapState,
    (state) => state.processedRecordIds[datasetId] || null
  );

export const selectMapRecords = (datasetId: string) =>
  createSelector(
    selectMapRecordsByDatasetId(datasetId),
    RecordSelectors.selectRecordsDict(),
    selectMapStyleId(),
    (recordIdsByFeatureId, recordsDict) => {
      if (recordIdsByFeatureId === null) return null;
      return Object.keys(recordIdsByFeatureId)
        .map((featureId) => recordIdsByFeatureId[featureId][0])
        .map((recordId) => recordsDict[recordId])
        .filter(notNullOrUndefined);
    }
  );

export const selectMapLayer = (datasetId: string) =>
  createSelector(
    selectMapRecords(datasetId),
    ProjectSelectors.selectFormById(datasetId),
    (mapRecords, form) => {
      if (!form || mapRecords === null) return null;
      return createRecordLayer(form, mapRecords);
    }
  );

export const selectFeatureRecords = () =>
  createSelector(
    selectMapState,
    RecordSelectors.selectRecordsDict(),
    ProjectSelectors.selectUserForms(),
    (state, recordsDict, formList): FeatureViewData | null => {
      if (state.selectedFeatureId === null) return null;
      const { featureId, formId } = state.selectedFeatureId;
      const features = state.processedRecordIds[formId];
      const recordsIds =
        features && features[featureId] ? features[featureId] : [];
      const featureRecords = recordsIds
        .map((recordId) => recordsDict[recordId])
        .filter(notNullOrUndefined);

      const form = formList.find((f) => f.id === formId);
      const specificId = featureRecords[0]?.formSpecificId;
      const specific = form?.specificList.find((s) => s.id === specificId);

      return form && specific
        ? { form, specific, records: featureRecords }
        : null;
    }
  );

export const selectContextMenuCreateFeature = () =>
  createSelector(
    ProjectSelectors.selectDatasetFormList(),
    (datasetList): { form: Form; enabledSpecificList: FormSpecific[] }[] => {
      return datasetList.map((ds) => {
        const enabledSpecificList = ds.form.specificList.filter((s) =>
          ds.dataset.specificIds.includes(s.id)
        );
        return { form: ds.form, enabledSpecificList };
      });
    }
  );

export const selectSearchLocation = () =>
  createSelector(selectMapState, (state) => state.searchLocation);
