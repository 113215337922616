import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartData } from '@trim-web-apps/weather-models';

@Component({
  selector: 'weather-core-chart-legend',
  template: `
    <div class="chart-legend" *ngFor="let data of chartDataList">
      <div
        class="chart-legend-color"
        [ngStyle]="{ 'background-color': data.color }"
      ></div>
      <div class="chart-legend-label">{{ data.legend }}</div>
    </div>
  `,
  styles: [
    `
      :host,
      .chart-legend {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .chart-legend-color {
        height: 10px;
        width: 30px;
      }

      .chart-legend {
        padding: 0 20px;
      }

      .chart-legend-label {
        margin-left: 5px;
        font-size: 0.9em;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherChartLegendComponent {
  @Input() chartDataList: ChartData[] | undefined;
}
