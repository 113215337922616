import { isValidLatitude, isValidLongitude } from '@trim-web-apps/core';
import { MapboxPlugin } from './MapConfig';

export function getMapPlugins(): MapboxPlugin[] {
  return [
    'SCALE',
    'SWITCHER',
    'RULER',
    'ZOOM',
    'NAVIGATION',
    'GEOLOCATE',
    'ATTRIBUTION',
  ];
}

export function isValidLngLat(lngLat: any): boolean {
  return isValidLatitude(lngLat.lat) && isValidLongitude(lngLat.lng);
}

export function isValidZoom(zoom: any): boolean {
  return !isNaN(zoom) && zoom >= 0 && zoom <= 30;
}
