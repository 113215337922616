import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MapResource, MapResourceSelectors } from '../../+state';
import { MapResourceService } from '../../+state/map-resource.service';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { Map } from 'mapbox-gl';

@Component({
  selector: 'app-map-resource-list[map]',
  template: ``,
})
export class MapResourceListComponent implements OnDestroy {
  @Input() map?: Map;
  private layerIdsList: string[] = [];
  private sub: Subscription | undefined;

  constructor(
    private store: Store,
    private mapResourceService: MapResourceService
  ) {
    this.sub = this.store
      .select(MapResourceSelectors.selectMapResourceEnabled())
      .subscribe((resList) => this.onResourceListChange(resList));
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onResourceListChange(resourceList: MapResource[]): void {
    const resourceLayerList = resourceList
      .map((resource) => {
        const type = resource.type;
        if (type === 'GEOJSON') return this.addGeoJsonResource(resource);
        if (type === 'IMAGE') return this.addImageResource(resource);
        return null;
      })
      .filter(notNullOrUndefined)
      .reduce((acc, item) => [...acc, ...item], []);

    for (const layerId of this.layerIdsList) {
      if (!resourceLayerList.includes(layerId))
        this.removeResourceFromMap(layerId);
    }

    this.layerIdsList = resourceLayerList;
  }

  /**
   'type' property for mapbox.AnyLayer is derived from style
   Examples:
   style: { 'circle-radius': 5 }   => 'circle'
   style: { 'line-color': 'red' }  => 'line'
   style: { 'fill-opacity': 0.5 }  => 'fill'

   Available:
   "symbol" | "circle" | "background" | "fill-extrusion" | "fill" | "heatmap" | "hillshade" | "line" | "raster" | "custom"'

   If style is empty, defaults to 'circle'
   */
  private addGeoJsonResource(resource: MapResource): string[] | null {
    const style = resource.style || {};
    const geoType = Object.keys(style)[0].split('-')[0] || 'circle';
    const geoJsonLayerId = resource.filename + '__json';
    const namesLayerId = resource.filename + '__names';
    const data = this.mapResourceService.getGeojson(resource);
    const geoJsonLayer = this.map?.getLayer(geoJsonLayerId) || null;
    const namesLayer = this.map?.getLayer(namesLayerId) || null;

    if (geoJsonLayer && namesLayer) return [geoJsonLayerId, namesLayerId];
    if (!data) return null;

    const newGeoJsonLayer: mapboxgl.AnyLayer = {
      id: geoJsonLayerId,
      source: {
        type: 'geojson',
        data: JSON.parse(data),
      },
      type: geoType as any,
      paint: style,
    };

    const newNamesLayer: mapboxgl.AnyLayer = {
      id: namesLayerId,
      source: {
        type: 'geojson',
        data: JSON.parse(data),
      },
      type: 'symbol',
      layout: {
        'text-field': '{name}',
        'text-offset': [0, -1.0],
        'text-size': 14,
      },
    };

    this.map?.addLayer(newGeoJsonLayer);
    this.map?.addLayer(newNamesLayer);

    return [geoJsonLayerId, namesLayerId];
  }

  private addImageResource(resource: MapResource): string[] | null {
    const style = resource.style;
    const opacity =
      style && style.hasOwn('raster-opacity') ? style['raster-opacity'] : 1;
    const base64 = this.mapResourceService.getImageBase64(resource);
    if (!base64) return null;

    const imageLayerId = resource.filename;
    const imageLayer = this.map?.getLayer(imageLayerId);

    if (imageLayer) return [imageLayerId];

    const newImageLayer: mapboxgl.RasterLayer = {
      id: resource.filename,
      source: {
        type: 'image',
        url: base64,
        coordinates: resource.boundingBox,
      },
      type: 'raster',
      paint: { 'raster-opacity': opacity },
    };

    this.map?.addLayer(newImageLayer);
    return [imageLayerId];
  }

  private removeResourceFromMap(layerId: string): void {
    if (!this.map?.getLayer(layerId)) return;
    this.map.removeLayer(layerId);
    this.map.removeSource(layerId);
  }
}
