import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartArea } from '../weather-chart-view/weather-chart-view.component';
import { ChartData } from '@trim-web-apps/weather-models';
import { Chart } from 'chart.js';

@Component({
  selector: 'weather-core-chart-view-multi',
  template: `
    <div>
      <canvas #chartCanvas class="chart-canvas"></canvas>
    </div>
  `,
})
export class WeatherChartViewMultiComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('chartCanvas') canvasRef!: ElementRef;
  @Input() chartDataList!: ChartData[];
  @Input() xAxisLabels!: boolean;
  @Input() xAxisLabelsColor!: string;
  @Input() xAxisLabelRotation!: { min: number; max: number };
  @Input() xAxisPadding!: number;
  @Input() showLegend!: boolean;
  @Input() chartArea!: ChartArea;

  @Output() chartReady: EventEmitter<void> = new EventEmitter<void>();
  @Output() canvasChartHeight: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() chartAreaChanged: EventEmitter<ChartArea> =
    new EventEmitter<ChartArea>();

  private chart!: Chart;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['chartData']?.firstChange) {
      return;
    }

    if (this.canvasRef?.nativeElement !== undefined) {
      console.log('draw chart');
      this.drawChart();
    }
  }

  ngAfterViewInit() {
    this.canvasRef.nativeElement.height = this.chartDataList[0].chartHeight;
    this.chartReady.emit();
    this.drawChart();
  }

  ngOnDestroy() {
    this.destroyChart();
  }

  private drawChart(): void {
    const yAxisIdList = ['left-y-axis', 'right-y-axis'];
    const positions = ['left', 'right'];
    const scalesY = this.chartDataList.map((data: ChartData, index: number) => {
      return {
        id: yAxisIdList[index],
        position: positions[index],
        ticks: {
          beginAtZero: data.type === 'bar',
        },
      };
    });

    const datasets = this.chartDataList.map(
      (data: ChartData, index: number) => {
        return {
          ...this.getStyle(data),
          yAxisID: yAxisIdList[index],
          data: data.data,
          label: data.legend,
        };
      }
    );

    this.destroyChart();

    this.chart = new Chart(this.canvasRef.nativeElement.getContext('2d'), {
      type: this.chartDataList[0].type as any,
      data: {
        labels: this.chartDataList[0].labels,
        datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          display: !!this.showLegend,
        },
        scales: {
          yAxes: scalesY,
          xAxes: [
            {
              ticks: {
                display: !!this.xAxisLabels,
                maxRotation: this.xAxisLabelRotation
                  ? this.xAxisLabelRotation.max
                  : 50, // chartJs default
                minRotation: this.xAxisLabelRotation
                  ? this.xAxisLabelRotation.min
                  : 0, // chartJs default
                fontColor: this.xAxisLabelsColor,
                padding: this.xAxisPadding ? this.xAxisPadding : 0,
              },
            },
          ],
        },
      },
    });

    this.chart.update();
    this.canvasChartHeight.emit(
      this.chart.chartArea.bottom + this.chart.chartArea.top
    );
    this.chartAreaChanged.emit(this.chart.chartArea);
  }

  private destroyChart(): void {
    this.chart?.destroy();
  }

  private getStyle(chartData: ChartData): {
    backgroundColor: string;
    borderColor?: string;
    fill?: boolean;
  } {
    switch (chartData.type) {
      case 'line':
        return {
          fill: false,
          borderColor: chartData.color || 'blue',
          backgroundColor: chartData.color || 'blue',
        };
      case 'bar':
        return {
          backgroundColor: chartData.color || 'blue',
        };
    }

    throw Error('[weather-core] Chart type not supported');
  }
}
