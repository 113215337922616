import { createAction, props } from '@ngrx/store';
import { Link } from '3map-models';

export const UPSERT_LINK = createAction(
  '[ Project ] Upsert Link ',
  props<{ link: Link }>()
);

export const REMOVE_LINK = createAction(
  '[ Project ] Remove Link',
  props<{ link: Link }>()
);
