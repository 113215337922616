import { Component, Input, OnInit } from '@angular/core';
import { Map } from 'mapbox-gl';
import { WeatherService } from '@trim-web-apps/weather-core';
import { WeatherModel } from '@trim-web-apps/weather-models';

@Component({
  selector: 'app-weather',
  template: `
    <ng-container *ngIf="map && mapStyleId">
      <weather-core
        [mapInstance]="map"
        [mapStyleId]="mapStyleId"
      ></weather-core>
      <weather-core-weathers-list></weather-core-weathers-list>
      <weather-core-popup></weather-core-popup>
    </ng-container>
  `,
})
export class WeatherComponent implements OnInit {
  @Input() map: Map | undefined;
  @Input() mapStyleId: string | undefined;

  constructor(private weather: WeatherService) {}

  ngOnInit(): void {
    this.weather.initModels();
    // setTimeout(() => this.weather.addWeatherModel('GPM'), 2000);
  }

  trackBy(index: number, data: WeatherModel) {
    return data.id;
  }
}
