import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MarkerService } from './marker.service';

@Component({
  selector: 'app-marker-canvas',
  template: ` <canvas #canvas style="display: none"></canvas> `,
  styles: [],
})
export class MarkerCanvasComponent implements AfterViewInit {
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement> | undefined;

  constructor(private markerManager: MarkerService) {}

  ngAfterViewInit(): void {
    const ctx = this.canvas?.nativeElement.getContext('2d');
    if (ctx) this.markerManager.ctx = ctx;
    else console.error('Marker canvas: cannot get DOM element!');
  }
}
