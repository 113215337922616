import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeployActionsComponent } from './components/deploy-actions/deploy-actions.component';
import { DeployComponent } from './containers/deploy.component';
import { InvalidRecordsComponent } from './components/invalid-records/invalid-records.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DeployStepperComponent } from './containers/deploy-stepper/deploy-stepper.component';
import { DeployStepperItemComponent } from './components/deploy-stepper-item/deploy-stepper-item.component';
import { EffectsModule } from '@ngrx/effects';
import { DeployAlertComponent } from './components/deploy-alert/deploy-alert.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from '../shared/shared.module';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { DeployEffects } from './+state/deploy.effects';

@NgModule({
  declarations: [
    DeployComponent,
    DeployActionsComponent,
    InvalidRecordsComponent,
    DeployStepperComponent,
    DeployStepperItemComponent,
    DeployAlertComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    // StoreModule.forFeature(ADMIN_DEPLOY_FEATURE_KEY, reducer),
    EffectsModule.forFeature([DeployEffects]),
    ScrollingModule,
    SharedModule,
    Map3UiModule,
  ],
  exports: [DeployAlertComponent],
})
export class DeployModule {}
