import { createSelector } from '@ngrx/store';
import * as fromSpecific from './form-specific.reducer';
import * as fromMarkerStyle from './marker-style.selectors';
import { FormSpecific, MarkerStyle, Record } from '3map-models';
import { notNullOrUndefined } from '@trim-web-apps/core';
import {
  FormSpecificLogic,
  MarkerItem,
  QuestionsGroup,
} from '@trim-web-apps/project-core';
import { selectAppAdminState } from '../../admin.reducer.map';

// const selectState = createFeatureSelector<State>(ADMIN_SPECIFIC_FEATURE_KEY);
const selectState = () =>
  createSelector(selectAppAdminState(), (state) => state.specifics);

export const { selectAll } = fromSpecific.adapter.getSelectors();

export const selectEntities = () => {
  return createSelector(selectState(), (state) =>
    Object.keys(state.entities)
      .map((k) => state.entities[k])
      .filter(notNullOrUndefined)
  );
};

export const selectSpecificById = (specificId: string) => {
  return createSelector(
    selectState(),
    fromMarkerStyle.selectMarkerStyle(specificId),
    (state, markerStyle): FormSpecific | null => {
      return entityToSpecific(state.entities[specificId], markerStyle);
    }
  );
};

export const selectSpecificEntitiesByFormId = (formId: string) => {
  return createSelector(
    selectAll,
    (entities): fromSpecific.EntitySpecific[] | undefined =>
      entities.filter((e) => e.formId === formId)
  );
};

export const selectSpecificListByFormId = (formId: string) => {
  return createSelector(
    selectSpecificEntitiesByFormId(formId),
    fromMarkerStyle.selectEntities(),
    (entities, msDict) => {
      return entities
        ?.map((e) => {
          const ms = fromMarkerStyle.entityToMarkerStyle(msDict[e.id]);
          const specific = entityToSpecific(e, ms);
          return specific || null;
        })
        .filter(notNullOrUndefined);
    }
  );
};

export const selectAllSpecific = () => {
  return createSelector(
    selectState(),
    fromMarkerStyle.selectEntities(),
    (state, markerStyleDict) => {
      return Object.keys(state.entities)
        .map((specificId) => {
          const specificEntity = state.entities[specificId];
          const markerStyleEntity = markerStyleDict[specificId];
          return entityToSpecific(
            specificEntity,
            fromMarkerStyle.entityToMarkerStyle(markerStyleEntity)
          );
        })
        .filter(notNullOrUndefined);
    }
  );
};

export const selectMarkerStyleQuestion = (specificId: string) => {
  return createSelector(
    selectState(),
    fromMarkerStyle.selectMarkerStyle(specificId),
    (state, markerStyle) => {
      const specific = state.entities[specificId];
      const questionStyle = markerStyle?.iconFieldType;
      const ft = specific?.questions.find((q) => q.id === questionStyle);
      return ft || null;
    }
  );
};

export const selectQuestionById = (specificId: string, fieldId: string) => {
  return createSelector(selectSpecificById(specificId), (specific) => {
    return specific?.questions.find((q) => q.id === fieldId);
  });
};

export const selectMarkerItems = (specificId: string) => {
  return createSelector(
    selectMarkerStyleQuestion(specificId),
    fromMarkerStyle.selectImgBase64List(specificId),
    (question, imgBase64List): MarkerItem[] => {
      if (imgBase64List.length === 0) return [];
      // if (!question) return [];

      const fields = question?.options || [];
      const defaultMarker: MarkerItem[] = [
        {
          fieldTypeId: question ? question.id : null,
          fieldName: 'Default',
          imageBase64: imgBase64List[0],
        },
      ];

      const markerItems: MarkerItem[] = fields.map((field, index) => ({
        fieldTypeId: question ? question.id : null,
        fieldName: field,
        imageBase64: imgBase64List[index + 1],
      }));
      return [...defaultMarker, ...markerItems];
    }
  );
};

export const selectRecordBase64Icon = (record: Record) => {
  return createSelector(
    selectMarkerStyleQuestion(record.formSpecificId),
    selectMarkerItems(record.formSpecificId),
    (markerStyleQuestion, markerItems): string => {
      if (markerStyleQuestion?.type !== 'LIST')
        return markerItems[0].imageBase64;
      const answer = (record.data as any)[markerStyleQuestion.id];
      const item = markerItems.find((m) => m.fieldName === answer?.text);
      return item ? item.imageBase64 : markerItems[0].imageBase64;
    }
  );
};

export const selectQuestionsGroupList = (specificId: string) =>
  createSelector(
    selectSpecificById(specificId),
    (specific): QuestionsGroup[] => {
      if (!specific) return [];
      const { questionGroups } = specific;
      if (!questionGroups || questionGroups.length === 0) return [];
      return questionGroups.map((group) => {
        const questions = specific.questions.filter((ft) =>
          group.questions.includes(ft.id)
        );
        const groupName = group.name;
        return { questions, groupName };
      });
    }
  );

export const selectLogic = (specificId: string) =>
  createSelector(
    selectSpecificById(specificId),
    (specific): FormSpecificLogic[] => {
      const logic = specific?.listLogic;
      if (!logic) return [];
      return logic
        .map((l) => {
          const q1 = specific?.questions.find((ft) => ft.id === l.q1);
          const q2 = specific?.questions.find((ft) => ft.id === l.q2);
          if (!q1 || !q2) throw Error('Logic: FieldType ID does not exists');
          return q1 && q2 ? { q1, q2, logic: l.logic } : null;
        })
        .filter(notNullOrUndefined);
    }
  );

export function entityToSpecific(
  entity: fromSpecific.EntitySpecific | undefined,
  markerStyle: MarkerStyle | null
): FormSpecific | null {
  if (entity && markerStyle) {
    const specific: FormSpecific = {
      id: entity.id,
      name: entity.name,
      questions: entity.questions,
      markerStyle,
    };

    if (entity.questionGroups) specific.questionGroups = entity.questionGroups;
    if (entity.listLogic) specific.listLogic = entity.listLogic;
    return specific;
  }
  return null;
}
