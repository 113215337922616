import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromWebHook from './';
import { Observable } from 'rxjs';
import { WebHook } from '3map-models';

@Injectable({
  providedIn: 'root',
})
export class WebHookService {
  constructor(private store: Store<fromWebHook.State>) {}

  getWebHookList(): Observable<WebHook[]> {
    return this.store.pipe(select(fromWebHook.selectWebhooks()));
  }

  upsertWebHook(webHook: WebHook): void {
    this.store.dispatch(fromWebHook.UPSERT_WEBHOOK({ webHook }));
  }

  removeWebHook(webHook: WebHook): void {
    this.store.dispatch(fromWebHook.REMOVE_WEBHOOK({ webHook }));
  }
}
