import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { Project, projectDecoder } from '3map-models';
import * as projectActions from './project.actions';
import * as projectSelectors from './project.selectors';
import { FormService } from './form/form.service';
import { UserService } from './user/user.service';
import { WebHookService } from './webhook/web-hook.service';
import { ResourceService } from './resource/resource.service';
import { ProjectPropService } from './project-props/project-prop.service';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { State } from './project.reducer.map';
import { FormSpecificService } from './specific/form-specific.service';
import { MarkerStyleService } from './marker-style/marker-style.service';
import { LinkService } from './link/link.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectCoreService {
  constructor(
    private store: Store,
    private _projectProp: ProjectPropService,
    private _form: FormService,
    private _specific: FormSpecificService,
    private _markerStyle: MarkerStyleService,
    private _user: UserService,
    private _webhook: WebHookService,
    private _link: LinkService,
    private _resource: ResourceService
  ) {}

  projectProp: ProjectPropService = this._projectProp;
  form: FormService = this._form;
  specific: FormSpecificService = this._specific;
  markerStyle: MarkerStyleService = this._markerStyle;
  user: UserService = this._user;
  webhook: WebHookService = this._webhook;
  link: LinkService = this._link;
  resource: ResourceService = this._resource;

  reset(): void {
    this.store.dispatch(projectActions.RESET());
  }

  initProject(
    project: Project,
    icons: { specificId: string; imgBase64List: string[] }[]
  ): void {
    this.store.dispatch(projectActions.INIT_PROJECT({ project, icons }));
  }

  // TODO validate restored state
  setState(projectState: State): void {
    this.store.dispatch(projectActions.RESTORE_PROJECT_STATE({ projectState }));
  }

  getState(): Observable<State> {
    return this.store.pipe(select(projectSelectors.selectState()));
  }

  getProjectName(): Observable<string> {
    return this.getProject().pipe(
      filter(notNullOrUndefined),
      map((project) => project.name)
    );
  }

  isProjectValid(): Observable<boolean> {
    return this.getProject().pipe(
      debounceTime(100),
      map((project) => {
        if (project === null) return false;
        const decoded = projectDecoder().run(project);
        if (!decoded.ok) console.error(decoded.error);
        return decoded.ok;
      })
    );
  }

  getProject(): Observable<Project | null> {
    return combineLatest([
      this._projectProp.getProjectName(),
      this._form.getFormList(),
      this._user.getUserList(),
      this._webhook.getWebHookList(),
      this._link.getList(),
      this._resource.getResourceList(),
    ]).pipe(
      debounceTime(100),
      map(([name, formList, userList, webHookList, linkList, resourceList]) => {
        return name !== null
          ? { name, formList, userList, webHookList, linkList, resourceList }
          : null;
      })
    );
  }

  // getProject(): Observable<Project> {
  //   return this.store.pipe(
  //     select(fromProject.selectProject()),
  //     filter(notNullOrUndefined)
  //   );
  // }
}
