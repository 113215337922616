/* eslint-disable */

import { colors } from './colors';

export function getColorFunction(layer: string): (value: number) => number[] {
  if (layer === 'temperature') {
    return getTemperatureArray;
  } else if (layer === 'pressure') {
    return getPressureArray;
  } else if (layer === 'mean_sea_level_pressure') {
    return getMeanSeaLevelPressureArray;
  } else if (layer === 'temperature_surface') {
    return getTemperatureSurfaceArray;
  } else if (layer === 'low_cloud_cover') {
    return getLowCloudCoverArray;
  } else if (layer === 'medium_cloud_cover') {
    return getMediumCloudCoverArray;
  } else if (layer === 'high_cloud_cover') {
    return getHighCloudCoverArray;
  } else if (layer === 'cloud_cover') {
    return getCloudCoverArray;
  } else if (layer === 'relative_humidity') {
    return getRelativeHumidityArray;
  } else if (layer === 'accumulated_precipitation') {
    return getAccumulatedPrecipitationArray;
  } else if (layer === 'precipitation_rate') {
    return getPrecipitationArray;
  } else if (layer === 'wind_gust') {
    return getWindGustArray;
  } else if (layer === 'wind') {
    return getWindArray;
  } else if (layer === 'altitude') {
    return getAltitudeArray;
  } else if (layer === 'turbolence') {
    return getTurbolenceArray;
  } else if (layer === 'rainfall_1day') {
    return getRainfall1DayArray;
  } else if (layer === 'rainfall_3day') {
    return getRainfall3DayArray;
  } else if (layer === 'rainfall_7day') {
    return getRainfall7DayArray;
  } else if (layer === 'rainfall_monthly') {
    return getRainfallMonthlyArray;
  } else if (layer === 'alert_rainfall_1day' || layer === 'alert_rainfall_3day' || layer === 'alert_rainfall_7day') {
    return getAlertRainfallArray;
  } else if (layer === 'rainfall_monthly_anomalies' || layer === 'rainfall_anomalies') {
    return getRainfallMonthlyAnomaliesArray;
  } else if (layer === 'current') {
    return getCurrentArray;
  }
  // funzione per avere l'array di colori per le allerte.

  throw Error('THIS SHOULD NOT HAPPEN!');
}

export function getTemperatureArray(value: number, print?: boolean): number[] {
  let i = 0;
  while (value >= colors.temperature.ranges[i]) {
    i++;
  }

  if (print) {
    console.log(value, i - 1, colors.temperature.colorsArray[i - 1]);
  }
  return colors.temperature.colorsArray[i - 1];
}

export function getCurrentArray(value: number): number[] {
  let i = 0;
  while (value >= colors.current.ranges[i]) {
    i++;
  }
  return colors.current.colorsArray[i - 1];
}

export function getWindArray(value: number): number[] {
  let i = 0;
  while (value >= colors.wind.ranges[i]) {
    i++;
  }
  return colors.wind.colorsArray[i - 1];
}

export function getWindGustArray(value: number): number[] {
  let i = 0;
  while (value >= colors.wind_gust.ranges[i]) {
    i++;
  }
  return colors.wind_gust.colorsArray[i - 1];
}

export function getRelativeHumidityArray(value: number): number[] {
  let i = 0;
  while (value >= colors.relative_humidity.ranges[i]) {
    i++;
  }
  return colors.relative_humidity.colorsArray[i - 1];
}

export function getPrecipitationArray(value: number): number[] {
  let i = 0;
  // todo delete probably
  if (value < 0) {
    return [0, 0, 0, 0];
  }
  while (value >= colors.precipitation_rate.ranges[i]) {
    i++;
  }
  return colors.precipitation_rate.colorsArray[i - 1];
}

export function getAccumulatedPrecipitationArray(value: number): number[] {
  let i = 0;
  while (value >= colors.accumulated_precipitation.ranges[i]) {
    i++;
  }
  return colors.accumulated_precipitation.colorsArray[i - 1];
}

export function getLowCloudCoverArray(value: number): number[] {
  let i = 0;
  while (value >= colors.low_cloud_cover.ranges[i]) {
    i++;
  }
  return colors.low_cloud_cover.colorsArray[i - 1];
}

export function getMediumCloudCoverArray(value: number): number[] {
  let i = 0;
  while (value >= colors.medium_cloud_cover.ranges[i]) {
    i++;
  }
  return colors.medium_cloud_cover.colorsArray[i - 1];
}

export function getHighCloudCoverArray(value: number): number[] {
  let i = 0;
  while (value >= colors.high_cloud_cover.ranges[i]) {
    i++;
  }
  return colors.high_cloud_cover.colorsArray[i - 1];
}

export function getCloudCoverArray(value: number): number[] {
  let i = 0;
  while (value >= colors.cloud_cover.ranges[i]) {
    i++;
  }
  return colors.cloud_cover.colorsArray[i - 1];
}

export function getMeanSeaLevelPressureArray(value: number): number[] {
  let i = 0;
  while (value >= colors.mean_sea_level_pressure.ranges[i]) {
    i++;
  }
  return colors.mean_sea_level_pressure.colorsArray[i - 1];
}

export function getTemperatureSurfaceArray(value: number): number[] {
  let i = 0;
  while (value >= colors.temperature_surface.ranges[i]) {
    i++;
  }
  return colors.temperature_surface.colorsArray[i - 1];
}

export function getPressureArray(value: number): number[] {
  let i = 0;
  while (value >= colors.pressure.ranges[i]) {
    i++;
  }
  return colors.pressure.colorsArray[i - 1];
}

export function getAltitudeArray(value: number): number[] {
  let i = 0;
  while (value >= colors.altitude.ranges[i]) {
    i++;
  }
  return colors.altitude.colorsArray[i - 1];
}

export function getTurbolenceArray(value: number): number[] {
  let i = 0;
  while (value >= colors.turbolence.ranges[i]) {
    i++;
  }
  return colors.turbolence.colorsArray[i - 1];
}

export function getRainfall1DayArray(value: number): number[] {
  let i = 0;
  while (value >= colors.rainfall_1day.ranges[i]) {
    i++;
  }
  return colors.rainfall_1day.colorsArray[i - 1];
}

export function getRainfall3DayArray(value: number): number[] {
  let i = 0;
  while (value >= colors.rainfall_3day.ranges[i]) {
    i++;
  }
  return colors.rainfall_3day.colorsArray[i - 1];
}

export function getRainfall7DayArray(value: number): number[] {
  let i = 0;
  while (value >= colors.rainfall_7day.ranges[i]) {
    i++;
  }
  return colors.rainfall_7day.colorsArray[i - 1];
}

export function getRainfallMonthlyArray(value: number): number[] {
  let i = 0;
  while (value >= colors.rainfall_monthly.ranges[i]) {
    i++;
  }
  return colors.rainfall_monthly.colorsArray[i - 1];
}

// color function for alert
export function getAlertRainfallArray(value: number): number[] {
  let i = 0;
  while (value >= colors.alert_rainfall_1day.ranges[i]) {
    i++;
  }
  return colors.alert_rainfall_1day.colorsArray[i - 1];
}

export function getRainfallMonthlyAnomaliesArray(value: number): number[] {
  let i = 0;
  while (value >= colors.rainfall_monthly_anomalies.ranges[i]) {
    i++;
  }
  return colors.rainfall_monthly_anomalies.colorsArray[i - 1];
}


export function rotate(xCenter: number, yCenter: number, x: number, y: number, degree: number): { x: number, y: number } {
  const angle = degree * Math.PI / 180;
  return {
    x: Math.cos(angle) * (x - xCenter) - Math.sin(angle) * (y - yCenter) + xCenter,
    y: Math.sin(angle) * (x - xCenter) + Math.cos(angle) * (y - yCenter) + yCenter
  };
}
