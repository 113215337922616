import { createSelector } from '@ngrx/store';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { selectAppAdminState } from '../../admin.reducer.map';

// export const selectState = createFeatureSelector<State>(
//   ADMIN_RESOURCE_FEATURE_KEY
// );

export const selectState = () =>
  createSelector(selectAppAdminState(), (state) => state.resources);

export const selectEntityResourceList = () =>
  createSelector(selectState(), (state) => {
    const entities = state.entities;
    return Object.keys(entities)
      .map((k) => entities[k])
      .filter(notNullOrUndefined);
  });

export const selectEntityResourceById = (id: string) =>
  createSelector(selectState(), (state) => {
    return state.entities[id];
  });

export const selectSortedResourceList = () =>
  createSelector(selectEntityResourceList(), (entities) =>
    entities.sort((a, b) => a.name.localeCompare(b.name))
  );

// export const selectResourceById = (id: string) =>
//   createSelector(selectEntityResourceById(id), (entity) =>
//     entity ? entityToResource(entity) : null
//   );
//
// export const selectResourceList = () =>
//   createSelector(selectEntityResourceList(), (entities) =>
//     entities.map((e) => entityToResource(e)).filter(notNullOrUndefined)
//   );
//
// export const selectResourceToDeploy = () =>
//   createSelector(selectEntityResourceList(), (resources) =>
//     resources.filter(
//       (resource) => resource.resourceData !== null && resource.filename !== ''
//     )
//   );
