import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as ResourceSelectors from '../../+state/resource.selectors';
import * as ResourceActions from '../../+state/resource.actions';
import { Dialog } from '@angular/cdk/dialog';
import { Resource } from '3map-models';

@Component({
  selector: 'app-resource-list',
  template: `
    <div class="header">
      <app-section-label sectionLabel="Resources">
        <app-resource-create />
      </app-section-label>
    </div>
    <div class="resources-list">
      <app-resource-thumbnail
        class="resource-item"
        *ngFor="let res of resources$ | async"
        [resource]="res"
        (removeResource)="removeResource(res)"
        (resourceSelected)="resourceSelected(res)"
      ></app-resource-thumbnail>
    </div>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .resources-list {
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        margin: 25px;
      }

      .resource-item {
        width: 350px;
        margin: 5px;
      }
    `,
  ],
})
export class ResourceListComponent {
  resources$: Observable<Resource[]>;

  constructor(
    private dialog: Dialog,
    private router: Router,
    private store: Store
  ) {
    this.resources$ = this.store.select(
      ResourceSelectors.selectSortedResourceList()
    );
  }

  removeResource(resource: Resource): void {
    this.store.dispatch(
      ResourceActions.removeResource({ id: resource.filename })
    );
  }

  resourceSelected(res: Resource): void {
    this.router.navigate([`admin/resources/${res.filename}`]);
  }
}
