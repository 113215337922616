import { Injectable } from '@angular/core';
import { ApiService } from '@trim-web-apps/api3map';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { NotificationService } from '@trim-web-apps/core';
import { combineLatest, forkJoin, of } from 'rxjs';
import { Record, recordDecoder } from '3map-models';
import * as DeployActions from './deploy.actions';
import { MarkerStyleSelectors } from '../../form/+state';
import { MarkerService } from '../../shared/components/marker-canvas/marker.service';
import { AdminActions, AdminSelectors } from '../../+state';
import { ResourceService } from '../../resource/+state/resource.service';

@Injectable({ providedIn: 'root' })
export class DeployEffects {
  constructor(
    private api: ApiService,
    private action$: Actions,
    private store: Store,
    private markerService: MarkerService,
    private notify: NotificationService,
    private resourceService: ResourceService
  ) {}

  validateRecords$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeployActions.validateRecords),
      withLatestFrom(this.store.select(AdminSelectors.selectProject())),
      switchMap(([, project]) => {
        if (!project) throw Error('Missing Project');

        return this.api.getRawRecordList().pipe(
          take(1),
          map((response) => {
            const recordList = (response as any)?.recordList || [];
            return recordList && Array.isArray(recordList)
              ? (recordList as Record[])
              : [];
          }),
          map((records): Record[] =>
            records.filter((record) => !recordDecoder(project).run(record).ok)
          )
        );
      }),
      map((invalidRecords) =>
        DeployActions.validateRecordsSuccess({ invalidRecords })
      ),
      catchError(() => {
        return of(DeployActions.deployProjectError());
      })
    )
  );

  deployProject$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeployActions.deployProject),
      withLatestFrom(this.store.select(AdminSelectors.selectProject())),
      switchMap(([, project]) => {
        if (!project) throw Error('Missing Project');
        return this.api.uploadProject(project);
      }),
      map((response) =>
        response.success
          ? DeployActions.deployProjectSuccess()
          : DeployActions.deployProjectError()
      ),
      catchError(() => of(DeployActions.deployProjectError()))
    )
  );

  deployMarkers$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeployActions.deployMarkers),
      switchMap(() =>
        combineLatest([
          this.store.select(AdminSelectors.selectProject()),
          this.store.pipe(select(MarkerStyleSelectors.selectMarkerToUpload())),
        ]).pipe(take(1))
      ),
      switchMap(([project, markerEntities]) => {
        if (!project) throw Error('Missing Project');
        if (markerEntities.length === 0) return of([]);

        const markerRequests = markerEntities.map((e) =>
          this.markerService.mergeAndUpload(
            e.imgBase64List,
            e.image,
            project.name
          )
        );
        return forkJoin([...markerRequests]);
      }),
      map(() => DeployActions.deployMarkersSuccess()),
      catchError(() => of(DeployActions.deployMarkersError()))
    )
  );

  deployResources$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeployActions.deployResources),
      withLatestFrom(this.store.select(AdminSelectors.selectProject())),
      switchMap(([, project]) => {
        if (!project) throw Error('Missing Project');

        const fileToUpload = this.resourceService.getFileToUpload();

        if (fileToUpload.length === 0)
          return of(DeployActions.deployResourcesSuccess());

        const requests = fileToUpload.map((item) => {
          return this.api.uploadAdminStaticFile(
            item.blob,
            item.filename,
            project.name
          );
        });

        return forkJoin([...requests]).pipe(
          map(() => {
            this.resourceService.reset();
            return DeployActions.deployResourcesSuccess();
          }),
          catchError(() => of(DeployActions.deployResourcesError()))
        );
      }),
      catchError(() => of(DeployActions.deployResourcesError()))
    )
  );

  deployProjectSuccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeployActions.deployProjectSuccess),
      map(() => DeployActions.deployMarkers())
    )
  );

  deployMarkersSuccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeployActions.deployMarkersSuccess),
      map(() => DeployActions.deployResources())
    )
  );

  deployResourcesSuccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeployActions.deployResourcesSuccess),
      tap(() => this.notify.success('Project deployed!')),
      map(() => AdminActions.setModified({ modified: false }))
    )
  );
}
