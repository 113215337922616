import { Component, Input, OnInit } from '@angular/core';
import { FieldList } from '3map-models';
import { FieldTypeBaseComponent } from '../../../models/FieldTypeBaseComponent';
import { DropdownEvent, DropdownItem } from '@trim-web-apps/map3-ui';

@Component({
  selector: 'app-field-list',
  template: `
    <app-field-name [fieldType]="fieldType"></app-field-name>
    <div class="disabled-select text-ellipsis" *ngIf="!allowEdit">
      {{ fieldList?.text }}
    </div>

    <ui-dropdown
      [itemList]="dropDownItems"
      [noneOptionLabel]="noneOptionLabel"
      [itemActive]="itemActive"
      (itemSelected)="onItemSelected($event)"
    />
    <div class="error" *ngIf="showError">Required field</div>
  `,
  styleUrls: ['../../field-type.base.component.scss'],
})
export class FieldListComponent
  extends FieldTypeBaseComponent
  implements OnInit
{
  @Input() fieldList: FieldList | undefined;

  dropDownItems: DropdownItem<string>[] = [];
  itemActive: DropdownItem<string> | null = null;
  noneOptionLabel?: string;
  showError?: boolean;

  ngOnInit(): void {
    const options = this.fieldType.options || [];
    const activeText = this.fieldList?.text;
    this.dropDownItems = options.map((option) => {
      return { label: option, payload: option };
    });
    if (!this.fieldType.required) this.noneOptionLabel = 'None';
    if (activeText)
      this.itemActive = { label: activeText, payload: activeText };
  }

  onItemSelected(event: DropdownEvent<string>): void {
    const source = event.source;
    const field: FieldList | null =
      source !== null ? { text: source.payload, type: 'LIST' } : null;

    this.showError = false;

    if (this.fieldType.required && source === null) {
      this.showError = true;
      return;
    }

    this.fieldChange.emit({
      fieldType: this.fieldType,
      field,
    });
  }
}
