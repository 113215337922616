import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Form, ProjectUser } from '3map-models';
import { removeElementFromArray } from '@trim-web-apps/core';
import { Store } from '@ngrx/store';
import * as UserActions from '../../+state/user.actions';

export type EnabledUserForm = { form: Form; enabled: boolean };

@Component({
  selector: 'app-user-forms',
  template: `
    <div class="forms-role" *ngIf="data">
      <div class="title">Forms and role ({{ data.userList.length }} Users)</div>

      <div class="row">
        <app-role-picker [user]="user" (roleChange)="onRoleChange($event)" />
      </div>

      <div class="row">
        <app-form-picker
          [userFormList]="enabledForms"
          (toggleForm)="onToggleForm($event)"
        />
      </div>

      <div class="row">
        <ui-btn (click)="onSave()" label="Save" />
      </div>
    </div>
  `,
  styles: [
    `
      .row {
        margin-top: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormsComponent implements OnInit {
  @Input() data?: {
    userList: ProjectUser[];
    formList: Form[];
  };
  @Output() saved = new EventEmitter<void>();

  user?: ProjectUser;
  enabledForms?: EnabledUserForm[];

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.data || this.data.userList.length === 0) return;
    this.user = structuredClone(this.data.userList[0]);
    this.enabledForms = createUserFormList(
      this.data.userList[0],
      this.data.formList
    );
  }

  onRoleChange(role: 'ADMIN' | 'MOD' | 'USER'): void {
    if (this.user) this.user = { ...this.user, role };
  }

  onToggleForm(form: Form): void {
    if (!this.user || !this.enabledForms || !this.data) return;
    const isEnabled = this.user.formList.find((f) => f === form.id);
    const newFormList = isEnabled
      ? removeElementFromArray(this.user.formList, form.id)
      : [...this.user.formList, form.id];

    this.user = { ...this.user, formList: newFormList };
    this.enabledForms = createUserFormList(this.user, this.data.formList);
  }

  onSave(): void {
    if (!this.user || !this.data || this.data.userList.length === 0) return;
    const { role, formList } = this.user;
    const users: ProjectUser[] = this.data.userList.map((user) => {
      return {
        username: user.username,
        role,
        formList,
        preferences: user.preferences,
      };
    });
    this.store.dispatch(UserActions.setUsers({ users }));
    this.saved.emit();
  }
}

function createUserFormList(
  user: ProjectUser,
  formList: Form[]
): EnabledUserForm[] {
  return formList.map((form) => ({
    form,
    enabled: user.formList.includes(form.id),
  }));
}
