import { Component, Input, OnDestroy } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { Project, Resource } from '3map-models';
import { Store } from '@ngrx/store';
import * as ResourceSelectors from '../../+state/resource.selectors';
import { Subscription, switchMap } from 'rxjs';
import { ResourceService } from '../../+state/resource.service';
import { take } from 'rxjs/operators';
import { blobImageToBase64, blobToString } from '@trim-web-apps/core';

@Component({
  selector: 'app-resource-map[resource]',
  template: `
    <div class="pending-op" *ngIf="!resourceLoaded">Fetching Resource</div>
    <map-core (mapReady)="onMapReady($event)" />
  `,
  styles: [
    `
      :host {
        height: 100%;
        width: 100%;
      }

      .pending-op {
        color: var(--bg-color);
        background-color: var(--primary-color);
        font-size: var(--font-size-1);
        text-align: center;
      }
    `,
  ],
})
export class ResourceMapComponent implements OnDestroy {
  @Input() resource?: Resource;
  @Input() project?: Project;
  resourceLoaded?: boolean;
  private map: mapboxgl.Map | undefined;
  private layerIds: string[] = [];
  private storeSub?: Subscription;

  // TODO show alert on big files
  constructor(private store: Store, private resourceService: ResourceService) {}

  onMapReady(map: mapboxgl.Map) {
    this.map = map;
    map.resize();
    const resourceId = this.resource?.filename;
    if (!resourceId) return;
    this.storeSub = this.store
      .select(ResourceSelectors.selectEntityResourceById(resourceId))
      .subscribe(() => {
        this.draw();
      });
  }

  ngOnDestroy(): void {
    this.storeSub?.unsubscribe();
  }

  private async draw(): Promise<void> {
    const resource = this.resource;
    if (!resource || !this.project) return;
    this.resourceService
      .getResource(resource.filename, this.project.name)
      .pipe(
        take(1),
        switchMap((blob) =>
          resource.type === 'GEOJSON'
            ? blobToString(blob)
            : blobImageToBase64(blob)
        )
      )
      .subscribe((data) => {
        this.resourceLoaded = true;
        resource.type === 'GEOJSON'
          ? this.drawGeoJson(data)
          : this.drawImage(data);
      });
  }

  private drawGeoJson(data: string): void {
    const map = this.map;
    const resource = this.resource;
    if (!map || !resource) return;
    console.log('drawGeoJson');
    /**
     'type' property for mapbox.AnyLayer is derived from style
     Examples:
     style: { 'circle-radius': 5 }   => 'circle'
     style: { 'line-color': 'red' }  => 'line'
     style: { 'fill-opacity': 0.5 }  => 'fill'

     Available:
     "symbol" | "circle" | "background" | "fill-extrusion" | "fill" | "heatmap" | "hillshade" | "line" | "raster" | "custom"'

     If style is empty, defaults to 'circle'
     */
    const style = resource.style || {};
    const geoType = Object.keys(style)[0].split('-')[0] || 'circle';
    const geoJsonLayerId = resource.name + '__json';
    const namesLayerId = resource.name + '__names';

    if (!data || !style) return;

    if (this.layerIds.length > 0) {
      this.layerIds.forEach((id) => {
        map.removeLayer(id);
        map.removeSource(id);
      });
      this.layerIds = [];
    }

    this.layerIds = [geoJsonLayerId, namesLayerId];

    const geoJsonLayer: mapboxgl.AnyLayer = {
      id: geoJsonLayerId,
      source: {
        type: 'geojson',
        data: JSON.parse(data),
      },
      type: geoType as any,
      paint: style,
      // paint: {
      //   'circle-radius': 8,
      //   'circle-stroke-width': 2,
      //   'circle-color': 'red',
      //   'circle-stroke-color': 'white',
      // },
    };

    const namesLayer: mapboxgl.AnyLayer = {
      id: namesLayerId,
      source: {
        type: 'geojson',
        data: JSON.parse(data),
      },
      type: 'symbol',
      layout: {
        'text-field': '{name}',
        'text-offset': [0, -1.0],
        'text-size': 14,
      },
    };

    map.addLayer(geoJsonLayer);
    map.addLayer(namesLayer);
  }

  private drawImage(url: string): void {
    const map = this.map;
    const resource = this.resource;
    if (!map || !resource) return;
    console.log('drawImage');
    const coordinates = resource.boundingBox;
    const style = resource.style || {};
    const opacity = style.hasOwnProperty('raster-opacity')
      ? style['raster-opacity']
      : 1;

    if (!this.map || !url || !coordinates || coordinates.length === 0) return;
    this.removeAllLayers();

    const layer: mapboxgl.RasterLayer = {
      id: resource.filename,
      source: {
        type: 'image',
        url, // actually a base64 img string
        coordinates,
      },
      type: 'raster',
      paint: { 'raster-opacity': opacity },
    };

    this.layerIds = [resource.filename];
    this.map.addLayer(layer);
  }

  private removeAllLayers(): void {
    if (this.map && this.layerIds.length > 0) {
      this.layerIds.forEach((id) => {
        this.map?.removeLayer(id);
        this.map?.removeSource(id);
      });
      this.layerIds = [];
    }
  }
}
