import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, from, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '@trim-web-apps/api3map';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { FormSpecific, Project } from '3map-models';
import { DEFAULT_MARKER_BASE64 } from '../../../../project/marker.service';

@Injectable({
  providedIn: 'root',
})
export class MarkerService {
  private _ctx: CanvasRenderingContext2D | undefined;
  private _canvasContainer: HTMLDivElement | undefined;

  constructor(private api: ApiService, private http: HttpClient) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx) this.ctx = ctx;
  }

  set canvasContainer(elem: HTMLDivElement) {
    this._canvasContainer = elem;
  }

  get canvasContainer() {
    if (this._canvasContainer) return this._canvasContainer;
    throw Error('Marker manager canvas container not found');
  }

  set ctx(ctx: CanvasRenderingContext2D) {
    this._ctx = ctx;
  }

  /**
   * If ctx is not defined an error is thrown.
   * Make sure to import MarkerCanvasComponent or provide a valid canvas ctx.
   */
  get ctx(): CanvasRenderingContext2D {
    if (this._ctx) return this._ctx;
    console.error('Marker manager canvas ctx not found');
    throw Error('Marker manager canvas ctx not found');
  }

  getProjectIconsBase64(
    project: Project
  ): Observable<{ specificId: string; imgBase64List: string[] }[]> {
    const specificList: FormSpecific[] = project.formList
      .map((f) => f.specificList)
      .reduce((acc, item) => [...acc, ...item], [])
      .map((specific) => specific);

    if (specificList.length === 0) return of([]);

    const requests = specificList.map((specific) =>
      this.api.getMarkerBlob(specific.markerStyle.image, project.name).pipe(
        switchMap((blob) =>
          from(this.splitImage(blob, specific.markerStyle.imageNumber))
        ),
        catchError(() => {
          return of(
            Array.from(Array(specific.markerStyle.imageNumber)).map(
              () => DEFAULT_MARKER_BASE64
            )
          );
        }),
        map((imgBase64List) => ({ specificId: specific.id, imgBase64List }))
      )
    );

    return forkJoin(requests);
  }

  mergeAndUpload(
    imageBase64List: string[],
    blobName: string,
    projectName: string
  ): Observable<boolean> {
    return this.getMergedBlob(imageBase64List).pipe(
      switchMap((blob) =>
        this.api.uploadAdminStaticFile(blob, blobName, projectName)
      )
    );
  }

  getMergedBlob(base64List: string[]): Observable<Blob> {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw Error('Cannot create canvas element');
    ctx.canvas.height = 150;
    ctx.canvas.width = 150 * base64List.length;
    ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    return forkJoin(base64List.map((img) => from(this.createImage(img)))).pipe(
      tap((htmlImages) => {
        htmlImages.forEach((img, i) =>
          ctx.drawImage(img, 0, 0, 150, 150, 150 * i, 0, 150, 150)
        );
      }),
      switchMap(() => from(getBlob(ctx))),
      filter(notNullOrUndefined)
    );
  }

  private async createImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.src = src;
    });
  }

  async splitImage(blob: Blob, splitInto?: number): Promise<string[]> {
    const src = URL.createObjectURL(blob);
    const image: HTMLImageElement = await new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.src = src;
    });
    const defaultIconBase64 = await this.getStandardMarkerBase64();
    // const defaultIconBase64 = this.getColorizedStandardMarkerBase64();
    const imagesNumber = splitInto || Math.floor(image.naturalWidth / 150);
    this.ctx.canvas.width = 150; // image.naturalWidth;
    this.ctx.canvas.height = 150; // image.naturalHeight;
    const markers = [];
    for (let i = 0; i < imagesNumber; i++) {
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
      this.ctx.drawImage(image, i * 150, 0, 150, 150, 0, 0, 150, 150);
      markers.push(
        await new Promise<string>((resolve) => {
          if (i * 150 >= image.naturalWidth) {
            resolve(defaultIconBase64);
          } else {
            resolve(this.ctx.canvas.toDataURL());
          }
        })
      );
    }
    return markers;
  }

  private getStandardMarker(): Observable<Blob> {
    return this.http.get('/assets/marker.png', { responseType: 'blob' });
  }

  private getColorizedStandardMarkerBase64(): string {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    if (!this.ctx) return DEFAULT_MARKER_BASE64;
    this.ctx.beginPath();
    this.ctx.arc(75, 75, 75, 0, Math.PI * 2, true);
    this.ctx.fillStyle = `#${randomColor}`;
    this.ctx.fill();
    this.ctx.closePath();

    this.ctx.beginPath();
    this.ctx.arc(75, 75, 50, 0, Math.PI * 2, true);
    this.ctx.fillStyle = '#ffffff';
    this.ctx.fill();
    this.ctx.closePath();

    return this.ctx.canvas.toDataURL();
  }

  private async getStandardMarkerBase64(): Promise<string> {
    const defaultBlob = await lastValueFrom(this.getStandardMarker());
    return URL.createObjectURL(defaultBlob);
    // TODO check if revokeObjectUrl reduces memory leaks
    // URL.revokeObjectURL(src)
    // return src;
  }

  /**
   * @deprecated
   */
  // async getMarkerImagesBase64(
  //   specific: FormSpecific,
  //   projectName: string
  // ): Promise<string[]> {
  //   console.log(specific.markerStyle.image);
  //   const markerBlob = await this.api
  //     .getMarkerBlob(specific.markerStyle.image, projectName)
  //     .toPromise();
  //   console.log('blob!!');
  //   return await this.splitImage(markerBlob);
  // }

  /**
   * @deprecated
   */
  // async getItemMarkerList(
  //   specific: FormSpecific,
  //   projectName: string
  // ): Promise<MarkerItem[]> {
  //   const markerStyle = specific.markerStyle;
  //   const iconFieldType = markerStyle.iconFieldType;
  //   const markerBlob = await this.api
  //     .getMarkerBlob(markerStyle.image, projectName)
  //     .toPromise();
  //   const base64ImageList = await this.splitImage(
  //     markerBlob,
  //     markerStyle.imageNumber
  //   );
  //   const markerItemList: MarkerItem[] = [
  //     {
  //       fieldName: 'Default',
  //       imageBase64: base64ImageList[0],
  //     },
  //   ];
  //
  //   if (iconFieldType !== undefined) {
  //     const options =
  //       specific.questions.find((q) => q.name === iconFieldType)?.options || [];
  //     return markerItemList.concat(
  //       options.map((question, index) => {
  //         return {
  //           fieldName: question,
  //           imageBase64: base64ImageList[index + 1],
  //         };
  //       })
  //     );
  //   }
  //   return markerItemList;
  // }
}

function getBlob(ctx: CanvasRenderingContext2D): Promise<Blob | null> {
  return new Promise((resolve) => {
    ctx.canvas.toBlob((p) => resolve(p));
  });
}
