import { createAction, props } from '@ngrx/store';
import { FieldType } from '3map-models/dist/lib/model';

export const SET_MARKER_STYLE_QUESTION = createAction(
  '[ Project ] Set MarkerStyle Question',
  props<{ specificId: string; question: FieldType | null }>()
);

export const SET_IMG_BASE64 = createAction(
  '[ Project ] Set Image Base64',
  props<{ specificId: string; base64: string; index: number }>()
);

export const SET_BASE64_IMAGE_LIST = createAction(
  '[ Project ] Fetch Marker Image Success',
  props<{ specificId: string; imgBase64List: string[] }>()
);
