import { createReducer, on } from '@ngrx/store';
import { getDefaultTimestep } from '../utils/weather.functions';
import * as Actions from './weather.actions';
import { WeatherPreferences } from '../weather.preferences';
import {
  ChartData,
  WeatherLayer,
  WeatherModel,
  WeatherPopupData,
  WeatherTimestep,
} from '@trim-web-apps/weather-models';
import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from '@ngrx/entity';
import { notNullOrUndefined } from '@trim-web-apps/core';

const weatherPref = new WeatherPreferences();

export interface State {
  popups: EntityState<WeatherPopupData>;
  models: EntityState<WeatherModel>;
  selectedLngLat: { lng: number; lat: number } | null;
  utcOffset: string;
  mapBoundingArray: number[];
}

export const popupAdapter: EntityAdapter<WeatherPopupData> =
  createEntityAdapter({ selectId: (p: WeatherPopupData) => p.modelId });

export const modelAdapter: EntityAdapter<WeatherModel> = createEntityAdapter();

const initialState: State = {
  popups: popupAdapter.getInitialState(),
  models: modelAdapter.getInitialState(),
  selectedLngLat: null,
  utcOffset: weatherPref.getUtcOffset(),
  mapBoundingArray: [],
};

export const weatherReducer = createReducer(
  initialState,
  /** GLOBAL */
  on(Actions.reset, (): State => initialState),
  on(Actions.setSelectedLngLat, (state, { selectedLngLat }): State => {
    if (selectedLngLat === null) {
      return {
        ...state,
        selectedLngLat,
        models: modelAdapter.map(
          (model) => ({ ...model, chartData: [] }),
          state.models
        ),
      };
    }
    return { ...state, selectedLngLat };
  }),
  on(
    Actions.setMapBoundingArray,
    (state, { mapBoundingArray }): State => ({
      ...state,
      mapBoundingArray,
    })
  ),
  on(Actions.setUtcOffset, (state, action): State => {
    weatherPref.setUtcOffset(action.utcOffset);
    return { ...state, utcOffset: weatherPref.getUtcOffset() };
  }),

  /** WEATHER MODEL  */
  on(
    Actions.fetchModelSuccess,
    (state, { model }): State => ({
      ...state,
      models: modelAdapter.upsertOne(model, state.models),
    })
  ),

  on(
    Actions.fetchAllModelSuccess,
    (state, { modelList }): State => ({
      ...state,
      models: modelAdapter.setAll(modelList, state.models),
    })
  ),

  on(Actions.enableWeatherModel, (state, action): State => {
    const update: Update<WeatherModel> = {
      id: action.modelId,
      changes: { modelEnabled: true },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.disableWeatherModel, (state, { modelId }): State => {
    const enabledModels = state.models.ids
      .map((id) => state.models.entities[id])
      .filter((model) => model?.modelEnabled)
      .filter(notNullOrUndefined);

    /**
     * Issue: Model popup on Map is only displayed if there's at least one Model enabled.
     * When all Models are removed popup will disappear BUT `selectedLngLat` will still have
     * the last stored value (last Map click coordinates). Therefore, when user enable a new
     * Model a popup will be rendered without user interaction (on the last coords).
     *
     * Quick&dirty solution: set `selectedLngLat` to NULL if there's only ONE Model (which will be removed
     * at the end of this function) - on next `enableWeatherModel` action, the `selectedLngLat` will be
     * NULL and popup will not appear.
     * */
    const selectedLngLat =
      enabledModels.length <= 1 ? null : state.selectedLngLat;

    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { modelEnabled: false },
    };
    const modelState = modelAdapter.updateOne(update, state.models);
    const popupState = popupAdapter.removeOne(modelId, state.popups);

    return { ...state, selectedLngLat, models: modelState, popups: popupState };
  }),

  on(Actions.setInitime, (state, { modelId, initime }): State => {
    const model = state.models.entities[modelId];
    const layer = model?.layers.find(
      (l: WeatherLayer) => l.id === model.selectedLayerId
    );
    return layer === undefined
      ? state
      : {
          ...state,
          models: modelAdapter.updateOne(
            {
              id: modelId,
              changes: {
                initime: initime,
                timestep: getDefaultTimestep(layer, initime),
              },
            },
            state.models
          ),
        };
  }),

  on(Actions.setLayer, (state, { modelId, layer }): State => {
    return setLayer(state, layer, modelId);
  }),

  on(Actions.setLayerById, (state, { modelId, layerId }): State => {
    const model = state.models.entities[modelId];
    if (!model) return state;

    const layer = model.layers.find((l: WeatherLayer) => l.id === layerId);
    if (!layer) return state;

    return setLayer(state, layer, modelId);
  }),

  on(Actions.setTimestep, (state, { modelId, timestep }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { timestep },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.setDateRange, (state, { modelId, dateRange }): State => {
    const model = state.models.entities[modelId];
    if (!model) return state;
    const { from, to } = dateRange;
    // swap from-to values to keep from < to
    const orderedDateRange = from > to ? { from: to, to: from } : { from, to };
    // check if timestep is contained in new date range
    const timestep =
      model.timestep >= orderedDateRange.from &&
      model.timestep <= orderedDateRange.to
        ? model.timestep // yep, keep it
        : orderedDateRange.from; // nope, set it to range.from

    return {
      ...state,
      models: modelAdapter.updateOne(
        {
          id: modelId,
          changes: { dateRange: orderedDateRange, timestep },
        },
        state.models
      ),
    };
  }),

  on(Actions.setModelVisibility, (state, { modelId, visible }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { visible },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.toggleModelVisibility, (state, { modelId }): State => {
    const entity = state.models.entities[modelId];
    if (!entity) return state;
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { visible: !entity.visible },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.setChartEnabled, (state, { modelId }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { chartEnabled: true },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.setChartDisabled, (state, { modelId }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { chartEnabled: false, chartData: [] },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.setChartData, (state, { modelId, chartData }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { chartData },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.setInterpolate, (state, { modelId, interpolate }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { interpolate },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.setWindStyle, (state, { modelId, windStyle }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { windStyle },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.tiffLoading, (state, { modelId }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { tiffLoading: true, tiffFetchError: false },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.tiffLoadingSuccess, (state, { modelId }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { tiffLoading: false, tiffFetchError: false },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(Actions.tiffLoadingError, (state, { modelId }): State => {
    const update: Update<WeatherModel> = {
      id: modelId,
      changes: { tiffLoading: false, tiffFetchError: true },
    };
    return { ...state, models: modelAdapter.updateOne(update, state.models) };
  }),

  on(
    Actions.setChartDataVisibility,
    (state, { modelId, data, visibility }): State => {
      const model = state.models.entities[modelId];
      if (!model) return state;

      // each ChartData has a unique `legend`, so use it as a key to find which one should be updated
      const newChartData = model.chartData.map((currentData: ChartData) =>
        currentData.legend === data.legend
          ? {
              ...currentData,
              visible: visibility,
            }
          : currentData
      );
      return {
        ...state,
        models: modelAdapter.updateOne(
          {
            id: modelId,
            changes: { chartData: newChartData },
          },
          state.models
        ),
      };
    }
  ),

  /** POPUP DATA */
  on(Actions.addPopupData, (state, { popupData }): State => {
    const popupState =
      popupData.data === null
        ? popupAdapter.removeOne(popupData.modelId, state.popups)
        : popupAdapter.upsertOne(popupData, state.popups);
    return { ...state, popups: popupState };
  })
  // on(Actions.disableWeatherModel, (state, { modelId }): State => {
  //   const weatherModelCount = state.models.ids.length;
  //   const selectedLngLat = weatherModelCount <= 1 ? state.selectedLngLat : null;
  //   console.log(selectedLngLat, weatherModelCount);
  //   return {
  //     ...state,
  //     popups: popupAdapter.removeOne(modelId, state.popups),
  //     selectedLngLat,
  //   };
  // })
);

function setLayer(state: State, layer: WeatherLayer, modelId: string): State {
  const model = state.models.entities[modelId];
  if (!model) return state;

  const currentLayer = model.layers.find(
    (l: WeatherLayer) => l.id === model.selectedLayerId
  );
  if (!currentLayer) return state;

  const timestepIndex = layer.timestepList
    .map((ts: WeatherTimestep) => ts.initime)
    .indexOf(model.initime);

  const initime =
    timestepIndex === -1 // check if initime is present in this layer
      ? layer.timestepList[0].initime // nope, set default
      : model.initime; // yep, keep the old one

  const timestep =
    layer.timestepList
      .find((ts: WeatherTimestep) => ts.initime === initime)
      ?.timesteps.indexOf(model.timestep) === -1
      ? getDefaultTimestep(layer, initime)
      : model.timestep;

  const dateRange =
    currentLayer.timestepType.type !== layer.timestepType.type
      ? layer.defaultDateRange
      : model.dateRange;

  return {
    ...state,
    models: modelAdapter.updateOne(
      {
        id: modelId,
        changes: {
          selectedLayerId: layer.id,
          timestep,
          initime,
          dateRange,
        },
      },
      state.models
    ),
  };
}
