import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectComponent } from './app-project.component';
import { MapBoxModule } from './map/mapbox.module';
import { SharedModule } from './shared/shared.module';
import { SidenavModule } from './sidenav/sidenav.module';
import { RecordModule } from './record/record.module';
import { Project2Module } from './project/project.module';
import { TableModule } from './table/table.module';
import { FeatureHistoryModule } from './feature-history/feature-history.module';
import { RecordEditorModule } from './record-editor/record-editor.module';
import { MapResourceModule } from './map-resource/map-resource.module';
import { MediaGalleryModule } from './media-gallery/media-gallery.module';
import { CoreModule } from '@trim-web-apps/core';

@NgModule({
  declarations: [ProjectComponent],
  imports: [
    CommonModule,
    Project2Module,
    MapBoxModule,
    SharedModule,
    SidenavModule,
    RecordModule,
    FeatureHistoryModule,
    TableModule,
    RecordEditorModule,
    MapResourceModule,
    MediaGalleryModule,
    CoreModule,
  ],
})
export class AppProjectModule {}
