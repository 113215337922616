import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { Store } from '@ngrx/store';
import { WeatherActions, WeatherSelectors } from '../../+state';
import { WeatherItemData } from './weather-item-data';

@Component({
  selector: 'weather-core-item',
  template: `
    <ng-container *ngIf="weatherItemData$ | async as weatherItemData">
      <weather-core-item-desktop
        *ngIf="!isMobile"
        [weatherItemData]="weatherItemData"
        (removeModel)="removeModel($event)"
        (toggleVisibility)="toggleVisibility($event)"
        (toggleChartVisibility)="toggleChartVisibility(weatherItemData)"
        (showInfo)="showInfo.emit($event)"
      ></weather-core-item-desktop>
      <weather-core-item-mobile
        *ngIf="isMobile"
        [weatherItemData]="weatherItemData"
        (removeModel)="removeModel($event)"
        (toggleVisibility)="toggleVisibility($event)"
        (toggleChartVisibility)="toggleChartVisibility(weatherItemData)"
      ></weather-core-item-mobile>
    </ng-container>
  `,
})
export class WeatherItemComponent implements OnInit {
  @Input() weatherModelId?: string;
  @Input() showRemoveIcon?: boolean;
  @Input() isMobile?: boolean;
  @Output() showInfo: EventEmitter<WeatherModel> =
    new EventEmitter<WeatherModel>();

  weatherItemData$?: Observable<WeatherItemData | null>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.weatherModelId) return;
    this.weatherItemData$ = combineLatest([
      this.store.select(WeatherSelectors.selectById(this.weatherModelId)),
      this.store.select(WeatherSelectors.selectLayer(this.weatherModelId)),
    ]).pipe(
      map(([model, selectedLayer]) => {
        if (!model || !selectedLayer) return null;
        return {
          model,
          selectedLayer,
          chartModes: ['Compact', 'Standard', 'Combined'],
          chartModeSelected: 0,
          showRemoveIcon: !!this.showRemoveIcon,
        };
      })
    );
  }

  removeModel(modelId: string): void {
    this.store.dispatch(WeatherActions.disableWeatherModel({ modelId }));
  }

  toggleVisibility(modelId: string): void {
    this.store.dispatch(WeatherActions.toggleModelVisibility({ modelId }));
  }

  toggleChartVisibility(data: WeatherItemData): void {
    const modelId = data.model.id;
    const isChartEnabled = data.model.chartEnabled;

    const action = isChartEnabled
      ? WeatherActions.setChartDisabled({ modelId })
      : WeatherActions.setChartEnabled({ modelId });
    this.store.dispatch(action);
  }
}
