import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <core-notification-list></core-notification-list>
  `,
})
export class AppComponent {}
