import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Form } from '3map-models';

@Component({
  selector: 'app-table-tabs',
  template: `
    <div class="table-tabs-wrapper">
      <div class="tabs-list">
        <div
          class="tab text-ellipsis"
          *ngFor="let ds of datasetList"
          [class.active]="ds.active"
          (click)="onDatasetSelected(ds)"
        >
          {{ ds.form.name }}
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .table-tabs-wrapper {
        border-bottom: 1px solid var(--border);
        height: 40px;
        padding: 0 15px;
        /*box-shadow: inset 0 -0.5px 10px rgba(0, 0, 0, 0.16);*/
        position: relative;
      }

      .tabs-list {
        max-width: calc(100% - 70px);
        position: absolute;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        z-index: 1;
        background-color: #ffffff;
        top: 10px;
      }

      .tab {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 5px 15px 0 15px;
        cursor: pointer;
        height: 30px;
        border-bottom: 1px solid var(--border);
        /*box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.16);*/
        max-width: 250px;
        color: var(--grey);
      }

      .active {
        border: 1px solid var(--border);
        border-bottom: none;
        box-shadow: none;
        font-weight: bold;
        color: #333333;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTabsComponent {
  @Input() datasetList: { form: Form; active: boolean }[] = [];
  @Output() datasetSelected: EventEmitter<string> = new EventEmitter();

  onDatasetSelected(dataset: { form: Form; active: boolean }): void {
    if (!dataset.active) this.datasetSelected.emit(dataset.form.id);
  }
}
