import { Component, Input } from '@angular/core';
import { FormDataset } from '../../+models/form-dataset.model';
import { Store } from '@ngrx/store';
import { selectMapDatasetList } from '../../+state/map.selectors';
import {
  getIconIdListFromSpecific,
  MarkerService,
} from '../../../marker.service';
import { map, Observable } from 'rxjs';

type LegendItem = {
  specificName: string;
  icons: { question: string; bitmap?: ImageBitmap }[];
};

@Component({
  selector: 'app-map-form-card-legend',
  template: `
    <ng-container *ngIf="legendItems$ | async as legendItems">
      <div class="legend-wrapper" *ngIf="legendItems.length > 0">
        <ui-expansion-panel [shadow]="true" [collapsed]="true">
          <div header><div class="label">Legend</div></div>
          <div class="legend-card-content" content>
            <div class="legend-item" *ngFor="let item of legendItems">
              <div class="specific-name text-ellipsis">
                {{ item.specificName }}
              </div>
              <div class="icon-and-label" *ngFor="let icon of item.icons">
                <ng-container *ngIf="icon.bitmap">
                  <app-marker-icon
                    [bitmap]="icon.bitmap"
                    [widthPx]="25"
                  ></app-marker-icon>
                  <div class="question-name text-ellipsis">
                    {{ icon.question }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ui-expansion-panel>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .legend-wrapper {
        width: 350px;
      }

      .specific-name {
        font-weight: var(--font-weight-3);
        text-align: center;
      }

      .label {
        font-weight: var(--font-weight-3);
        font-size: var(--font-size-2);
      }

      .legend-card-content {
        max-height: 40vh;
        overflow: auto;
      }

      .icon-and-label {
        display: flex;
        align-items: baseline;
        margin-bottom: var(--spacing-2);
      }

      .icon-and-label:last-child {
        margin-bottom: 0;
      }

      .question-name {
        margin-left: var(--spacing-2);
      }
    `,
  ],
})
export class FormCardLegendComponent {
  @Input() formDatasetList: FormDataset[] = [];
  legendItems$: Observable<LegendItem[]>;
  expanded: boolean | undefined;

  constructor(private store: Store, private markerService: MarkerService) {
    this.legendItems$ = this.store.select(selectMapDatasetList()).pipe(
      map((dsList) => {
        return dsList
          .map((dataset) => {
            const enabledSpecificIds = dataset.dataset.specificIds;
            const specificList = dataset.form.specificList;
            return specificList.filter((specific) =>
              enabledSpecificIds.includes(specific.id)
            );
          })
          .reduce((acc, item) => [...acc, ...item], [])
          .map((specific): LegendItem => {
            const questIconList = getIconIdListFromSpecific(specific);
            const icons = questIconList.map((questIcon) => ({
              question: questIcon.question,
              bitmap: this.markerService.markers.get(questIcon.iconId),
            }));

            return { specificName: specific.name, icons };
          });
      })
    );
  }
}
