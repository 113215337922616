import { RAINFALL_1DAY } from '../../weather-layers/weather.layers';
import {
  createTimestepListGpm,
  modelTemplate,
} from '../weather-model.factory.utils';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createGfsHistory(
  tsDay: number[],
  tsMonth: number[]
): WeatherModel {
  const timestepListDay = createTimestepListGpm(tsDay, false);
  const timestepTypeDay: TimestepType = { type: 'DAYS', interval: 1 };

  const l = timestepListDay[0].timesteps.length;
  const defaultDateRangeDay = {
    from:
      l > 30
        ? timestepListDay[0].timesteps[l - 30]
        : timestepListDay[0].timesteps[0],
    to: timestepListDay[0].timesteps[l - 1],
  };

  const timestepListMonth = createTimestepListGpm(tsMonth, true);
  const timestepTypeMonth: TimestepType = { type: '1MONTH', interval: 1 };
  const defaultDateRangeMonth = {
    from: timestepListMonth[0].timesteps[
      timestepListMonth[0].timesteps.length - 12
    ],
    to: timestepListMonth[0].timesteps[
      timestepListMonth[0].timesteps.length - 1
    ],
  };

  const gfsLayers: WeatherLayer[] = [
    {
      ...RAINFALL_1DAY,
      timestepType: timestepTypeDay,
      timestepList: timestepListDay,
      defaultDateRange: defaultDateRangeDay,
    },
    // {...RAINFALL_MONTHLY, timestepType: timestepTypeMonth, timestepList: timestepListMonth, defaultDateRange: defaultDateRangeMonth},
  ];

  return {
    ...modelTemplate,
    id: 'GFS_HISTORY',
    label: 'GFS_HISTORY',
    category: 'MONITORING',
    layers: gfsLayers,
    initime: gfsLayers[0].timestepList[0].initime,
    timestep: gfsLayers[0].timestepList[0].timesteps[0],
    hasChart: true,
    selectedLayerId: gfsLayers[0].id,
    dateRange: defaultDateRangeDay,
    bbox: [
      [-180, 90],
      [180, 90],
      [180, -90],
      [-180, -90],
      [-180, 90],
    ],
    info: {
      resolution: '0.25°',
      coverage: 'Global',
      url: 'https://www.ncei.noaa.gov/products/weather-climate-models/global-forecast',
      credits: 'NOAA',
    },
  };
}
