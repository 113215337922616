import { createSelector } from '@ngrx/store';
import { notNullOrUndefined } from '@trim-web-apps/core';
import * as fromRoot from '../project.selectors';

export const selectState = createSelector(
  fromRoot.selectProjectState,
  (state) => state.webhooks
);

export const selectWebhooks = () => {
  return createSelector(selectState, (hooks) =>
    Object.keys(hooks.entities)
      .map((k) => hooks.entities[k])
      .filter(notNullOrUndefined)
  );
};
