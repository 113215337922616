import { createAction, props } from '@ngrx/store';
import { Project } from '3map-models';
import { State } from '@trim-web-apps/project-core';

export const RESET = createAction('[ Project Core ] Reset');

export const INIT_PROJECT = createAction(
  '[ Project Core ] Init Project',
  props<{
    project: Project;
    icons: { specificId: string; imgBase64List: string[] }[];
  }>()
);

export const RESTORE_PROJECT_STATE = createAction(
  '[ Project Core ] Restore Project State',
  props<{ projectState: State }>()
);

// const all = union({RESET, FETCH_PROJECT, FETCH_PROJECT_SUCCESS});
// export type LoginActionsUnion = typeof all;
