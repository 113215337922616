import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>('auth');

export const selectAdminToken = () =>
  createSelector(selectAuthState, (state) => state.adminToken);

export const selectProjectToken = () =>
  createSelector(selectAuthState, (state) => state.projectToken);

export const selectIsAdminLogged = () =>
  createSelector(selectAdminToken(), (token) => token !== null);

export const selectIsProjectLogged = () =>
  createSelector(selectProjectToken(), (token) => token !== null);

export const selectPendingReq = () =>
  createSelector(selectAuthState, (state) => state.pendingRequest);

export const selectErrorRequest = () =>
  createSelector(selectAuthState, (state) => state.errorRequest);

export const selectCollaboration = () =>
  createSelector(selectAuthState, (state) => state.col);

export const selectProjectName = () =>
  createSelector(selectCollaboration(), (col) => col?.projectName);

export const selectAuthUsername = () =>
  createSelector(selectAuthState, (state) => state.username);

export const selectIsAdmin = () =>
  createSelector(
    selectAuthState,
    (state) => state.col !== null && state.col.role === 'ADMIN'
  );

export const selectRole = () =>
  createSelector(selectAuthState, (state) => state.col?.role || null);
