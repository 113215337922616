import { BehaviorSubject, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { StorePersistence } from './StorePersistence';

export class Store<T> {
  private storeSub: BehaviorSubject<T>;
  private readonly persistence: StorePersistence<T> | undefined;
  private initState: T;

  constructor(private initialState: T, private persistenceKey: string | null) {
    this.initState = initialState;
    if (persistenceKey !== null) {
      this.persistence = new StorePersistence<T>(persistenceKey);
    }

    const stored = this.persistence ? this.persistence.get() : null;
    this.storeSub = new BehaviorSubject<T>(
      stored !== null ? stored : initialState
    );

    if (persistenceKey !== null) {
      this.storeSub.pipe(skip(1)).subscribe((value) => {
        this.persistence?.save(value);
      });
    }
  }

  /**
   * Return current Store value.
   * Note: return only current snapshot, prefer {@link getState} if possible.
   */
  getValue(): T {
    return this.storeSub.value;
  }

  /**
   * Update current Store value, then call next()
   * @param partial: Partial<T>
   */
  patchState(partial: Partial<T>): void {
    this.storeSub.next({ ...this.storeSub.value, ...partial });
  }

  setState(state: T): void {
    this.storeSub.next(state);
  }

  /**
   * Return current Store value as observable
   */
  getState(): Observable<T> {
    return this.storeSub.asObservable();
  }

  /**
   * Reset Store to initial value and clear persistence value.
   * TODO init persistence to default?
   */
  reset(): void {
    this.storeSub.next(this.initState);
    this.persistence?.clear();
  }
}
