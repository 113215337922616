import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import { State } from '../+state/weather.reducer';
import {
  ChartData,
  TimeUnit,
  WeatherLayer,
  WeatherModel,
  WeatherPopupData,
} from '@trim-web-apps/weather-models';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { WeatherActions, WeatherSelectors } from '../+state';

@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  constructor(private store: Store<State>) {}

  getUtcOffsetList(): string[] {
    return momentTz.tz
      .names()
      .map((name) => moment().tz(name).format('Z'))
      .filter((tz, index, array) => array.indexOf(tz) === index)
      .sort();
  }

  reset(): void {
    this.store.dispatch(WeatherActions.reset());
  }

  initModels(): void {
    this.store.dispatch(WeatherActions.fetchAllModels());
  }

  initModel(modelId: string, setEnabled?: boolean): void {
    this.store.dispatch(
      WeatherActions.fetchModel({ modelId, setEnabled: !!setEnabled })
    );
  }

  getWeatherModelsList(): Observable<WeatherModel[]> {
    return this.store.select(WeatherSelectors.selectAll());
  }

  getWeatherModelListByCategory(): Observable<{
    forecastList: WeatherModel[];
    monitoringList: WeatherModel[];
  }> {
    return this.store.select(WeatherSelectors.selectWeatherModelsByCategory());
  }

  getWeatherModel(modelId: string): Observable<WeatherModel> {
    return this.getWeatherModelsList().pipe(
      map((models) => models.find((m) => m.id === modelId)),
      filter(notNullOrUndefined)
    );
  }

  addWeatherModel(modelId: string): void {
    this.store.dispatch(WeatherActions.enableWeatherModel({ modelId }));
  }

  fetchWeatherModel(modelId: string): void {
    this.store.dispatch(WeatherActions.fetchModel({ modelId }));
  }

  getEnabledModels(): Observable<WeatherModel[]> {
    return this.store.select(WeatherSelectors.selectEnabledWeatherModels());
  }

  setLngLat(lngLat: { lng: number; lat: number }): void {
    this.store.dispatch(
      WeatherActions.setSelectedLngLat({ selectedLngLat: lngLat })
    );
  }

  getLngLat(): Observable<{ lng: number; lat: number } | null> {
    return this.store.select(WeatherSelectors.selectSelectedLngLat());
  }

  getInitimeList(modelId: string): Observable<number[]> {
    return this.store.select(WeatherSelectors.selectInitimeList(modelId));
  }

  getInitime(modelId: string): Observable<number> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.initime));
  }

  getSelectedLayer(modelId: string): Observable<WeatherLayer> {
    return this.store
      .select(WeatherSelectors.selectLayer(modelId))
      .pipe(filter(notNullOrUndefined));
  }

  getLayerList(modelId: string): Observable<WeatherLayer[]> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.layers));
  }

  setInitime(modelId: string, initime: number): void {
    this.store.dispatch(WeatherActions.setInitime({ modelId, initime }));
  }

  setLayer(modelId: string, layer: WeatherLayer): void {
    this.store.dispatch(WeatherActions.setLayer({ modelId, layer }));
  }

  getTimestep(modelId: string): Observable<number> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.timestep));
  }

  getTimestepList(modelId: string): Observable<number[]> {
    return this.store.select(WeatherSelectors.selectTimestepList(modelId));
  }

  getFullTimestepList(modelId: string): Observable<number[]> {
    return this.store.select(WeatherSelectors.selectFullTimestepList(modelId));
  }

  setTimestep(modelId: string, timestep: number): void {
    this.store.dispatch(WeatherActions.setTimestep({ modelId, timestep }));
  }

  getTimestepFullListAsTimeUnit(modelId: string): Observable<TimeUnit[]> {
    return this.store.select(
      WeatherSelectors.selectTimestepFullListAsTimeUnit(modelId)
    );
  }

  getMinMaxTimestepAsTimeUnit(
    modelId: string
  ): Observable<{ min: TimeUnit; max: TimeUnit }> {
    return this.store
      .select(WeatherSelectors.selectMinMaxTimestepAsTimeUnit(modelId))
      .pipe(filter(notNullOrUndefined));
  }

  getDateRangeAsTimeUnit(
    modelId: string
  ): Observable<{ from: TimeUnit; to: TimeUnit }> {
    return this.store
      .select(WeatherSelectors.selectDateRangeAsTimeUnit(modelId))
      .pipe(filter(notNullOrUndefined));
  }

  getDateRange(modelId: string): Observable<{ from: number; to: number }> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.dateRange));
  }

  getUtcOffset(): Observable<string> {
    return this.store.select(WeatherSelectors.selectUtcOffset());
  }

  setUtcOffset(utcOffset: string | null): void {
    this.store.dispatch(WeatherActions.setUtcOffset({ utcOffset }));
  }

  setDateRange(modelId: string, dateRange: { from: number; to: number }): void {
    this.store.dispatch(WeatherActions.setDateRange({ modelId, dateRange }));
  }

  getModelVisibility(modelId: string): Observable<boolean> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.visible));
  }

  getChartEnable(modelId: string): Observable<boolean> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.chartEnabled));
  }

  setModelVisibility(modelId: string, visible: boolean): void {
    this.store.dispatch(
      WeatherActions.setModelVisibility({ modelId, visible })
    );
  }

  toggleModelVisibility(modelId: string): void {
    this.store.dispatch(WeatherActions.toggleModelVisibility({ modelId }));
  }

  setChartEnabled(modelId: string, chartEnabled: boolean): void {
    chartEnabled
      ? this.store.dispatch(WeatherActions.setChartEnabled({ modelId }))
      : this.store.dispatch(WeatherActions.setChartDisabled({ modelId }));
  }

  setChartDataVisibility(
    modelId: string,
    data: ChartData,
    visibility: boolean
  ): void {
    this.store.dispatch(
      WeatherActions.setChartDataVisibility({ modelId, data, visibility })
    );
  }

  getModelsWithChartEnabled(): Observable<WeatherModel[]> {
    return this.store.select(WeatherSelectors.selectModelsWithChartEnabled());
  }

  getChartData(modelId: string): Observable<ChartData[]> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.chartData));
  }

  setWindStyle(modelId: string, windStyle: string): void {
    this.store.dispatch(WeatherActions.setWindStyle({ modelId, windStyle }));
  }

  setInterpolate(modelId: string, interpolate: string): void {
    this.store.dispatch(
      WeatherActions.setInterpolate({ modelId, interpolate })
    );
  }

  getInterpolate(modelId: string): Observable<string> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.interpolate));
  }

  getWindStyle(modelId: string): Observable<string> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.windStyle));
  }

  removeWeatherModel(modelId: string): void {
    this.store.dispatch(WeatherActions.disableWeatherModel({ modelId }));
  }

  // getPopupData(): Observable<WeatherPopupData[]> {
  //   return this.store.select(WeatherSelectors.selectAllPopupData());
  // }

  addPopupData(popupData: WeatherPopupData): void {
    this.store.dispatch(WeatherActions.addPopupData({ popupData }));
  }

  setMapBoundingArray(bbox: number[]): void {
    this.store.dispatch(
      WeatherActions.setMapBoundingArray({ mapBoundingArray: bbox })
    );
  }

  getTiffUrl(modelId: string): Observable<string> {
    return this.store
      .select(WeatherSelectors.selectTiffUrl(modelId))
      .pipe(filter(notNullOrUndefined));
  }

  setTiffLoading(modelId: string): void {
    this.store.dispatch(WeatherActions.tiffLoading({ modelId }));
  }

  setTiffLoadingSuccess(modelId: string): void {
    this.store.dispatch(WeatherActions.tiffLoadingSuccess({ modelId }));
  }

  getTiffLoading(modelId: string): Observable<boolean> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.tiffLoading));
  }

  getHasChart(modelId: string): Observable<boolean> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.hasChart));
  }

  setTiffFetchError(modelId: string): void {
    this.store.dispatch(WeatherActions.tiffLoadingError({ modelId }));
  }

  getTiffFetchError(modelId: string): Observable<boolean> {
    return this.getWeatherModel(modelId).pipe(map((m) => m.tiffFetchError));
  }
}
