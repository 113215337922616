import { createAction, props } from '@ngrx/store';
import { EntityResource } from '@trim-web-apps/project-core';

export const CREATE_RESOURCE = createAction(
  '[ Project ] Create Resource',
  props<{ entity: EntityResource }>()
);

export const REMOVE_RESOURCE = createAction(
  '[ Project ] Remove Resource',
  props<{ id: string }>()
);

export const SET_RESOURCE_NAME = createAction(
  '[ Project ] Set Resource Name',
  props<{ id: string; name: string }>()
);

export const SET_RESOURCE_DATA = createAction(
  '[ Project ] Set Resource Data',
  props<{ id: string; resourceData: string | null }>()
);

export const SET_RESOURCE_STYLE = createAction(
  '[ Project ] Set Resource Style',
  props<{ id: string; style: any }>()
);

export const SET_RESOURCE_BBOX = createAction(
  '[ Project ] Set Resource BBOX',
  props<{ id: string; boundingBox: number[][] }>()
);
