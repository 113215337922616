import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  faImage,
  faPen,
  faVectorSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Resource } from '3map-models';

@Component({
  selector: 'app-resource-thumbnail[resource]',
  template: `
    <ng-container *ngIf="resource">
      <div class="resource-thumbnail" (click)="onResourceSelected($event)">
        <div class="header">
          <div class="icon">
            <fa-icon
              [icon]="iconImg"
              *ngIf="resource.type === 'IMAGE'"
              matTooltip="Image Resource"
            ></fa-icon>
            <fa-icon
              [icon]="iconGeo"
              *ngIf="resource.type === 'GEOJSON'"
              matTooltip="GeoJSON Resource"
            ></fa-icon>
          </div>
          <div class="actions" (click)="$event.stopImmediatePropagation()">
            <core-double-confirm-inline
              (confirm)="removeResource.emit(resource)"
            ></core-double-confirm-inline>
          </div>
        </div>

        <div class="name">{{ resource.name }}</div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .resource-thumbnail {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border-radius: 3px;
        height: 150px;
        background-color: #1e4294;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        padding-left: 10px;
        color: #fff;
        background-color: rgba(255, 255, 255, 0.15);
      }

      .icon {
        font-size: 1.2em;
      }

      .name {
        background-color: #fff;
        height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
      }
    `,
  ],
})
export class ResourceThumbnailComponent {
  @Input() resource?: Resource;
  @Output() removeResource = new EventEmitter<Resource>();
  @Output() resourceSelected = new EventEmitter<Resource>();
  iconImg = faImage;
  iconGeo = faVectorSquare;
  iconEdit = faPen;

  onResourceSelected(evt: Event): void {
    evt.stopImmediatePropagation();
    this.resourceSelected.emit(this.resource);
  }
}
