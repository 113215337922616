import {
  RAINFALL_1DAY,
  RAINFALL_1MONTH,
  RAINFALL_1MONTH_ABSOLUTE_ANOMALIES,
  RAINFALL_1MONTH_RELATIVE_ANOMALIES,
  RAINFALL_3DAY,
  RAINFALL_3MONTH,
  RAINFALL_3MONTH_ABSOLUTE_ANOMALIES,
  RAINFALL_3MONTH_RELATIVE_ANOMALIES,
  RAINFALL_7DAY,
  RAINFALL_MAR_APR_MAY,
  RAINFALL_MAR_APR_MAY_ABSOLUTE_ANOMALIES,
} from '../../weather-layers/weather.layers';
import {
  createTimestepListGpm,
  modelTemplate,
} from '../weather-model.factory.utils';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createGpm(serverResponse: any): WeatherModel {
  const tsDay = serverResponse.timestepList;
  const tsMonth = serverResponse?.timestepMonthList;
  const ts3month = serverResponse?.timestep3MonthList;
  const tsYear = serverResponse?.timestepYearList;

  const timestepListDay = createTimestepListGpm(tsDay, false);
  const timestepTypeDay: TimestepType = { type: 'DAYS', interval: 1 };
  const defaultDateRangeDay = {
    from: timestepListDay[0].timesteps[
      timestepListDay[0].timesteps.length - 30
    ],
    to: timestepListDay[0].timesteps[timestepListDay[0].timesteps.length - 1],
  };

  const timestepListMonth = createTimestepListGpm(tsMonth, true);
  const timestepTypeMonth: TimestepType = { type: '1MONTH', interval: 1 };
  const defaultDateRangeMonth = {
    from: timestepListMonth[0].timesteps[
      timestepListMonth[0].timesteps.length - 12
    ],
    to: timestepListMonth[0].timesteps[
      timestepListMonth[0].timesteps.length - 1
    ],
  };

  const timestepList3Month = createTimestepListGpm(ts3month, true);
  const timestepType3Month: TimestepType = { type: '3MONTH', interval: 3 };
  const defaultDateRange3Month = {
    from: timestepList3Month[0].timesteps[
      timestepList3Month[0].timesteps.length - 12
    ],
    to: timestepList3Month[0].timesteps[
      timestepList3Month[0].timesteps.length - 1
    ],
  };

  const timestepListYear = createTimestepListGpm(tsYear, true);
  const timestepTypeYear: TimestepType = { type: '1YEAR', interval: 12 };
  const defaultDateRangeYear = {
    from: timestepListYear[0].timesteps[0],
    to: timestepListYear[0].timesteps[timestepListYear[0].timesteps.length - 1],
  };

  const gpmLayers: WeatherLayer[] = [
    {
      ...RAINFALL_1DAY,
      timestepType: timestepTypeDay,
      timestepList: timestepListDay,
      defaultDateRange: defaultDateRangeDay,
    },
    {
      ...RAINFALL_3DAY,
      timestepType: timestepTypeDay,
      timestepList: timestepListDay,
      defaultDateRange: defaultDateRangeDay,
    },
    {
      ...RAINFALL_7DAY,
      timestepType: timestepTypeDay,
      timestepList: timestepListDay,
      defaultDateRange: defaultDateRangeDay,
    },
    {
      ...RAINFALL_1MONTH,
      timestepType: timestepTypeMonth,
      timestepList: timestepListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_1MONTH_ABSOLUTE_ANOMALIES,
      timestepType: timestepTypeMonth,
      timestepList: timestepListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_1MONTH_RELATIVE_ANOMALIES,
      timestepType: timestepTypeMonth,
      timestepList: timestepListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_3MONTH,
      timestepType: timestepType3Month,
      timestepList: timestepList3Month,
      defaultDateRange: defaultDateRange3Month,
    },
    {
      ...RAINFALL_3MONTH_ABSOLUTE_ANOMALIES,
      timestepType: timestepType3Month,
      timestepList: timestepList3Month,
      defaultDateRange: defaultDateRange3Month,
    },
    {
      ...RAINFALL_3MONTH_RELATIVE_ANOMALIES,
      timestepType: timestepType3Month,
      timestepList: timestepList3Month,
      defaultDateRange: defaultDateRange3Month,
    },
    {
      // TODO create 1year layer + legend
      ...RAINFALL_3MONTH,
      id: 'rainfall_1year',
      label: 'rainfall_1year',
      timestepType: timestepTypeYear,
      timestepList: timestepListYear,
      defaultDateRange: defaultDateRangeYear,
    },
    // LAYERS LORENZO TEMPORARY, BUT ASK BEFORE REMOVING PLEASE ----- START
    {
      ...RAINFALL_MAR_APR_MAY,
      timestepType: timestepTypeMonth,
      timestepList: timestepListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    {
      ...RAINFALL_MAR_APR_MAY_ABSOLUTE_ANOMALIES,
      timestepType: timestepTypeMonth,
      timestepList: timestepListMonth,
      defaultDateRange: defaultDateRangeMonth,
    },
    // LAYERS LORENZO TEMPORARY, BUT ASK BEFORE REMOVING PLEASE ----- END
  ];

  return {
    ...modelTemplate,
    id: 'GPM',
    label: 'GPM',
    category: 'MONITORING',
    layers: gpmLayers,
    initime: gpmLayers[0].timestepList[0].initime,
    timestep: defaultDateRangeDay.to,
    selectedLayerId: gpmLayers[0].id,
    dateRange: defaultDateRangeDay,
    bbox: [
      [-180, 90],
      [180, 90],
      [180, -90],
      [-180, -90],
      [-180, 90],
    ],
    info: {
      resolution: '0.1°',
      coverage: 'Global',
      url: 'https://gpm.nasa.gov/missions/GPM',
      credits: 'NASA',
    },
  };
}
