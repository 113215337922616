import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Media } from '3map-models';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-media-card',
  template: `
    <div class="media-card-wrapper" *ngIf="media">
      <div class="media">
        <img *ngIf="media.type === 'IMAGE'" [src]="mediaUrl" alt="" />
        <video
          *ngIf="media.type === 'VIDEO'"
          [src]="mediaUrl"
          [controls]="showControls"
        ></video>
        <audio
          *ngIf="media.type === 'AUDIO'"
          [src]="mediaUrl"
          [controls]="showControls"
        ></audio>
      </div>
    </div>
  `,
  styles: [
    `
      .media-card-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .media img,
      .media video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCardComponent {
  @Input() projectName?: string;
  @Input() media?: Media;
  @Input() base64Img?: string;
  @Input() showControls: boolean | undefined = true;

  get mediaUrl(): string | null {
    if (this.base64Img) return this.base64Img;
    if (!this.media || !this.projectName) return null;
    const apiUrl = environment.API_URL;
    return `${apiUrl}/static/${this.projectName}/project/${this.media.fileName}`;
  }
}
