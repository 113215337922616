import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '../../../auth/state/auth';
import { Project } from '3map-models';
import { DialogConfirmComponent, DialogConfirmData } from '@trim-web-apps/core';
import { take } from 'rxjs/operators';
import { State } from '../../../auth/state/auth/auth.reducer';
import { AdminActions, AdminSelectors } from '../../+state';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-settings',
  template: `
    <div class="header">
      <app-section-label sectionLabel="Settings" />
    </div>
    <div class="auth" *ngIf="auth$ | async as auth">
      <div class="item" *ngIf="project$ | async as project">
        <div class="item-label">Delete Project</div>
        <div class="item-content item-content-btn">
          <ui-btn (click)="deleteProject(project)" label="Delete"></ui-btn>
        </div>
      </div>
      <div class="item">
        <div class="item-label">Admin Token</div>
        <div class="item-content">{{ auth.adminToken }}</div>
      </div>
      <div class="item">
        <div class="item-label">Project Token</div>
        <div class="item-content">{{ auth.projectToken }}</div>
      </div>
      <div class="item">
        <div class="item-label">Logged in as</div>
        <div class="item-content">{{ auth.username }}</div>
      </div>
      <div class="item">
        <div class="item-label">Project JSON</div>
        <pre class="project-json">{{ project$ | async | json }}</pre>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .auth {
        overflow: auto;
        word-wrap: break-word;
        padding: 0 30px 30px 30px;
      }

      .item {
        display: flex;
        margin: 20px 0;
        padding: 5px;
      }

      .item-content {
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: all;
        cursor: copy;
        flex: 1;
      }

      .item-content-btn {
        display: flex;
        align-items: center;
      }

      .item-label {
        width: 150px;
        border-right: 1px solid #dbdbdb;
        margin-right: 10px;
      }

      .project-json {
        user-select: text;
        cursor: text;
      }
    `,
  ],
})
export class SettingsComponent {
  auth$: Observable<State>;
  project$: Observable<Project | null>;

  constructor(private store: Store, private dialog: Dialog) {
    this.auth$ = this.store.select(AuthSelectors.selectAuthState);
    this.project$ = this.store.select(AdminSelectors.selectProject());
  }

  deleteProject(project: Project): void {
    const data: DialogConfirmData = {
      title: 'Delete Project',
      message: `Project "${project.name}" and all Records will be deleted.`,
      acceptLabel: 'Yes, delete',
      rejectLabel: 'Back',
    };
    this.dialog
      .open<boolean>(DialogConfirmComponent, { data })
      .closed.pipe(take(1))
      .subscribe((confirm) => {
        if (confirm)
          this.store.dispatch(
            AdminActions.deleteProject({ projectName: project.name })
          );
      });
  }
}
