import { Resource } from '3map-models';
import { getDefaultRawStyle } from './models/GeoStyle';

export interface EntityResource extends Resource {
  id: string;
  resourceData: string | null;
}

export function createEntity(
  id: string,
  name: string,
  type: 'IMAGE' | 'GEOJSON'
): EntityResource {
  const res = {
    id,
    name,
    type,
    resourceData: null,
    filename: '',
  };
  return type === 'GEOJSON' ? { ...res, style: getDefaultRawStyle() } : res;
}

export function entityToResource(entity: EntityResource): Resource | null {
  if (entity.filename === '') return null;
  const style = entity.style;
  const boundingBox = entity.boundingBox;
  const baseResource: Resource = {
    name: entity.name,
    type: entity.type,
    filename: entity.filename,
  };

  if (entity.type === 'GEOJSON' && style !== undefined)
    return { ...baseResource, style };
  if (entity.type === 'IMAGE' && boundingBox !== undefined)
    return { ...baseResource, boundingBox, style };

  return baseResource;
}
