import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { DropdownItem } from '@trim-web-apps/map3-ui';
import { WeatherActions, WeatherSelectors } from '../../+state';
import {
  timestepToLabel,
  utcOffsetFormatter,
} from '../../utils/weather.functions';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';

@Component({
  selector: 'weather-core-timestep-picker',
  template: `
    <div class="ts-picker-wrapper" *ngIf="tsPickerData$ | async as data">
      <fa-icon
        [icon]="iconPrev"
        class="timestep-btn ts-prev"
        (click)="changeTs(-1, data)"
      />

      <ui-dropdown
        [itemList]="data.itemList"
        [itemActive]="data.itemActive"
        (itemSelected)="onItemSelected($event.source)"
      />

      <fa-icon
        [icon]="iconNext"
        class="timestep-btn ts-next"
        (click)="changeTs(1, data)"
      />
    </div>
  `,
  styles: [
    `
      .ts-picker-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
      }

      ui-dropdown {
        width: 100%;
      }

      .timestep-btn {
        cursor: pointer;
        padding: 0 5px;
      }
    `,
  ],
})
export class TimestepPickerComponent implements OnInit {
  @Input() weatherModelId?: string;
  tsPickerData$?: Observable<{
    itemList: DropdownItem<number>[];
    itemActive: DropdownItem<number> | null;
    activeIndex: number;
  } | null>;
  iconPrev = faChevronLeft;
  iconNext = faChevronRight;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.weatherModelId) return;

    this.tsPickerData$ = combineLatest([
      this.store.select(WeatherSelectors.selectById(this.weatherModelId)),
      this.store.select(WeatherSelectors.selectUtcOffset()),
    ]).pipe(
      map(([model, utcOffset]) => {
        if (!model) return null;
        let itemList: DropdownItem<number>[] = [];
        let itemActive: DropdownItem<number> | null = null;
        let activeIndex = -1;
        const layer = model.layers.find((l) => l.id === model.selectedLayerId);
        const tsList =
          layer?.timestepList
            .find((ts) => ts.initime === model.initime)
            .timesteps.filter(
              (t: number) =>
                t >= model.dateRange.from && t <= model.dateRange.to
            ) || [];

        if (!layer || !tsList) return { itemList, itemActive, activeIndex };

        const utcOffsetLabel =
          layer.timestepType.type === 'UNIX'
            ? utcOffsetFormatter(utcOffset)
            : '';

        for (let i = 0; i < tsList.length; i++) {
          const ts = tsList[i];
          const tsLabel = timestepToLabel(ts, model.initime, layer, utcOffset);
          const item = {
            // label: `${tsLabel} ${utcOffsetLabel}`,
            label: `${tsLabel}`,
            payload: ts,
          };

          itemList = [...itemList, item];

          if (item.payload === model.timestep) {
            itemActive = item;
            activeIndex = i;
          }
        }

        return { itemList, itemActive, activeIndex };
      })
    );
  }

  changeTs(
    direction: number,
    dropdownData: {
      itemList: DropdownItem<number>[];
      itemActive: DropdownItem<number> | null;
      activeIndex: number;
    }
  ): void {
    if (dropdownData.activeIndex === -1) return;
    const newTsIndex = dropdownData.activeIndex + direction;
    if (newTsIndex >= 0 && newTsIndex < dropdownData.itemList.length)
      this.setTs(dropdownData.itemList[newTsIndex].payload);
  }

  setTs(timestep: number): void {
    if (this.weatherModelId)
      this.store.dispatch(
        WeatherActions.setTimestep({ modelId: this.weatherModelId, timestep })
      );
  }

  onItemSelected(item: DropdownItem<number> | null): void {
    if (item !== null) this.setTs(item.payload);
  }
}
