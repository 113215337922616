import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapContextMenuComponent } from './map-context-menu/map-context-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MapBaseComponent } from './container/map-base.component';
import { CoreModule } from '@trim-web-apps/core';

@NgModule({
  declarations: [
    MapContextMenuComponent,
    MapBaseComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    CoreModule
  ],
  exports: [
    MapBaseComponent,
  ]
})
export class MapModule {
}
