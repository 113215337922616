import { NgModule } from '@angular/core';
import { StdCheckboxComponent } from './ui/std-checkbox/std-checkbox.component';
import { CommonModule } from '@angular/common';
import { FormatUnixPipe } from './pipes/unix-to-date.pipe';
import { SecureImagePipe } from './pipes/secure-image.pipe';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MouseDragDirective } from './directives/mouse-drag.directive';
import { SplitViewComponent } from './ui/split-view/split-view.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DialogConfirmComponent } from './ui/dialog-confirm/dialog-confirm.component';
import {
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
} from '@angular/material/legacy-tooltip';
import { IconButtonComponent } from './ui/icon-button/icon-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { InputTextComponent } from './ui/input-text/input-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DoubleConfirmInlineComponent } from './ui/double-confirm-inline/double-confirm-inline.component';
import { LngLatInputComponent } from './ui/lng-lat-input/lng-lat-input.component';
import { TruncateStrPipe } from './pipes/truncate-str.pipe';
import { NotificationListComponent } from './ui/notification/notification-list/notification-list.component';
import { NotificationItemComponent } from './ui/notification/notification-item/notification-item.component';
import { ArrayStringPipe } from './pipes/array-string.pipe';

export const matTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 700,
  hideDelay: 500,
  touchendHideDelay: 1000,
};

@NgModule({
  declarations: [
    StdCheckboxComponent,
    FormatUnixPipe,
    SecureImagePipe,
    MouseDragDirective,
    SplitViewComponent,
    DialogConfirmComponent,
    IconButtonComponent,
    InputTextComponent,
    DoubleConfirmInlineComponent,
    LngLatInputComponent,
    TruncateStrPipe,
    NotificationListComponent,
    NotificationItemComponent,
    ArrayStringPipe,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatMenuModule,
    OverlayModule,
    FontAwesomeModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    StdCheckboxComponent,
    FormatUnixPipe,
    SecureImagePipe,
    MouseDragDirective,
    SplitViewComponent,
    IconButtonComponent,
    InputTextComponent,
    DoubleConfirmInlineComponent,
    LngLatInputComponent,
    TruncateStrPipe,
    NotificationListComponent,
    ArrayStringPipe,
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaults },
  ],
})
export class CoreModule {}
