export type EditorTab = {
  type: 'MEDIA' | 'METADATA' | 'QUESTIONS';
  label: string;
  payload: string | null;
};

export const QUESTION_TAB: EditorTab = {
  type: 'QUESTIONS',
  label: 'Questions',
  payload: null,
};

export const METADATA_TAB: EditorTab = {
  type: 'METADATA',
  label: 'Metadata',
  payload: null,
};

export const MEDIA_TAB: EditorTab = {
  type: 'MEDIA',
  label: 'Media',
  payload: null,
};
