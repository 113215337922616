import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebhookListComponent } from './containers/webhook-list/webhook-list.component';
import { WebhookListItemComponent } from './components/webhook-list-item/webhook-list-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@trim-web-apps/core';
import { SharedModule } from '../shared/shared.module';
import { Map3UiModule } from '@trim-web-apps/map3-ui';

@NgModule({
  declarations: [WebhookListComponent, WebhookListItemComponent],
  imports: [
    CommonModule,
    // StoreModule.forFeature(ADMIN_WEB_HOOK_FEATURE_KEY, reducer),
    FontAwesomeModule,
    FormsModule,
    CoreModule,
    SharedModule,
    Map3UiModule,
  ],
})
export class WebhooksModule {}
