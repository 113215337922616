import { Component, Inject, OnInit } from '@angular/core';
import { Form } from '3map-models';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-form-dialog',
  template: `
    <app-form-properties
      (formChanged)="onFormChanged($event)"
      [mode]="data.mode"
      [form]="data.form"
    >
    </app-form-properties>
  `,
  styles: [],
})
export class FormDialogComponent implements OnInit {
  constructor(
    public dialogRef: DialogRef<Form | undefined>,
    @Inject(DIALOG_DATA)
    public data: { form: Form; mode: 'create' | 'edit' | 'duplicate' }
  ) {}

  ngOnInit(): void {
    if (!this.data.form) this.dialogRef.close();
  }

  onFormChanged(form: Form): void {
    this.dialogRef.close(form);
  }
}
