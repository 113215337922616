import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';
import { CURRENT } from '../../weather-layers/weather.layers';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createScheveningen(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 109, 1800, '+00:00');
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[96],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 1800 };

  const layers: WeatherLayer[] = [
    {
      ...CURRENT,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'SHEVENINGEN',
    label: 'Sheveningen',
    category: 'FORECAST',
    layers: layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].timestepList[0].timesteps[0],
    selectedLayerId: layers[0].id,
    dateRange: defaultDateRng,
    bbox: [
      [3.49973, 51.64803],
      [5.50229, 51.64803],
      [5.50229, 52.46897],
      [3.49973, 52.46897],
      [3.49973, 51.64803],
    ],
  };
}
