import {
  PRESSURE,
  RAINFALL_3HOUR,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  WIND_KTS,
} from '../../weather-layers/weather.layers';
import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';
import {
  TimestepType,
  WeatherLayer,
  WeatherModel,
  WeatherTimestep,
} from '../../types';

export function createGfs(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 121, 3600 * 3, '+00:00');
  const tsRainfall = ts.map(
    (t): WeatherTimestep => ({ ...t, timesteps: t.timesteps.slice(1) })
  );
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[120],
  };
  const defaultDateRngRainfall: { from: number; to: number } = {
    from: ts[0].timesteps[1],
    to: ts[0].timesteps[120],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 3600 * 3 };

  const gfsLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...WIND_KTS,
      id: 'wind_925',
      label: 'Wind (925 hPa)',
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RAINFALL_3HOUR,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    {
      ...RELATIVE_HUMIDITY,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...PRESSURE,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'GFS',
    label: 'GFS',
    category: 'FORECAST',
    layers: gfsLayers,
    initime: gfsLayers[0].timestepList[0].initime,
    timestep: gfsLayers[0].timestepList[0].timesteps[0],
    selectedLayerId: gfsLayers[0].id,
    dateRange: defaultDateRng,
    bbox: [
      [-180, 90],
      [180, 90],
      [180, -90],
      [-180, -90],
      [-180, 90],
    ],
    info: {
      resolution: '0.25°',
      coverage: 'Global',
      url: 'https://www.ncei.noaa.gov/products/weather-climate-models/global-forecast',
      credits: 'NOAA',
    },
  };
}
