import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  Column,
  ColumnApi,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  RowNode,
} from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { FieldType, Record } from '3map-models';
import { MenuItemDef } from 'ag-grid-community/dist/lib/entities/gridOptions';
import {
  BaseExportParams,
  ProcessCellForExportParams,
} from 'ag-grid-community/dist/lib/interfaces/exportParams';
import * as moment from 'moment';
import { Moment } from 'moment';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { TableData } from '../+types/table-data.type';
import {
  DEFAULT_GRID_OPTIONS,
  TABLE_CONFIG_DEFAULT,
} from '../+helpers/table.defaults';
import { recordToRow } from '../+helpers/table.utils';
import { getColumns } from '../+helpers/table.columns';
import {
  TableEvent,
  TableProcessedRecordsEvent,
  TableZoomToRecordEvent,
  TableZoomToRecordListEvent,
} from '../+types/table-record-event.type';
import { TableConfig } from '../+types/table-config.type';

@Component({
  selector: 'map3-table',
  template: `
    <div class="table-wrapper">
      <ng-container *ngIf="tableConfig.showActions">
        <map3-table-actions
          *ngIf="tableData"
          [tableData]="tableData"
          [columns]="columns"
          [groupByColumn]="groupByColumn"
          (columnSelected)="setGroupByColumn($event)"
          (toggleHistory)="onToggleHistory(tableData.dataset.formId)"
          (resetFilters)="onResetFilters()"
          (enabledSpecificList)="
            onSpecificEnabledChange(tableData.dataset.formId, $event)
          "
        ></map3-table-actions>
      </ng-container>
      <ag-grid-angular
        *ngIf="gridOptions"
        [gridOptions]="gridOptions"
        style="height: 100%"
        class="ag-theme-balham"
      ></ag-grid-angular>
    </div>
  `,
  styles: [
    `
      .table-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        /*border-top: 1px solid var(--disable-light-color);*/
      }

      map3-table-actions {
        height: 70px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() tableData$?: Observable<TableData>;
  @Input() tableConfig: TableConfig = TABLE_CONFIG_DEFAULT;
  @Output() tableEvent: EventEmitter<TableEvent> =
    new EventEmitter<TableEvent>();

  gridOptions: GridOptions | undefined;
  columns: Column[] = [];
  groupByColumn: Column | undefined | null;
  tableData: TableData | null = null;
  private _tableDataSub: Subscription | undefined;

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {
    this._tableDataSub = this.tableData$?.subscribe((tableData) => {
      this.tableData = tableData;
      console.log('trigger');
      this.onTableDataChange();
    });
  }

  ngOnDestroy(): void {
    this._tableDataSub?.unsubscribe();
  }

  get gridApi(): GridApi {
    if (!this.gridOptions?.api) throw Error('GridApi not initialized');
    return this.gridOptions.api;
  }

  get columnApi(): ColumnApi {
    if (!this.gridOptions?.columnApi) throw Error('ColumnApi not initialized');
    return this.gridOptions.columnApi;
  }

  setGroupByColumn(col: Column | null): void {
    this.columnApi.setRowGroupColumns(col ? [col] : []);
    this.groupByColumn = col;
  }

  onToggleHistory(formId: string): void {
    this.tableEvent.emit({
      type: 'TOGGLE_HISTORY',
      formId,
    });
  }

  onSpecificEnabledChange(formId: string, specificIdsList: string[]): void {
    this.tableEvent.emit({
      type: 'ENABLED_SPECIFIC_ID_LIST',
      formId,
      specificIdsList,
    });
  }

  onResetFilters(): void {
    this.gridOptions?.api?.setFilterModel({});
  }

  private async onTableDataChange(): Promise<void> {
    if (!this.tableData) return;
    if (!this.gridOptions) await this.initGrid(this.tableData.form.id);
    const iconCb = (recordId: string, mediaIndex: number) => {
      this.tableEvent.emit({ type: 'TABLE_MEDIA', recordId, mediaIndex });
    };
    this.gridApi.setColumnDefs(getColumns(this.tableData, iconCb));
    this.columns = this.columnApi.getAllColumns() || [];
    this.updateRows(this.tableData);
    this.emitProcessedRecords(this.tableData.form.id);
  }

  private updateRows(tableData: TableData): void {
    const specificMap: Map<string, string> = new Map<string, string>();
    let specificQuestions: FieldType[] = [];
    tableData.form.specificList.forEach((specific) => {
      specificMap.set(specific.id, specific.name);
      specificQuestions = [...specificQuestions, ...specific.questions];
    });

    this.gridApi.setRowData(
      tableData.records.map((r) =>
        recordToRow(r, specificQuestions, specificMap)
      )
    );

    const filterModel = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(filterModel);
  }

  private async initGrid(formId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.gridOptions = {
        ...DEFAULT_GRID_OPTIONS,
        // defaultExportParams: { fileName: this.getExportFilename()},
        onGridReady: () => resolve(), // this.onGridReady(tableData),
        onFilterChanged: () => this.emitProcessedRecords(formId),
        onSortChanged: () => this.emitProcessedRecords(formId),
        getContextMenuItems: (params) => this.getContextMenu(params),
      };
    });
  }

  private emitProcessedRecords(formId: string): void {
    const processedRecordsIds: string[] = [];
    this.gridApi.forEachNodeAfterFilterAndSort((row) => {
      if (!row.group) processedRecordsIds.push(row.data.recordId);
    });
    const event: TableProcessedRecordsEvent = {
      type: 'PROCESSED_RECORDS',
      processedRecordsIds,
      formId,
    };
    this.tableEvent.emit(event);
  }

  private getContextMenu(
    params: GetContextMenuItemsParams
  ): (MenuItemDef | string)[] {
    if (!this.tableConfig.enableContextMenu) return [];
    if (!this.tableData || !params.node) return [];
    const contextItems: (MenuItemDef | string)[] = [];
    contextItems.push(this.ctxMenuCreateRecord(this.tableData));

    const selectedNodes = params.api?.getSelectedNodes() || [];
    let selectedRecords = rowNodesToRecords(
      selectedNodes,
      this.tableData.records
    );
    // right-clicked row
    const rightClickRecord = rowNodesToRecords(
      [params.node],
      this.tableData.records
    );

    // no rows selected, marks right-clicked row as selected
    if (selectedRecords.length === 0) {
      params.node.setSelected(true);
      selectedRecords = rightClickRecord;
    } else {
      // there are some rows selected
      const selectedRecordIds = selectedRecords.map((r) => r.recordId);
      const rightClickRecordId = rightClickRecord[0].recordId;
      // if right-clicked row is *NOT* in selected rows, then unselect all rows
      // and mark right-clicked row as the only one selected
      if (!selectedRecordIds.includes(rightClickRecordId)) {
        params.api?.deselectAll();
        params.node.setSelected(true);
        selectedRecords = rightClickRecord;
      }
    }

    const userHasRightAllRecords = selectedRecords
      .map((record) => userHasEditDeleteRights(record, this.tableData))
      .reduce((acc, item) => acc && item, true);

    if (userHasRightAllRecords && selectedRecords.length > 0) {
      if (selectedRecords.length === 1) {
        const recordId = selectedRecords[0].recordId;
        contextItems.push(this.ctxMenuUpdateRecord(recordId));
        contextItems.push(this.ctxMenuEditRecord(recordId));
      }
      contextItems.push(this.ctxMenuDeleteRecord(selectedRecords));
    }

    contextItems.push(this.ctxShowRecordOnMap(selectedRecords));
    contextItems.push('separator');
    if (selectedRecords.length === 1)
      contextItems.push(this.ctxCopyCell(params));
    contextItems.push(this.ctxMenuExport(this.tableData));

    return contextItems;
  }

  private ctxMenuCreateRecord(tableData: TableData): MenuItemDef {
    const subMenu: MenuItemDef[] = tableData.form.specificList
      .filter((s) => tableData.dataset.specificIds.includes(s.id))
      .map((specific) => {
        return {
          name: specific.name,
          action: () =>
            this.tableEvent.emit({
              type: 'CREATE_RECORD',
              formId: tableData.form.id,
              specificId: specific.id,
              latitude: 0,
              longitude: 0,
              altitude: 0,
            }),
        };
      });
    return { name: 'Create', subMenu };
  }

  private ctxMenuEditRecord(recordId: string): MenuItemDef {
    return {
      name: 'Edit',
      action: () => this.tableEvent.emit({ type: 'EDIT_RECORD', recordId }),
    };
  }

  private ctxMenuUpdateRecord(recordId: string): MenuItemDef {
    return {
      name: 'Update',
      action: () => this.tableEvent.emit({ type: 'UPDATE_RECORD', recordId }),
    };
  }

  private ctxMenuDeleteRecord(recordList: Record[]): MenuItemDef {
    const recordIdsList = recordList.map((r) => r.recordId);
    return {
      name: 'Delete',
      action: () =>
        this.tableEvent.emit({ type: 'DELETE_RECORD_LIST', recordIdsList }),
    };
  }

  private ctxShowRecordOnMap(recordList: Record[]): MenuItemDef {
    const menuName = 'Show on Map';

    if (recordList.length === 0) return { name: menuName };

    const event: TableZoomToRecordEvent | TableZoomToRecordListEvent =
      recordList.length === 1
        ? {
            type: 'ZOOM_TO_RECORD',
            recordId: recordList[0].recordId,
            formId: recordList[0].formId,
            featureId: recordList[0].featureId,
          }
        : {
            type: 'ZOOM_TO_RECORD_LIST',
            recordIdsList: recordList.map((r) => r.recordId),
          };

    return {
      name: 'Show on Map',
      action: () => this.tableEvent.emit(event),
    };
  }

  private ctxMenuExport(tableData: TableData): MenuItemDef {
    const exportParams: BaseExportParams = {
      fileName: `${tableData.form.name}`,
      processCellCallback: processCellCallback,
    };
    return {
      name: 'Export',
      subMenu: [
        {
          name: 'Export CSV',
          action: () => this.gridOptions?.api?.exportDataAsCsv(exportParams),
        },
        {
          name: 'Export Excel',
          action: () => this.gridOptions?.api?.exportDataAsExcel(exportParams),
        },
      ],
    };
  }

  private ctxCopyCell(params: GetContextMenuItemsParams): MenuItemDef {
    return {
      name: 'Copy cell value',
      action: () => this.clipboard.copy(params.value),
    };
  }
}

/**
 * Format cell for export (CSV or Excel).
 * If cell contains a Moment object than check headerClass: if it contains `dateFieldType` then is a DATE
 * FieldType and must be formatted without time (see {@link getSpecificColumns} ).
 * If not a Moment then return as is.
 * @param p
 */
function processCellCallback(p: ProcessCellForExportParams): string {
  if (p.value instanceof moment) {
    const isFieldDate = p.column.getColDef().headerClass === 'dateFieldType';
    return isFieldDate
      ? (p.value as Moment).format('YYYY-MM-DD')
      : (p.value as Moment).format('YYYY-MM-DD HH:mm:ss');
  }
  return p.value;
}

function rowNodesToRecords(rowNodes: RowNode[], records: Record[]): Record[] {
  return rowNodes
    .map((node) => node.data.recordId)
    .map((recId) => records.find((record) => record.recordId === recId))
    .filter(notNullOrUndefined);
}

function userHasEditDeleteRights(
  record: Record,
  tableData: TableData | null
): boolean {
  if (!tableData) return false;
  const isOwner = record.username === tableData.username;
  const isModOrAdmin = tableData.role === 'ADMIN' || tableData.role === 'MOD';
  return isOwner || isModOrAdmin;
}
