import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SelectBaseComponent } from './select-base.component';

@Component({
  selector: 'ui-select',
  template: `
    <div #wrapper [ngStyle]="{ width: widthPx ? widthPx + 'px' : 'auto' }">
      <div
        #menuTrigger
        class="menu-trigger"
        [cdkMenuTriggerFor]="menu"
        [class.opened-above]="menuOpenedDirection === 'above'"
        [class.opened-below]="menuOpenedDirection === 'below'"
        (cdkMenuOpened)="onCdkMenuOpened()"
        (cdkMenuClosed)="onCdkMenuClosed()"
      >
        <div class="menu-item text-ellipsis">
          {{ itemSelected?.toString() || 'Select one' }}
        </div>
        <fa-icon [icon]="iconExpand" />
      </div>
      <ng-template #menu>
        <div
          class="menu-content menu-single"
          cdkMenu
          [class.opened-above]="menuOpenedDirection === 'above'"
          [class.opened-below]="menuOpenedDirection === 'below'"
        >
          <div class="menu-content-inner" [style.width.px]="menuWidth" #menuDiv>
            <ng-container *ngFor="let item of items; let index = index">
              <ng-container *ngIf="index === 0 && allowNoneOption">
                <div class="menu-item" (click)="onItemClick(null)">
                  {{ noneOptionLabel }}
                </div>
              </ng-container>
              <div class="menu-item text-ellipsis" (click)="onItemClick(item)">
                {{ item }}
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  // see style in +styles/menu.style.scss
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends SelectBaseComponent {
  @Input() itemSelected?: string | null;
  @Input() allowNoneOption?: boolean;
  @Input() noneOptionLabel = 'None';
  @Output() itemSelectedChange = new EventEmitter<string | null>();

  onItemClick(item: string | null): void {
    this.itemSelectedChange.emit(item);
    this.menuTrigger?.nativeElement.click();
  }
}
