import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Form, FormSpecific } from '3map-models';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

export interface RecordCreateData {
  form: Form;
  specific: FormSpecific;
  lngLatAlt: number[];
}

@Component({
  selector: 'map-context-menu',
  template: `
    <div class="map-context-menu" (mouseleave)="addRecordVisible = false">
      <div class="left">
        <div class="top-left" *ngIf="forms && forms.length > 0">
          <div (mouseenter)="addRecordVisible = true">
            <span>Add Record</span>
            <fa-icon [icon]="iconChevronRight"></fa-icon>
          </div>
        </div>

        <div class="bottom-left" (mouseenter)="addRecordVisible = false">
          {{ lat?.toFixed(5) }} ; {{ lng?.toFixed(5) }}
        </div>
      </div>

      <div *ngIf="addRecordVisible" class="right">
        <ng-container *ngFor="let form of forms">
          <div
            *ngFor="let specific of form.specificList"
            class="add-record-item"
            (click)="emitData(form, specific)"
          >
            {{ specific.name }}
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .map-context-menu {
        display: flex;
        border-radius: 3px;
        align-items: baseline;
      }

      .left,
      .right {
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }

      .top-left > div:hover,
      .add-record-item:hover {
        cursor: pointer;
        color: black;
      }

      .add-record-item,
      .top-left {
        padding: 5px 10px;
        border-bottom: 1px solid #eeeeee;
      }

      .bottom-left {
        padding: 5px 10px;
        text-align: center;
        user-select: text;
      }

      .top-left > div {
        display: flex;
        justify-content: space-between;
      }
    `,
  ],
})
export class MapContextMenuComponent {
  @Input() lng: number | undefined;
  @Input() lat: number | undefined;
  @Input() forms: Form[] | undefined;
  @Output() addRecord: EventEmitter<RecordCreateData> =
    new EventEmitter<RecordCreateData>();

  addRecordVisible: boolean | undefined;
  iconChevronRight = faChevronRight;

  emitData(form: Form, specific: FormSpecific): void {
    if (this.lng && this.lat) {
      this.addRecord.emit({
        form,
        specific,
        lngLatAlt: [this.lng, this.lat, 0],
      });
    }
  }
}
