import { createAction, props } from '@ngrx/store';

export const setGroupByColumn = createAction(
  '[Table] Set Group By Column',
  props<{ formId: string; groupByColumn: string | null }>()
);

export const processedRecordIdsChanged = createAction(
  '[Table] Processed Records Changed',
  props<{ formId: string; processedRecordsIds: string[] }>()
);

export const setActiveTable = createAction(
  '[Table] Set Active Table',
  props<{ tableId: string }>()
);

export const createRecord = createAction(
  '[Table] Create Record',
  props<{
    formId: string;
    specificId: string;
    latitude: number;
    longitude: number;
    altitude: number;
  }>()
);

export const updateRecord = createAction(
  '[Table] Update Record',
  props<{ recordId: string }>()
);

export const editRecord = createAction(
  '[Table] Edit Record',
  props<{ recordId: string }>()
);

export const deleteRecords = createAction(
  '[Table] Delete Records',
  props<{ recordIdsList: string[] }>()
);

export const toggleHistory = createAction(
  '[Table] Toggle History',
  props<{ formId: string }>()
);

export const zoomToRecords = createAction(
  '[Table] Zoom To Multiple Records',
  props<{ recordIdsList: string[] }>()
);

export const zoomToRecord = createAction(
  '[Table] Zoom To Record',
  props<{ recordId: string; featureId: string; formId: string }>()
);

export const setGalleryData = createAction(
  '[Table] Set Media Gallery Data',
  props<{ recordId: string; mediaIndex: number }>()
);

export const setEnabledSpecificList = createAction(
  '[Table] Set Enabled Specifics',
  props<{ formId: string; specificIdsList: string[] }>()
);
