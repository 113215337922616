/**
 * Confirm Dialog Service
 *
 * This service is used by ConfirmDialogComponent in order to display a confirm modal/dialog.
 * Component template is defined by ConfirmDialogData object.
 * User's interaction result (accepted/canceled) will be emitted through `response$` observable
 * returned on showConfirmDialog() method.
 */

import { Injectable } from '@angular/core';
import { ConfirmDialogData } from './confirm-dialog.data';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { notNullOrUndefined } from '@trim-web-apps/core';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  private data$: BehaviorSubject<ConfirmDialogData | null> =
    new BehaviorSubject<ConfirmDialogData | null>(null);
  private response$: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);

  /**
   * Return current dialog data or null is no data was previously set.
   * @return Observable<ConfirmDialogData | null>
   */
  getDialogData(): Observable<ConfirmDialogData | null> {
    return this.data$.asObservable();
  }

  /**
   * Show confirm dialog.
   * @param data Defines the template/content of dialog
   * @return Observable<boolean> where response will be emitted (true: accepted, false: canceled).
   */
  showConfirmDialog(data: ConfirmDialogData): Observable<boolean> {
    this.response$ = new BehaviorSubject<boolean | null>(null);
    this.data$.next(data);
    return this.response$.asObservable().pipe(filter(notNullOrUndefined));
  }

  /**
   * Called when user select "confirm" option.
   */
  confirm(): void {
    this.response$.next(true);
    this.close();
  }

  /**
   * Called when user select "cancel" option.
   */
  cancel(): void {
    this.response$.next(false);
    this.close();
  }

  /**
   * Call complete() on response$ observable to avoid multiple subscriptions
   * (eg. component did not call unsubscribe) and reset data observable.
   * Called after confirm/cancel methods.
   */
  private close(): void {
    this.response$.complete();
    this.data$.next(null);
  }
}
