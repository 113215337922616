import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromResource from './';
import { EntityResource } from './';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Resource } from '3map-models';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(private store: Store<fromResource.State>) {}

  getResourceList(): Observable<Resource[]> {
    return this.store.pipe(select(fromResource.selectResourceList()));
  }

  getEntityResourceList(): Observable<EntityResource[]> {
    return this.store.pipe(select(fromResource.selectEntityResourceList()));
  }

  getEntityResourceById(id: string): Observable<EntityResource | undefined> {
    return this.store.pipe(select(fromResource.selectEntityResourceById(id)));
  }

  getResourceById(id: string): Observable<Resource | null> {
    return this.store.pipe(select(fromResource.selectResourceById(id)));
  }

  entityExist(id: string): Observable<boolean> {
    return this.getEntityResourceById(id).pipe(map((res) => res !== undefined));
  }

  getResourceNameList(): Observable<string[]> {
    return this.getEntityResourceList().pipe(
      map((list) => list.map((resource) => resource.name))
    );
  }

  getResourceToDeploy(): Observable<EntityResource[]> {
    return this.store.pipe(select(fromResource.selectResourceToDeploy()));
  }

  createResource(entity: EntityResource): void {
    this.store.dispatch(fromResource.CREATE_RESOURCE({ entity }));
  }

  removeResource(id: string): void {
    this.store.dispatch(fromResource.REMOVE_RESOURCE({ id }));
  }

  setResourceName(id: string, name: string): void {
    this.store.dispatch(fromResource.SET_RESOURCE_NAME({ id, name }));
  }

  setResourceData(id: string, resourceData: string | null): void {
    this.store.dispatch(fromResource.SET_RESOURCE_DATA({ id, resourceData }));
  }

  setResourceStyle(id: string, style: any): void {
    this.store.dispatch(fromResource.SET_RESOURCE_STYLE({ id, style }));
  }

  setResourceBbox(id: string, boundingBox: number[][]): void {
    this.store.dispatch(fromResource.SET_RESOURCE_BBOX({ id, boundingBox }));
  }
}
