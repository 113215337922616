import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Record } from '3map-models';
// @ts-ignore
import * as tz_lookup from 'tz-lookup';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EditorMode } from '../../models/EditorMode';
import { RecordEditorMetadata } from '../../models/metadata-location.model';
import {
  dateUtcValidator,
  getTodayMidnightUTC,
  latitudeValidator,
  longitudeValidator,
} from '@trim-web-apps/core';

interface MetadataForm {
  latitude: FormControl<number>;
  longitude: FormControl<number>;
  altitude: FormControl<number>;
  datetimeUtc: FormControl<string>;
}

@Component({
  selector: 'app-record-editor-metadata',
  template: `
    <form [formGroup]="metadataForm" *ngIf="record">
      <div class="form-item">
        <div class="label">Latitude</div>
        <input
          type="number"
          formControlName="latitude"
          [class.disabled]="readOnlyPosition"
          [readOnly]="readOnlyPosition"
        />
        <div class="error" *ngIf="!metadataForm.controls.latitude.valid">
          Invalid latitude
        </div>
      </div>

      <div class="form-item">
        <div class="label">Longitude</div>
        <input
          type="number"
          formControlName="longitude"
          [class.disabled]="readOnlyPosition"
          [readOnly]="readOnlyPosition"
        />
        <div class="error" *ngIf="!metadataForm.controls.longitude.valid">
          Invalid longitude
        </div>
      </div>

      <div class="form-item">
        <div class="label">Altitude</div>
        <input
          type="number"
          formControlName="altitude"
          [class.disabled]="readOnlyPosition"
          [readOnly]="readOnlyPosition"
        />
        <div class="error" *ngIf="!metadataForm.controls.altitude.valid">
          Invalid altitude
        </div>
      </div>

      <div class="form-item">
        <div class="label">Datetime</div>
        <input
          type="date"
          formControlName="datetimeUtc"
          [class.disabled]="readOnlyDate"
          [readOnly]="readOnlyDate"
        />
        <div class="error" *ngIf="!metadataForm.controls.datetimeUtc.valid">
          Invalid date
        </div>
      </div>

      <div class="label">Zone: {{ record.zone }}</div>
    </form>
  `,
  styles: [
    `
      .form-item {
        margin-bottom: 10px;
      }

      .label {
        font-size: 0.9em;
      }

      input {
        width: 100%;
        margin-bottom: 5px;
      }

      .error {
        text-align: center;
        font-size: 0.9em;
        color: var(--error-color);
      }

      .disabled {
        cursor: not-allowed;
        color: var(--border);
      }

      .disabled:focus {
        outline: none;
        border: 1px solid #dbdbdb;
        box-shadow: none;
      }
    `,
  ],
})
export class RecordEditorMetadataComponent implements OnInit, OnDestroy {
  @Input() record: Record | undefined;
  @Input() isFeatureOwner: boolean | undefined;
  @Input() editorMode: EditorMode | null | undefined;
  @Output() metadataChange: EventEmitter<RecordEditorMetadata> =
    new EventEmitter<RecordEditorMetadata>();
  metadataForm: FormGroup<MetadataForm>;
  readOnlyPosition: boolean | undefined;
  readOnlyDate: boolean | undefined;
  private formSub: Subscription | undefined;

  constructor() {
    this.metadataForm = new FormGroup<MetadataForm>({
      latitude: new FormControl<number>(0, {
        nonNullable: true,
        validators: [latitudeValidator, Validators.required],
      }),
      longitude: new FormControl<number>(0, {
        nonNullable: true,
        validators: [longitudeValidator, Validators.required],
      }),
      altitude: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      datetimeUtc: new FormControl<string>(
        getTodayMidnightUTC().toISOString().substring(0, 10),
        {
          nonNullable: true,
          validators: [dateUtcValidator, Validators.required],
        }
      ),
    });
  }

  ngOnInit(): void {
    if (!this.record || !this.editorMode) return;

    /**
     * Update MetadataForm controls with values from current edit Record
     */
    const { latitude, longitude, altitude } = this.record;
    const datetimeUtc = this.record.datetimeUtc.toISOString().substring(0, 10);
    this.metadataForm.patchValue({
      latitude,
      longitude,
      altitude,
      datetimeUtc,
    });

    /**
     * Position can be changed only on CREATE Record.
     * Datetime can be always changed (if owner and/or ADMIN/MOD)
     */

    if (this.editorMode !== 'CREATE') {
      this.readOnlyPosition = true;
      // this.metadataForm.controls.latitude();
      // this.metadataForm.controls.longitude.disable();
      // this.metadataForm.controls.altitude.disable();
    }

    if (!this.isFeatureOwner) {
      this.readOnlyDate = true;
      // this.metadataForm.controls.datetimeUtc.disable();
    }

    /**
     * Listen on MetadataForm changes and emit new values when user update
     * any of input.
     */
    this.formSub = this.metadataForm.valueChanges
      .pipe(debounceTime(100))
      .subscribe((formValue) => {
        if (this.metadataForm.invalid) return;
        const { latitude, longitude, altitude, datetimeUtc } = formValue;
        if (
          latitude === undefined ||
          longitude === undefined ||
          altitude === undefined ||
          datetimeUtc === undefined
        )
          return;

        const zone = tz_lookup(latitude, longitude);
        this.metadataChange.emit({
          latitude,
          longitude,
          altitude,
          zone,
          datetimeUtc: new Date(`${formValue.datetimeUtc}T00:00:00.000Z`),
        });
      });

    // let disabled = true;
    // if (this.editorMode === 'CREATE') disabled = false;
    // if (this.editorMode === 'UPDATE') disabled = true;
    // if (this.editorMode === 'EDIT') disabled = !this.isFeatureOwner;
    //
    // this.metadataForm.patchValue({ latitude, longitude, altitude });
    // disabled ? this.metadataForm.disable() : this.metadataForm.enable();

    // const dateStr =
    //   this.record.datetimeUtc.toISOString().substring(0, 10) || null;
    // this.datetimeForm.patchValue({ datetimeUtc: dateStr });
    // disabled ? this.datetimeForm.disable() : this.datetimeForm.enable();

    // this.dateSub = this.datetimeForm.valueChanges.subscribe(() => {
    //   this.errorMsg = null;
    //   const dateValue = this.datetimeForm?.get('datetimeUtc')?.value;
    //   const datetimeUtc = new Date(`${dateValue}T00:00:00.000Z`);
    //   if (this.datetimeForm?.invalid) this.errorMsg = 'FieldDate required';
    //   else if (isNaN(datetimeUtc.getTime())) this.errorMsg = 'Invalid date';
    //   else this.datetimeChange.emit(datetimeUtc);
    // });
  }

  ngOnDestroy(): void {
    this.formSub?.unsubscribe();
  }
}
