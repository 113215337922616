import { createAction, props } from '@ngrx/store';
import { FieldType, FormSpecific, Media, Record } from '3map-models';
import {
  FieldCheck,
  FieldDate,
  FieldInt,
  FieldList,
  FieldNumber,
  FieldRange,
  FieldText,
} from '3map-models/dist/lib/model';
import { EditorMode } from '../models/EditorMode';
import { EditorTab } from '../models/EditorTab';
import { RecordEditorMetadata } from '../models/metadata-location.model';

export const enableRecordEditor = createAction(
  '[ RecordEditor ] Enable Record Editor',
  props<{
    mode: EditorMode;
    record: Record;
    activeTab: EditorTab | null;
  }>()
);

export const disableRecordEditor = createAction(
  '[ RecordEditor ] Disable Record Editor'
);

export const setActiveTab = createAction(
  '[ RecordEditor ] Set Active Tab',
  props<{ activeTab: EditorTab | null }>()
);

export const nextTab = createAction('[ RecordEditor ] Next Tab');

export const uploadRecord = createAction('[ RecordEditor ] Upload Record');

export const uploadRecordSuccess = createAction(
  '[ RecordEditor ] Upload Record Success',
  props<{ record: Record }>()
);

export const uploadRecordError = createAction(
  '[ RecordEditor ] Upload Record Error',
  props<{ error: string | null }>()
);

export const deleteRecordsSuccess = createAction(
  '[ RecordEditor ] Delete Records Success',
  props<{ recordIdsList: string[] }>()
);

export const deleteRecordsCanceled = createAction(
  '[ RecordEditor ] Delete Records Canceled'
);

export const deleteRecordsError = createAction(
  '[ RecordEditor ] Delete Records Error',
  props<{ error: string }>()
);

export const editQuestion = createAction(
  '[ RecordEditor ] Edit Question',
  props<{
    fieldType: FieldType;
    field:
      | FieldNumber
      | FieldText
      | FieldList
      | FieldRange
      | FieldCheck
      | FieldInt
      | FieldDate
      | null;
    specific: FormSpecific;
  }>()
);

export const editRecordMetadata = createAction(
  '[ RecordEditor ] Edit Location Metadata',
  props<{ metaLocation: RecordEditorMetadata }>()
);

export const setError = createAction(
  '[ RecordEditor ] Set Error',
  props<{ error: string | null }>()
);

export const addMedia = createAction(
  '[ RecordEditor ] Add Media',
  props<{ media: Media }>()
);

// TODO remove Media also from server...
export const removeMedia = createAction(
  '[ RecordEditor ] Remove Media',
  props<{ media: Media }>()
);

export const addUploadMediaList = createAction(
  '[ RecordEditor ] Upload Media List',
  props<{ mediaList: Media[] }>()
);
