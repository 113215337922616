import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartData } from '@trim-web-apps/weather-models';
import { ChartConfiguration } from 'chart.js';
import Chart from 'chart.js/auto';

export interface ChartArea {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

@Component({
  selector: 'weather-core-chart-view',
  template: `
    <div>
      <canvas
        #chartCanvas
        class="chart-canvas"
        style="cursor: crosshair"
      ></canvas>
    </div>
  `,
})
export class WeatherChartViewComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('chartCanvas') canvasRef!: ElementRef;
  @Input() chartData!: ChartData;
  @Input() xAxisLabels!: boolean;
  @Input() xAxisLabelsColor!: string;
  @Input() xAxisLabelRotation!: { min: number; max: number };
  @Input() xAxisPadding!: number;
  @Input() showLegend = false;
  @Input() responsive = true;
  @Input() maintainAspectRatio = false;
  @Input() canvasWidth!: number;
  @Input() isMobile!: boolean;
  @Output() chartReady: EventEmitter<void> = new EventEmitter<void>();
  @Output() canvasChartHeight: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() chartAreaChanged: EventEmitter<ChartArea> =
    new EventEmitter<ChartArea>();

  private chart!: Chart;
  private defaultChartJsEvents: any[] = [
    'mousemove',
    'mouseout',
    'click',
    'touchstart',
    'touchmove',
  ];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartData']['firstChange']) {
      return;
    }
    this.drawChart();
  }

  ngAfterViewInit() {
    this.canvasRef.nativeElement.height = this.chartData.chartHeight;
    if (this.canvasWidth) {
      this.canvasRef.nativeElement.width = this.canvasWidth;
    }

    const chartConfig: ChartConfiguration = {
      data: { labels: [], datasets: [] },
      options: {
        animation: { duration: 0 },
        events: this.isMobile
          ? ['click', 'touchend']
          : this.defaultChartJsEvents,
        maintainAspectRatio: this.maintainAspectRatio,
        responsive: this.responsive,
        scales: {
          y: {
            afterFit: (scale) => {
              scale.width = 40;
            },
            beginAtZero: this.chartData.type === 'bar',
          },
          x: {
            display: this.xAxisLabels,
            ticks: {
              maxRotation: this.xAxisLabelRotation
                ? this.xAxisLabelRotation.max
                : 50, // chartJs default
              minRotation: this.xAxisLabelRotation
                ? this.xAxisLabelRotation.min
                : 0, // chartJs default
              color: this.xAxisLabelsColor,
              padding: this.xAxisPadding ? this.xAxisPadding : 0,
            },
          },
        },
        plugins: {
          legend: {
            display: this.showLegend,
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              title: (tooltipItem) => {
                // label may be something like: "    06:00    ,    15 Jan    "
                return tooltipItem[0].label
                  .split(',')
                  .reduce((acc, curr) => acc + curr.trim() + ' ', '');
              },
            },
          },
        },
      },
      type: this.chartData.type as any,
    };

    this.chart = new Chart(
      this.canvasRef.nativeElement.getContext('2d'),
      chartConfig
    );

    this.chartReady.emit();
    this.drawChart();
  }

  ngOnDestroy() {
    this.chart?.destroy();
  }

  private drawChart(): void {
    this.chart.data = {
      labels: this.chartData.labels,
      datasets: [
        {
          ...this.getStyle(),
          data: this.chartData.data,
          label: this.chartData.legend,
        },
      ],
    };
    this.chart.update();
    this.canvasChartHeight.emit(
      this.chart.chartArea.bottom + this.chart.chartArea.top
    );
    this.chartAreaChanged.emit(this.chart.chartArea);
  }

  private getStyle(): any {
    switch (this.chartData.type) {
      case 'line':
        return {
          fill: false,
          borderColor: this.chartData.color || 'blue',
          backgroundColor: this.chartData.color || 'blue',
        };
      case 'bar':
        return {
          backgroundColor: this.chartData.color || 'blue',
        };
    }
  }
}
