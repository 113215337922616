import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, from, Observable, of, take } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService, notNullOrUndefined } from '@trim-web-apps/core';
import {
  ChartData,
  fromServerResponse,
  WeatherLayer,
  WeatherModel,
} from '@trim-web-apps/weather-models';
import {
  calculateWind,
  createArrayForecastUrl,
  createArrayUrl,
  createTiffUrl,
  getVectorCompArray,
  isVectorLayer,
  timestepToLabelArray,
} from '../utils/weather.functions';
/* eslint-disable-next-line */
// @ts-ignore
import { fromBlob } from 'geotiff/dist/geotiff.bundle.min.js';

@Injectable({
  providedIn: 'root',
})
export class WeatherApiService {
  private readonly API_URL = 'https://weather-api.trimweb.it';

  // private readonly API_URL = 'https://api.trimweb.it/api_v2';

  constructor(private http: HttpClient, private notify: NotificationService) {}

  fetchModels(): Observable<WeatherModel[]> {
    const models = [
      'AROME',
      'GFS',
      'GFS_ACC',
      'GFS_HISTORY',
      'LAMMA_ARW_3',
      'ALADIN',
      'GPM',
      'GPM2',
      'MOLOCH',
      // 'WRF_JAPAN_25',
      // 'WRF_JAPAN_05',
      'SHEVENINGEN',
      'HARMONIE_AROME',
      'AARHUS',
      'WRF_AARHUS_05',
      'WRF_AARHUS_25',
      'NWMED_MARSEILLE',
      'ERA5_MARSEILLE',
      'RFE',
      'ECMWF',
    ];
    const modelsRequests = models.map((model) =>
      this.fetchSpecification(model).pipe(
        switchMap((res) => of({ error: false, model, data: res })),
        catchError(() => of({ error: true, model, data: null }))
      )
    );
    return forkJoin(modelsRequests).pipe(
      tap((responses) => {
        const invalidModelResponses = responses
          .filter((res) => res.error)
          .map((res) => res.model);
        if (invalidModelResponses.length > 0) {
          this.notify.error(
            `[ ${invalidModelResponses.toString()} ] currently not available`,
            3000
          );
        }
      }),
      map((responses) =>
        responses
          .filter((res) => !res.error)
          .map((m) => {
            try {
              return fromServerResponse(m);
            } catch (e) {
              return null;
            }
          })
          .filter(notNullOrUndefined)
      )
    );
  }

  fetchModelById(modelId: string): Observable<WeatherModel> {
    return this.http
      .get(`${this.API_URL}/${modelId}/specification`)
      .pipe(
        switchMap((response) =>
          of(
            fromServerResponse({ error: false, model: modelId, data: response })
          )
        )
      );
  }

  /** @deprecated */
  fetchGeotiff(weather: WeatherModel, bbox: number[]): Observable<any> {
    const url = `${this.API_URL}/${createTiffUrl(weather, bbox)}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      switchMap((response) => from(fromBlob(response))),
      switchMap((tiff) => from((tiff as any).getImage()))
    );
  }

  fetchSpecification(modelId: string): Observable<any> {
    return this.http.get(`${this.API_URL}/${modelId}/specification`);
  }

  fetchGeotiffByUrl(url: string): Observable<any> {
    return this.http
      .get(`${this.API_URL}/${url}`, { responseType: 'blob' })
      .pipe(
        switchMap((response) => from(fromBlob(response))),
        switchMap((tiff) => from((tiff as any).getImage()))
      );
  }

  fetchArrayData(
    modelId: string,
    layerId: string,
    point: { lng: number; lat: number },
    initime: number
  ): Observable<any> {
    const fcst = createArrayForecastUrl(modelId, layerId, point, initime);
    return this.http.get(`${this.API_URL}/${fcst}`);
  }

  getChartArray(
    model: WeatherModel,
    l: WeatherLayer,
    point: { lng: number; lat: number },
    utcOffset: string
  ): Observable<ChartData[]> {
    const url = `${this.API_URL}/${createArrayUrl(model, point)}`;
    return this.http.get(url, { responseType: 'text' }).pipe(
      switchMap((response) =>
        of({ ...JSON.parse(response.replace(/\bNaN\b/g, 'null')) })
      ),
      switchMap((data) => {
        if (isVectorLayer(l)) {
          const vectorComponents = getVectorCompArray(data);
          if (!vectorComponents) return of([]);
          const { u_comp, v_comp } = vectorComponents;

          return [
            this.getWindChartData(model, l, utcOffset, {
              timestep_list: data.timestep_list,
              values: data[u_comp],
              values2: data[v_comp],
            }),
          ];
        }
        return [
          this.getStandardChartData(model, l, utcOffset, {
            timestep_list: data.timestep_list,
            values: data[l.id] as number[],
          }),
        ];
      })
    );
  }

  async downloadTiffAsFile(url: string, filename: string): Promise<void> {
    await this.http
      .get(`${this.API_URL}/${url}`, { responseType: 'blob' })
      .pipe(take(1))
      .subscribe((response: Blob) => {
        const link: any = document.createElement('a');
        link.href = URL.createObjectURL(response);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    // await this.http.get(`${this.API_URL}/${getGeotiffUrl(weather, bbox)}`, {responseType: 'blob'}).toPromise().then(
    //     ((response: Blob) => {
    //         const link: any = document.createElement('a')
    //         link.href = URL.createObjectURL(response)
    //         link.download = `${weather.model.name}_${weather.layer.name}_${weather.initime}_${weather.timestep}.tiff`
    //         document.body.appendChild(link)
    //         link.click()
    //         document.body.removeChild(link)
    //     })
    // )
  }

  private getWindChartData(
    model: WeatherModel,
    layer: WeatherLayer,
    utcOffset: string,
    response: any
  ): ChartData[] {
    const timestepReduced =
      layer.timestepType.type === 'UNIX'
        ? response.timestep_list.map(
            (tsUnix: any) =>
              (tsUnix - model.initime) / layer.timestepType.interval
          )
        : response.timestep_list;

    const valueIndexes = {
      start: timestepReduced.indexOf(model.dateRange.from), // 0
      end: timestepReduced.indexOf(model.dateRange.to), // response.timestep_list.length,
    };

    const u = response.values.slice(valueIndexes.start, valueIndexes.end + 1);
    const v = response.values2.slice(valueIndexes.start, valueIndexes.end + 1);
    const dirs: number[] = [];
    const speeds: number[] = [];
    const labels: string[] = timestepReduced
      .slice(valueIndexes.start, valueIndexes.end + 1)
      .map((ts: any) =>
        timestepToLabelArray(ts, model.initime, layer, utcOffset)
      );

    for (let i = 0; i < u.length; i++) {
      if (u[i] === null || v[i] === null) {
        // missing timestep, set null
        speeds.push(null as any);
        dirs.push(null as any);
      } else {
        const { speed, dir } = calculateWind(
          u[i],
          v[i],
          layer.conversionFactor
        );
        speeds.push(speed);
        dirs.push(dir);

        // previous version -- uncomment the following line and comment the above line

        // const speed = Math.sqrt(Math.pow(u[i], 2) + Math.pow(v[i], 2));
        // const dir = (Math.acos(v[i] / speed) * 360) / (2 * Math.PI);
        // speeds.push(Math.round(speed * layer.conversionFactor * 10) / 10);
        // dirs.push(
        //   u[i] < 0
        //     ? Math.round((360 - dir + 180) % 360)
        //     : Math.round((dir + 180) % 360)
        // );
      }
    }

    return [
      {
        data: dirs,
        type: 'line',
        color: 'blue',
        legend: 'Direction (deg)',
        chartHeight: 200,
        labels,
        visible: timestepReduced,
      },
      {
        data: speeds,
        type: 'line',
        color: 'red',
        legend: `Speed (${layer.unit})`,
        chartHeight: 200,
        labels,
        visible: true,
      },
    ];
  }

  private getStandardChartData(
    model: WeatherModel,
    layer: WeatherLayer,
    utcOffset: string,
    response: any
  ): ChartData[] {
    const barLayers = ['rainfall'];
    const isRainfallData = barLayers.indexOf(layer.id.substring(0, 8)) > -1;
    const chartType = isRainfallData ? 'bar' : 'line';
    const timestepReduced =
      layer.timestepType.type === 'UNIX'
        ? response.timestep_list.map(
            (tsUnix: any) =>
              (tsUnix - model.initime) / layer.timestepType.interval
          )
        : response.timestep_list;

    const valueIndexes = {
      start: timestepReduced.indexOf(model.dateRange.from), // 0
      end: timestepReduced.indexOf(model.dateRange.to), // response.timestep_list.length,
    };

    const labels: string[] = timestepReduced
      .slice(valueIndexes.start, valueIndexes.end + 1)
      .map((ts: any) =>
        timestepToLabelArray(ts, model.initime, layer, utcOffset)
      );

    let values = response.values;
    if (response.values.length !== response.timestep_list.length) {
      values = values.filter((item: any, index: number) => index % 3 === 0);
    }

    const chartData: ChartData = {
      data: values
        .slice(valueIndexes.start, valueIndexes.end + 1)
        .map((value: any) =>
          value === null
            ? null // return null if timestep is missing
            : Math.round(value * layer.conversionFactor * 10) / 10
        ),
      type: chartType,
      legend: `${layer.label} (${layer.unit})`,
      chartHeight: 200,
      labels,
      visible: true,
      color: 'blue',
    };

    if (isRainfallData) {
      let sum: number;
      const accumulated = chartData.data.map(
        (elem) => (sum = (sum || 0) + elem)
      );
      return [
        chartData,
        {
          data: accumulated,
          type: 'line',
          legend: `Accumulated ${layer.label} (${layer.unit})`,
          chartHeight: 200,
          labels,
          color: '#FFC107',
          visible: false,
        },
      ];
    }

    return [chartData];
  }
}
