import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CoreModule } from '@trim-web-apps/core';
import { BtnComponent } from './btn/btn.component';
import { CdkMenuModule } from '@angular/cdk/menu';
import { DropdownComponent } from './dropdown/dropdown.component';
import { SpinnerCircleComponent } from './spinner-circle/spinner-circle.component';
import { RoleBadgeComponent } from './role-badge/role-badge.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { DropdownIconComponent } from './dropdown-icon/dropdown-icon.component';
import { SelectComponent } from './select/select.component';
import { CdkListbox, CdkOption } from '@angular/cdk/listbox';
import { SelectMultipleComponent } from './select/select-multiple.component';
import { SelectBaseComponent } from './select/select-base.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    CoreModule,
    CdkMenuModule,
    CdkListbox,
    CdkOption,
  ],
  declarations: [
    CheckboxComponent,
    BtnComponent,
    DropdownComponent,
    SpinnerCircleComponent,
    RoleBadgeComponent,
    ExpansionPanelComponent,
    DropdownIconComponent,
    SelectComponent,
    SelectBaseComponent,
    SelectMultipleComponent,
    LoadingBarComponent,
  ],
  exports: [
    CheckboxComponent,
    BtnComponent,
    DropdownComponent,
    RoleBadgeComponent,
    ExpansionPanelComponent,
    DropdownIconComponent,
    SelectComponent,
    SelectMultipleComponent,
    LoadingBarComponent,
  ],
})
export class Map3UiModule {}
