import { Injectable } from '@angular/core';
import { ApiService } from '@trim-web-apps/api3map';
import { Observable, of } from 'rxjs';
import { notNullOrUndefined } from '@trim-web-apps/core';

@Injectable({ providedIn: 'root' })
export class ResourceService {
  private fileToUpload: { [filename: string]: Blob | null } = {};

  constructor(private api: ApiService) {}

  getResource(filename: string, projectName: string): Observable<Blob> {
    const localFile = this.fileToUpload[filename];
    return localFile
      ? of(localFile)
      : this.fetchResource(filename, projectName);
  }

  removeResource(filename: string): void {
    this.fileToUpload[filename] = null;
    delete this.fileToUpload[filename];
    this.debug();
  }

  getFileToUpload(): { filename: string; blob: Blob }[] {
    return Object.keys(this.fileToUpload)
      .map((filename) => {
        const blob = this.fileToUpload[filename];
        return blob ? { filename, blob } : null;
      })
      .filter(notNullOrUndefined);
  }

  reset(): void {
    this.fileToUpload = {};
  }

  addResourceToUpload(filename: string, file: File): void {
    this.fileToUpload[filename] = file;
    this.debug();
  }

  fetchResource(filename: string, projectName: string): Observable<Blob> {
    return this.api.fetchAdminStaticFile(filename, projectName);
  }

  private debug(): void {
    console.log('ResourceService', this.fileToUpload);
  }
}
