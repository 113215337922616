import { ActionReducerMap } from '@ngrx/store';
import * as fromForm from './form/form.reducer';
import * as fromSpecific from './specific/form-specific.reducer';
import * as fromMarkerStyle from './marker-style/marker-style.reducer';
import * as fromUser from './user/user.reducer';
import * as fromWebHook from './webhook/webhook.reducer';
import * as fromProjectProps from './project-props/project-props.reducer';
import * as fromResource from './resource/resource.reducer';
import * as fromLink from './link';

export interface State {
  forms: fromForm.State;
  specifics: fromSpecific.State;
  markerStyle: fromMarkerStyle.State;
  users: fromUser.State;
  webhooks: fromWebHook.State;
  links: fromLink.State;
  projectProps: fromProjectProps.State;
  resource: fromResource.State;
}

export const projectReducerMap: ActionReducerMap<State> = {
  forms: fromForm.reducer,
  specifics: fromSpecific.reducer,
  markerStyle: fromMarkerStyle.reducer,
  users: fromUser.reducer,
  webhooks: fromWebHook.reducer,
  links: fromLink.reducer,
  projectProps: fromProjectProps.reducer,
  resource: fromResource.reducer,
};
