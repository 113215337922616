export class StorePersistence<T> {
  private readonly lsk: string;

  constructor(localStorageKey: string) {
    this.lsk = localStorageKey;
  }

  /**
   * Save the given value to localStorage.
   * Note: value will pass through `JSON.stringify`.
   * @param value: T
   */
  save(value: T): void {
    localStorage.setItem(this.lsk, JSON.stringify(value));
  }

  /**
   * Retrieve previously saved value. Return null if it does not exists.
   */
  get(): T | null {
    const stored = localStorage.getItem(this.lsk);
    return stored !== null ? JSON.parse(stored) : null;
  }

  /**
   * Remove currently saved value.
   */
  clear(): void {
    localStorage.removeItem(this.lsk);
  }
}
