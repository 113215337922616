import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { MapMouseEvent } from 'mapbox-gl';
import { fromEvent, Observable, Subscription, tap } from 'rxjs';
import { MapService } from '@trim-web-apps/map';
import { debounceTime } from 'rxjs/operators';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { WeatherService } from '../../services/weather.service';

@Component({
  selector: 'weather-core',
  template: `
    <div
      class="wrapper"
      *ngFor="let model of weatherModels$ | async; trackBy: trackBy"
    >
      <weather-core-box
        [weatherModel]="model"
        [mapStyleId]="mapStyleId"
        [selectedLngLat]="selectedLngLat$ | async"
      >
      </weather-core-box>
    </div>
    <weather-core-popup *ngIf="!disablePopup"></weather-core-popup>
  `,
  styles: [],
})
export class WeatherComponent implements OnInit, OnDestroy {
  @Input() mapInstance!: mapboxgl.Map;
  @Input() mapStyleId!: string;
  @Input() autoDetectUtcOffset!: boolean;
  @Input() disablePopup?: boolean;
  @Output() tiffDrawerReady: EventEmitter<void> = new EventEmitter<void>();

  weatherModels$: Observable<WeatherModel[]>;
  selectedLngLat$: Observable<{ lng: number; lat: number } | null>;
  private moveEndSubs: Subscription | undefined;

  constructor(
    private weatherService: WeatherService,
    private mapService: MapService
  ) {
    this.weatherModels$ = this.weatherService
      .getEnabledModels()
      .pipe(tap((models) => this.onEnabledModelsChange(models)));
    this.selectedLngLat$ = this.weatherService.getLngLat();
  }

  ngOnInit(): void {
    if (!this.mapInstance) throw Error('Map not provided');
    if (!this.mapStyleId)
      console.warn('[weather-core] mapStyleId not provided');

    this.setMapBoundingArray();
    this.mapInstance.on('click', (e: MapMouseEvent) =>
      this.weatherService.setLngLat({
        lng: e.lngLat.lng,
        lat: e.lngLat.lat,
      })
    );
    this.moveEndSubs = fromEvent(this.mapInstance as any, 'moveend')
      .pipe(debounceTime(500))
      .subscribe(() => this.setMapBoundingArray());
  }

  ngOnDestroy() {
    this.moveEndSubs?.unsubscribe();
  }

  trackBy(index: number, data: WeatherModel) {
    return data.id;
  }

  private setMapBoundingArray(): void {
    this.weatherService.setMapBoundingArray(
      this.mapService.getPaddedBoundsAsArray()
    );
  }

  private onEnabledModelsChange(weatherModelList: WeatherModel[]): void {
    if (!this.mapInstance) return;
    const weatherLayerIds = [
      'border_other_weather',
      'border_country_weather',
      'border_disputed_weather',
      'countries_weather',
      'water_coastline_weather',
    ];

    const hasModels = weatherModelList.length > 0;
    const boundaryVisibility = hasModels ? 'visible' : 'none';
    const styleName = this.mapInstance.getStyle().name;

    // if (hasModels && styleName !== GREY.title)
    //   this.mapInstance.setStyle(GREY.uri);
    // if (!hasModels && styleName === GREY.title)
    //   this.mapInstance.setStyle(LIGHT.title);

    weatherLayerIds.forEach((layerId) => {
      if (this.mapInstance.getLayer(layerId))
        this.mapInstance.setLayoutProperty(
          layerId,
          'visibility',
          boundaryVisibility
        );
    });
  }
}
