import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, map, switchMap, tap } from 'rxjs';
import { MarkerService } from '../../marker.service';
import { MapboxService } from '../mapbox.service';
import * as MapActions from './map.actions';
import { Store } from '@ngrx/store';
import { ProjectActions, ProjectSelectors } from '../../project/+state';
import { SidenavActions } from '../../sidenav/+state';
import { MapSelectors } from './index';
import { take, withLatestFrom } from 'rxjs/operators';
import { TableActions } from '../../table/+state';
import { RecordSelectors } from '../../record/+state';
import { Record } from '3map-models';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { splitByFeatureId } from '../../shared/project.utils';

@Injectable()
export class MapEffects {
  constructor(
    private actions$: Actions,
    private mapboxService: MapboxService,
    private markerService: MarkerService,
    private store: Store
  ) {}

  loadIcons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.ADD_MARKERS),
      map(() => {
        // console.log('draw marker!!');
        const map = this.mapboxService.map;
        for (const [iconId, image] of this.markerService.markers) {
          if (map.hasImage(iconId)) map.removeImage(iconId);
          map.addImage(iconId, image);
        }
        return MapActions.ADD_MARKERS_SUCCESS();
      })
    )
  );

  /**
   * Markers need to be (re)drawn on:
   * - mapStyle change (e.g. base layer changed)
   * - Project is successfully fetched
   * Do not dispatch if Project is null or Map is not yer loaded.
   */
  styleChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MapActions.MAP_STYLE_CHANGED,
          ProjectActions.fetchProjectSuccess
        ),
        switchMap(() => {
          return combineLatest([
            this.store.select(ProjectSelectors.selectProject()),
            this.store.select(MapSelectors.selectMapLoaded()),
          ]).pipe(take(1));
        }),
        map(([project, mapLoaded]) => {
          if (project === null || !mapLoaded) return;
          this.store.dispatch(MapActions.ADD_MARKERS());
        })
      ),
    { dispatch: false }
  );

  sectionChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SidenavActions.setSection),
        tap(({ section }) => {
          if (section === 'MAP') {
            try {
              setTimeout(() => this.mapboxService.map.resize());
            } catch {}
          }
        })
      ),
    { dispatch: false }
  );

  processedRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TableActions.processedRecordIdsChanged),
      concatLatestFrom(() =>
        this.store.select(RecordSelectors.selectRecordsDict())
      ),
      map(([{ formId, processedRecordsIds }, recordsDict]) => {
        const records: Record[] = processedRecordsIds
          .map((recordId) => recordsDict[recordId])
          .filter(notNullOrUndefined);
        const recordByFeatureId = splitByFeatureId(records, false);
        const recordIdsByFeatureId: { [featureId: string]: string[] } = {};
        for (const [featureId, records] of Object.entries(recordByFeatureId)) {
          if (records.length > 0) {
            recordIdsByFeatureId[featureId] = records.map(
              (record) => record.recordId
            );
          }
        }
        return MapActions.setMapRecords({ formId, recordIdsByFeatureId });
      })
    )
  );

  zoomToRecord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MapActions.zoomToRecords,
          TableActions.zoomToRecords,
          TableActions.zoomToRecord
        ),
        map((action) => {
          switch (action.type) {
            case MapActions.zoomToRecords.type:
              return action.recordIdsList;
            case TableActions.zoomToRecords.type:
              return action.recordIdsList;
            case TableActions.zoomToRecord.type:
              return [action.recordId];
            default:
              return [];
          }
        }),
        concatLatestFrom(() =>
          this.store.select(RecordSelectors.selectRecordsDict())
        ),
        map(([recordIdsList, recordsDict]) => {
          const records = recordIdsList
            .map((id) => recordsDict[id])
            .filter(notNullOrUndefined);
          if (records.length) this.mapboxService.zoomToRecords(records);
        })
      ),
    { dispatch: false }
  );

  zoomToForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MapActions.zoomToForm),
        concatLatestFrom(({ formId }) =>
          this.store.select(MapSelectors.selectMapRecords(formId))
        ),
        map(([, mapRecords]) =>
          this.mapboxService.zoomToRecords(mapRecords || [])
        )
      ),
    { dispatch: false }
  );

  searchLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SidenavActions.searchLocation),
        tap(({ searchLocation }) =>
          this.mapboxService.setSearchMarker(searchLocation)
        )
      ),
    { dispatch: false }
  );

  zoomToSearchLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SidenavActions.zoomToSearchLocation),
        withLatestFrom(this.store.select(MapSelectors.selectSearchLocation())),
        tap(([, searchLocation]) => {
          if (searchLocation) {
            const { lng, lat } = searchLocation;
            this.mapboxService.setMapToLngLat(lng, lat);
          }
        })
      ),
    { dispatch: false }
  );
}
