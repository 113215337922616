import { Component, Inject } from '@angular/core';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'weather-core-model-info',
  template: `
    <div class="model-info-wrapper shadow">
      <div class="model-info" *ngIf="model">
        <div class="info-row">
          <div class="label">Name</div>
          <div class="value">{{ model.label }}</div>
        </div>

        <div class="info-row">
          <div class="label">Layers list</div>
          <div class="value value-list">
            <div class="item" *ngFor="let layer of model.layers">
              <div class="layer-name text-ellipsis">{{ layer.label }}</div>
              <div class="layer-unit">{{ layer.unit }}</div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="model.info">
          <div class="info-row">
            <div class="label">Resolution</div>
            <div class="value">{{ model.info.resolution }}</div>
          </div>

          <div class="info-row">
            <div class="label">Coverage</div>
            <div class="value">{{ model.info.coverage }}</div>
          </div>

          <div class="info-row">
            <div class="label">Source</div>
            <div class="value url" (click)="openUrl(model.info.url)">
              {{ model.info.credits }}
              <fa-icon [icon]="iconUrl"></fa-icon>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!model.info">
          <div class="model-info-nope" *ngIf="!model">
            Model details not available
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .model-info-wrapper {
        background-color: #ffffff;
        padding: 10px;
        overflow: auto;
        max-height: 80vh;
      }

      .info-row {
        margin-bottom: 20px;
      }

      .label {
        margin-bottom: 5px;
        color: var(--primary-color);
      }

      .value {
        margin: 0 10px;
      }

      .item {
        display: flex;
        justify-content: space-between;
      }

      .layer-name {
        margin-bottom: 5px;
      }

      .layer-unit {
        white-space: nowrap;
        margin-bottom: 5px;
        color: var(--border);
      }

      .model-info-nope {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .url {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .url fa-icon {
        margin-left: 5px;
      }
    `,
  ],
})
export class ModelInfoComponent {
  iconUrl = faExternalLinkAlt;
  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public model: WeatherModel
  ) {}

  openUrl(url?: string): void {
    if (url) window.open(url, '_blank');
  }
}
