// WHAT THE ACTUAL FUCK
// DO NOT CHANGE THIS UNLESS ABSOLUTELY NECESSARY... GOOD LUCK.

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { TimestepType, TimeUnit } from '@trim-web-apps/weather-models';
import * as moment from 'moment';
import { Moment } from 'moment';
import { notNullOrUndefined } from '@trim-web-apps/core';

@Component({
  selector: 'weather-core-datetime-picker-inline',
  template: `
    <div class="datetime-picker-wrapper" *ngIf="dateRangeData">
      <!--      <pre>{{ pickerDate | json }}</pre>-->
      <div cdkMenuBar class="menu-bar" [class.error]="errorMsg">
        <div
          cdkMenuItem
          class="menu-bar-item time"
          *ngIf="dateRangeData.showTime"
          [cdkMenuTriggerFor]="time"
        >
          {{ pickerDate.time || 'Time' }}
        </div>

        <div
          cdkMenuItem
          class="menu-bar-item day"
          *ngIf="dateRangeData.showDay"
          [cdkMenuTriggerFor]="day"
        >
          {{ pickerDate.day || 'Day' }}
        </div>

        <div
          cdkMenuItem
          class="menu-bar-item quarter"
          *ngIf="dateRangeData.showQuarter"
          [cdkMenuTriggerFor]="quarter"
        >
          {{ pickerDate.quarter || 'Quarter' }}
        </div>

        <div
          cdkMenuItem
          class="menu-bar-item month"
          *ngIf="dateRangeData.showMonth"
          [cdkMenuTriggerFor]="month"
        >
          {{ pickerDate.month || 'Month' }}
        </div>

        <div
          cdkMenuItem
          class="menu-bar-item semester"
          *ngIf="dateRangeData.showSemester"
          [cdkMenuTriggerFor]="semester"
        >
          {{ pickerDate.semester || 'Semester' }}
        </div>

        <div
          cdkMenuItem
          class="menu-bar-item year"
          *ngIf="dateRangeData.showYear"
          [cdkMenuTriggerFor]="year"
        >
          {{ pickerDate.year || 'Year' }}
        </div>
      </div>

      <ng-template #year>
        <div class="menu shadow" cdkMenu>
          <div
            class="menu-item"
            cdkMenuItem
            *ngFor="let year of yearsList"
            (click)="onYearSelect(year)"
          >
            {{ year }}
          </div>
        </div>
      </ng-template>

      <ng-template #semester>
        <div class="menu shadow" cdkMenu>
          <div
            class="menu-item"
            cdkMenuItem
            *ngFor="let semester of semesterList"
            (click)="onSemesterSelect(semester)"
          >
            {{ year }}
          </div>
        </div>
      </ng-template>

      <ng-template #month>
        <div class="menu shadow" cdkMenu>
          <div
            class="menu-item"
            cdkMenuItem
            *ngFor="let month of monthList"
            (click)="onMonthSelect(month)"
          >
            {{ month }}
          </div>
        </div>
      </ng-template>

      <ng-template #quarter>
        <div class="menu shadow" cdkMenu>
          <div
            class="menu-item"
            cdkMenuItem
            *ngFor="let q of quarterList"
            (click)="onQuarterSelect(q)"
          >
            {{ q }}
          </div>
        </div>
      </ng-template>

      <ng-template #day>
        <div class="menu shadow" cdkMenu>
          <div
            class="menu-item"
            cdkMenuItem
            *ngFor="let day of dayList"
            (click)="onDaySelect(day)"
          >
            {{ day }}
          </div>
        </div>
      </ng-template>

      <ng-template #time>
        <div class="menu shadow" cdkMenu>
          <div
            class="menu-item"
            cdkMenuItem
            *ngFor="let time of timeList"
            (click)="onTimeSelect(time)"
          >
            {{ time }}
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .menu-bar {
        display: flex;
        cursor: pointer;
      }

      .menu-bar-item {
        border: 1px solid var(--border);
        padding: 5px 10px;
        border-radius: 5px;
        margin: 0 5px;
        text-align: center;
      }

      .menu-item {
        margin: 0 10px;
        padding: 5px 10px;
        text-align: center;
      }

      .menu {
        background-color: #ffffff;
        cursor: pointer;
        max-height: 300px;
        overflow: auto;
      }

      .header div {
        width: 120px;
        margin: 0 5px;
        text-align: center;
      }

      .error {
        color: red;
      }

      .year {
        width: 65px;
      }

      .quarter {
        width: 100px;
      }

      .month {
        width: 60px;
      }

      .day {
        width: 45px;
      }

      .time {
        width: 65px;
      }
    `,
  ],
})
export class DatetimePickerInlineComponent implements OnChanges {
  @Input() data!: {
    tsList: TimeUnit[];
    selected: TimeUnit;
    tsType: TimestepType;
  };
  @Input() utcOffset?: string;
  @Input() showHeader?: boolean;
  @Output() timeUnitChange: EventEmitter<TimeUnit> =
    new EventEmitter<TimeUnit>();

  pickerDate: {
    year: string | null;
    month: string | null;
    quarter: string | null;
    semester: string | null;
    day: string | null;
    time: string | null;
  };

  yearsList: string[];
  monthList: string[];
  quarterList: string[];
  semesterList: string[];
  dayList: string[];
  timeList: string[];

  dateRangeData?: {
    showTime: boolean;
    showDay: boolean;
    showMonth: boolean;
    showQuarter: boolean;
    showSemester: boolean;
    showYear: boolean;
  };

  errorMsg?: boolean;

  constructor() {
    this.pickerDate = {
      year: null,
      semester: null,
      month: null,
      quarter: null,
      day: null,
      time: null,
    };
    this.yearsList =
      this.monthList =
      this.semesterList =
      this.quarterList =
      this.dayList =
      this.timeList =
        [];
  }

  ngOnChanges(): void {
    if (!this.data) return;
    const { tsList, selected, tsType } = this.data;

    this.pickerDate = {
      year: selected.asDate.format('YYYY'),
      semester: semesterToLabel(selected.asDate),
      month: selected.asDate.format('MMM'),
      quarter: quarterToLabel(selected.asDate),
      day: selected.asDate.format('DD'),
      time: selected.asDate.format('HH:mm'),
    };

    this.dateRangeData = {
      showYear: true,
      showMonth:
        tsType.type === 'UNIX' ||
        tsType.type === 'DAYS' ||
        tsType.type === '1MONTH' ||
        tsType.type === '3MONTH' ||
        tsType.type === '6MONTH',
      showQuarter: tsType.type === 'QUARTER',
      showSemester: tsType.type === 'SEMESTER',
      showDay: tsType.type === 'UNIX' || tsType.type === 'DAYS',
      showTime: tsType.type === 'UNIX',
    };

    this.yearsList = getYearList(tsList);
    this.semesterList = getSemesterList(tsList, this.pickerDate.semester);
    this.monthList = getMonthList(tsList, this.pickerDate.year);
    this.quarterList = getQuarterList(tsList, this.pickerDate.year);
    this.dayList = getDayList(
      tsList,
      this.pickerDate.year,
      this.pickerDate.month
    );
    this.timeList = getTimeList(
      tsList,
      this.pickerDate.year,
      this.pickerDate.month,
      this.pickerDate.day
    );
  }

  get selected() {
    if (this.data) return this.data.selected;
    throw Error();
  }

  get _utcOffset() {
    if (this.utcOffset) return this.utcOffset;
    throw Error('Missing utcOffset');
  }

  onYearSelect(year: string): void {
    this.pickerDate.year = year;
    const newDate = this.getDatetimePickerMoment();
    const timeUnit = this.getTimeUnit(newDate);
    if (timeUnit) this.emit(timeUnit);
    else {
      this.yearsList = getYearList(this.data.tsList);
      this.semesterList = getSemesterList(this.data.tsList, year);
      this.monthList = getMonthList(this.data.tsList, year);
      this.quarterList = getQuarterList(this.data.tsList, year);
      this.dayList = [];
      this.timeList = [];
      this.errorMsg = true;
    }
  }

  onMonthSelect(month: string): void {
    this.pickerDate.month = month;
    const newDate = this.getDatetimePickerMoment();
    const timeUnit = this.getTimeUnit(newDate);
    if (timeUnit) this.emit(timeUnit);
    else {
      this.dayList = getDayList(this.data.tsList, this.pickerDate.year, month);
      this.timeList = [];
      this.errorMsg = true;
    }
  }

  onSemesterSelect(semester: string): void {
    this.pickerDate.semester = semester;
    this.pickerDate.month = semester.split(' - ')[1];
    const newDate = this.getDatetimePickerMoment();
    const timeUnit = this.getTimeUnit(newDate);
    if (timeUnit) this.emit(timeUnit);
    else {
      this.quarterList = getSemesterList(
        this.data.tsList,
        this.pickerDate.year
      );
      this.errorMsg = true;
    }
  }

  onQuarterSelect(quarter: string): void {
    this.pickerDate.quarter = quarter;
    this.pickerDate.month = quarter.split(' - ')[1];
    const newDate = this.getDatetimePickerMoment();
    const timeUnit = this.getTimeUnit(newDate);
    if (timeUnit) this.emit(timeUnit);
    else {
      this.quarterList = getQuarterList(this.data.tsList, this.pickerDate.year);
      this.errorMsg = true;
    }
  }

  onDaySelect(day: string): void {
    this.pickerDate.day = day;
    const newDate = this.getDatetimePickerMoment();
    const timeUnit = this.getTimeUnit(newDate);
    if (timeUnit) this.emit(timeUnit);
    else {
      this.timeList = getTimeList(
        this.data.tsList,
        this.pickerDate.year,
        this.pickerDate.month,
        this.pickerDate.day
      );
      this.errorMsg = true;
    }
  }

  onTimeSelect(time: string): void {
    this.pickerDate.time = time;
    const newDate = this.getDatetimePickerMoment();
    const timeUnit = this.getTimeUnit(newDate);
    if (timeUnit) this.emit(timeUnit);
    else this.errorMsg = true;
  }

  private emit(timeUnit: TimeUnit | null): void {
    console.log(`API ts: ${timeUnit?.apiValue} - label: ${timeUnit?.label}`);
    if (timeUnit) {
      this.timeUnitChange.emit(timeUnit);
      this.errorMsg = false;
    }
  }

  private getTimeUnit(date: Moment | null): TimeUnit | null {
    if (!this.data || !date) return null;
    return (
      this.data.tsList.find((d) => {
        return d.asDate.isSame(date);
      }) || null
    );
  }

  private getDatetimePickerMoment(): Moment | null {
    const { year, month, quarter, day, time } = this.pickerDate;
    if (!year || !month || !day || !time) return null;
    const monthNumber = moment()
      .set('months', monthStringToNumber(month))
      .format('MM');
    const hour = this.data.tsType.type === 'UNIX' ? time.split(':')[0] : '00';
    const minute = this.data.tsType.type === 'UNIX' ? time.split(':')[1] : '00';

    if (this.data.tsType.type !== 'UNIX') {
      const dateString = `${year}-${monthNumber}-${day}T${hour}:${minute}:00.000Z`;
      return moment(dateString);
    } else {
      const m = moment()
        .utcOffset(this._utcOffset)
        .set('year', parseInt(year))
        .set('month', monthStringToNumber(month))
        .set('date', parseInt(day))
        .set('hour', parseInt(hour))
        .set('minute', parseInt(minute))
        .set('second', 0)
        .set('millisecond', 0);
      return m;
    }
  }
}

function getYearList(tsList: TimeUnit[]): string[] {
  const yearList = tsList.map((ts) => ts.asDate.format('YYYY'));
  return Array.from(new Set(yearList));
}

function getMonthList(tsList: TimeUnit[], year: string | null): string[] {
  if (!year) return [];
  const monthList = tsList
    .map((ts) => {
      if (ts.asDate.year() === parseInt(year)) return ts.asDate.format('MMM');
      return null;
    })
    .filter(notNullOrUndefined);
  return Array.from(new Set(monthList));
}

function getDayList(
  tsList: TimeUnit[],
  year: string | null,
  month: string | null
): string[] {
  if (!year || !month) return [];
  const monthNumber = monthStringToNumber(month);
  const dayList = tsList
    .map((ts) => {
      const tsYear = ts.asDate.year();
      const tsMonth = ts.asDate.month();
      if (tsYear === parseInt(year) && tsMonth === monthNumber)
        return ts.asDate.format('DD');
      return null;
    })
    .filter(notNullOrUndefined);

  return Array.from(new Set(dayList));
}

function monthStringToNumber(monthStr: string): number {
  return moment(monthStr, 'MMM').get('month');
}

function getTimeList(
  tsList: TimeUnit[],
  year: string | null,
  month: string | null,
  day: string | null
): string[] {
  if (!year || !month || !day) return [];
  const monthNumber = monthStringToNumber(month);
  const timeList = tsList.map((ts) => {
    const tsYear = ts.asDate.year();
    const tsMonth = ts.asDate.month();
    const tsDay = ts.asDate.date();
    if (
      tsYear === parseInt(year) &&
      tsMonth === monthNumber &&
      tsDay === parseInt(day)
    )
      return ts.asDate.format('HH:mm');
    return null;
  });

  return Array.from(new Set(timeList.filter(notNullOrUndefined)));
}

function getQuarterList(tsList: TimeUnit[], year: string | null): string[] {
  if (!year) return [];
  const quarterList = tsList
    .map((ts) => {
      if (ts.asDate.year() === parseInt(year)) return quarterToLabel(ts.asDate);
      return null;
    })
    .filter(notNullOrUndefined);
  return Array.from(new Set(quarterList));
}

function getSemesterList(tsList: TimeUnit[], year: string | null): string[] {
  if (!year) return [];
  const semesterList = tsList
    .map((ts) => {
      if (ts.asDate.year() === parseInt(year))
        return semesterToLabel(ts.asDate);
      return null;
    })
    .filter(notNullOrUndefined);
  return Array.from(new Set(semesterList));
}

function quarterToLabel(date: Moment): string {
  const from = date.clone().subtract(2, 'months');
  return `${from.format('MMM')} - ${date.format('MMM')}`;
}

function semesterToLabel(date: Moment): string {
  const from = date.clone().subtract(5, 'months');
  return `${from.format('MMM')} - ${date.format('MMM')}`;
}
