import { Component } from '@angular/core';
import { getDefaultRawStyle } from '@trim-web-apps/project-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import * as uuid from 'uuid';
import { Store } from '@ngrx/store';
import { Resource } from '3map-models';
import { createResource } from '../../+state/resource.actions';
import { NotificationService } from '@trim-web-apps/core';

@Component({
  selector: 'app-resource-create]',
  template: `
    <div class="create-resource-wrapper">
      <ui-btn
        [icon]="iconAdd"
        type="border"
        (click)="inputFile.click()"
        label="Select File"
      ></ui-btn>
      <input #inputFile type="file" (change)="onFileSelected($event)" />
      <div class="info">Supported file types: .png, .jpg, .jpeg, .geojson</div>
    </div>
  `,
  styles: [
    `
      input {
        display: none;
      }

      ui-btn {
        margin-right: var(--spacing-3);
      }

      .create-resource-wrapper {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class ResourceCreateComponent {
  iconAdd = faPlus;

  constructor(private store: Store, private notify: NotificationService) {}

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.item(0);
    if (!file) return;
    const isImage = file.type.startsWith('image/');
    const isGeoJson = file.type === 'application/geo+json';

    if (isImage) return this.createImageResource(file);
    if (isGeoJson) return this.createGeoJsonResource(file);

    this.notify.error('Invalid file type');
  }

  private createImageResource(file: File): void {
    const { name, ext } = splitFilename(file.name);
    const resource: Resource = {
      type: 'IMAGE',
      name: name,
      filename: `${uuid.v4()}.${ext}`,
      boundingBox: [
        [-45, 45],
        [45, 45],
        [45, -45],
        [-45, -45],
      ],
    };
    this.store.dispatch(createResource({ resource, file }));
  }

  private createGeoJsonResource(file: File): void {
    const { name } = splitFilename(file.name);
    const resource: Resource = {
      type: 'GEOJSON',
      name: name,
      filename: `${uuid.v4()}.geojson`,
      style: getDefaultRawStyle(),
    };
    this.store.dispatch(createResource({ resource, file }));
  }
}

function splitFilename(filename: string): { name: string; ext: string } {
  const name = filename.split('.')[0] || '';
  const ext = filename.split('.').pop() || '';
  return { name, ext };
}
