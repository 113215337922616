import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as DeploySelectors from '../+state/deploy.selectors';
import * as DeployActions from '../+state/deploy.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-deploy',
  template: `
    <div class="header">
      <app-section-label sectionLabel="Deploy Project" />
      <div class="deploy-container">
        <app-deploy-actions
          [deployAllowed]="(deployAllowed$ | async) ?? false"
          (deploy)="onDeploy()"
          (revalidate)="onRevalidateRecords()"
        ></app-deploy-actions>
        <app-deploy-stepper></app-deploy-stepper>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .deploy-container {
        padding: 0 30px 30px 30px;
        overflow: auto;
      }

      app-deploy-actions {
        margin-bottom: 30px;
      }
    `,
  ],
})
export class DeployComponent implements OnInit, OnDestroy {
  deployAllowed$: Observable<boolean>;

  constructor(private store: Store) {
    this.deployAllowed$ = this.store.select(
      DeploySelectors.selectProjectDeployable()
    );
  }

  ngOnInit(): void {
    this.store.dispatch(DeployActions.validateRecords());
  }

  ngOnDestroy(): void {
    this.store.dispatch(DeployActions.reset());
  }

  onDeploy(): void {
    this.store.dispatch(DeployActions.deployProject());
  }

  onRevalidateRecords(): void {
    this.store.dispatch(DeployActions.validateRecords());
  }
}
