import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Form, FormSpecific, Record } from '3map-models';
import {
  faLocationCrosshairs,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

export type FeatureViewData = {
  form: Form;
  specific: FormSpecific;
  records: Record[];
};

@Component({
  selector: 'app-feature-view',
  template: `
    <div class="feature-view-wrapper" *ngIf="featureViewData">
      <div class="header">
        <div class="icon">
          <app-specific-icon
            [widthPx]="40"
            [specificId]="featureViewData.records[0].formSpecificId"
          ></app-specific-icon>
        </div>
        <div class="specific-name text-ellipsis">
          {{ featureViewData.specific.name }}
        </div>
        <div class="actions">
          <fa-icon [icon]="iconPlus" (click)="update()"></fa-icon>
          <fa-icon [icon]="iconGps" (click)="onZoomToRecord()"></fa-icon>
        </div>
      </div>

      <!-- "itemSize" should match app-record-card-compact height! -->
      <cdk-virtual-scroll-viewport itemSize="160" class="record-list">
        <app-record-card-compact
          *cdkVirtualFor="let record of featureViewData.records; let i = index"
          [record]="record"
          [form]="featureViewData.form"
          [specific]="featureViewData.specific"
          [isFirst]="i === 0"
          [isLast]="i === featureViewData.records.length - 1"
          (recordSelected)="recordSelected.emit(record)"
          (recordDelete)="recordDelete.emit($event)"
          (recordEdit)="recordEdit.emit($event)"
        ></app-record-card-compact>
      </cdk-virtual-scroll-viewport>

      <app-feature-metadata
        [record]="featureViewData.records[0]"
      ></app-feature-metadata>
    </div>
  `,
  styles: [
    `
      .feature-view-wrapper {
        width: 350px;
        padding: 10px 0 10px 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 100vh;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }

      .header {
        align-items: center;
        margin-bottom: 10px;
        height: 40px;
        display: flex;
      }

      .specific-name {
        padding: 0 10px;
        flex: 1 1 auto;
      }

      .actions {
        width: 80px;
        display: flex;
        justify-content: right;
      }

      .actions fa-icon {
        padding: 5px;
        margin: 0 5px;
        font-size: 1.2em;
        cursor: pointer;
      }

      .record-list {
        height: 100%;
        margin-bottom: 10px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureViewComponent {
  @Input() featureViewData: FeatureViewData | undefined;
  @Output() zoomToRecord = new EventEmitter<string>();
  @Output() recordSelected = new EventEmitter<Record>();
  @Output() recordDelete = new EventEmitter<Record>();
  @Output() recordEdit = new EventEmitter<Record>();
  @Output() recordUpdate = new EventEmitter<Record>();
  iconGps = faLocationCrosshairs;
  iconPlus = faPlus;

  onZoomToRecord(): void {
    const record = this.featureViewData?.records[0];
    if (record) this.zoomToRecord.emit(record.recordId);
  }

  update(): void {
    const record = this.featureViewData?.records[0];
    if (record) this.recordUpdate.emit(record);
  }
}
