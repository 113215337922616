import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { WeatherItemData } from './weather-item-data';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { WeatherModel } from '@trim-web-apps/weather-models';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'weather-core-item-desktop',
  template: `
    <div class="weather-item-wrapper" *ngIf="weatherItemData">
      <div class="row">
        <div class="controls">
          <weather-core-model-status
            [weatherModel]="weatherItemData.model"
            (click)="toggleVisibility.emit(weatherItemData.model.id)"
          ></weather-core-model-status>

          <div class="remove-model" *ngIf="weatherItemData.showRemoveIcon">
            <fa-icon
              [icon]="iconRemove"
              (click)="removeModel.emit(weatherItemData.model.id)"
            ></fa-icon>
          </div>

          <fa-icon [icon]="iconSettings" (click)="openSettings()"></fa-icon>

          <ng-template #settings>
            <div
              class="settings shadow"
              cdkDrag
              cdkDragRootElement=".cdk-overlay-pane"
            >
              <weather-core-settings-desktop
                [weatherModelId]="weatherItemData.model.id"
                (closeSettings)="closeSettings()"
              ></weather-core-settings-desktop>
            </div>
          </ng-template>

          <div class="separator"></div>

          <ui-checkbox
            *ngIf="weatherItemData.model.hasChart"
            [label]="'Chart'"
            [checked]="weatherItemData.model.chartEnabled"
            (click)="toggleChartVisibility.emit()"
          >
          </ui-checkbox>

          <weather-core-layer-picker
            [weatherModelId]="weatherItemData.model.id"
          ></weather-core-layer-picker>

          <weather-core-timestep-picker
            [weatherModelId]="weatherItemData.model.id"
          ></weather-core-timestep-picker>
        </div>
        <div class="legend">
          <div class="legend-inner">
            <weather-core-legend
              [layer]="weatherItemData.selectedLayer"
              [isMobile]="true"
            ></weather-core-legend>
          </div>
        </div>

        <div class="info">
          <fa-icon
            [icon]="iconInfo"
            (click)="showInfo.emit(weatherItemData.model)"
          ></fa-icon>
        </div>
      </div>

      <div class="row">
        <weather-core-chart
          [weatherModelId]="weatherItemData.model.id"
        ></weather-core-chart>
      </div>
    </div>
  `,
  styles: [
    `
      .weather-item-wrapper {
        background-color: #ffffff;
        margin: 10px;
        padding: 5px 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        border-radius: 7px;
        min-height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      fa-icon {
        cursor: pointer;
      }

      .settings {
        background-color: #ffffff;
      }

      .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .controls {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .controls,
      .legend {
        height: 40px;
      }

      weather-core-model-status {
        width: 200px;
      }

      weather-core-chart {
        width: 100%;
      }

      .remove-model,
      .separator,
      .legend,
      weather-core-layer-picker,
      weather-core-timestep-picker,
      ui-checkbox {
        margin: 0 5px;
      }

      weather-core-layer-picker {
        width: 250px;
      }

      weather-core-timestep-picker {
        width: 300px;
      }

      .legend {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 300px;
        flex: 1;
      }

      .legend-inner {
        min-width: 300px;
      }

      weather-core-legend {
        width: 100%;
      }

      .weather-item-wrapper .separator {
        height: 25px;
        border-left: 1px solid #dbdbdb;
      }

      .info {
        padding: 0 5px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherItemDesktopComponent implements AfterViewInit, OnDestroy {
  @ViewChild('settings') _dialogTemplate?: TemplateRef<any>;
  @Input() weatherItemData?: WeatherItemData;
  @Output() toggleVisibility: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeModel: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleChartVisibility: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() showInfo: EventEmitter<WeatherModel> =
    new EventEmitter<WeatherModel>();

  iconRemove = faTrash;
  iconInfo = faCircleInfo;
  iconSettings = faCog;

  private _overlayRef!: OverlayRef;
  private _portal?: TemplatePortal;

  constructor(
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit() {
    if (!this._dialogTemplate) return;
    this._portal = new TemplatePortal(
      this._dialogTemplate,
      this._viewContainerRef
    );
    this._overlayRef = this._overlay.create({
      positionStrategy: this._overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: false,
    });
    this._overlayRef.backdropClick().subscribe(() => this._overlayRef.detach());
  }

  ngOnDestroy() {
    this._overlayRef?.dispose();
  }

  openSettings() {
    this.closeSettings();
    this._overlayRef?.attach(this._portal);
  }

  closeSettings(): void {
    if (this._overlayRef?.hasAttached()) this._overlayRef.detach();
  }
}
