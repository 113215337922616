import {
  CLOUD_COVER,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  WIND_KTS,
} from '../../weather-layers/weather.layers';
import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createLammaArw3(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 73, 3600, '+00:00');
  // const tsRainfall = ts.map((t): WeatherTimestep => ({...t, timesteps: t.timesteps.slice(1)}))
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[32],
  };
  // const defaultDateRngRainfall: { from: number, to: number } = {from: ts[0].timesteps[1], to: ts[0].timesteps[32]}
  const timestepType: TimestepType = { type: 'UNIX', interval: 3600 };

  const lammaLayers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RELATIVE_HUMIDITY,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...CLOUD_COVER,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'LAMMA_ARW_3',
    label: 'LAMMA ARW3',
    category: 'FORECAST',
    layers: lammaLayers,
    initime: lammaLayers[0].timestepList[0].initime,
    timestep: lammaLayers[0].timestepList[0].timesteps[0],
    selectedLayerId: lammaLayers[0].id,
    dateRange: defaultDateRng,
    // todo this is wrong (?)
    bbox: [
      [-10.005, 37.495002],
      [-10.005, 51.005002],
      [16.005, 51.005002],
      [16.005, 37.495002],
      [-10.005, 37.495002],
    ],
    info: {
      resolution: '0.25°',
      coverage: 'Italy',
      url: 'http://www.lamma.rete.toscana.it/modelli/atmo/wrf-info-sul-modello',
      credits: 'Consorzio LaMMA',
    },
  };
}
