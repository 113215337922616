import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SplitViewLayout } from './split-view-layout';

@Component({
  selector: 'core-split-view',
  template: `
    <div #wrapper [ngClass]='layout.mode' [style]='wrapperStyle'>
      <div class='view' [style]='view1Style'>
        <ng-content select='[view1]'></ng-content>
      </div>

      <div [ngClass]='"draggable-" + layout.mode'
           coreMouseDrag
           (onDrag)='onMouseMove($event)'>
      </div>

      <div class='view' [style]='view2Style'>
        <ng-content select='[view2]'></ng-content>
      </div>
    </div>
  `,
  styles: [`
      :host,
      .view {
          overflow: hidden;
      }

      .stacked {
          display: flex;
          height: 100%;
          width: 100%;
      }

      .side-by-side {
          display: flex;
          height: 100%;
          width: 100%;
          flex-direction: row;
      }
      
      .draggable-stacked {
          cursor: n-resize;
          height: 5px;
          background-color: #dbdbdb;
      }

      .draggable-side-by-side {
          cursor: e-resize;
          width: 5px;
          height: 100%;
          background-color: #dbdbdb;
      }
  `]
})
export class SplitViewComponent implements AfterViewInit {
  @ViewChild('wrapper') wrapper: ElementRef | undefined;
  // @Input() mode: 'side-by-side' | 'stacked';
  // @Input() reverse: boolean;
  @Input() layout!: SplitViewLayout
  view1Size: { top: number, left: number, };

  constructor() {
    // this.mode = 'stacked';
    // this.reverse = false;
    this.view1Size = {
      top: Math.floor(window.innerHeight / 2),
      left: Math.floor(window.innerWidth / 2)
    };
  }

  get wrapperStyle(): { 'flex-direction': string } {
    if (this.layout.mode === 'stacked') {
      return this.layout.reverse
        ? { 'flex-direction': 'column-reverse' }
        : { 'flex-direction': 'column' };
    }

    return this.layout.reverse
      ? { 'flex-direction': 'row-reverse' }
      : { 'flex-direction': 'row' };
  }

  get view1Style(): { width: string, height: string } | { flex: number } {
    return !this.layout.reverse ? this.getViewSize() : { flex: 1 };
  }

  get view2Style(): { width: string, height: string } | { flex: number } {
    return this.layout.reverse ? this.getViewSize() : { flex: 1 };
  }

  private getViewSize(): { width: string, height: string } {
    return this.layout.mode === 'stacked'
      ? { height: `${this.view1Size.top}px`, width: `unset` }
      : { height: `unset`, width: `${this.view1Size.left}px` };
  }

  ngAfterViewInit(): void {
    if (this.wrapper) {
      // todo fix ExpressionChangedAfterItHasBeenCheckedError
      /**
       * Goal: remove setTimeout.
       *
       * By default `this.view1Size` should have the half of width/height
       * of parent container, however this value is only available after the
       * view has been initiated (this hook).
       * Since children component (ng-content) may need a defined height/width,
       * `this.view1Size` is initialized in constructor with a safe value taken
       * from `window` object.

       * Without `setTimeout` delay, `this.view1Size` will be updated too
       * early (before the next angular detection cycle) which will cause
       * ExpressionChangedAfterItHasBeenCheckedError.
       */
      setTimeout(() => {
        this.view1Size = {
          top: Math.floor(this.wrapper?.nativeElement.offsetHeight / 2),
          left: Math.floor(this.wrapper?.nativeElement.offsetWidth / 2)
        };
      });
    }
  }

  onMouseMove(evt: MouseEvent): void {
    if (this.wrapper) {
      const top = evt.y - (window.innerHeight - this.wrapper.nativeElement.offsetHeight);
      const left = evt.x - (window.innerWidth - this.wrapper.nativeElement.offsetWidth);
      this.view1Size = { top, left };
    }
  }
}
