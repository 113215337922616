import { Injectable } from '@angular/core';
import { FieldType, FormSpecific } from '3map-models';
import * as fromSpecific from './';
import { QuestionsGroup, SET_LOGIC_LIST } from './';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { FormSpecificLogic } from './models/FormSpecificLogic';

@Injectable({
  providedIn: 'root',
})
export class FormSpecificService {
  constructor(private store: Store) {}

  createSpecific(formId: string, specific: FormSpecific): void {
    this.store.dispatch(fromSpecific.CREATE_SPECIFIC({ formId, specific }));
  }

  duplicateSpecific(formId: string, specific: FormSpecific): void {
    this.store.dispatch(
      fromSpecific.ON_DUPLICATE_SPECIFIC({ formId, specific })
    );
  }

  upsertQuestions(specificId: string, questions: FieldType[]): void {
    this.store.dispatch(
      fromSpecific.UPSERT_QUESTIONS({ specificId, questions })
    );
  }

  upsertQuestionGroup(
    questionsGroupList: QuestionsGroup[],
    specificId: string
  ): void {
    this.store.dispatch(
      fromSpecific.UPSERT_QUESTIONS_GROUPS({ specificId, questionsGroupList })
    );
  }

  deleteSpecific(formId: string, specificId: string): void {
    this.store.dispatch(fromSpecific.REMOVE_SPECIFIC({ formId, specificId }));
  }

  setSpecificName(specificId: string, name: string): void {
    this.store.dispatch(fromSpecific.SET_SPECIFIC_NAME({ specificId, name }));
  }

  getSpecificById(specificId: string): Observable<FormSpecific | null> {
    return this.store.pipe(
      select(fromSpecific.selectSpecificById({ specificId }))
    );
  }

  getMarkerStyleQuestion(specificId: string): Observable<FieldType | null> {
    return this.store.pipe(
      select(fromSpecific.selectMarkerStyleQuestion({ specificId }))
    );
  }

  getSpecificIdsList(): Observable<string[]> {
    return this.store.pipe(select(fromSpecific.selectIds()));
  }

  removeQuestion(
    question: FieldType,
    specificId: string,
    groupName?: string
  ): void {
    this.store.dispatch(
      fromSpecific.REMOVE_QUESTION({ specificId, question, groupName })
    );
  }

  getQuestionsList(specificId: string): Observable<FieldType[]> {
    return this.getSpecificById(specificId).pipe(
      filter(notNullOrUndefined),
      map((specific) => specific.questions)
    );
  }

  getQuestionsGroupList(specificId: string): Observable<QuestionsGroup[]> {
    return this.getSpecificById(specificId).pipe(
      filter(notNullOrUndefined),
      map((specific) => {
        if (specific.questionGroups && specific.questionGroups.length > 0) {
          return specific.questionGroups.map((group) => {
            const questions = specific.questions.filter((ft) =>
              group.questions.includes(ft.id)
            );
            const groupName = group.name;
            return { questions, groupName };
          });
        } else {
          return [];
        }
      })
    );
  }

  addQuestionGroup(
    specificId: string,
    groupName: string,
    questionList?: FieldType[]
  ): void {
    this.store.dispatch(
      fromSpecific.ADD_QUESTION_GROUP({ specificId, groupName, questionList })
    );
  }

  removeQuestionGroup(specificId: string, groupName: string): void {
    this.store.dispatch(
      fromSpecific.REMOVE_QUESTION_GROUP({ specificId, groupName })
    );
  }

  removeAllQuestionGroup(specificId: string): void {
    this.store.dispatch(fromSpecific.REMOVE_ALL_QUESTION_GROUP({ specificId }));
  }

  moveQuestionsGroup(
    specificId: string,
    fromGroup: QuestionsGroup,
    toGroupName: string | null
  ): void {
    this.store.dispatch(
      fromSpecific.MOVE_QUESTIONS_GROUP({ specificId, fromGroup, toGroupName })
    );
  }

  renameQuestionGroup(
    specificId: string,
    oldName: string,
    newName: string
  ): void {
    this.store.dispatch(
      fromSpecific.RENAME_QUESTION_GROUP({ specificId, oldName, newName })
    );
  }

  setLogic(specificId: string, logicList: FormSpecificLogic[]): void {
    this.store.dispatch(SET_LOGIC_LIST({ specificId, logicList }));
  }

  getLogic(specificId: string): Observable<FormSpecificLogic[]> {
    return this.getSpecificById(specificId).pipe(
      map((specific) => {
        const logic = specific?.listLogic;
        if (!logic) return [];
        return logic
          .map((l) => {
            const q1 = specific?.questions.find((ft) => ft.id === l.q1);
            const q2 = specific?.questions.find((ft) => ft.id === l.q2);
            if (!q1 || !q2) throw Error('Logic: FieldType ID does not exists');
            return q1 && q2 ? { q1, q2, logic: l.logic } : null;
          })
          .filter(notNullOrUndefined);
      })
    );
  }
}
