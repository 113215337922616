import { Injectable } from '@angular/core';
import * as fromForm from './';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Form } from '3map-models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private store: Store) {}

  getFormList(): Observable<Form[]> {
    return this.store.pipe(select(fromForm.selectFormList()));
  }

  getFormById(formId: string): Observable<Form | undefined> {
    return this.getFormList().pipe(
      map((list) => list.find((f) => f.id === formId))
    );
  }

  createForm(form: Form): void {
    this.store.dispatch(fromForm.CREATE_FORM({ form }));
  }

  duplicateForm(form: Form): void {
    this.store.dispatch(fromForm.ON_DUPLICATE_FORM({ form }));
  }

  updateForm(form: Form): void {
    this.store.dispatch(fromForm.UPDATE_FORM({ form }));
  }

  deleteForm(form: Form): void {
    this.store.dispatch(fromForm.REMOVE_FORM({ form }));
  }
}
