import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-number',
  template: ` <p>field-number works!</p> `,
  styles: [],
})
export class FieldNumberComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
