import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './containers/user-list/user-list.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserListItemComponent } from './components/user-list-item/user-list-item.component';
import { FormModule } from '../form/form.module';
import { UserDialogComponent } from './components/user-dialog/user-dialog.component';
import { CoreModule } from '@trim-web-apps/core';
import { SharedModule } from '../shared/shared.module';
import { Map3UiModule } from '@trim-web-apps/map3-ui';
import { RolePickerComponent } from './components/role-picker/role-picker.component';
import { FormPickerComponent } from './components/form-picker/form-picker.component';
import { UserFormsComponent } from './containers/user-forms/user-forms.component';

@NgModule({
  declarations: [
    UserListComponent,
    UserSearchComponent,
    UserListItemComponent,
    UserDialogComponent,
    RolePickerComponent,
    FormPickerComponent,
    UserFormsComponent,
  ],
  exports: [UserListComponent],
  imports: [
    CommonModule,
    // StoreModule.forFeature(ADMIN_USER_FEATURE_KEY, reducer),
    ReactiveFormsModule,
    CoreModule,
    OverlayModule,
    FontAwesomeModule,
    FormModule,
    SharedModule,
    Map3UiModule,
  ],
})
export class UserModule {}
