import { Form, Project } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
import * as ProjectActions from './project.actions';
// import { SidenavActions } from '../../sidenav/+state';
import * as SidenavActions from '../../sidenav/+state/sidenav.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as MapActions from '../../map/+state/map.actions';
import { TableActions } from '../../table/+state';

export const projectFeatureKey = 'project2';

export type State = {
  project: Project | null;
  projectError: boolean;
  projectLoading: boolean;
  enabledForms: EntityState<EnabledFormEntity>;
};

type EnabledFormEntity = {
  formId: string;
  specificIds: string[];
  showHistory: boolean;
};

export const enabledFormAdapter: EntityAdapter<EnabledFormEntity> =
  createEntityAdapter<EnabledFormEntity>({
    selectId: (entity) => entity.formId,
  });

export const initialState: State = {
  project: null,
  projectError: false,
  projectLoading: false,
  enabledForms: enabledFormAdapter.getInitialState(),
};

const projectReducer = createReducer(
  initialState,
  on(ProjectActions.closeProject, () => ({ ...initialState })),
  on(
    ProjectActions.fetchProject,
    (): State => ({ ...initialState, projectLoading: true })
  ),
  on(ProjectActions.fetchProjectSuccess, (state, { project }): State => {
    // enabled first Form collection by default
    const firstForm = project.formList[0];
    const enabledForm = firstForm ? createDataset(firstForm) : null;
    return {
      ...initialState,
      enabledForms: enabledForm
        ? enabledFormAdapter.addOne(enabledForm, initialState.enabledForms)
        : state.enabledForms,
      project,
      projectLoading: false,
    };
  }),
  on(
    ProjectActions.fetchProjectError,
    (): State => ({ ...initialState, projectError: true })
  ),
  on(SidenavActions.createDataset, (state, { formId }): State => {
    const form = state.project?.formList.find((f) => f.id === formId) || null;
    if (form === null) return state;
    return {
      ...state,
      enabledForms: enabledFormAdapter.addOne(
        createDataset(form),
        state.enabledForms
      ),
    };
  }),
  on(
    SidenavActions.removeDataset,
    MapActions.removeDataset,
    (state, { id }): State => {
      return {
        ...state,
        enabledForms: enabledFormAdapter.removeOne(id, state.enabledForms),
      };
    }
  ),
  on(
    MapActions.setEnabledSpecificList,
    TableActions.setEnabledSpecificList,
    (state, { formId, specificIdsList }): State => {
      return {
        ...state,
        enabledForms: enabledFormAdapter.updateOne(
          { id: formId, changes: { specificIds: specificIdsList } },
          state.enabledForms
        ),
      };
    }
  ),
  on(TableActions.toggleHistory, (state, { formId }): State => {
    const entity = state.enabledForms.entities[formId];
    if (!entity) return state;
    return {
      ...state,
      enabledForms: enabledFormAdapter.updateOne(
        {
          id: formId,
          changes: { showHistory: !entity.showHistory },
        },
        state.enabledForms
      ),
    };
  })
);

export function reducer(state: State | undefined, action: Action): State {
  return projectReducer(state, action);
}

function createDataset(form: Form): EnabledFormEntity {
  return {
    formId: form.id,
    specificIds: form.specificList.map((s) => s.id),
    showHistory: true,
  };
}
