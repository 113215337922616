import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthApiService } from '../../services/auth-api.service';
import {
  adminLoginError,
  adminLoginSuccess,
  adminProjectLoginError,
  adminProjectLoginSuccess,
  authLogout,
  doAdminLogin,
  doAdminProjectLogin,
  doProjectLogin,
  projectLoginError,
  projectLoginSuccess,
  setProjectName,
} from './auth.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { ResProjectToken } from '3map-models';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authApi: AuthApiService,
    private router: Router
  ) {}

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        map(() => this.router.navigate(['/auth']))
      ),
    { dispatch: false }
  );

  adminLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(doAdminLogin),
      switchMap((action) =>
        this.authApi.adminLogin(action.username, action.password).pipe(
          map((resAdminToken) => adminLoginSuccess({ resAdminToken })),
          catchError(() => of(adminLoginError()))
        )
      )
    )
  );

  adminLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adminLoginSuccess),
        switchMap(() => this.router.navigate(['/auth/projects']))
      ),
    { dispatch: false }
  );

  doAdminProjectLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(doAdminProjectLogin),
      switchMap(({ collaboration }) =>
        this.authApi.projectLogin(collaboration.projectName).pipe(
          map((resProjectToken: ResProjectToken) =>
            adminProjectLoginSuccess({ resProjectToken })
          ),
          catchError(() => of(adminProjectLoginError()))
        )
      ),
      tap(() => this.router.navigate(['/admin']))
    )
  );

  adminProjectLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adminProjectLoginSuccess),
        switchMap(() => this.router.navigate(['admin']))
      ),
    { dispatch: false }
  );

  projectLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(doProjectLogin),
      switchMap(({ collaboration }) =>
        this.authApi.projectLogin(collaboration.projectName).pipe(
          map((resProjectToken: ResProjectToken) =>
            projectLoginSuccess({ resProjectToken })
          ),
          catchError(() => of(projectLoginError()))
        )
      )
    )
  );

  $onSetProjectName = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setProjectName),
        tap(() => {
          this.router
            .navigate(['/admin'])
            .then((v) => console.log(v))
            .catch((e) => console.log(e));
        })
      ),
    { dispatch: false }
  );

  projectLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectLoginSuccess),
        switchMap(() => this.router.navigate(['project']))
      ),
    { dispatch: false }
  );
}
