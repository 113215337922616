import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WebHook } from '3map-models';

@Component({
  selector: 'app-webhook-list-item',
  template: `
    <div
      class="webhook-item show-on-hover-toggle selectable-text"
      *ngIf="webHook"
    >
      <div class="left text-ellipsis">
        <div class="name text-ellipsis">{{ webHook.name }}</div>
        <div class="url text-ellipsis">{{ webHook.url }}</div>
      </div>

      <div class="right">
        <div class=" show-on-hover">
          <core-double-confirm-inline (confirm)="remove.emit(webHook)" />
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .webhook-item {
        border: 1px solid var(--border);
        border-radius: var(--radius-1);
        padding: var(--spacing-2);
        margin: var(--spacing-3) 0;
        display: flex;
        align-items: center;
      }

      .webhook-item:hover {
        background-color: var(--hover);
      }

      .left {
        flex: 1 1 auto;
      }

      .name {
        font-weight: var(--font-weight-3);
      }
    `,
  ],
})
export class WebhookListItemComponent {
  @Input() webHook: WebHook | undefined;
  @Output() remove = new EventEmitter<WebHook>();
}
