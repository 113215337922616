import {
  HIGH_CLOUD_COVER,
  LOW_CLOUD_COVER,
  MEDIUM_CLOUD_COVER,
  PRESSURE,
  RAINFALL_1HOUR,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  WIND_KTS,
} from '../../weather-layers/weather.layers';
import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';
import {
  TimestepType,
  WeatherLayer,
  WeatherModel,
  WeatherTimestep,
} from '../../types';

export function createHarmonieArome(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 48, 3600, '+00:00');
  const tsRainfall = ts.map(
    (t): WeatherTimestep => ({ ...t, timesteps: t.timesteps.slice(1) })
  );
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[1],
    to: ts[0].timesteps[32],
  };
  const defaultDateRngRainfall: { from: number; to: number } = {
    from: ts[0].timesteps[1],
    to: ts[0].timesteps[32],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 3600 };

  const layersArome: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RAINFALL_1HOUR,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
    },
    {
      ...RELATIVE_HUMIDITY,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...PRESSURE,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...LOW_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
      conversionFactor: 100,
    },
    {
      ...MEDIUM_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
      conversionFactor: 100,
    },
    {
      ...HIGH_CLOUD_COVER,
      timestepType,
      timestepList: tsRainfall,
      defaultDateRange: defaultDateRngRainfall,
      conversionFactor: 100,
    },
  ];

  return {
    ...modelTemplate,
    id: 'HARMONIE_AROME',
    label: 'Harmonie Arome',
    category: 'FORECAST',
    layers: layersArome,
    initime: layersArome[0].timestepList[0].initime,
    timestep: layersArome[0].timestepList[0].timesteps[1],
    selectedLayerId: layersArome[0].id,
    dateRange: defaultDateRng,
    bbox: [
      [-10.005, 37.495002],
      [-10.005, 51.005002],
      [16.005, 51.005002],
      [16.005, 37.495002],
      [-10.005, 37.495002],
    ],
    info: {
      resolution: '0.034° x 0.024° (~2.5 km)',
      coverage: 'Netherlands',
      url: 'https://dataplatform.knmi.nl/dataset/harmonie-arome-cy40-p1-0-2',
      credits: 'KNMI - Koninklijk Nederlands Meteorologisch Instituut',
    },
  };
}
