import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './admin.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormModule } from './form/form.module';
import { UserModule } from './user/user.module';
import { WebhooksModule } from './webhooks/webhooks.module';
import { SettingsModule } from './settings/settings.module';
import { CoreModule } from '@trim-web-apps/core';
import { SharedModule } from './shared/shared.module';
import { DeployModule } from './deploy/deploy.module';
import { StoreModule } from '@ngrx/store';
import { ResourceModule } from './resource/resource.module';
import { LinkModule } from './link/link.module';
import { adminReducerMap, APP_ADMIN_FEATURE_KEY } from './admin.reducer.map';
import { EffectsModule } from '@ngrx/effects';
import { AdminEffects } from './+state/admin.effects';

@NgModule({
  declarations: [AdminComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    CoreModule,
    FontAwesomeModule,
    FormModule,
    UserModule,
    WebhooksModule,
    SettingsModule,
    StoreModule.forFeature(APP_ADMIN_FEATURE_KEY, adminReducerMap),
    EffectsModule.forFeature([AdminEffects]),
    SharedModule,
    DeployModule,
    ResourceModule,
    SharedModule,
    LinkModule,
  ],
})
export class AdminModule {}
