import { createAction, props } from '@ngrx/store';
import { FieldType } from '3map-models/dist/lib/model';

export const setMarkerStyleQuestion = createAction(
  '[ MarkerStyle ] Set Question',
  props<{ specificId: string; question: FieldType | null }>()
);

export const setImageBase64 = createAction(
  '[ MarkerStyle ] Set Image Base64',
  props<{ specificId: string; base64: string; index: number }>()
);

export const setImageBase64List = createAction(
  '[ MarkerStyle ] Set Image Base64 List',
  props<{ specificId: string; imgBase64List: string[] }>()
);
