import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { AuthSelectors } from './state/auth';
import { map, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly ADMIN_ENDPOINTS = [
    environment.API_URL + '/admin',
    environment.API_URL + '/auth/projectLogin',
    environment.API_URL + '/static/uploadFilesAdmin',
  ];

  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isApiRequest = request.url.indexOf(environment.API_URL) !== -1;
    const action = this.isAdminUrl(request)
      ? AuthSelectors.selectAdminToken()
      : AuthSelectors.selectProjectToken();

    return this.store.pipe(
      select(action),
      take(1),
      map((token: string | null) =>
        isApiRequest && token !== null
          ? request.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
          : request
      ),
      switchMap((req) => next.handle(req))
    );
  }

  private isAdminUrl(request: HttpRequest<any>): boolean {
    return this.ADMIN_ENDPOINTS.map((partialUrl: string) =>
      request.url.includes(partialUrl)
    ).reduce((acc: boolean, item: boolean) => acc || item, false);
  }
}
