import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  // TODO rename to downloadBlob
  showDownloadDialog(file: Blob, filename: string): void {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadText(dataStr: string, filename: string): void {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', dataStr);
    anchor.setAttribute('download', filename);
    document.body.appendChild(anchor); // required for firefox
    anchor.click();
    anchor.remove();
  }
}
