import { Injectable } from '@angular/core';
import { FieldType, Record } from '3map-models';
import * as fromMarkerStyle from './';
import { MarkerItem } from './';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromSpecific from '../specific';
import { MarkerStyleEntity } from './marker-style.entity';
import { Dictionary } from '@ngrx/entity';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarkerStyleService {
  constructor(private store: Store) {}

  setMarkerStyleQuestion(specificId: string, question: FieldType | null): void {
    this.store.dispatch(
      fromMarkerStyle.SET_MARKER_STYLE_QUESTION({ specificId, question })
    );
  }

  setImgBase64(specificId: string, base64: string, index: number): void {
    this.store.dispatch(
      fromMarkerStyle.SET_IMG_BASE64({
        specificId,
        base64,
        index,
      })
    );
  }

  setImageBase64List(specificId: string, imgBase64List: string[]): void {
    this.store.dispatch(
      fromMarkerStyle.SET_BASE64_IMAGE_LIST({ specificId, imgBase64List })
    );
  }

  // TODO move to specificService?
  getMarkerItems(specificId: string): Observable<MarkerItem[]> {
    return this.store.pipe(
      select(fromSpecific.selectMarkerItems({ specificId }))
    );
  }

  getRecordBase64Icon(record: Record): Observable<string> {
    return this.store.pipe(
      select(fromSpecific.selectRecordBase64Icon({ record }))
    );
  }

  getMarkerStyleEntity(
    specificId: string
  ): Observable<MarkerStyleEntity | undefined> {
    return this.store.pipe(
      select(fromMarkerStyle.selectEntity({ specificId }))
    );
  }

  getMarkerStyleEntityDict(): Observable<Dictionary<MarkerStyleEntity>> {
    return this.store.pipe(select(fromMarkerStyle.selectEntities()));
  }

  getMarkersToUpload(): Observable<MarkerStyleEntity[]> {
    return this.store.pipe(select(fromMarkerStyle.selectMarkerToUpload()));
  }
}
