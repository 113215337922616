import { createAction, props } from '@ngrx/store';
import { Link } from '3map-models';

export const upsertLink = createAction(
  '[ LinkList ] Upsert Link ',
  props<{ link: Link }>()
);

export const removeLink = createAction(
  '[ LinkList ] Remove Link',
  props<{ link: Link }>()
);
