import { createSelector } from '@ngrx/store';
import { notNullOrUndefined } from '@trim-web-apps/core';
import * as fromRoot from '../project.selectors';

export const selectState = createSelector(
  fromRoot.selectProjectState,
  (state) => state.links
);

export const selectLinks = () =>
  createSelector(selectState, (state) =>
    Object.keys(state.entities)
      .map((k) => state.entities[k])
      .filter(notNullOrUndefined)
  );
