import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MarkerService } from '../../../marker.service';

@Component({
  selector: 'app-specific-icon',
  template: `
    <app-marker-icon [widthPx]="widthPx" [bitmap]="getIcon()"></app-marker-icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificIconComponent {
  @Input() specificId: string | undefined;
  @Input() widthPx: number = 32;

  constructor(private markerService: MarkerService) {}

  getIcon(): ImageBitmap | null {
    return this.specificId
      ? this.markerService.getDefaultSpecificIdIcon(this.specificId)
      : null;
  }
}
