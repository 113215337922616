import { createAction, props } from '@ngrx/store';
import { Form } from '3map-models';

export const CREATE_FORM = createAction(
  '[ Project ] Create Form',
  props<{ form: Form }>()
);

export const ON_DUPLICATE_FORM = createAction(
  '[ Project ] On Duplicate Form',
  props<{ form: Form }>()
);

export const DUPLICATE_FORM = createAction(
  '[ Project ] Duplicate Form',
  props<{
    form: Form;
    icons: { specificId: string; imgBase64List: string[] }[];
  }>()
);

export const DUPLICATE_FORM_ERROR = createAction(
  '[ Project ] Duplicate Form Error'
);

export const UPDATE_FORM = createAction(
  '[ Project ] Update Form',
  props<{ form: Form }>()
);

export const REMOVE_FORM = createAction(
  '[ Project ] Remove Form',
  props<{ form: Form }>()
);
