import {
  CURRENT,
  TimestepType,
  WeatherLayer,
  WeatherModel,
} from '@trim-web-apps/weather-models';
import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';

export function createAarhus(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 55, 3600, '+00:00');
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[54],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 3600 };

  const layers: WeatherLayer[] = [
    {
      ...CURRENT,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'AARHUS',
    label: 'AARHUS',
    category: 'FORECAST',
    layers: layers,
    initime: layers[0].timestepList[0].initime,
    timestep: layers[0].timestepList[0].timesteps[0],
    selectedLayerId: layers[0].id,
    dateRange: defaultDateRng,
    bbox: [
      [9.3981474, 56.3388888],
      [15.0740736, 56.3388888],
      [15.0740736, 53.5055547],
      [9.3981474, 53.5055547],
      [9.3981474, 56.3388888],
    ],
  };
}
