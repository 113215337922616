import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherLegendLabel'
})
export class WeatherLegendLabelPipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case Number.NEGATIVE_INFINITY:
        return '--';
      case Number.POSITIVE_INFINITY:
        return '++';
      case 9999:
        return '++';
      default:
        return value.toString();
    }
  }
}
