import { EntityResource } from '../resource';
import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as projectActions from '../project.actions';
import * as formActions from '../form/form.actions';
import * as specificActions from '../specific/form-specific.actions';
import * as markerStyleActions from '../marker-style/marker-style.actions';
import * as resourceActions from '../resource/resource.actions';
import * as userActions from '../user/user.actions';
import * as webHookActions from '../webhook/webhook.actions';
import * as linkActions from '../link/link.actions';
import { SET_MODIFIED } from './project-prop.actions';

export interface State {
  name: string | null;
  modified: boolean;
}

export const adapter = createEntityAdapter<EntityResource>();
export const initialState: State = { name: null, modified: false };
const resourceReducer = createReducer(
  initialState,
  on(projectActions.RESET, () => initialState),
  on(projectActions.RESTORE_PROJECT_STATE, (state, { projectState }) => ({
    ...projectState.projectProps,
  })),
  on(projectActions.INIT_PROJECT, (state, { project }) => ({
    ...state,
    name: project.name,
  })),
  on(SET_MODIFIED, (state, { modified }) => ({ ...state, modified })),
  on(
    // todo how to merge all actions into single export?
    formActions.CREATE_FORM,
    formActions.UPDATE_FORM,
    formActions.REMOVE_FORM,
    specificActions.CREATE_SPECIFIC,
    specificActions.ON_DUPLICATE_SPECIFIC,
    specificActions.DUPLICATE_SPECIFIC,
    specificActions.REMOVE_SPECIFIC,
    specificActions.SET_SPECIFIC_NAME,
    specificActions.UPSERT_QUESTIONS,
    specificActions.UPSERT_QUESTIONS_GROUPS,
    specificActions.REMOVE_QUESTION,
    specificActions.MOVE_QUESTIONS_GROUP,
    specificActions.ADD_QUESTION_GROUP,
    specificActions.REMOVE_QUESTION_GROUP,
    specificActions.REMOVE_ALL_QUESTION_GROUP,
    specificActions.RENAME_QUESTION_GROUP,
    specificActions.SET_LOGIC_LIST,
    markerStyleActions.SET_MARKER_STYLE_QUESTION,
    markerStyleActions.SET_BASE64_IMAGE_LIST,
    markerStyleActions.SET_IMG_BASE64,
    resourceActions.CREATE_RESOURCE,
    resourceActions.REMOVE_RESOURCE,
    resourceActions.SET_RESOURCE_BBOX,
    resourceActions.SET_RESOURCE_DATA,
    resourceActions.SET_RESOURCE_NAME,
    resourceActions.SET_RESOURCE_STYLE,
    userActions.SET_USERS,
    userActions.REMOVE_USERS,
    webHookActions.UPSERT_WEBHOOK,
    webHookActions.REMOVE_WEBHOOK,
    linkActions.UPSERT_LINK,
    linkActions.REMOVE_LINK,
    (state) => ({ ...state, modified: true })
  )
);

export function reducer(state: State | undefined, action: Action): State {
  return resourceReducer(state, action);
}
