import { createSelector } from '@ngrx/store';
import { EntityForm } from './form.reducer';
import { Form, FormSpecific } from '3map-models';
import { notNullOrUndefined } from '@trim-web-apps/core';
import {
  selectAllSpecific,
  selectSpecificListByFormId,
} from './form-specific.selectors';
import { selectAppAdminState } from '../../admin.reducer.map';
// import * as fromSpecific from '../../++old_state/lib/specific';

// export const selectState = createFeatureSelector<State>(ADMIN_FORM_FEATURE_KEY);
export const selectState = () =>
  createSelector(selectAppAdminState(), (state) => state.forms);

export const selectFormEntityById = (formId: string) => {
  return createSelector(selectState(), (state) => state.entities[formId]);
};

export const selectFormList = () => {
  return createSelector(
    selectState(),
    selectAllSpecific(),
    (state, allSpecificList) => {
      return Object.keys(state.entities)
        .map((formId) => {
          const formEntity = state.entities[formId];
          const specificList = allSpecificList.filter((s) =>
            formEntity?.specificListIds.includes(s.id)
          );
          return formEntity && specificList
            ? entityToForm(formEntity, specificList)
            : null;
        })
        .filter(notNullOrUndefined);
    }
  );
};

export const selectFormById = (formId: string) =>
  createSelector(
    selectFormEntityById(formId),
    selectSpecificListByFormId(formId),
    (entity, specificList): Form | null => {
      return entityToForm(entity, specificList);
    }
  );

export function entityToForm(
  entity: EntityForm | undefined,
  specificList: FormSpecific[] | undefined
): Form | null {
  if (entity && specificList) {
    return {
      id: entity.id,
      name: entity.name,
      specificList: specificList,
      allowImage: entity.allowImage,
      allowAudio: entity.allowAudio,
      allowVideo: entity.allowVideo,
    };
  }

  return null;
}
