import { FieldType } from '3map-models';

export class FieldComponent {
  question!: FieldType;

  // isValid(): boolean {
  //     throw Error('Override me!');
  // }

  getQuestion(): FieldType | null {
    return this.question;
  }
}
