import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartData } from '@trim-web-apps/weather-models';
import { Store } from '@ngrx/store';
import { WeatherSelectors } from '../../+state';

@Component({
  selector: 'weather-core-chart-mobile',
  template: `
    <ng-container *ngIf="chartDataList$ | async as dataList">
      <div class="no-point-msg" *ngIf="dataList.length === 0">
        Select a point
      </div>
      <weather-core-chart-legend
        *ngIf="dataList.length > 1"
        [chartDataList]="dataList"
      ></weather-core-chart-legend>
      <weather-core-chart-view
        *ngFor="let chart of dataList; let i = index"
        [chartData]="chart"
        [xAxisLabels]="true"
        [xAxisLabelsColor]="dataList.length > 1 && i === 0 ? '#fff' : '#666'"
        [xAxisLabelRotation]="{ min: 0, max: 0 }"
        [xAxisPadding]="dataList.length > 1 && i === 0 ? -20 : 0"
        [showLegend]="false"
        [responsive]="false"
        [canvasWidth]="1200"
        [isMobile]="true"
      >
      </weather-core-chart-view>
    </ng-container>
  `,
  styles: [
    `
      .no-point-msg {
        text-align: center;
      }
    `,
  ],
})
export class WeatherChartMobileComponent implements OnInit {
  @Input() weatherModelId?: string;

  chartDataList$?: Observable<ChartData[]>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.weatherModelId) return;
    this.chartDataList$ = this.store
      .select(WeatherSelectors.selectById(this.weatherModelId))
      .pipe(
        map((model) => {
          if (!model) return [];
          return model?.chartData.map((chartData) => {
            return {
              ...chartData,
              chartHeight: 150,
              // chart js supports only 'top' and 'bottom' padding, so emulate left/right padding using white space (!)
              labels: chartData.labels.map((label) =>
                (label as any).map((l: string) => `  ${l}  `)
              ),
            };
          });
        })
      );
  }
}
