import { Component, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as EditorSelectors from '../../+state/record-editor.selectors';
import { EditorMode } from '../../models/EditorMode';
import { Subscription } from 'rxjs';
import { EditorActions } from '../../+state';
import { ConfirmDialogService } from '../../../shared/ui/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-record-editor-modal',
  template: `
    <!--    <ng-container *ngIf="editorMode$ | async as editorMode">-->
    <ng-container *ngIf="editorMode">
      <div class="editor-modal-wrapper" (click)="backdropClick()">
        <div class="content" (click)="$event.stopPropagation()">
          <app-record-editor
            *ngIf="editorMode !== 'DELETE'"
          ></app-record-editor>

          <div class="content" *ngIf="editorMode === 'DELETE'">
            DELETE WARNING
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .editor-modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
      }

      .content {
        height: 70vh;
        width: 500px;
      }
    `,
  ],
})
export class RecordEditorModalComponent implements OnDestroy {
  editorMode: EditorMode | null = null;
  private modeSub: Subscription | undefined;

  constructor(
    private store: Store,
    private confirmDialog: ConfirmDialogService
  ) {
    // subscribe to get editorMode used later in handleKeyboardEvent
    this.modeSub = this.store
      .select(EditorSelectors.selectMode())
      .subscribe((mode) => (this.editorMode = mode));
  }

  ngOnDestroy(): void {
    this.modeSub?.unsubscribe();
  }

  backdropClick(): void {
    this.showConfirmDialog();
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent() {
    if (this.editorMode === null) return;
    this.showConfirmDialog();
  }

  private showConfirmDialog(): void {
    this.confirmDialog
      .showConfirmDialog({
        title: 'Confirm?',
        content: 'Any unsaved changes will be discarded.',
        cancelText: 'CANCEL',
        confirmText: 'OK',
      })
      .subscribe((confirm) => {
        if (confirm) this.store.dispatch(EditorActions.disableRecordEditor());
      });
  }
}
