import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  adminLoginError,
  adminLoginSuccess,
  adminProjectLoginError,
  adminProjectLoginSuccess,
  authLogout,
  doAdminLogin,
  doAdminProjectLogin,
  doProjectLogin,
  projectLoginError,
  projectLoginSuccess,
  setProjectName,
} from './auth.actions';
import { Collaboration } from '../collaboration/collaboration.reducer';
import { SidenavActions } from '../../../project/sidenav/+state';

export interface State {
  adminToken: string | null;
  projectToken: string | null;
  username: string | null;
  pendingRequest: boolean;
  errorRequest: boolean;
  projectName: string | null;
  col: Collaboration | null;
}

export const FEATURE_KEY = 'auth';

export type SyncKey<T> =
  | string
  | { [featureKey: string]: string[] }
  | {
      [featureKey: string]: {
        serialize: (state: T) => Partial<T>;
        deserialize: (rowState: any) => T;
      };
    };

export const syncKey: SyncKey<State> = FEATURE_KEY;

const initialState: State = {
  adminToken: null,
  projectToken: null,
  username: null,
  pendingRequest: false,
  errorRequest: false,
  projectName: null,
  col: null,
};

const authReducer: ActionReducer<State> = createReducer(
  initialState,
  on(SidenavActions.sidenavLogout, () => ({ ...initialState })),
  on(authLogout, () => ({ ...initialState })),
  on(doAdminLogin, (state, { username }) => ({
    ...initialState,
    username,
    pendingRequest: true,
  })),
  on(adminLoginSuccess, (state, { resAdminToken }) => ({
    ...initialState,
    username: state.username,
    adminToken: resAdminToken.adminToken,
  })),
  on(adminLoginError, () => ({
    ...initialState,
    errorRequest: true,
  })),
  on(doProjectLogin, doAdminProjectLogin, (state, { collaboration }) => ({
    ...state,
    col: collaboration,
    pendingRequest: true,
    errorRequest: false,
    projectToken: null,
  })),
  // on(DO_ADMIN_PROJECT_LOGIN, (state, { collaboration }) => ({
  //   ...state,
  //   col: collaboration,
  //   projectToken: null,
  // })),
  on(
    projectLoginSuccess,
    adminProjectLoginSuccess,
    (state, { resProjectToken }) => ({
      ...state,
      projectToken: resProjectToken.projectToken,
      errorRequest: false,
      pendingRequest: false,
    })
  ),
  on(projectLoginError, adminProjectLoginError, (state) => ({
    ...state,
    col: null,
    projectToken: null,
    errorRequest: true,
    pendingRequest: false,
  })),
  on(setProjectName, (state, { projectName }) => ({
    ...state,
    projectName,
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return authReducer(state, action);
}
