import { getAutoUtcOffset } from './utils/weather.functions';
import { isValidOffset } from '@trim-web-apps/core';

/**
 * UTC Offset can be:
 *      string: if user has manually selected specific timezone/offset
 *      null: if user has selected `auto`
 *
 * Default is null (auto)
 *
 * Get method always return a valid offset string (eg +02:00), if none (null) is found
 * then return `auto` utcOffset (see getAutoUtcOffset() function)
 */
export class WeatherPreferences {
  private readonly UTC_OFFSET_KEY = 'weatherUtcOffset';

  setUtcOffset(utcOffset: string | null) {
    localStorage.setItem(this.UTC_OFFSET_KEY, JSON.stringify(utcOffset));
  }

  getUtcOffset(): string {
    const fromLS = localStorage.getItem(this.UTC_OFFSET_KEY);
    if (fromLS !== null && isValidOffset(JSON.parse(fromLS))) {
      return JSON.parse(fromLS);
    }
    return getAutoUtcOffset();
    // const offset = isValidOffset(fromLS)
    // const offset = JSON.parse(localStorage.getItem(this.UTC_OFFSET_KEY));
    // return offset !== null ? offset : getAutoUtcOffset();
  }
}
