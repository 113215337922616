import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectUser } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
import { REMOVE_USERS, SET_USERS } from './user.actions';
import * as projectActions from '../project.actions';
import * as formActions from '../form/form.actions';

export interface State extends EntityState<ProjectUser> {}

export const adapter = createEntityAdapter<ProjectUser>({
  selectId: (user) => user.username,
});

const initialState = adapter.getInitialState();

const userReducer = createReducer(
  initialState,
  on(projectActions.RESET, () => initialState),
  on(projectActions.RESTORE_PROJECT_STATE, (state, { projectState }) => ({
    ...projectState.users,
  })),
  on(projectActions.INIT_PROJECT, (state, { project }) => {
    return adapter.setAll(project.userList, state);
  }),
  on(formActions.CREATE_FORM, (state, { form }) =>
    adapter.map((pUser) => {
      return { ...pUser, formList: [...pUser.formList, form.id] };
    }, state)
  ),
  on(formActions.REMOVE_FORM, (state, { form }) =>
    adapter.map((pUser) => {
      return {
        ...pUser,
        formList: pUser.formList.filter((id) => id !== form.id),
      };
    }, state)
  ),
  on(SET_USERS, (state, { users }) => {
    return adapter.upsertMany(users, state);
  }),
  on(REMOVE_USERS, (state, { users }) => {
    return adapter.removeMany(
      users.map((u) => u.username),
      state
    );
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
