import { createArome } from './factories/arome';
import { createGfs } from './factories/gfs';
import { createGpm } from './factories/gpm';
import { createAladin } from './factories/aladin';
import { createLammaArw3 } from './factories/lammaArw3';
import { createGfsAcc } from './factories/gfsAcc';
import { createGfsHistory } from './factories/gfsHistory';
import { WeatherModel } from '../types';
import { createMoloch } from './factories/moloch';
import { createWrfJapan25 } from './factories/wrf_japan_25';
import { createWrfJapan05 } from './factories/wrf_japan_05';
import { createScheveningen } from './factories/scheveningen';
import { createHarmonieArome } from './factories/harmonie_arome';
import { createAarhus } from './factories/aarhus';
import { createWrfAarhus25 } from './factories/wrf_aarhus_25';
import { createWrfAarhus05 } from './factories/wrf_aarhus_05';
import { createNwmedMarseille } from './factories/nwmed_marseille';
import { createEra5Marseille } from './factories/era5_marseille';
import { createGpm2 } from './factories/gpm2';
import { createRfe } from './factories/rfe';
import { createEcmwf } from './factories/ecmwf';

export function fromServerResponse(serverResponse: {
  error: boolean;
  model: string;
  data: any;
}): WeatherModel {
  switch (serverResponse.model) {
    case 'AROME':
      return createArome(serverResponse.data.initimeList);
    case 'GFS':
      return createGfs(serverResponse.data.initimeList);
    case 'GFS_ACC':
      return createGfsAcc(serverResponse.data.initimeList);
    case 'GFS_HISTORY':
      return createGfsHistory(
        serverResponse.data.timestepList,
        serverResponse.data.timestepMonthList
      );
    case 'GPM':
      return createGpm(serverResponse.data);
    case 'GPM2':
      return createGpm2(serverResponse.data);
    case 'ALADIN':
      return createAladin(serverResponse.data.initimeList);
    case 'LAMMA_ARW_3':
      return createLammaArw3(serverResponse.data.initimeList);
    case 'MOLOCH':
      return createMoloch(serverResponse.data.initimeList);
    case 'WRF_JAPAN_25':
      return createWrfJapan25(serverResponse.data.initimeList);
    case 'WRF_JAPAN_05':
      return createWrfJapan05(serverResponse.data.initimeList);
    case 'SHEVENINGEN':
      return createScheveningen(serverResponse.data.initimeList);
    case 'HARMONIE_AROME':
      return createHarmonieArome(serverResponse.data.initimeList);
    case 'AARHUS':
      return createAarhus(serverResponse.data.initimeList);
    case 'WRF_AARHUS_25':
      return createWrfAarhus25(serverResponse.data.initimeList);
    case 'WRF_AARHUS_05':
      return createWrfAarhus05(serverResponse.data.initimeList);
    case 'NWMED_MARSEILLE':
      return createNwmedMarseille(serverResponse.data.initimeList);
    case 'ERA5_MARSEILLE':
      return createEra5Marseille(serverResponse.data.initimeList);
    case 'RFE':
      return createRfe(serverResponse.data);
    case 'ECMWF':
      return createEcmwf(serverResponse.data.initimeList);
    default:
      throw Error('Unsupported model'); // todo handle this error
  }
}
