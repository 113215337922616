import {
  HIGH_CLOUD_COVER,
  LOW_CLOUD_COVER,
  MEDIUM_CLOUD_COVER,
  PRESSURE,
  RAINFALL_1HOUR,
  RELATIVE_HUMIDITY,
  TEMPERATURE,
  TOTAL_CLOUD_COVER,
  WIND_GUST,
  WIND_KTS,
} from '../../weather-layers/weather.layers';
import {
  createTimestepListForecast,
  modelTemplate,
} from '../weather-model.factory.utils';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createWrfJapan05(initimeList: number[]): WeatherModel {
  const ts = createTimestepListForecast(initimeList, 48, 3600, '+00:00');
  const defaultDateRng: { from: number; to: number } = {
    from: ts[0].timesteps[0],
    to: ts[0].timesteps[47],
  };
  const timestepType: TimestepType = { type: 'UNIX', interval: 3600 };

  const wrf05Layers: WeatherLayer[] = [
    {
      ...WIND_KTS,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...WIND_GUST,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...TEMPERATURE,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...TEMPERATURE,
      id: 'temperature_2m',
      label: 'Temperature (2m)',
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RELATIVE_HUMIDITY,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...PRESSURE,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...LOW_CLOUD_COVER,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...MEDIUM_CLOUD_COVER,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...HIGH_CLOUD_COVER,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...TOTAL_CLOUD_COVER,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
    {
      ...RAINFALL_1HOUR,
      timestepType,
      timestepList: ts,
      defaultDateRange: defaultDateRng,
    },
  ];

  return {
    ...modelTemplate,
    id: 'WRF_JAPAN_05',
    label: 'WRF Japan - 0.5',
    category: 'FORECAST',
    layers: wrf05Layers,
    initime: wrf05Layers[0].timestepList[0].initime,
    timestep: wrf05Layers[0].timestepList[0].timesteps[0],
    selectedLayerId: wrf05Layers[0].id,
    dateRange: defaultDateRng,
    bbox: [
      [135.4975, 38.5025],
      [144.0025, 38.5025],
      [144.0025, 31.4975],
      [135.4975, 31.4975],
      [135.4975, 38.5025],
    ],
  };
}
