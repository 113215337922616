import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FieldType, FormSpecific } from '3map-models';
import { MarkerItem } from '@trim-web-apps/project-core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { DropdownItem } from '@trim-web-apps/map3-ui';

@Component({
  selector: 'app-marker-style-selector',
  template: `
    <div class="wrapper" *ngIf="specific && markerItems">
      <div class="content">
        <div class="label">Bind markers on question</div>

        <ui-dropdown
          [itemList]="itemList"
          [itemActive]="itemActive"
          (itemSelected)="onQuestionSelected($event.source)"
          noneOptionLabel="None"
        />

        <app-marker-list
          [markerItems]="markerItems"
          (markerItemChange)="markerItemChange.emit($event)"
        ></app-marker-list>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        /*   display: none; */
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        padding: 10px;
      }

      .label {
        margin: 10px 0;
      }
    `,
  ],
})
export class MarkerStyleSelectorComponent implements OnChanges {
  @Input() markerStyleQuestion?: FieldType | null;
  @Input() specific?: FormSpecific;
  @Input() markerItems?: MarkerItem[];
  @Output() questionSelected: EventEmitter<FieldType | null>;
  @Output() markerItemChange: EventEmitter<{ base64: string; index: number }>;
  iconCaret = faCaretDown;

  itemList: DropdownItem<FieldType>[] = [];
  itemActive?: DropdownItem<FieldType> | null;

  constructor() {
    this.markerItems = [];
    this.questionSelected = new EventEmitter<FieldType | null>();
    this.markerItemChange = new EventEmitter<{
      base64: string;
      index: number;
    }>();
  }

  ngOnChanges(): void {
    if (!this.specific) return;
    this.itemList = this.specific.questions
      .filter((q) => q.type === 'LIST')
      .map((q) => ({ label: q.name, payload: q }));
    this.itemActive = this.markerStyleQuestion
      ? {
          label: this.markerStyleQuestion.name,
          payload: this.markerStyleQuestion,
        }
      : null;
  }

  onQuestionSelected(item: DropdownItem<FieldType> | null): void {
    this.questionSelected.emit(item ? item.payload : null);
  }
}
