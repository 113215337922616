import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { faAnglesLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SidenavData } from '../../+types/sidenav-data.type';

@Component({
  selector: 'app-logo-nav-item',
  template: `
    <div class="logo-nav-item-wrapper" [class.grey]="greyBg" *ngIf="data">
      <div class="row">
        <div class="logo">
          <!-- src="assets/3map-project/trim_white_simple.png" -->
          <img
            class="logo-full"
            [ngStyle]="{ display: data.opened ? 'block' : 'none' }"
            [src]="greyBg ? logoBlue : logoWhite"
            alt="logo"
          />
          <!-- src="assets/3map-project/trim_white_simple_sm.png" -->
          <img
            class="logo-sm"
            [ngStyle]="{ display: !data.opened ? 'block' : 'none' }"
            alt="logo"
            [src]="greyBg ? logoBlueSm : logoWhiteSm"
            (click)="toggleSidenavOpened.emit(!data.opened)"
          />
        </div>

        <div class="sidebar-toggle" *ngIf="data.opened">
          <fa-icon
            [icon]="toggleIcon"
            (click)="toggleSidenavOpened.emit(!data.opened)"
          ></fa-icon>
        </div>
      </div>

      <div class="row">
        <div class="project-name" *ngIf="data.opened">
          {{ data.project.name }}
        </div>
        <fa-icon [icon]="iconInfo" (click)="greyBg = !greyBg"></fa-icon>
      </div>
    </div>
  `,
  styles: [
    `
      .logo-nav-item-wrapper {
        padding: var(--spacing-3);
        margin-bottom: var(--spacing-2);
        background-color: var(--primary-color);
        color: var(--bg-color);
      }

      .grey {
        background-color: #eeeeee !important;
      }

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .logo {
        padding: 5px;
      }

      .logo-sm {
        cursor: pointer;
      }

      .logo img {
        height: 30px;
      }

      fa-icon {
        cursor: pointer;
      }

      .project-name {
        font-size: var(--font-size-2);
        /*color: var(--primary-color);*/
        color: var(--bg-color);
        padding: var(--spacing-2);
        font-weight: var(--font-weight-3);
      }

      .grey .project-name,
      .grey fa-icon {
        color: var(--primary-color);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoNavItemComponent {
  @Input() data?: SidenavData;
  @Output() toggleSidenavOpened = new EventEmitter();
  greyBg?: boolean;
  toggleIcon = faAnglesLeft;
  iconInfo = faInfoCircle;

  logoBlue = 'assets/3map-project/trim_blue_simple.png';
  logoBlueSm = 'assets/3map-project/trim_blue_simple_sm.png';
  logoWhite = 'assets/3map-project/trim_white_simple.png';
  logoWhiteSm = 'assets/3map-project/trim_white_simple_sm.png';
}
