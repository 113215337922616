import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from './project.reducer.map';
import { FEATURE_KEY } from './feature.key';
import * as fromProjectProps from './project-props';
import * as fromForm from './form';
import * as fromUser from './user';
import * as fromWebHook from './webhook';
import * as fromResource from './resource';

export const selectProjectState = createFeatureSelector<fromRoot.State>(
  FEATURE_KEY
);

export const selectState = () =>
  createSelector(selectProjectState, (state) => state);

// export const selectProject = () =>
//   createSelector(
//     fromProjectProps.selectState,
//     fromForm.selectState,
//     fromUser.selectState,
//     fromWebHook.selectState,
//     fromResource.selectState,
//     (projectProps, forms, users, webhooks, resource): fromRoot.State => {
//       return { projectProps, forms, users, webhooks, resource };
//     }
//   );

// export const selectProject = () =>
//   createSelector(
//     fromProjectProps.selectName(),
//     fromForm.selectFormList(),
//     fromUser.selectUsers(),
//     fromWebHook.selectWebhooks(),
//     fromResource.selectResourceList(),
//     (name, formList, userList, webHookList, resourceList): Project | null => {
//       return name
//         ? { name, formList, userList, webHookList, resourceList }
//         : null;
//     }
//   );

// export const selectProjectState = (featureName: string) => {
//   return createFeatureSelector<fromRoot.State>(featureName)
// };
