export type MapStyleName = 'Light' | 'Dark' | 'Grey' | 'Satellite';
export type MapStyle = { title: MapStyleName; uri: string };

export const LIGHT: MapStyle = {
  title: 'Light',
  uri: 'https://api.maptiler.com/maps/be3d3012-0e29-4d6d-9272-6665819ba049/style.json?key=55WxU7DRslwPdbdvJvYQ',
};

export const DARK: MapStyle = {
  title: 'Dark',
  uri: 'https://api.maptiler.com/maps/84704e0b-7a9b-49ef-8156-6ee8a3a2c9db/style.json?key=55WxU7DRslwPdbdvJvYQ',
};

export const GREY: MapStyle = {
  title: 'Grey',
  uri: 'https://api.maptiler.com/maps/9d44fa64-d170-4c08-8362-fa3061a27c8f/style.json?key=55WxU7DRslwPdbdvJvYQ',
};

export const SATELLITE: MapStyle = {
  title: 'Satellite',
  uri: 'https://api.maptiler.com/maps/3a3a74f4-925c-4e2a-b00b-a68ea65947d1/style.json?key=55WxU7DRslwPdbdvJvYQ',
};
