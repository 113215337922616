import { RAINFALL_1DAY_RFE } from '../../weather-layers/weather.layers';
import {
  createTimestepListGpm,
  modelTemplate,
} from '../weather-model.factory.utils';
import { TimestepType, WeatherLayer, WeatherModel } from '../../types';

export function createRfe(serverResponse: any): WeatherModel {
  const tsDay = serverResponse.timestepList;

  const timestepListDay = createTimestepListGpm(tsDay, false);
  const timestepTypeDay: TimestepType = { type: 'DAYS', interval: 1 };
  const defaultDateRangeDay = {
    from: timestepListDay[0].timesteps[
      timestepListDay[0].timesteps.length - 30
    ],
    to: timestepListDay[0].timesteps[timestepListDay[0].timesteps.length - 1],
  };

  const layers: WeatherLayer[] = [
    // {
    //   ...RAINFALL_1DAY,
    //   timestepType: timestepTypeDay,
    //   timestepList: timestepListDay,
    //   defaultDateRange: defaultDateRangeDay,
    // },
    {
      ...RAINFALL_1DAY_RFE,
      timestepType: timestepTypeDay,
      timestepList: timestepListDay,
      defaultDateRange: defaultDateRangeDay,
    },
  ];

  return {
    ...modelTemplate,
    id: 'RFE',
    label: 'RFE',
    category: 'MONITORING',
    layers: layers,
    initime: layers[0].timestepList[0].initime,
    timestep: defaultDateRangeDay.to,
    selectedLayerId: layers[0].id,
    dateRange: defaultDateRangeDay,
    bbox: [
      [-20.05, 40.05],
      [55.05, 40.05],
      [55.05, -40.05],
      [-20.05, -40.05],
      [-20.05, 40.05],
    ],
    info: {
      resolution: '0.1°',
      coverage: 'Africa',
      url: 'https://earlywarning.usgs.gov/fews/product/59#documentation',
      credits: 'USGS FEWS NET',
    },
  };
}
