import * as mapboxgl from 'mapbox-gl';
import { CanvasSource } from 'mapbox-gl';
import * as uuid from 'uuid';
import {
  bicubicInterpolation,
  bilinearInterpolation,
  nearestValue,
} from './rasterInterpolation';
import { rotate } from './legacy/util';
import { Arrow, WindBarb } from './arrows';
import { WeatherLayer } from '@trim-web-apps/weather-models';

export const NO_VALUE = 9999;

export class GeotiffDrawer {
  private ctx: CanvasRenderingContext2D;
  private interpolateFn: (ds: number[][], x: number, y: number) => number =
    nearestValue; // bicubicInterpolation
  tiffImage: any;
  imageData: any;

  constructor(public canvas: HTMLCanvasElement, public map: mapboxgl.Map) {
    const ctx = this.canvas.getContext('2d');
    if (ctx === null) {
      throw Error('[geotiff-drawer] Canvas ctx is null');
    }

    this.canvas.id = uuid.v4();
    this.adjustCanvasSize();
    this.ctx = ctx;
    this.ctx.globalAlpha = 1;
  }

  private static getColor(value: number, layer: WeatherLayer): number[] {
    if (value * layer.conversionFactor >= NO_VALUE) {
      // todo this can be included in legend.values array as 'transparent'
      return [0, 0, 0, 0];
    }
    let colorIndex = 0;
    while (value * layer.conversionFactor >= layer.legend.values[colorIndex]) {
      colorIndex++;
    }
    return layer.legend.rgb[colorIndex - 1];
  }

  async setTiffImage(tiff: any): Promise<void> {
    const start = Date.now();
    const imageData = await this.getGeotiffRaster(tiff, 1);
    // console.log(`tiff read: ${Date.now() - start} ms`)
    if (isNaN(imageData[0][0][0])) {
      this.tiffImage = null;
      this.imageData = null;
      throw Error('Invalid tiff data');
    }
    this.tiffImage = tiff;
    this.imageData = imageData;
  }

  reset(): void {
    this.clearCanvas();
    this.tiffImage = null;
    this.imageData = null;
  }

  tiffImageExists(): boolean {
    return this.tiffImage !== null && this.tiffImage !== undefined;
  }

  getGeoTransform(): number[] {
    const tiePoint = this.tiffImage.getTiePoints()[0];
    const pixelScale = this.tiffImage.getFileDirectory().ModelPixelScale;
    return [tiePoint.x, pixelScale[0], 0, tiePoint.y, 0, -pixelScale[1]];
  }

  draw(layer: WeatherLayer, windSymbol?: Arrow | WindBarb): void {
    if (this.isTiffOutOfMap() || !windSymbol) {
      return;
    }

    this.adjustCanvasSize();
    this.repositionCanvasLayer();
    this.clearCanvas();
    this.getCanvasSource().play();

    if (this.imageData.length === 1) {
      this.printFillBoxDataset(this.imageData[0], layer);
    } else {
      if (windSymbol instanceof Arrow && windSymbol.fillBackground) {
        const windSpeeds: number[][] = [];

        for (let i = 0; i < this.imageData[0].length; i++) {
          windSpeeds[i] = [];
          for (let j = 0; j < this.imageData[0][0].length; j++) {
            // tslint:disable-next-line:max-line-length
            windSpeeds[i][j] = Math.sqrt(
              Math.pow(this.imageData[0][i][j], 2) +
                Math.pow(this.imageData[1][i][j], 2)
            );
          }
        }
        this.printFillBoxDataset(windSpeeds, layer);
      }
      this.printWindDataset(windSymbol, this.imageData, layer);
    }
    this.getCanvasSource().pause();
  }

  setInterpolate(interpolateType: string) {
    if (interpolateType === 'nearestValue') {
      this.interpolateFn = nearestValue;
    } else if (interpolateType === 'bilinear') {
      this.interpolateFn = bilinearInterpolation;
    } else {
      this.interpolateFn = bicubicInterpolation;
    }
  }

  isLngLatInsideImage(lng: number, lat: number): boolean {
    return this.isPointInsideImage(this.translateToPoint(lng, lat));
  }

  isPointInsideImage(point: { x: number; y: number }): boolean {
    return (
      point.x >= 0 &&
      point.x < this.imageData[0][0].length &&
      point.y >= 0 &&
      point.y < this.imageData[0].length
    );
  }

  getPixelValue(lng: number, lat: number, conversionFactor: number): number[] {
    const point = this.translateToPoint(lng, lat);
    if (!this.isPointInsideImage(point)) {
      return [];
    }
    if (this.imageData.length === 1) {
      return [this.imageData[0][point.y][point.x] * conversionFactor];
    } else {
      const wind = this.getWindValues(point.x, point.y);
      return [wind.direction, wind.speed * conversionFactor];
    }
  }

  clearCanvas(): void {
    this.getCanvasSource().play();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.getCanvasSource().pause();
  }

  translateToPoint(lng: number, lat: number): { x: number; y: number } {
    const geoTransform = this.getGeoTransform();
    return {
      x: Math.floor((lng - geoTransform[0]) / geoTransform[1]),
      y: Math.floor((lat - geoTransform[3]) / geoTransform[5]),
    };
  }

  private adjustCanvasSize(): void {
    this.canvas.height = this.map.getContainer().clientHeight;
    this.canvas.width = this.map.getContainer().clientWidth;
  }

  private isTiffOutOfMap(): boolean {
    const height = this.tiffImage.getHeight();
    const width = this.tiffImage.getWidth();
    const bb = this.visibleBoundingBox(height, width, this.getGeoTransform());
    return (
      bb.topLeft.x > this.canvas.width ||
      bb.bottomRight.x < 0 ||
      bb.bottomRight.y < 0 ||
      bb.topLeft.y > this.canvas.height
    );
  }

  private repositionCanvasLayer() {
    const bounds = this.map.getBounds();
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    const source: mapboxgl.CanvasSource = this.map.getSource(
      this.canvas.id
    ) as mapboxgl.CanvasSource;

    source.setCoordinates([
      [southWest.lng, northEast.lat],
      [northEast.lng, northEast.lat],
      [northEast.lng, southWest.lat],
      [southWest.lng, southWest.lat],
    ]);
  }

  private getCanvasSource(): CanvasSource {
    return this.map.getSource(this.canvas.id) as CanvasSource;
  }

  private getGeotiffRaster(
    image: any,
    unitConv: number
  ): Promise<number[][][]> {
    const height = image.getHeight();
    const width = image.getWidth();
    return new Promise<number[][][]>((resolve: any, reject: any) => {
      let data: any;
      image.readRasters().then((rasters: any) => {
        data = new Array<number>(rasters.length);
        for (let i = 0; i < rasters.length; i++) {
          data[i] = new Array(height);
          for (let j = 0; j < height; j++) {
            data[i][j] = new Array(width);
            for (let k = 0; k < width; k++) {
              data[i][j][k] = rasters[i][k + j * width] * unitConv;
            }
          }
        }
        if (data) {
          resolve(data);
        }
        reject(data);
      });
    });
  }

  private visibleBoundingBox(
    height: number,
    width: number,
    gt: number[]
  ): { topLeft: mapboxgl.Point; bottomRight: mapboxgl.Point } {
    const bounds = this.map.getBounds();

    // Get the the pixel that are currently visible on the map (+/-1 to avoid white spaces)
    let top = Math.floor((bounds.getNorth() - gt[3]) / gt[5]) - 1;
    let left = Math.floor((bounds.getWest() - gt[0]) / gt[1]) - 1;
    let bottom = Math.floor((bounds.getSouth() - gt[3]) / gt[5]) + 1;
    let right = Math.floor((bounds.getEast() - gt[0]) / gt[1]) + 1;

    // fix the upper and lower bound
    if (top < 0) {
      top = 0;
    } else if (top > height) {
      top = height;
    }
    if (bottom < 0) {
      bottom = 0;
    } else if (bottom > height) {
      bottom = height;
    }
    if (left < 0) {
      left = 0;
    } else if (left > width) {
      left = width;
    }
    if (right < 0) {
      right = 0;
    } else if (right > width) {
      right = width;
    }

    // calculate the pixels
    const pixelBounds = {
      topLeft: this.map.project({
        lng: gt[0] + left * gt[1] + top * gt[2],
        lat: gt[3] + left * gt[4] + top * gt[5],
      }),
      bottomRight: this.map.project({
        lng: gt[0] + right * gt[1] + bottom * gt[2],
        lat: gt[3] + right * gt[4] + bottom * gt[5],
      }),
    };

    if (pixelBounds.bottomRight.x > this.map.project(bounds.getSouthEast()).x) {
      pixelBounds.bottomRight.x = this.map.project(bounds.getSouthEast()).x;
    }
    if (pixelBounds.bottomRight.y > this.map.project(bounds.getSouthEast()).y) {
      pixelBounds.bottomRight.y = this.map.project(bounds.getSouthEast()).y;
    }

    return pixelBounds;
  }

  private printFillBoxDataset(
    gtiffData: number[][],
    layer: WeatherLayer
  ): void {
    const gt = this.getGeoTransform();
    const width = this.canvas.width;
    const height = this.canvas.height;
    const imgData = this.ctx.createImageData(width, height);
    const quadSide = 10;
    const numberQuadX = Math.ceil(width / quadSide);
    // const numberQuadY = Math.floor(height / quadSide)
    const numberQuadY = Math.ceil(height / quadSide);

    for (let quadx = 0; quadx < numberQuadX; quadx++) {
      for (let quady = 0; quady < numberQuadY; quady++) {
        const lngLatTopLeft = this.map.unproject([
          quadSide * quadx,
          quadSide * quady,
        ]);
        const lngLatBottomRight = this.map.unproject([
          quadSide * (quadx + 1),
          quadSide * (quady + 1),
        ]);
        const lngIncrement =
          (lngLatBottomRight.lng - lngLatTopLeft.lng) / quadSide;
        const latIncrement =
          (lngLatBottomRight.lat - lngLatTopLeft.lat) / quadSide;
        for (let x = 0; x < quadSide; x++) {
          // Consider only screen pixels before the end of the screen on the right
          if (quadx * quadSide + x < width) {
            for (let y = 0; y < quadSide; y++) {
              const pxx =
                (lngLatTopLeft.lng - gt[1] / 2 + x * lngIncrement - gt[0]) /
                gt[1];
              const pxy =
                (lngLatTopLeft.lat - gt[5] / 2 + y * latIncrement - gt[3]) /
                gt[5];
              if (
                pxx >= 0 &&
                pxy >= 0 &&
                pxx < gtiffData[0].length - 1 &&
                pxy < gtiffData.length - 1
              ) {
                const colorArray = GeotiffDrawer.getColor(
                  this.interpolateFn(gtiffData, pxx, pxy),
                  layer
                );
                const i =
                  (x +
                    y * width +
                    quady * width * quadSide +
                    quadx * quadSide) *
                  4;
                imgData.data[i] = colorArray[0];
                imgData.data[i + 1] = colorArray[1];
                imgData.data[i + 2] = colorArray[2];
                imgData.data[i + 3] = colorArray[3];
              }
            }
          }
        }
      }
    }
    this.ctx.putImageData(imgData, 0, 0);
  }

  private printWindDataset(
    windSymbol: Arrow | WindBarb,
    gtiffData: number[][][],
    layer: WeatherLayer
  ): void {
    const gt = this.getGeoTransform();
    const maxArrowNum = 60;
    const bb = this.visibleBoundingBox(
      gtiffData[0].length,
      gtiffData[0][0].length,
      gt
    );
    // const getWindColor: (value: number) => number[] = getColorFunction(layer)
    let next = new mapboxgl.Point(0, 0);
    let x = Math.round(bb.topLeft.x);
    let y = Math.round(bb.topLeft.y);

    // find pixel's width
    const pixelA = this.map.project([
      gt[0] + Math.round(gtiffData[0].length / 2) * gt[1],
      gt[3] + Math.round(gtiffData[0][0].length / 2) * gt[5],
    ]);
    const pixelB = this.map.project([
      gt[0] + (Math.round(gtiffData[0].length / 2) + 1) * gt[1],
      gt[3] + (Math.round(gtiffData[0][0].length / 2) + 1) * gt[5],
    ]);

    const drawOffsetX = (pixelB.x - pixelA.x) / 2;
    const drawOffsetY = (pixelB.y - pixelA.y) / 2;

    let skip = 1;
    const max =
      (this.canvas.width / windSymbol.height) * 0.5 >= maxArrowNum
        ? maxArrowNum
        : Math.floor((this.canvas.width / windSymbol.height) * 0.5);
    const num = this.canvas.width / (pixelB.x - pixelA.x);

    if (num > max) {
      while (num / skip > max) {
        skip = skip * 2;
      }
    }

    while (x < bb.bottomRight.x) {
      y = Math.round(bb.topLeft.y);
      while (y < bb.bottomRight.y) {
        const lnglat = this.map.unproject([x, y]);
        // find which raster's pixel cover the specified lnglat
        const rasterx =
          Math.floor(Math.round((lnglat.lng - gt[0]) / gt[1]) / skip) * skip;
        const rastery =
          Math.floor(Math.round((lnglat.lat - gt[3]) / gt[5]) / skip) * skip;

        // find the next x/y pixel relative to the next raster pixel
        next = this.map.project([
          gt[0] + (rasterx + skip) * gt[1],
          gt[3] + (rastery + skip) * gt[5],
        ]);

        if (
          rasterx >= 0 &&
          rastery >= 0 &&
          rasterx < gtiffData[0][0].length - 1 &&
          rastery < gtiffData[0].length - 1
        ) {
          const wind = this.getWindValues(rasterx, rastery);
          if (windSymbol instanceof Arrow && wind.speed < 999) {
            // const colorArray = getWindColor(wind.speed)
            const colorArray = GeotiffDrawer.getColor(wind.speed, layer);
            const color = windSymbol.nocolor
              ? '#000000'
              : 'rgba(' +
                colorArray[0] +
                ', ' +
                colorArray[1] +
                ', ' +
                colorArray[2] +
                ', ' +
                colorArray[3] +
                ')';
            this.drawArrow(
              windSymbol,
              x + drawOffsetX,
              y + drawOffsetY,
              wind.direction,
              color
            );
          } else {
            this.drawBarb(windSymbol, x, y, wind.speed, wind.direction);
          }
        }
        y = next.y;
      }
      x = next.x;
    }
  }

  private drawArrow(
    windSymbol: Arrow | WindBarb,
    xCenter: number,
    yCenter: number,
    angle: number,
    color: string
  ): void {
    if (color !== 'transparent' && windSymbol instanceof Arrow) {
      const height = windSymbol.height;
      const headHeight = windSymbol.headHeight;
      const tailWidth = windSymbol.tailWidth;
      const tailHeadOffset = windSymbol.tailHeadOffset;

      // punta della freccia
      const p1 = rotate(xCenter, yCenter, xCenter, yCenter - height / 2, angle);
      // angolo a destra del triangolo della freccia
      const p2 = rotate(
        xCenter,
        yCenter,
        xCenter + windSymbol.width / 2,
        yCenter - height / 2 + headHeight,
        angle
      );
      // angolo destra di congiunzione tra rettangolo e triangolo
      const p3 = rotate(
        xCenter,
        yCenter,
        xCenter + tailWidth / 2,
        yCenter - height / 2 + headHeight - tailHeadOffset,
        angle
      );
      // angolo in basso a destra
      const p4 = rotate(
        xCenter,
        yCenter,
        xCenter + tailWidth / 2,
        yCenter + height / 2,
        angle
      );
      // angolo in basso a sinistra
      const p5 = rotate(
        xCenter,
        yCenter,
        xCenter - tailWidth / 2,
        yCenter + height / 2,
        angle
      );
      // angolo sinistra di congiunzione tra rettangolo e triangolo
      const p6 = rotate(
        xCenter,
        yCenter,
        xCenter - tailWidth / 2,
        yCenter - height / 2 + headHeight - tailHeadOffset,
        angle
      );
      // angolo a sinistra della freccia
      const p7 = rotate(
        xCenter,
        yCenter,
        xCenter - windSymbol.width / 2,
        yCenter - height / 2 + headHeight,
        angle
      );

      this.ctx.strokeStyle = windSymbol.border ? '#000000' : color;
      this.ctx.beginPath();
      this.ctx.moveTo(p1.x, p1.y);
      this.ctx.lineTo(p2.x, p2.y);
      this.ctx.lineTo(p3.x, p3.y);
      this.ctx.lineTo(p4.x, p4.y);
      this.ctx.lineTo(p5.x, p5.y);
      this.ctx.lineTo(p6.x, p6.y);
      this.ctx.lineTo(p7.x, p7.y);
      this.ctx.closePath();
      this.ctx.stroke();
      this.ctx.fillStyle = color;
      this.ctx.fill();
    }
  }

  private drawBarb(
    windSymbol: Arrow | WindBarb,
    xCenter: number,
    yCenter: number,
    speed: number,
    angle: number
  ): void {
    if (windSymbol instanceof WindBarb) {
      const height = windSymbol.height;
      const width = windSymbol.width;
      const segHeight = windSymbol.segHeight;
      const numSeg = windSymbol.numSeg;

      const longLines = Math.floor(speed / windSymbol.speedStepOn);
      const lineBegin = rotate(
        xCenter,
        yCenter,
        xCenter,
        yCenter - height / 2 - segHeight / 2,
        angle
      );
      const lineEnd = rotate(
        xCenter,
        yCenter,
        xCenter,
        yCenter + height / 2,
        angle
      );

      this.ctx.strokeStyle = '#000000';
      this.ctx.beginPath();
      this.ctx.moveTo(lineBegin.x, lineBegin.y);
      this.ctx.arc(
        lineBegin.x,
        lineBegin.y,
        segHeight / 2,
        ((angle + 90) * Math.PI) / 180,
        ((angle + 450) * Math.PI) / 180
      );
      this.ctx.lineTo(lineEnd.x, lineEnd.y);

      if (longLines === 0) {
        const start = rotate(
          xCenter,
          yCenter,
          xCenter,
          yCenter - height / 2 + segHeight * (numSeg - 1),
          angle
        );
        const end = rotate(
          xCenter,
          yCenter,
          xCenter - width / 2,
          yCenter - height / 2 + segHeight * (numSeg - 1) + segHeight / 2,
          angle
        );
        this.ctx.moveTo(start.x, start.y);
        this.ctx.lineTo(end.x, end.y);
      } else {
        let numSegCount = numSeg;
        for (let i = 0; i < longLines; i++, numSegCount--) {
          const start = rotate(
            xCenter,
            yCenter,
            xCenter,
            yCenter - height / 2 + segHeight * numSegCount,
            angle
          );
          const end = rotate(
            xCenter,
            yCenter,
            xCenter - width,
            yCenter - height / 2 + (segHeight * numSegCount + segHeight),
            angle
          );
          this.ctx.moveTo(start.x, start.y);
          this.ctx.lineTo(end.x, end.y);
        }
        if (Math.floor(speed / numSeg) % 2) {
          const start = rotate(
            xCenter,
            yCenter,
            xCenter,
            yCenter - height / 2 + segHeight * numSeg,
            angle
          );
          const end = rotate(
            xCenter,
            yCenter,
            xCenter - width / 2,
            yCenter - height / 2 + segHeight * numSeg + segHeight / 2,
            angle
          );
          this.ctx.moveTo(start.x, start.y);
          this.ctx.lineTo(end.x, end.y);
        }
      }
      this.ctx.stroke();
    }
  }

  private getWindValues(
    x: number,
    y: number
  ): { speed: number; direction: number } {
    const uComp = this.imageData[0][y][x];
    const vComp = this.imageData[1][y][x];
    const windSpeed = Math.sqrt(Math.pow(uComp, 2) + Math.pow(vComp, 2));
    let dir = (Math.acos(vComp / windSpeed) * 360) / (2 * Math.PI);

    if (uComp < 0) {
      dir = 360 - dir;
    }

    return {
      speed: windSpeed,
      direction: dir,
    };
  }
}
