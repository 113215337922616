import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { WeatherActions, WeatherSelectors } from '../../+state';
import { map, Observable } from 'rxjs';
import { TimestepType, TimeUnit } from '@trim-web-apps/weather-models';

export type DatePickerInlineData = {
  from: {
    tsList: TimeUnit[];
    selected: TimeUnit;
    tsType: TimestepType;
  };
  to: {
    tsList: TimeUnit[];
    selected: TimeUnit;
    tsType: TimestepType;
  };
};

@Component({
  selector: 'weather-core-date-range-picker-inline',
  template: `
    <ng-container *ngIf="data$ | async as data">
      <div class="range-info">
        <div class="label">Data available range</div>
        <div class="range-values">
          <div class="em">{{ data.from.tsList[0].label }}</div>
          <div class="em">
            {{ data.from.tsList[data.from.tsList.length - 1].label }}
          </div>
        </div>
      </div>

      <div class="range-info">
        <div class="label">Current range</div>
      </div>

      <div class="picker-wrapper">
        <div class="label">From</div>
        <div class="picker">
          <weather-core-datetime-picker-inline
            [data]="data.from"
            [showHeader]="true"
            [utcOffset]="utcOffset"
            (timeUnitChange)="onFromChange($event, data)"
          >
          </weather-core-datetime-picker-inline>
        </div>
      </div>
      <div class="picker-wrapper">
        <div class="label">To</div>
        <div class="picker">
          <weather-core-datetime-picker-inline
            [data]="data.to"
            [showHeader]="true"
            [utcOffset]="utcOffset"
            (timeUnitChange)="onToChange($event, data)"
          >
          </weather-core-datetime-picker-inline>
        </div>
      </div>

      <weather-core-date-index-test
        [data]="data"
        [tsType]="tsType"
      ></weather-core-date-index-test>
    </ng-container>
  `,
  styles: [
    `
      .range-info .label {
        color: var(--primary-color);
      }

      .range-values {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
      }

      .em {
        font-weight: 500;
      }

      .picker-wrapper {
        display: flex;
        align-items: stretch;
        padding: 10px 0;
      }

      .picker-wrapper .label {
        width: 60px;
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class DateRangePickerInlineComponent implements OnInit {
  @Input() weatherModelId?: string;
  @Input() tsType?: TimestepType;
  @Input() utcOffset?: string;

  data$?: Observable<DatePickerInlineData | null>;

  constructor(private store: Store) {}
  ngOnInit(): void {
    if (!this.weatherModelId) return;

    this.data$ = this.store
      .select(WeatherSelectors.selectDatePickerInlineData(this.weatherModelId))
      .pipe(
        map((datepickerData) => {
          if (!datepickerData || !this.tsType) return null;
          const from = {
            tsList: datepickerData.tsList,
            selected: datepickerData.from,
            tsType: this.tsType,
          };

          const to = {
            tsList: datepickerData.tsList,
            selected: datepickerData.to,
            tsType: this.tsType,
          };
          return { from, to };
        })
      );
  }

  onFromChange(timeUnit: TimeUnit, data: DatePickerInlineData): void {
    if (!this.weatherModelId) return;
    this.store.dispatch(
      WeatherActions.setDateRange({
        modelId: this.weatherModelId,
        dateRange: { from: timeUnit.apiValue, to: data.to.selected.apiValue },
      })
    );
  }

  onToChange(timeUnit: TimeUnit, data: DatePickerInlineData): void {
    if (!this.weatherModelId) return;
    this.store.dispatch(
      WeatherActions.setDateRange({
        modelId: this.weatherModelId,
        dateRange: { from: data.from.selected.apiValue, to: timeUnit.apiValue },
      })
    );
  }
}
