import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of, tap } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ApiService } from '@trim-web-apps/api3map';
import { ProjectUser } from '3map-models';

@Component({
  selector: 'app-user-search',
  template: `
    <div class="title" (click)="userControl.reset()">Add Users</div>
    <div class="search">
      <input
        type="text"
        [formControl]="userControl"
        placeholder="Search username"
      />
      <div class="search-result-wrapper">
        <div
          class="result-item"
          *ngFor="let search of searchResult$ | async"
          (click)="onUsernameSelected(search.username)"
        >
          <div class="label">{{ search.username }}</div>
          <div class="already-in-project" *ngIf="search.alreadyInProject">
            (added)
          </div>
        </div>
      </div>
    </div>
    <div class="added-username-list" (click)="userControl.reset()">
      <div class="username" *ngFor="let user of usernameList">
        <div class="label">{{ user }}</div>
        <core-double-confirm-inline
          (confirm)="removeUsername(user)"
        ></core-double-confirm-inline>
      </div>
    </div>
    <ui-btn
      label="Next"
      [disabled]="usernameList.length === 0"
      (btnClick)="onNext()"
    />
  `,
  styles: [
    `
      :host input {
        width: 350px;
      }

      .search {
        margin: 20px 0;
      }

      .search-result-wrapper {
        border: 1px solid #e7e7e7;
        position: absolute;
        background-color: #ffffff;
        z-index: 3;
        width: 350px;
      }

      .result-item {
        padding: 5px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      .result-item:hover {
        background-color: var(--hover);
      }

      .added-username-list {
        height: 150px;
        overflow: auto;
        margin: 20px 0;
      }

      .username {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
      }

      .already-in-project {
        font-size: 0.8em;
        color: var(--border);
      }
    `,
  ],
})
export class UserSearchComponent {
  @Input() existingUserList: ProjectUser[] = [];
  @Output() userList = new EventEmitter<ProjectUser[]>();
  userControl: FormControl<string | null>;
  searchResult$: Observable<{ username: string; alreadyInProject: boolean }[]>;
  usernameList: string[] = [];

  constructor(private api: ApiService) {
    this.userControl = new FormControl<string | null>('');
    this.searchResult$ = this.userControl.valueChanges.pipe(
      debounceTime(150),
      switchMap((inputValue) => {
        return inputValue !== null && inputValue.length > 0
          ? this.api.getUserList(inputValue)
          : of([]);
      }),
      map((userList) => {
        return userList.map((username) => {
          const added = [
            ...this.existingUserList.map((user) => user.username),
            ...this.usernameList,
          ];
          return {
            username,
            alreadyInProject: added.includes(username),
          };
        });
      }),
      tap((result) => console.log(result))
    );
  }

  onUsernameSelected(username: string): void {
    const userInProject =
      this.existingUserList.findIndex((u) => u.username === username) > -1;
    if (!userInProject)
      this.usernameList = Array.from(new Set([...this.usernameList, username]));
  }

  removeUsername(username: string): void {
    this.usernameList = this.usernameList.filter((user) => user !== username);
  }

  onNext(): void {
    const userList: ProjectUser[] = this.usernameList.map((username) => {
      return {
        username,
        role: 'USER',
        formList: [],
        preferences: {},
      };
    });

    if (userList.length > 0) this.userList.emit(userList);
  }
}
