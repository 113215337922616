import * as mapboxgl from 'mapbox-gl';
import { MapConfig } from './MapConfig';
import { zoomControl } from '../set-zoom-plugin/set-zoom-plugin';
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher';
import { RulerControl } from 'mapbox-gl-controls';
import { DARK, GREY, LIGHT, SATELLITE } from '../map-utils/map.styles';

export function createMap(
  mapContainer: string | HTMLElement,
  config: MapConfig
): mapboxgl.Map {
  (mapboxgl as any).accessToken =
    'pk.eyJ1Ijoic2YzMSIsImEiOiJjam40bTEzODIwNXhkM3BtaXVhNnpzY3JrIn0.2KWgl9Ssf9RKhqhLA_dWNw';
  const map: mapboxgl.Map = new mapboxgl.Map({
    container: mapContainer,
    style: GREY.uri,
    // style: 'mapbox://styles/mapbox/streets-v9',
    zoom: config.zoom,
    minZoom: 2.5,
    center: config.center,
    touchZoomRotate: true,
    attributionControl: false,
    dragRotate: false,
    logoPosition: 'top-right',
  });

  if (config.maxBounds) {
    map.setMaxBounds(config.maxBounds);
  }

  map.touchZoomRotate.disableRotation();
  // map.scrollZoom.setWheelZoomRate(0.5);

  config.plugins.forEach((plugin) => {
    switch (plugin) {
      case 'SCALE':
        return map.addControl(
          new mapboxgl.ScaleControl(),
          config.controlPosition
        );
      case 'RULER':
        return map.addControl(
          new RulerControl({}) as any,
          config.controlPosition
        );
      case 'ZOOM':
        return map.addControl(zoomControl, config.controlPosition);
      case 'NAVIGATION':
        return map.addControl(
          new mapboxgl.NavigationControl({
            showCompass: false,
            showZoom: true,
          }),
          config.controlPosition
        );
      case 'ATTRIBUTION':
        return map.addControl(
          new mapboxgl.AttributionControl({ compact: true }),
          config.controlPosition
        );
      case 'GEOLOCATE':
        return map.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: { enableHighAccuracy: true },
            trackUserLocation: true,
          }),
          config.controlPosition
        );
      case 'SWITCHER':
        return map.addControl(
          new MapboxStyleSwitcherControl([GREY, LIGHT, DARK, SATELLITE]),
          config.controlPosition
        );
      default:
        console.error(`[ MapBuilder ] Unsupported plugin: ${plugin}`);
        return map;
    }
  });
  return map;
}
