import { createSelector } from '@ngrx/store';
import { notNullOrUndefined } from '@trim-web-apps/core';
import { selectAppAdminState } from '../../admin.reducer.map';

// export const selectState = createFeatureSelector<State>(ADMIN_LINK_FEATURE_KEY);
export const selectState = () =>
  createSelector(selectAppAdminState(), (state) => state.links);

export const selectLinks = () =>
  createSelector(selectState(), (state) =>
    Object.keys(state.entities)
      .map((k) => state.entities[k])
      .filter(notNullOrUndefined)
  );
