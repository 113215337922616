import { Component, Input } from '@angular/core';
import { Record } from '3map-models';

@Component({
  selector: 'app-invalid-records[records]',
  template: `
    <ng-container *ngIf="records.length > 0">
      <p>Some Records are not compatible with new Project.</p>
      <p>Conflict found: {{ records.length }}</p>
      <cdk-virtual-scroll-viewport itemSize="50" class="invalid-record-list">
        <div class="record-wrapper" *cdkVirtualFor="let rec of records">
          <ui-expansion-panel [collapsed]="true">
            <div header>RecordId: {{ rec.recordId }}</div>
            <div content>
              <pre class="json"> {{ rec | json }}</pre>
            </div>
          </ui-expansion-panel>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  `,
  styles: [
    `
      .invalid-record-list {
        height: 50vh;
        overflow: auto;
      }

      .record-wrapper {
        margin: var(--spacing-2) 0;
      }

      .json {
        user-select: text;
      }
    `,
  ],
})
export class InvalidRecordsComponent {
  @Input() records!: Record[];
}
