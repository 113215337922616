import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { NominatimResponse } from '@trim-web-apps/nominatim';

@Component({
  selector: 'app-search-nav-item',
  template: `
    <!--    <app-collapsed-nav-item-->
    <!--      [icon]="iconSearch"-->
    <!--      *ngIf="!sidebarOpen"-->
    <!--    ></app-collapsed-nav-item>-->

    <div class="search" *ngIf="sidebarOpen">
      <nominatim-search
        (location)="location.emit($event)"
        (zoomToLocation)="zoomToLocation.emit()"
      ></nominatim-search>
    </div>
  `,
  styles: [
    `
      .search {
        margin-top: 10px;
        background-color: #eeeeee;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 5px 15px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchNavItemComponent {
  @Input() sidebarOpen: boolean = false;
  @Output() location: EventEmitter<NominatimResponse | null> =
    new EventEmitter();
  @Output() zoomToLocation: EventEmitter<void> = new EventEmitter<void>();
  iconSearch = faMagnifyingGlass;
}
