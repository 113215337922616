import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MarkerItem } from '@trim-web-apps/project-core';

@Component({
  selector: 'app-marker-item',
  template: `
    <div class="item">
      <img (click)="file.click()" [src]="markerUrl" alt="Marker icon" />
      <div class="field-name">{{ markerItem.fieldName }}</div>
    </div>

    <input
      #file
      type="file"
      accept="image/*"
      (change)="onImageSelected(file.files)"
      style="display: none"
    />
    <canvas
      #canvas
      style="display: none; border: 1px solid red"
      width="150"
      height="150"
    ></canvas>
  `,
  styles: [
    `
      .item {
        display: flex;
        align-items: center;
        margin: 10px 0;
      }

      img {
        max-width: 40px;
        margin-right: 20px;
        cursor: pointer;
      }
    `,
  ],
})
export class MarkerItemComponent implements OnInit, AfterViewInit {
  @Input() markerItem!: MarkerItem;
  @Output()
  markerItemChanged: EventEmitter<MarkerItem> = new EventEmitter<MarkerItem>();
  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D | null | undefined;
  markerUrl: SafeUrl | undefined;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (!this.markerItem) {
      throw Error('Missing MarkerItem');
    }
    this.markerUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.markerItem.imageBase64
    );
  }

  ngAfterViewInit(): void {
    const ctx = this.canvas.nativeElement.getContext('2d');
    if (ctx) {
      this.ctx = ctx;
    }
  }

  async onImageSelected(files: FileList | null): Promise<void> {
    if (files === null || files.length === 0 || !this.ctx) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      console.error('not an image');
      return;
    }

    const imageFile = files[0];
    const base64 = (await this.readFile(imageFile)) as string;
    const image: HTMLImageElement = await new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.src = base64;
    });
    this.ctx.clearRect(0, 0, 150, 150);
    this.ctx.drawImage(image, 0, 0, 150, 150);

    this.markerItemChanged.emit({
      ...this.markerItem,
      imageBase64: this.ctx.canvas.toDataURL(),
    });
  }

  private async readFile(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  }
}
