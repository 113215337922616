import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Link } from '3map-models';
import { Action, createReducer, on } from '@ngrx/store';
import { REMOVE_LINK, UPSERT_LINK } from './link.actions';
import * as projectActions from '../project.actions';

export interface State extends EntityState<Link> {}

export const adapter = createEntityAdapter<Link>({
  selectId: (link) => link.name,
});

const initialState = adapter.getInitialState();

const webHookReducer = createReducer(
  initialState,
  on(projectActions.RESET, () => initialState),
  on(projectActions.RESTORE_PROJECT_STATE, (state, { projectState }) => ({
    ...projectState.links,
  })),
  on(projectActions.INIT_PROJECT, (state, { project }) => {
    return project.linkList ? adapter.setAll(project.linkList, state) : state;
  }),
  on(UPSERT_LINK, (state, { link }) => {
    return adapter.upsertOne(link, state);
  }),
  on(REMOVE_LINK, (state, { link }) => {
    return adapter.removeOne(link.name, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return webHookReducer(state, action);
}
