import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule],
  declarations: [SearchComponent],
  exports: [SearchComponent],
})
export class NominatimModule {}
